<template>
  <div class="row">
    <!-- errors -->
    <!-- /errors -->
    <div class="col-12">
      <div class="card card-warning card-outline">
        <!-- card-header -->
        <div class="card-header">
          <h3 class="card-title">Lista podmiotów <loading-icon v-if="loading"></loading-icon> &nbsp;</h3>

          <app-search :color="'btn-warning'" :post="post" @reload="reload" @post="post = $event"></app-search>
        </div>
        <!-- /.card-header -->
        <div class="card-body p-0">
          <div class="mailbox-controls clientitem top" style="height:90px;">
            <app-pages
              :filteredCount="filteredCount"
              :maxcount="maxcount"
              :colorActive="'#000'"
              :backgroundActive="'#ffc107'"
              :post="post"
              @post="post = $event"
              @reload="reload()"
            >
              <button type="button" class="btn btn-warning" @click="addlink()"><i class="fa fa-fw fa-plus"></i>Dodaj podmiot</button>
            </app-pages>
          </div>
          <!-- /buttons -->
          <!--list -->
          <div class="table-responsive mailbox-messages">
            <div class="alert alert-warning text-center" v-if="!users.length">Brak podmiotów</div>
            <div v-for="user in users" :key="user.id">
              <!-- userItem -->
              <app-user-item @edit="editlink(user.id)" @delete="deleteButton(user)" :user="user"></app-user-item>
            </div>
          </div>
          <!-- /list -->
        </div>
        <div class="card-footer p-0">
          <div class="mailbox-controls">
            <!-- switch buttons -->

            <app-pages
              style="padding-right:15px"
              :filteredCount="filteredCount"
              :maxcount="maxcount"
              :colorActive="'#000'"
              :backgroundActive="'#ffc107'"
              :post="post"
              @post="post = $event"
              @reload="reload()"
            ></app-pages>
          </div>
        </div>
      </div>
      <!-- editApp -->
      <!--
      <app-edit :user="user" @edit="edit=$event" :changed="changed" @changed="changed=$event"></app-edit>
      -->
      <app-edit v-if="modalShow" :show="modalShow" :user="editUser" @save="save()" @cancel="cancel()"></app-edit>
      <app-delete v-if="deleteShow" :show="deleteShow" :object="deleteUser" @cancel="cancel()" v-on:delete="del" :data="editModel"></app-delete>
    </div>
  </div>
</template>

<script>
import appEdit from './Edit.vue';
import appUserItem from './UserItem.vue';
import loadingIcon from '../modules/LoadingIcon.vue';
import appPages from '../modules/Pages.vue';
import appDelete from '../modules/Delete.vue';
import appSearch from '../modules/Search.vue';

import {setTimeout} from 'timers';
export default {
  components: {
    appEdit,
    appDelete,
    appUserItem,
    loadingIcon,
    appPages,
    appSearch
  },
  data() {
    return {
      modalAdd: '',
      action: this.$route.params.action,
      users: [],
      loading: false,
      changed: 0,
      editUser: {},
      deleteUser: {},
      edit: 0,
      modalShow: false,
      deleteShow: false,
      timerQuery: undefined,
      modalShow2: false,
      maxcount: 0,
      filteredCount: 0,
      post: {draw: 1},
      postDefaults: {q: '', count: 50, skip: 0, page: 1},
      editModel: {}
    };
  },
  mounted() {
    this.reloadLink();
    this.reload();
    this.doaction();
  },
  methods: {
    reloadLink() {
      if (true) {
        this.post = Object.assign({draw: this.post.draw}, this.postDefaults, this.$route.query);
      } else {
        this.post.q = this.$route.query.q;
        this.post.page = this.$route.query.page;
        this.post.skip = this.$route.query.skip;
        this.post.count = this.$route.query.count;
        if (!this.post.count) {
          this.post.count = 50;
        }
        if (!this.post.skip) {
          this.post.skip = 0;
        }
        if (!this.post.page) {
          this.post.page = 1;
        }
      }
    },
    reload() {
      this.loading = true;

      this.$http.post('client/find', JSON.stringify(this.post), {}).then(
        response => {
          if (response && response.body && response.body.status == 1 && this.post.draw == response.data.draw) {
            this.users = response.data.data.data;
            this.maxcount = response.data.data.count;
            this.filteredCount = response.data.data.filteredCount;

            // this.$router.push({
            //   name: "Clients",
            //   //params: {id: 100},
            //   query: {
            //     page: this.post.page,
            //     q: this.post.q,
            //     skip: this.post.skip
            //   }
            // });
            this.loading = false;
          } else {
            this.error = response.body.messages;
          }
        },
        response => {}
      );
    },
    doaction() {
      this.action = this.$route.params.action;
      if (this.action == 'add') {
        //dodawanie
        this.doadd();
      } else {
        let id = parseInt(this.action);
        if (!isNaN(id)) {
          this.doedit(id);
        } else {
          this.modalShow = false;
        }
      }
    },
    save() {
      this.editUser.minDaysBeforeCancellation = parseInt(this.editUser.minDaysBeforeCancellation);

      if (this.editUser.id) {
        this.$http.post('client/update', JSON.stringify(this.editUser), {}).then(response => {
          if (response && response.body && response.body.status == 1) {
            this.$router.push({path: '/users'});
            this.reload();
            this.$komunikat('success', 'Sukces', 'Pomyślnie edytowano podmiot', 3000);
          } else {
            this.$komunikat('error', 'Błąd', 'Błąd przy edytowaniu podmiotu', 3000);
          }
        });
      } else {
        this.$http.post('client/add', JSON.stringify(this.editUser), {}).then(response => {
          if (response && response.body && response.body.status == 1) {
            this.$router.push({path: '/users'});
            this.reload();
            this.$komunikat('success', 'Sukces', 'Pomyślnie dodano podmiot', 3000);
          } else {
            this.$komunikat('error', 'Błąd', 'Błąd przy dodawaniu podmiotu', 3000);
          }
        });
      }
      //this.$emit("changed", ++this.changed);
    },
    cancel() {
      this.$router.push({path: '/users'});
      this.deleteShow = false;
      //this.reload();
    },
    deleteButton(user) {
      this.deleteUser = user;
      this.deleteShow = true;
    },
    editlink(id) {
      this.$router.push({path: '/users/' + id});
      //this.doedit(id);
    },
    del(id) {
      this.$http.get('client/delete/' + id).then(response => {
        if (response && response.body && response.body.status == 1) {
          this.deleteShow = false;
          this.reload();
          this.$komunikat('success', 'Sukces', 'Pomyślnie usunięto podmiot', 3000);
        } else {
          this.$komunikat('error', 'Błąd', 'Błąd przy usuwaniu podmiotu', 3000);
        }
      });
    },

    doedit(id) {
      this.modalShow = true;
      this.$http.get('client/get/' + id).then(response => {
        this.editUser = response.data.data;
      });
    },
    addlink() {
      this.$router.push({path: '/users/add'});
      //this.doedit(id);
    },
    doadd() {
      this.modalShow = true;

      this.editUser = {
        color: '#000000',
        name: '',
        accessKey: '',
        phone: '',
        email: '',
        minDaysBeforeCancellation: ''
      };
    }
  },

  watch: {
    $route(to, from) {
      //this.reload();
      this.doaction();
    }
  }
};
</script>

<style></style>
