<template>
  <!-- Modal -->
  <b-modal v-model="showLocal" @hidden="$emit('cancel')">
    <h5 v-if="option == 'edit'" slot="modal-title">Edytuj typ {{ object.name }}</h5>
    <h5 v-if="option != 'edit'" slot="modal-title">Dodaj typ rezerwacji</h5>
    <div slot="modal-header-close">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$emit('cancel')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="box-body">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label :class="{'text-danger': $v.name.$anyError}" for="surname">Nazwa typu rezerwacji</label>
            <input :class="{'is-invalid': $v.name.$anyError}" class="form-control " id="surname" type="text" v-model.trim="$v.name.$model" />
            <small class="text-danger" v-if="$v.name.$anyError">Nazwa typu jest wymagana</small>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label for="surname">Przypisany obiekt</label>
            <customSelect :url="'object/find'" @picked="pickObject" :itemId="id">
              <template slot-scope="child">{{ child.child.name }}</template>
            </customSelect>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal-footer -->
    <div slot="modal-footer">
      <button type="button" class="btn btn-default" data-dismiss="modal" @click="$emit('cancel')">Wyjdź</button>&nbsp;
      <button v-if="option != 'edit'" type="button" class="btn btn-danger" data-dismiss="modal" @click="save()">Zapisz</button>
      <button v-if="option == 'edit'" type="button" class="btn btn-danger" data-dismiss="modal" @click="update()">Zapisz</button>
    </div>
    <!-- /Modal-footer -->
  </b-modal>
</template>

<script>
import customSelect from '../modules/CustomSelect.vue';
import {required} from 'vuelidate/lib/validators';

export default {
  components: {
    customSelect
  },
  name: 'appEdit',
  props: ['show', 'object', 'option'],
  data() {
    return {
      name: this.object.name,
      id: this.object.objectId,
      showLocal: this.show
    };
  },
  validations: {
    name: {
      required
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    pickObject(object) {
      this.id = object.id;
    },
    update() {
      this.$http.post('reservationtype/update', JSON.stringify({name: this.name, id: this.object.id, objectId: this.id}), {}).then(
        response => {
          if (response && response.body && response.body.status == 1) {
            this.$emit('reload');
            this.$emit('cancel');
            this.$komunikat('success', 'Sukces', 'Pomyślnie edytowano typ', 3000);
          } else {
            this.$komunikat('error', 'Błąd', 'Błąd przy edycji typu', 3000);
          }
        },
        response => {}
      );
    },
    save() {
      if (this.$v.$invalid == true) {
        this.$v.$touch();
      } else {
        this.$http.post('reservationtype/add', JSON.stringify({name: this.name, objectId: this.id}), {}).then(
          response => {
            if (response && response.body && response.body.status == 1) {
              this.$emit('reload');
              this.$emit('cancel');
              this.$komunikat('success', 'Sukces', 'Pomyślnie dodano typ', 3000);
            } else {
              this.$komunikat('error', 'Błąd', 'Błąd przy dodawaniu typu', 3000);
            }
          },
          response => {}
        );
      }
    }
  },
  watch: {
    showLocal: function(val) {
      if (this.showLocal == false) {
        this.$emit('cancel');
      }
    }
  }
};
</script>

<style></style>
