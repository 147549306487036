<template>
  <div class="row">
    <div class="col-6">
      <div class="card card-danger card-outline">
        <!-- card-header -->
        <div class="card-header">
          <h3 class="card-title">
            <i class="nav-icon fa fa-address-book fa-fw"></i>
            <b>Rezerwacja {{ res.reservationId }}</b>
            <small v-if="res.reservationDate"> {{ res.reservationDate }}</small>

            <button class="btn btn-danger float-right btn-sm" @click="editButton" v-if="canEdit"><i class="fa fa-fw fa-lg fa-edit "></i>Edytuj rezerwację</button>
          </h3>
        </div>
        <!-- /.card-header -->
        <div class="card-body" style="overflow: hidden;">
          <div class="row">
            <div class="col-md-12">
              <div v-if="res.clientId" class="form-group">
                <b>Nazwa podmiotu: </b>
                <router-link :to="{name: 'UsersProfile', params: {id: res.clientId}}">{{ res.clientName || '[brak nazwy podmiotu]' }}</router-link>
              </div>
              <div v-if="!res.clientId" class="form-group">
                <b>Podmiot: </b>
                <span>{{ res.clientName || '[brak nazwy podmiotu]' }}</span>
              </div>
            </div>
            <div class="col-md-12">
              <div v-if="res.objects" class="form-group">
                <b>Obiekty: </b>

                <template v-for="(object, index) in res.objects">
                  <router-link v-if="object.id" :key="index" :to="{name: 'Timeline', params: {id: object.id}, query: {date: res.startDate}}">
                    <span v-if="res.objects[index + 1]"> {{ object.name }},&nbsp; </span>
                    <span v-else>{{ object.name }}</span>
                  </router-link>
                </template>
              </div>
              <div v-if="!res.objects" class="form-group"><b>Obiekty: </b> [brak nazwy obiektu]</div>
            </div>
            <div class="col-md-12">
              <div class="form-group"><b>Nazwa rezerwacji:</b> {{ res.reservationName || '[brak nazwy rezerwacji]' }}</div>
            </div>
            <div class="col-md-12">
              <div class="form-group"><b>Cena rezerwacji:</b> {{ reservationPrice | money }}
              <span v-if="res.payPrice">(opłacono {{ res.payPrice | money }} - {{res.payDate}})</span>
              </div>
            </div>


            <div class="col-md-12">
              <div class="form-group"><b>Od:</b> {{ res.startDate | fdate }}</div>
            </div>
            <div class="col-md-12">
              <div class="form-group"><b>Do:</b> {{ res.endDate | fdate }}</div>
            </div>

            <div class="col-md-12">
              <div class="form-group"><b>Typ rezerwacji:</b> {{ res.reservationTypeName || '[brak typu rezerwacji]' }}</div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <b>Status: </b>
                <span v-if="res.status == 1" style="color: #28a745">Aktywna</span>
                <span v-else-if="res.status == 0" style="color: red">Odwołana</span>
                <span v-else-if="res.status == 3" class="text-primary">Oczekiwanie na akceptację</span>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <b>Opis:</b>
                {{ res.reservationDescription || '[brak opisu rezerwacji]' }}
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="float-right btn-group">
            <button v-if="$hasRole('admin') && showConfirmReservationButton" @click="confirmReservation" class="btn btn-primary">
              Aktywuj rezerwację
            </button>
            <router-link :to="{name: 'Terms', params: {id: reservationId}}" class="btn btn-danger">
              Przejdź do terminów
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6" v-if="$useClients()">
      <div class="card card-danger card-outline">
        <!-- card-header -->
        <div class="card-header">
          <h3 class="card-title">
            <i class="nav-icon fa fa-user fa-fw"></i>
            <b>Przypisani klienci</b>
            <div class="pull-right" v-if="attence_edit">
              <button type="button" class="btn btn-secondary btn-sm" @click="cancelAttendanceList()">
                Anuluj</button
              >&nbsp;
              <button type="button" class="btn btn-danger btn-sm" @click="saveAttendanceList()"><i class="fa fa-fw fa-edit"></i> Zapisz</button>
            </div>
            <div class="pull-right" v-else>
              <router-link :to="{name: 'Terms', params: {id: reservationId}, query: {addClient: true}}" type="button" class="btn btn-danger btn-sm" @click="addButton()">
                <i class="fa fa-fw fa-plus"></i> Dodaj klienta </router-link
              >&nbsp;
              <button type="button" class="btn btn-danger btn-sm" @click="startAttendanceList()" v-if="cardOwners.length">
                Lista obecności
              </button>
            </div>
          </h3>
        </div>
        <!-- /.card-header -->
        <div class="card-body  p-0">
          <div v-if="!cardOwners.length > 0" class="alert alert-danger">Brak przypisanych klientów</div>
          <div v-if="attence_edit" class="pb-2">
            <datepicker :language="pl" input-class="form-control" :full-month-name="true" format="dd-MM-yyyy" v-model="attence_date"></datepicker>
          </div>
          <ul class="todo-list" style="padding:10px">
            <template v-for="(cardOwner, index) in cardOwners">
              <li :key="index" v-if="!attence_edit || (attence_edit && (cardOwner.terms.length == 0 || checkTerms(cardOwner.terms, attence_date)))">
                <router-link :to="{name: 'ClientsProfile', params: {id: cardOwner.id}}">{{ cardOwner.firstName }}&nbsp;{{ cardOwner.lastName }}</router-link>
                &nbsp;
                <small v-if="cardOwner.terms.length" class="text-muted">{{ cardOwner.terms.length }} terminy/ów</small>
                <small v-else class="text-muted">cała rezerwacja</small>
                <div class="tools" v-if="attence_edit">
                  <loading-icon v-if="attence_loading"></loading-icon>
                  <input type="checkbox" v-if="!attence_list_saved.filter(x => x == cardOwner.id).length" class="form-check-input" :value="cardOwner.id" v-model="attence_list" />
                  <span class="text-success" v-else>obecny</span>
                </div>
                <div class="tools" v-else>
                  <i class="fa fa-trash-o" @click="deleteClientButton(cardOwner)"></i>
                </div>
              </li>
            </template>
          </ul>
        </div>
        <!-- <div class="card-footer text-right">
                    <button class="btn btn-danger btn-sm">Historia</button>
                </div> -->
      </div>
    </div>

    <app-edit v-if="showEdit" :show="showEdit" :res="res" @cancel="cancel()"  @del="deleteButton()" @reload="reload"></app-edit>
    <app-delete v-if="showDelete" :show="showDelete" v-on:cancel="cancel()" v-on:delete="deleteRes()" :object="{name: res.reservationName}"></app-delete>
    <app-delete v-if="showDeleteClient" :show="showDeleteClient" v-on:cancel="cancel()" v-on:delete="delClient" :object="deleteClient"></app-delete>

    <client-add v-if="showAdd" :show="showAdd" v-on:reload="reload" v-on:cancel="cancel"></client-add>
  </div>
</template>

<script>
import appEdit from './Edit.vue';
import clientAdd from './AddCardOwner.vue';
import appDelete from '../modules/Delete.vue';
import Datepicker from 'vuejs-datepicker';
import {pl} from 'vuejs-datepicker/dist/locale';
import loadingIcon from '../modules/LoadingIcon.vue';

export default {
  data() {
    return {
      res: {},
      canEdit: false,
      showEdit: false,
      showDelete: false,
      showDeleteClient: false,
      showAdd: false,
      cardOwners: [],
      reservationId: this.$route.params.id,
      deleteClient: {},
      reservationPrice: 0,
      attence_edit: false,
      attence_list: [],
      attence_date: new Date(),
      pl: pl,
      attence_list_saved: [],
      attence_loading: true,
      showConfirmReservationButton: false
    };
  },
  components: {
    appEdit,
    clientAdd,
    appDelete,
    Datepicker,
    loadingIcon
  },
  methods: {
    startAttendanceList() {
      this.attence_list = [];
      this.attence_edit = true;
      this.attence_date = new Date();
    },
    cancelAttendanceList() {
      this.attence_edit = false;
    },
    saveAttendanceList() {
      let users = this.cardOwners.filter(x => this.attence_list.filter(y => y == x.id).length > 0).map(x => x.id);
      this.$http
        .post('reservation/attendancelist', {
          reservationId: this.reservationId,
          date: this.attence_date,
          list: users
        })
        .then(response => {
          if (response && response.body && response.body.status == 1) {
            this.$komunikat('success', 'Zapis', 'Poprawnie zapisano listę obecności');
            this.cancelAttendanceList();
          } else {
            this.$komunikat('error', 'Błąd', response.body.messages[0]);
          }
        });
    },
    editButton() {
      this.showEdit = true;
    },
    deleteButton() {
      this.cancel();
      this.showDelete = true;
    },
    deleteClientButton(client) {
      this.showDeleteClient = true;
      this.deleteClient = {id: client.id, name: client.firstName + ' ' + client.lastName};
    },
    addButton() {
      this.showAdd = true;
    },
    cancel() {
      this.showEdit = false;
      this.showDelete = false;
      this.showAdd = false;
      this.showDeleteClient = false;
    },
    confirmReservation() {
      this.$http.get('reservation/changereservationstatus?reservationId=' + this.res.reservationId + '&&status=1').then(() => {
        this.showConfirmReservationButton = false;
        this.reload();
      });
    },
    getCardOwners() {
      let post = {
        reservationId: this.reservationId,
        clientId: this.$store.state.user.id
      };
      this.$http.post('reservation/cardowners', JSON.stringify(post), {}).then(
        response => {
          this.cardOwners = response.data.data.data;
        },
        response => {}
      );
    },
    getReservationDetail() {
      this.$http.get('reservation/get/' + this.reservationId).then(
        response => {
          this.reservationPrice = 0;
          this.res = response.body.data;
          if (this.res.status == 3) {
            this.showConfirmReservationButton = true;
          }
          for (var i = 0; this.res.reservations.length > i; i++) {
            for (var j = 0; this.res.reservations[i].items.length > j; j++) {
              if (this.res.reservations[i].items[j].status == 1) {
                this.reservationPrice += this.res.reservations[i].items[j].price;
              }
            }
          }
          // upraenienia
          var canEdit = false;
          for (var i=0; i<this.res.objects.length; i++) {
            var obj = this.res.objects[i];
            // window.console.log('hasPerm',obj.id,obj.path,this.$hasPerm(obj.id,obj.path));
            if (this.$hasPerm(obj.id,obj.path)) {
              canEdit = true;
              break;
            }
          }
          this.canEdit = canEdit;
          window.console.log('reservastion',canEdit,this.res);
        },
        response => {}
      );
    },
    checkTerms(terms, date) {
      let tmpDate = this.$moment.utc(date, 'YYYY-MM-DD').startOf('day');
      return (
        terms.filter(d => {
          let dateToDiff = this.$moment.utc(d.date, 'YYYY-MM-DD').startOf('day');
          console.log(tmpDate, dateToDiff);
          return dateToDiff.diff(tmpDate, 'days') == 0;
        }).length > 0
      );
    },
    reload() {
      this.getCardOwners();
      this.getReservationDetail();

      

    },
    deleteRes() {
      this.$http.get('reservation/delete/' + this.reservationId, {}, {}).then(
        response => {
          if (response && response.body && response.body.status == 1) {
            this.$router.push({name: 'Reservations'});
            this.$komunikat('success', 'Sukces', 'Pomyślnie usunięto rezerwację', 3000);
          } else {
            this.$komunikat('error', 'Błąd', 'Błąd przy usuwaniu rezerwacji ', 3000);
          }
        },
        response => {}
      );
    },
    delClient(id) {
      let post = {
        cardOwnerId: id,
        reservationId: this.reservationId
      };

      this.$http.post('reservation/removecardowner', JSON.stringify(post)).then(
        response => {
          if (response && response.body && response.body.status == 1) {
            this.cancel();
            this.reload();
            this.$komunikat('success', 'Sukces', 'Pomyślnie usunięto klienta', 3000);
          } else {
            this.$komunikat('error', 'Błąd', 'Błąd przy usuwaniu klienta', 3000);
          }
        },
        response => {}
      );
    }
  },
  watch: {
    $route(to, from) {
      this.reservationId = this.$route.params.id;
      this.reload();
    },
    attence_date() {
      this.attence_list_saved = [];
      this.attence_list = [];
      this.attence_loading = true;
      this.$http.get(`reservation/attendancelist?reservationId=${this.reservationId}&date=${this.$moment(this.attence_date).format('YYYY-MM-DD')}`).then(response => {
        this.attence_list_saved = response.body.data;
        this.attence_loading = false;
      });
      console.log('attence_date changed');
    }
  },

  mounted() {
    //this.canEdit = this.$isAdmin();
    this.reload();
  }
};
</script>

<style>
.cov-date-body {
  z-index: 99999;
}
</style>
