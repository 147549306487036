<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <div class="card card-outline card-info ">
          <div class="card-header">
            <h3 class="card-title">
              Użytkownik:
              <router-link :to="{name: 'ClientsProfile', params: {id: userID}}">{{ title }}</router-link>
            </h3>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label :class="{'text-danger': $v.user.firstName.$anyError}" for="surname">Imię</label>
                  <input :class="{'is-invalid': $v.user.firstName.$anyError}" class="form-control " id="surname" type="text" v-model.trim="$v.user.firstName.$model" />
                  <small class="text-danger" v-if="$v.user.firstName.$anyError">Imię jest wymagane</small>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label :class="{'text-danger': $v.user.lastName.$anyError}" for="surname">Nazwisko</label>
                  <input :class="{'is-invalid': $v.user.lastName.$anyError}" class="form-control " id="surname" type="text" v-model.trim="$v.user.lastName.$model" />
                  <small class="text-danger" v-if="$v.user.lastName.$anyError">Nazwisko jest wymagane</small>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label :class="{'text-danger': $v.user.email.$anyError}" for="surname">Email</label>
              <input :class="{'is-invalid': $v.user.email.$anyError}" class="form-control " id="surname" type="text" v-model.trim="$v.user.email.$model" />
              <small class="text-danger" v-if="$v.user.email.$anyError">Błędny email</small>
            </div>
            <div class="form-group">
              <label :class="{'text-danger': $v.user.mobilePhone.$anyError}" for="surname">Nr telefonu</label>
              <input :class="{'is-invalid': $v.user.mobilePhone.$anyError}" class="form-control " id="surname" type="text" v-model.trim="$v.user.mobilePhone.$model" />
              <small class="text-danger" v-if="$v.user.mobilePhone.$anyError">Błędny numer telefonu</small>
            </div>
            <div class="form-group">
              <label>Data urodzenia </label>
              <datepicker v-model="user.dateOfBirth" :language="pl" input-class="form-control" :full-month-name="true" format="dd-MM-yyyy"></datepicker>
            </div>
            <div class=" form-group ">
              <label :class="{'text-danger': $v.user.pesel.$anyError}" for="surname">Pesel</label>
              <input :class="{'is-invalid': $v.user.pesel.$anyError}" class="form-control " id="surname" type="text" v-model.trim="$v.user.pesel.$model" />
              <small class="text-danger" v-if="$v.user.pesel.$anyError">Błędny pesel</small>
            </div>
            <div class="form-group ">
              <label> Płeć </label>
              <select class="form-control " type="text " v-model="user.sex">
                <option></option>
                <option value="M">Mężczyzna</option>
                <option value="K">Kobieta</option>
              </select>
            </div>
          </div>
          <div class="card-footer">
            <button class="btn btn-info pull-right" @click="save()">Zapisz</button>
          </div>
        </div>
      </div>
      <div class="col-md-6 ">
        <div class="card card-outline card-info ">
          <div class="card-header ">
            <h3 class="card-title ">
              Karty
              <button type="button" class="btn btn-info btn-sm float-right" @click="addButton()"><i class="fa fa-fw fa-plus"></i> Dodaj kartę</button>
            </h3>
          </div>
          <div class="card-body ">
            <div v-if="!cards.length > 0" class="alert alert-info">Brak kart</div>

            <ul class="list-items ">
              <li v-for="(card, id) in cards" :key="id">
                <i class="fa fa-fw fa-address-card "></i>
                <span
                  >{{ card.cardNumber }}
                  <i class="fa fa-trash-o float-right" style="cursor:pointer" @click="deleteButton(card)"></i>
                  <i class="fa fa-fw  fa-edit float-right" style="cursor: pointer" @click="editButton(card)"></i>
                </span>
                <div class="text-muted" style="padding-left:30px;">
                  <span v-if="card.title" class="text-muted"> {{ card.title }} </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!--                 <div class="card card-outline card-info ">
                    <div class="card-header ">
                        <div class="card-title ">Grupy</div>
                    </div>
                    <div class="card-body ">
                        <ul class="list-items ">
                            <div class="row ">
                                <div class="col-md-9 ">
                                    <select class="form-control " v-model="newGroupId">
                                        <option value="0">Wybierz grupę</option>
                                        <option v-for="(group, index) in allGroups.filter(x=> groups.filter(y => y.id == x.id).length == 0)" :key="index" v-bind:value="group.id">{{group.name}}</option>
                                    </select>
                                </div>
                                <div class="col-md-3">
                                    <button class="btn btn-info btn-block" @click="addGroup">
                                        <i class="fa fa-fw fa-plus"></i>Dodaj grupę</button>
                                </div>
                            </div>
                            <hr/>
                            <li v-for="(group, index) in groups" :key=index>
                                <i class="fa fa-fw fa-users"></i>
                                {{group.name}}</li>
                        </ul>
                    </div>
                </div> -->
      </div>
    </div>
    <app-delete v-if="showDelete" :show="showDelete" :object="ObjectEdit" @cancel="cancel()" v-on:delete="del"></app-delete>
    <card-edit v-if="showEdit" :show="showEdit" :card="ObjectEdit" @cancel="cancel()" :option="modalOption"></card-edit>
  </div>
</template>

<script>
import {required, numeric, email, minLength, maxLength, helpers} from 'vuelidate/lib/validators';
import datepicker from 'vuejs-datepicker';
import {pl} from 'vuejs-datepicker/dist/locale';
import appDelete from '../modules/Delete.vue';
import cardEdit from './cardEdit.vue';
function pesel(pesel) {
  return this.$pesel(pesel);
}
export default {
  components: {datepicker, appDelete, cardEdit},
  data() {
    return {
      userID: this.$route.params.id,
      user: {},
      newCardNumber: '',
      newCardTitle: '',
      cards: [],
      pl: pl,
      showDelete: false,
      showEdit: false,
      ObjectEdit: {},
      modalOption: 'add',
      title: ''
    };
  },
  created() {
    this.$http.get('cardowner/get/' + this.userID).then(response => {
      this.user = response.data.data;
      this.title = this.user.firstName + ' ' + this.user.lastName;
    });
    this.reload();
  },
  validations: {
    user: {
      firstName: {
        required
      },
      lastName: {
        required
      },
      email: {
        email
      },
      pesel: {
        numeric,
        minLength: minLength(11),
        maxLength: maxLength(11),
        pesel
      },
      mobilePhone: {
        numeric
        //minLength: minLength(9)
      }
    }
  },
  methods: {
    reload() {
      this.$http.post('card/find/', JSON.stringify({cardOwnerId: this.userID}), {}).then(response => {
        this.cards = response.data.data.data;

        //this.loading = false;
      });
    },
    addButton() {
      this.modalOption = 'add';
      this.ObjectEdit = {};
      this.showEdit = true;
    },
    save() {
      if (this.$v.$invalid == true) {
        this.$v.$touch();
      } else {
        this.$http.post('cardowner/update', JSON.stringify(this.user), {}).then(
          response => {
            if (response && response.body && response.body.status == 1) {
              //this.$router.go();
              this.$komunikat('success', 'Sukces', 'Pomyślnie edytowano klienta', 3000);
              this.title = this.user.firstName + ' ' + this.user.lastName;
            } else {
              this.$komunikat('error', 'Błąd', 'Błąd przy edytowaniu klienta', 3000);
            }
          },
          response => {}
        );
      }
    },
    deleteButton(card) {
      this.showDelete = true;
      this.ObjectEdit = {id: card.id, name: card.title};
    },
    editButton(card) {
      this.modalOption = 'edit';

      this.showEdit = true;
      this.ObjectEdit = card;
    },
    cancel() {
      this.showDelete = false;
      this.showEdit = false;
      this.reload();
    },
    del(id) {
      this.$http.get('card/delete/' + id).then(response => {
        if (response && response.body && response.body.status == 1) {
          this.reload();
          this.cancel();
          this.$komunikat('success', 'Sukces', 'Pomyślnie usunięto kartę', 3000);
        } else {
          this.$komunikat('error', 'Błąd', 'Błąd przy usuwaniu karty', 3000);
        }
      });
    }
  }
};
</script>

<style>
.list-items {
  list-style: none;
  padding-left: 0;
}
.list-items li {
  padding: 10px;
  font-size: 1.3rem;
  border-bottom: 1px solid #ccc;
}
.list-items li i {
  margin-right: 5px;
}
</style>
