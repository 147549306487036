<template>
  <div class="clientitem">
    <div class="mailbox-name">
      <span v-if="employee.name">
        <b>{{ employee.name }}</b><small> &nbsp; {{employee.email}}</small>
      </span>
      <b v-else>
        {{ employee.name || employee.email }}
      </b>
    </div>

    <div>
      <span class="clientspan" title="Czy E-mail został potwierdzony">
        <i class="fa fa-envelope fa-fw" /> {{ employee.emailConfirmed ? 'Potwierdzony' : 'Niepotwierdzony' }}</span
      >
    </div>

    <div class="itembuttons align-items-center">
      <div class="btn-group">
        &nbsp;
        <div class="btn-group">
          <button title="Zmień hasło" class="btn btn-info btn-sm" @click="$emit('change')">
            <i class="fa fa-fw fa-key" />
          </button>
          &nbsp;
          <button title="Edycja" class="btn btn-info btn-sm" @click="$emit('details')">
            <i class="fa fa-fw fa-pencil" />
          </button>

          <button title="Usuń" class="btn btn-danger btn-sm" @click="$emit('delete')">
            <i class="fa fa-fw fa-trash" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @vuese
// Element listy pracowników
export default {
  props: {
    // Pracownik
    employee: {
      required: true,
      type: Object
    }
  }
};
</script>
