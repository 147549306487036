<template>
  <div>
    <div class="row" v-if="!success">
      <div class="col-12">
        <div class="card card-success card-outline">
          <div class="card-header">
            <h3 class="card-title">
              Dodawanie rezerwacji<span v-if="objectName"
                >, obiekt <b>{{ objectName }}</b></span
              >
              <loading-icon v-if="loading"></loading-icon> &nbsp;
            </h3>

            <div class="card-tools">
              <button class="btn btn-default" @click="$router.go(-1)"><i class="fa fa-fw fa-arrow-left"></i> Wróć</button>
              <button class="btn btn-danger" @click="save()"><i class="fa fa-fw fa-plus"></i> Dodaj rezerwacje</button>
            </div>
          </div>
          <div class="card-body p-0">
            <div v-if="option != 'update'" class="row" style="padding: 10px 30px 10px 30px">
              <div class="col-6 form-group">
                <label :class="{'text-danger': $v.imie.$anyError}" for="imie">Imię</label>
                <input :class="{'is-invalid': $v.imie.$anyError}" class="form-control" id="imie" type="text" v-model.trim="$v.imie.$model" />
                <small class="text-danger" v-if="$v.imie.$anyError">Imię jest wymagane</small>
              </div>
              <div class="col-6 form-group">
                <label :class="{'text-danger': $v.nazwisko.$anyError}" for="nazwisko">Nazwisko</label>
                <input :class="{'is-invalid': $v.nazwisko.$anyError}" class="form-control" id="nazwisko" type="text" v-model.trim="$v.nazwisko.$model" />
                <small class="text-danger" v-if="$v.nazwisko.$anyError">Nazwisko jest wymagane</small>
              </div>
              <div class="col-6 form-group">
                <label :class="{'text-danger': $v.email.$anyError}" for="email">Email</label>
                <input :class="{'is-invalid': $v.email.$anyError}" class="form-control" id="email" type="text" v-model.trim="$v.email.$model" />
                <small class="text-danger" v-if="$v.email.$anyError">Prawidłowy email jest wymagany</small>
              </div>
              <div class="col-6 form-group">
                <label :class="{'text-danger': $v.telefon.$anyError}" for="telefon">Telefon</label>
                <input :class="{'is-invalid': $v.telefon.$anyError}" class="form-control" id="telefon" type="text" v-model.trim="$v.telefon.$model" />
                <small class="text-danger" v-if="$v.telefon.$anyError">Telefon wymagany, 9 cyfr</small>
              </div>
              <div class="col-12 form-group">
                <div class="form-check">
                  <input class="form-check-input" id="czyKarta" type="checkbox" v-model="czyKarta" />
                <label class="form-check-label" for="czyKarta">Posiadam kartę mieszkańca Słupska</label>
              </div>
                
                
              </div>
              <div v-if="czyKarta" class="col-6 form-group">
                <label :class="{'text-danger': $v.kartaMiekszancaNr.$anyError}" for="kartaMiekszancaNr">Numer karty mieszkańca</label>
                <input :class="{'is-invalid': $v.kartaMiekszancaNr.$anyError}" class="form-control" id="kartaMiekszancaNr" type="text" v-model.trim="$v.kartaMiekszancaNr.$model" />
                <small class="text-danger" v-if="$v.kartaMiekszancaNr.$anyError">Wpisz prawidłowy numer karty mieszkańca</small>
              </div>
              <div v-if="czyKarta" class="col-6 form-group">
                <label :class="{'text-danger': $v.kartaMiekszancaDataUrodzenia.$anyError}" for="telefon">Data urodzenia (YYYY-MM-DD)</label>
                <input :class="{'is-invalid': $v.kartaMiekszancaDataUrodzenia.$anyError}" class="form-control" id="telefon" type="text" placeholder="1980-04-25" v-model.trim="$v.kartaMiekszancaDataUrodzenia.$model" />
                <small class="text-danger" v-if="$v.kartaMiekszancaDataUrodzenia.$anyError">Wymagany format daty to rok-miesiac-dzien</small>
              </div>
              <div v-if="czyKarta && kartaInfo " class="col-12 form-group">
                <label class="text-danger" for="kartaInfo">{{ kartaInfo }}</label>
              </div>
              <div v-else-if="czyKarta && kartaInfoOk" class="col-12 form-group">
                <label class="text-success" for="kartaInfoOk">{{ kartaInfoOk }}</label>
              </div>
              <div v-if="false" class="col-6 form-group" >
                <!--
                
                <label style="padding-right: 5px" class="checkbox-bootstrap checkbox-lg">
                    <input type="checkbox" v-model="calyObjekt" />
                    <span class="checkbox-placeholder"></span>
                   
                </label>
                 <label>cały kort</label>
                 -->

                 <!--

                 <label>Liczba osób: </label>
                 <label class="radio" v-for="n in [1,2,3,4]" style="margin-left: 10px;">
                   <input type="radio" :value="n" v-model="personCount" />
                   {{ n }}

                 </label>
                -->
                 <!--
                
                
                    <label v-if="!calyObjekt" style="margin-left: 20px;margin-right: 5px;">liczba osób:</label>
                    <select v-if="!calyObjekt" v-model="personCount">
                    <option :value="1">1</option>
                    <option :value="2">2</option>
                    <option :value="3">3</option>
                    <option :value="4">4</option>
                    
                    </select>
                    -->

                
                
               
              </div>
              <div v-if="totalPrice" class="col-6 form-group">
              <label> Całkowity koszt rezerwacji: <b>{{ totalPrice}} zł</b></label>
                 
              </div>
            </div>

            <div class="row" style="padding: 10px 30px 10px 30px">
              <template :class="dateWidthClass" v-for="(data, dzien) in response.dates">
                <div :class="dateWidthClass" v-if="data.errors.length == 0" :key="dzien">
                  <div class="card card-danger card-outline">
                    <div class="card-header">
                      <h3 class="card-title">
                        <div :class="[data.errors.length > 0 ? 'text-danger' : 'text-default']">
                        <b>{{ data.date | moment('LL') }}</b>
                        &nbsp; <small title="wschód słońca"><i class="fa fa-fw fa-sun-o"></i>{{data.sunrise  | hminute}}</small>
                        &nbsp; <small title="zachód słońca"><i class="fa fa-fw fa-moon-o"></i>{{data.sunset  | hminute}}</small>
                        </div>
                       

                      </h3>
                    </div>
                    <div class="card-body p-0">
                      <template v-for="(errors, index) in data.errors">
                        <div class="text-danger text-bold" style="padding-left: 30px" v-if="data.errors.length > 0" :key="index">{{ errors }}</div>
                      </template>

                      <div style="height: 5px"></div>
                      <div style="padding-left: 6px" v-for="item in data.items" :key="item.timeunitId">
                        <div style="display: inline" v-if="item.errors">
                          <label style="padding-right: 5px">
                            <input disabled type="checkbox" v-model="item.picked" />
                          </label>
                          <label style="color: red" :title="item.errors.map((err) => err.info).join('\r\n')">
                            {{ item.start | hminute }}-{{ (item.start + item.length) | hminute }} </label
                          >&nbsp;

                          <i :title="item.errors.map((err) => err.info).join('\r\n')" class="fa fa-question-circle" aria-hidden="true"></i>
                        </div>

                        <div v-else style="display: inline" class="checkbox">
                          <label style="padding-right: 5px" class="checkbox-bootstrap checkbox-lg">
                            <input :id="data.date + '-' + item.timeunitId + 'checkbox'" type="checkbox" v-model="item.picked" />
                            <span class="checkbox-placeholder"></span>
                            <span :id="data.date + '-' + item.timeunitId" title="wolny termin " :style="{color: item.picked ? 'green' : 'black'}">
                             {{ item.start | hminute }} - {{ (item.start + item.length) | hminute }}
                            </span>
                            
                            
                            
                            
                          </label>
                          <template v-if="item.light!==undefined">
                            <i v-if="item.light" class="fa fa-fw fa-lightbulb-o"></i>
                            <button v-if="!item.light" class="btn btn-sm btn-primary" style="line-height: 1em;" @click="item.light=true;calcAll()">+ oświetlenie</button>
                            <button v-else class="btn btn-sm btn-danger" style="line-height: 1em;" @click="item.light=false;calcAll()">- oświetlenie</button>
                          </template>
                          <!--
                          <label style="padding-right: 5px" class="checkbox-bootstrap checkbox-lg">
                              <input type="checkbox" v-model="item.light" @change="calcAll" />
                              <span class="checkbox-placeholder"></span>
                              <span> + oświetlenie</span>

                          </label>
                          -->
                          <span v-if="showPrices && item.price!==undefined" class="price" style="margin-left: 10px;" :class="{zero: item.price == 0}">{{ item.price }} zł</span>
                          <i v-if="!item.errors && !data.useTimeunits" style="cursor: pointer" class="fa fa-fw fa-edit" @click="editTimeUnitButton(data.date, dzien)"></i>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="success">
      <div class="col-12">
        <div class="card card-success card-outline">
          <div class="card-header text-center">
            <div class="card-title">
              Pomyślnie dodano rezerwację na nazwisko: <b>{{ savedReservation.name }}</b>
            </div>
          </div>
          <div class="card-body">
            <h3>Zarezerwowane terminy na obiekcie: <b>{{ savedReservation.objectName }}</b></h3>
            <ul>
              <li v-for="unit in savedReservation.units" :key="'sunit_' + unit.id">
                <b>{{ unit.date }}</b
                >: {{ unit.start | hminute }} - {{ (unit.start + unit.length) | hminute }}
                <span v-if="unit.light"  style="margin-left: 10px;">+ oświetlenie</span>
                <span v-if="unit.price!==undefined" class="price" style="margin-left: 10px;">{{ unit.price }}zł</span>
              </li>
            </ul>
            <p>Numer rezerwacji: {{ savedReservation.id }}</p>
            <p v-if="savedReservation.totalPrice">Całkowity koszt: <b>{{ savedReservation.totalPrice }} zł</b></p>

            <div v-if="false && savedReservation.payHash" >
             <form target="_blank" action="https://pay.bm.pl/payment" method="POST" @submit="afterSubmit">
              <input type="hidden" name="ServiceID" :value="savedReservation.payServiceID">
              <input type="hidden" name="OrderID" :value="savedReservation.id">
              <input type="hidden" name="Amount" :value="savedReservation.payPrice">
              <input type="hidden" name="Description" :value="savedReservation.payDescription">
              <input type="hidden" name="CustomerEmail" :value="savedReservation.email">
              <input type="hidden" name="Hash" :value="savedReservation.payHash">

              <button type="submit" class="btn btn-primary">Zapłać online (Blue Media)</button>

            </form>
            <br />
              
            </div>
            <div v-if="true">
              <button class="btn btn-primary" @click="payNow">Zapłać online (PayNow)</button>
            </div>

           

            
          </div>
        </div>
      </div>

      
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import loadingIcon from '../../components/modules/LoadingIcon';
import {pl} from 'vuejs-datepicker/dist/locale';
import {setTimeout, clearTimeout} from 'timers';
import customSelect from '../../components/modules/CustomSelect.vue';
import {required, between, numeric, minLength, maxLength, minValue, maxValue, withParams, email, helpers} from 'vuelidate/lib/validators';
import { getSunrise, getSunset } from 'sunrise-sunset-js';

const placePosition = [54.465545988742484, 17.027839993802175];

const dataUrodzenia = helpers.regex("dataUrodzenia", /^\d{4}-\d{2}-\d{2}$/);
const kartaMieszkanca = helpers.regex("kartaMieszkanca", /^2\d{7}$/);

const kartaToken = "feb5f1b6-8ba2-4e7f-985d-514b81904d12";

// https://api.slupskakarta.pl/integration/benefitUse?token=feb5f1b6-8ba2-4e7f-985d-514b81904d12&cardNumber=24458342&value=1&benefitId=181

export default {
  validations: {
    imie: {
      required
    },
    nazwisko: {
      required
    },
    email: {
      required,
      email
    },
    telefon: {
      numeric,
      required,
      minLength: minLength(9),
      maxLength: maxLength(9)
    },
    kartaMiekszancaNr: {
      kartaMieszkanca

    },
    kartaMiekszancaDataUrodzenia: {
      dataUrodzenia
    }

  },
  components: {
    Datepicker,
    loadingIcon,
    customSelect
  },
  data() {
    return {
      pl: pl,
      dateFrom: new Date(),
      dateTo: new Date(),
      users: '',
      frequency: 0,
      empty: [],
      searchText: '',
      objectId: this.$route.params.id,
      objectName: this.$route.query.name,
      reservation: {},
      listTypes: '',
      type: 0,

      noUnits: [],
      validReser: false,
      validClient: false,
      picks: [
        {name: 'jednorazowo', id: 0},
        {name: 'codziennie', id: 1},
        {name: 'co tydzień', id: 2},
        {name: 'co miesiąc', id: 3},
        {name: 'co poniedziałek', id: 11},
        {name: 'co wtorek', id: 12},
        {name: 'co środe', id: 13},
        {name: 'co czwartek', id: 14},
        {name: 'co piątek', id: 15},
        {name: 'co sobote', id: 16},
        {name: 'co niedziele', id: 17},
        {name: 'w kazdy dzień roboczy pon-pt', id: 18},
        {name: 'w kazdy dzień weekendu so-nd', id: 19}
      ],

      response: {},

      imie: '',
      nazwisko: '',
      email: '',
      telefon: '',
      czyKarta: false,
      kartaMiekszancaNr: '',
      kartaMiekszancaDataUrodzenia: '',

      kartaInfo: '',
      kartaInfoOk: '',
      benefitId: 0,
      calyObjekt: true,
      personCount: 2,

      emptylist: [],
      start: '',
      end: '',
      id: '',
      reserName: '',
      reserDesc: '',
      addRez: '',
      loading: false,
      loadingUsers: false,
      responseShow: true,
      success: false,
      rezerid: null,
      showPrices: true,
      closeForm: 'open',
      option: 'new',
      reservationId: 0,
      closedDays: [],
      showAdding: false,
      addedResDate: '',
      selectAllPick: true,
      showModal: false,
      editingDate: {},
      disabled: true,
      savedReservation: null
    };
  },
  computed: {
    dateWidthClass() {
      if (this.response && this.response.dates) {
        if (this.response.dates.length <= 1) {
          return 'col-12';
        } else if (this.response.dates.length == 2) {
          return 'col-6';
        }
      }
      return 'col-4';
    },
    totalPrice() {
        var result =0;
        if (this.response && this.response.dates) {
      for (let i = 0; i < this.response.dates.length; i++) {
        let date = this.response.dates[i];
        
        for (let j = 0; j < date.items.length; j++) {
          let item = date.items[j];
          if (item.picked && item.price) {
            result+=item.price;
          }

          //date.items[j].picked=sel;
        }

      }
        }
      return result;

    }
  },
  methods: {
    invalidReser(validation) {
      if (validation) {
        this.validReser = false;
      } else {
        this.validReser = true;
      }
    },
    invalidClient(validation) {
      if (validation) {
        this.validClient = false;
      } else {
        this.validClient = true;
      }
    },



    addNewUnit(newUnit) {
      this.response.dates[newUnit.dzien].items.push({
        length: newUnit.length,
        picked: true,
        start: newUnit.start,
        price: 10,
        timeunitId: 0
      });
      this.response.dates[newUnit.dzien].items.splice(0, 1);
      this.showModal = false;
    },
    addNewUnits(newUnits) {
      for (let i = 0; this.response.dates.length > i; i++) {
        if (this.response.dates[i].timetableId == newUnits.timetableId) {
          this.response.dates[i].items.push({
            length: newUnits.length,
            picked: true,
            start: newUnits.start,
            timeunitId: 0,
            price: 10
          });
          this.response.dates[i].items.splice(0, 1);
        }
      }
      this.showModal = false;
    },
    plusButton() {
      this.success = false;
      this.closeForm = 'open';
      this.responseShow = false;
      this.response.dates = [];
      this.selectAllPick = true;
    },
    editTimeUnitButton(data, dzien) {
      this.showModal = true;
      data.dzien = dzien;
      this.editingDate = data;
    },
    cancelModal() {
      this.showModal = false;
    },
    changeclass() {
      var select = document.getElementById('selectid');
      select.className = 'col-12 displaytrue';
    },
    changeclassback() {
      var select = document.getElementById('selectid');
      setTimeout(function () {
        select.className = 'col-12 notshow';
      }, 300);
    },
    selData(data) {
      if (data.picked) {
        //this.$set(data,"picked",false);
        data.picked = false;
      } else {
        //this.$set(data,"picked",true);
        data.picked = true;
      }
      for (let i = 0; i < data.items.length; i++) {
        //this.$set(data.items[i],"picked",data.picked);
        if (!data.items[i].errors) data.items[i].picked = data.picked;
      }
    },
    getWybrane() {
      let dates = [];
      for (let i = 0; i < this.response.dates.length; i++) {
        let date = this.response.dates[i];
        let items = [];
        for (let j = 0; j < date.items.length; j++) {
          let item = date.items[j];
          // item.date = this.$fdate(date.items[j].date);
          if (item.picked) {
            items.push(item);
          }
          //date.items[j].picked=sel;
        }
        if (items.length > 0) {
          dates.push({
            //date: this.$fdate(date.date),
            date: date.date,
            items: items,
            timetableId: date.timetableId
          });
        }
      }
      return dates;
    },

    save() {
      this.showAdding = false;
      let dates = this.getWybrane();
      this.addedResDate = dates[0].date;

      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }

      //if ((""+this.price)=="") {
      //  alert("nie wypełniono ceny, jeśli nie chcesz podawać wpisz 0");
      //  return;
      //}
      const post = {
        objectId: parseInt(this.objectId),
        imie: this.imie,
        nazwisko: this.nazwisko,
        email: this.email,
        telefon: this.telefon,
        calyObjekt: this.calyObjekt,
        personCount: this.personCount,
        dates: this.getWybrane()
      };

      this.$http.post('reservation/addpublic', JSON.stringify(post), {}).then(
        (response) => {
          if (response && response.body && response.body.status == 1) {

            //zapisz benefit use
            //          let url = "https://api.slupskakarta.pl/integration/benefitList?token=" + kartaToken + "&cardNumber="+this.kartaMiekszancaNr+"&birthDate="+this.kartaMiekszancaDataUrodzenia;
            if (this.benefitId && this.kartaMiekszancaNr) {
              let url = "https://api.slupskakarta.pl/integration/benefitUse?token=" + kartaToken + "&cardNumber="+this.kartaMiekszancaNr+"&benefitId="+this.benefitId;
              fetch(url).then(res => {
                if (res.status==200) {
                  res.json(data => {
                    //
                  })
                }
              })
            }







            this.savedReservation = response.data.data;
            this.rezerid = response.data.data.id;
            this.addRez = false;
            this.success = true;
            //this.post.id = response.data.id;
            this.reload();
            // if (this.option == "update") {
            //   this.$router.push({ name: "Terms", params: { id: this.reservationId } });
            // }
            this.$komunikat('success', 'Sukces', 'Pomyślnie dodane terminy: ' + response.body.data.count, 3000);
            if (response.body.data.failed != 0) {
              this.$komunikat('error', 'Błąd', 'Nie udało się dodać terminów: ' + response.body.data.failed, 3000);
            }
          } else {
            this.$komunikat('error', 'Błąd', 'Błąd przy dodawaniu rezerwacji', 3000);
          }
        },
        (response) => {}
      );
    },

    edit() {
      this.addRez = false;
      this.responseShow = true;
    },

    checkKarta() {
      return new Promise((resolve,reject) => {
        this.kartaInfoOk = '';
        this.benefitId = 0;
        if (!this.czyKarta) {
          this.kartaInfo = '';
          resolve(false);
          return;
        }
        if (!this.kartaMiekszancaNr || !this.kartaMiekszancaDataUrodzenia) {
            this.kartaInfo = '';
            resolve(false);
            return;
          }
       

          if (this.$v.kartaMiekszancaNr.$anyError) {
            // this.kartaInfo = 'Nieprawdłowy numer karty, nie można pobrać zniżek';
            this.kartaInfo = '';
            resolve(false);
            return;
          }
          if (this.$v.kartaMiekszancaDataUrodzenia.$anyError) {
            this.kartaInfo = '';
            // this.kartaInfo = 'Nieprawodłowa data urodzenia, nie można pobrać zniżek';
            resolve(false);
            return;
          }


          let url = "https://api.slupskakarta.pl/integration/benefitList?token=" + kartaToken + "&cardNumber="+this.kartaMiekszancaNr+"&birthDate="+this.kartaMiekszancaDataUrodzenia;
          fetch(url).then(res => {
            if (res.status == 200) {
              res.json().then(data => {
                console.log('data',data)
                if (data && data.length) {
                  this.kartaInfo = '';
                  this.benefitId = data[0].id;
                  this.kartaInfoOk = 'Karta prawidłowa, zastosowano zniżki'
                  resolve(true);
                } else {
                  this.kartaInfo = 'Nieprawidłowa karta lub brak żniżek'
                  resolve(false);
                }
              })
            } else {
              resolve(false);
            }
          });
          // https://api.slupskakarta.pl/integration/benefitList?token=67aec0d6-6847-49ab-bd81-182b8de113be&cardNumber=24458342
          // resolve(true);
        
      })
    },

    calcPrice(date,item,cardOk) {
      var dt = new Date(date+'T00:00:00');

      var cena = cardOk ? 30 : 38;  //normalna wyjściowa cena
        
      

      var wk = dt.getDay();
      var isWeekday = wk!=0 && wk!=6;
      var is6till15 = item.start>=6*60 && (item.start+item.length)<=15*60;

      if (isWeekday && is6till15) cena=cardOk ? 30 : 38;  //cena w dni powszenie


      
      /*
      if (!this.calyObjekt) {
        cena = (cena * this.personCount)/2;
      }
      */

      if (item.light) {
        cena+= cardOk ? 10 : 10;  //cena swiatła
      }
      
      this.$set(item, 'price', cena);
      return cena;

    },
      


    calcAll() {
      if (this.response && this.response.dates) {
        this.checkKarta().then(cardOk => {
          console.log('cardOk',cardOk);
        for (let i = 0; i < this.response.dates.length; i++) {
          let date = this.response.dates[i];

          var dt = new Date(date.date+'T00:00:00');
          var wk = dt.getDay();
          var isWeekday = wk!=0 && wk!=6;

          //sunset & sunrize
          if (date.sunset === undefined) {
            var vv = getSunset(placePosition[0],placePosition[1],dt);
            date.sunset = vv.getHours()*60+vv.getMinutes();
          }
          if (date.sunrise === undefined) {
            var vv = getSunrise(placePosition[0],placePosition[1],dt);
            date.sunrise = vv.getHours()*60+vv.getMinutes();
          }
          



          for (let j = 0; j < date.items.length; j++) {
            let item = date.items[j];
            var is6till15 = item.start>=6*60 && (item.start+item.length)<=15*60;
            if (item.light === undefined && !(isWeekday && is6till15)) {
              var light = item.start<date.sunrise || (item.start+item.length)>date.sunset;
              this.$set(item, 'light', light);
            }
            this.calcPrice(date.date,item,cardOk);
            //this.$set(date.items[j], 'price', this.calcPrice(date.date,date.items[j]))
          
          }
        
        }
      });
      }
    },

    selectAll(sel) {
      for (let i = 0; i < this.response.dates.length; i++) {
        let date = this.response.dates[i];
        //date.picked=sel;
        let allpicked = true;
        for (let j = 0; j < date.items.length; j++) {
          if (!date.items[j].errors) {
            this.$set(date.items[j], 'picked', sel);
          } else {
            this.$set(date.items[j], 'picked', false);
            allpicked = false;
          }

          //date.items[j].picked=sel;
        }
        if (allpicked) {
          this.$set(date, 'picked', sel);
        }
      }
      this.calcAll();
    },
    reload() {
      this.loading = true;
      this.$http.post('reservation/findfreeterms', JSON.stringify(this.reservation), {}).then((response) => {
        this.response = response.data.data;
        this.noUnits = [];
        this.empty = this.response.dates.filter(function (el) {
          return el.errors.includes('brak harmonogramu');
        });
        for (let i = 0; this.response.dates.length > i; i++) {
          let date = this.response.dates[i];

          if (date.useTimeunits == false) {
            //this.response.dates[i].editTimeUnit = false;
            date.minStart = date.items[0].start;
            date.maxEnd = date.items[0].start + date.items[0].length;
          }
          for (let j = 0; date.errors.length > j; j++) {
            if (date.errors[j].indexOf('brak jednostek') != -1) {
              this.noUnits.push(date);
            }
          }
        }
        this.closedDays = this.response.dates.filter(function (el) {
          return el.closed == true;
        });
        var sekunda = 1000;
        var minuta = 60 * sekunda;
        var godzina = 60 * minuta;
        var dzien = 24 * godzina;
        var liczba = 0;
        for (var i = 0; i < this.empty.length - 1; i++) {
          var data = new Date(this.empty[i].date).getTime();
          if (new Date(this.empty[i + 1].date).getTime() - data == dzien) {
            liczba++;
            if (i == this.empty.length - 2) {
              var start = data - dzien * liczba;
              var end = data;
              this.emptylist.push(this.$moment(new Date(start)).format('LL') + '-' + this.$moment(new Date(end)).format('LL'));
            }
          } else {
            var start = data - dzien * liczba;
            liczba = 0;
            var end = data;
            if (start != end) {
              this.emptylist.push(this.$moment(new Date(start)).format('LL') + '-' + this.$moment(new Date(end)).format('LL'));
            } else {
              this.emptylist.push(this.$moment(new Date(start)).format('LL'));
            }
          }
        }
        if (this.empty.length == 1) {
          this.emptylist.push(this.$moment(new Date(this.$fdate(this.dateFrom))).format('LL'));
        }

        this.selectAll(true);

        this.loading = false;
      });
    },
    reloadLink() {
      this.id = this.$route.query.clientId;

      this.dateFrom = this.$route.query.dateFrom;
      this.start = this.$route.query.start;
      this.dateTo = this.$route.query.dateTo;
      this.end = this.$route.query.end;
      this.frequency = this.$route.query.frequency;
      this.type = this.$route.query.type;
      this.option = this.$route.query.option;
      this.reservationId = this.$route.query.reservationId;
      // if (this.reservationId) {
      //   alert('reservationId' + this.reservationId);
      // }

      if (this.$route.query.date) {
        this.dateFrom = this.$route.query.date;
        this.dateTo = this.$route.query.date;
      } else {
        this.dateFrom = this.$route.query.dateFrom;
        this.dateTo = this.$route.query.dateTo;
      }
      if (!this.type) {
        this.type = 0;
      }
      if (!this.dateFrom) {
        this.dateFrom = new Date();
      }

      if (!this.dateTo) {
        this.dateTo = new Date();
      }

      if (!this.frequency) {
        this.frequency = 0;
      }
      if (!this.option) {
        this.option = 'new';
      }
      this.find();
    },
    find() {
      this.emptylist = [];

      if (this.frequency == 0) {
        this.dateTo = this.dateFrom;
      }
      this.reservation = {
        objectId: parseInt(this.objectId),
        type: this.type,
        startDate: this.dateFrom,
        endDate: this.dateTo,
        startFullDate: this.dateFrom,
        endFullDate: this.dateTo,
        repeat: this.frequency,
        start: this.start,
        end: this.end
      };

      this.reload();
      this.responseShow = true;

      this.closeForm = 'close';
    },

    afterSubmit() {
      setTimeout(() => {
        this.$router.push({name: 'ExternReservationsManage',query: {OrderID: this.savedReservation.id,Email: this.savedReservation.email}})

      },1000)

    },

    payNow() {
      /*
                    <input type="hidden" name="ServiceID" :value="savedReservation.payServiceID">
              <input type="hidden" name="OrderID" :value="savedReservation.id">
              <input type="hidden" name="Amount" :value="savedReservation.payPrice">
              <input type="hidden" name="Description" :value="savedReservation.payDescription">
              <input type="hidden" name="CustomerEmail" :value="savedReservation.email">
              <input type="hidden" name="Hash" :value="savedReservation.payHash">
      */
      let post = {
        orderId: this.savedReservation.id,
        email: this.savedReservation.email,
        description: this.savedReservation.payDescription,
        amount: this.savedReservation.payPrice

      }
      this.$http.post('payment/paynow', JSON.stringify(post), {}).then(
        (response) => {
          if (response && response.body/* && response.body.status == 1*/) {


            let redirectUrl = response.body.redirectUrl;
            if (redirectUrl) {
              window.top.location.href = redirectUrl;
            }


            // this.$komunikat('success', 'Sukces', 'Pomyślnie dodane terminy: ' + response.body.data.count, 3000);

          } else {
            this.$komunikat('error', 'Błąd', 'Błąd przy dodawaniu rezerwacji', 3000);
          }
        },
        (response) => {}
      );

    }
  },
  created() {
    this.reloadLink();
    this.loadingUsers = true;
    /*
    this.$http.get('reservation/listtypes').then((response) => {
      this.listTypes = response.data.data;
    });
    */
  },
  watch: {
    $route(to, from) {
      this.objectId = this.$route.params.id;
      this.reloadLink();
    },
    option: function (val) {
      if (this.option == 'update') {
        this.reloadLink();
        this.option = 'update';
      } else {
      }
    },
    calyObjekt: function(newValue) {
      this.calcAll();
    },
    personCount: function(newValue) {
      this.calcAll();
    },
    kartaMiekszancaNr: function(newValue) {
      this.calcAll();
    },
    kartaMiekszancaDataUrodzenia: function(newValue) {
      this.calcAll();
    }
  }
};
</script>

<style scoped>
.plusminus {
  background-color: transparent;
}
.lol {
  background-color: lightblue;
}
.notshow {
  display: none;
}

.displaytrue {
  display: block;
}
.szukaj:hover + .notshow {
  display: block;
}
#frequency {
  margin-bottom: 10px;
}
#edit {
  margin-right: 0.5em;
}
.openForm {
  display: block;
}
.closeForm {
  display: none;
}
label span {
  font-size: 17px;
}
h3.card-title {
  font-size: 1.05rem;
}
</style>