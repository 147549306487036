<template>
  <div class="login-box" style="width: 720px">
    <div class="login-logo">
      <img :src="'/img/logo/' + $config.current.logo" />
    </div>
    <div class="text-center">NOWE KONTO KLIENTA</div>
    <div class="card">
      <div class="card-body login-card-body">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label :class="{'text-danger': $v.email.$anyError}" for="email">Email</label>
              <input :class="{'is-invalid': $v.email.$anyError}" class="form-control" id="email" type="text" v-model.trim="$v.email.$model" />
              <small class="text-danger" v-if="$v.email.$anyError">Błędny email</small>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label :class="{'text-danger': $v.password.$anyError}" for="mobilePhone">Hasło</label>
              <input :class="{'is-invalid': $v.password.$anyError}" class="form-control" id="password" type="password" v-model.trim="$v.password.$model" />
              <small class="text-danger" v-if="$v.password.$anyError">Hasło nie może być puste</small>
            </div>
          </div>
          <!--
          <div class="col-md-6">
            <div class="form-group">
              <label for="surname">Data urodzenia</label>
              <datepicker v-model="dateOfBirth" :language="pl" input-class="form-control" :full-month-name="true" format="dd-MM-yyyy"></datepicker>
            </div>
          </div>
          -->
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label :class="{'text-danger': $v.firstName.$anyError}" for="firstName">Imię</label>
              <input :class="{'is-invalid': $v.firstName.$anyError}" class="form-control" id="firstName" type="text" v-model.trim="$v.firstName.$model" />
              <small class="text-danger" v-if="$v.firstName.$anyError">Imię jest wymagane</small>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label :class="{'text-danger': $v.lastName.$anyError}" for="lastName">Nazwisko</label>
              <input :class="{'is-invalid': $v.lastName.$anyError}" class="form-control" id="lastName" type="text" v-model.trim="$v.lastName.$model" />
              <small class="text-danger" v-if="$v.lastName.$anyError">Nazwisko jest wymagane</small>
            </div>
          </div>
        </div>
        <div class="row">
          <!--
          <div class="col-md-6">
            <div class="form-group">
              <label :class="{'text-danger': $v.email.$anyError}" for="email">Email</label>
              <input :class="{'is-invalid': $v.email.$anyError}" class="form-control" id="email" type="text" v-model.trim="$v.email.$model" />
              <small class="text-danger" v-if="$v.email.$anyError">Błędny email</small>
            </div>
          </div>
          -->
          <div class="col-md-6">
            <div class="form-group">
              <label :class="{'text-danger': $v.mobilePhone.$anyError}" for="mobilePhone">Nr telefonu</label>
              <input :class="{'is-invalid': $v.mobilePhone.$anyError}" class="form-control" id="mobilePhone" type="text" v-model.trim="$v.mobilePhone.$model" />
              <small class="text-danger" v-if="$v.mobilePhone.$anyError">Błędny numer telefonu</small>
            </div>
          </div>
          <!--
          <div class="col-md-6">
            <div class="form-group">
              <label for="surname">Data urodzenia</label>
              <datepicker v-model="dateOfBirth" :language="pl" input-class="form-control" :full-month-name="true" format="dd-MM-yyyy"></datepicker>
            </div>
          </div>
          -->
        </div>
        <!--
        <div class="row">
            
          <div class="col-md-6">
            <div class="form-group">
              <label :class="{'text-danger': $v.pesel.$anyError}" for="surname">Pesel</label>
              <input :class="{'is-invalid': $v.pesel.$anyError}" class="form-control" id="surname" type="text" v-model.trim="$v.pesel.$model" />
              <small class="text-danger" v-if="$v.pesel.$anyError">Błędny pesel</small>
            </div>
          </div>
          
          <div class="col-md-6">
            <div class="form-group">
              <label for="surname">Płeć</label>
              <select class="form-control" id="surname" type="text" v-model="sex">
                <option value="M">M</option>
                <option value="K">K</option>
              </select>
            </div>
          </div>
        </div>
        -->
        <!--
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label :class="{'text-danger': $v.mobilePhone.$anyError}" for="surname">Nr telefonu</label>
              <input :class="{'is-invalid': $v.mobilePhone.$anyError}" class="form-control" id="surname" type="text" v-model.trim="$v.mobilePhone.$model" />
              <small class="text-danger" v-if="$v.mobilePhone.$anyError">Błędny numer telefonu</small>
            </div>
          </div>
        </div>
        -->

        <button type="button" class="btn btn-default" data-dismiss="modal" @click="cancel()">Anuluj</button>&nbsp;
        <button type="button" class="btn btn-info" data-dismiss="modal" @click="save()">Załóż konto</button>
      </div>
      <!-- /.login-card-body -->
    </div>
  </div>
</template>

<script>
import {required, numeric, email, minLength, maxLength, helpers} from 'vuelidate/lib/validators';
import Datepicker from 'vuejs-datepicker';
import {pl} from 'vuejs-datepicker/dist/locale';
import methods from '@/methods';
import websocket from '@/websocket';
function pesel(pesel) {
  return this.$pesel(pesel);
}

export default {
  name: 'Create',
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      dateOfBirth: '',
      mobilePhone: '',
      pesel: '',
      sex: '',
      nazwaFirmy: '',
      nip: '',
      adres: '',
      password: '',
      pl: pl
      // showLocal: this.show,
      // client: {...this.model}
    };
  },
  components: {
    Datepicker
  },
  validations: {
    firstName: {
      required
    },
    lastName: {
      required
    },
    email: {
      email
    },
    password: {
      required
    },
    pesel: {
      numeric,
      minLength: minLength(11),
      maxLength: maxLength(11),
      pesel
    },
    mobilePhone: {
      numeric
      //minLength: minLength(9)
    }
  },
  methods: {
    cancel() {
      this.$router.push('/login');
    }
  }
};
</script>

<style scoped>
img {
  filter: brightness(2) invert(1);
}
#error {
  color: red;
}
</style>
