<template>
  <div class="row">
    <div class="col-12">
      <div class="card card-primary card-outline">
        <div class="card-header">
          <h3 class="card-title">Lista pracowników <loading-icon v-if="loading" /> &nbsp;</h3>

          <app-search :color="'btn-primary'" :post="post" @reload="reload" @post="post = $event" />
        </div>

        <div class="card-body p-0">
          <div class="mailbox-controls clientitem top" style="height:90px;">
            <app-pages
              :filteredCount="filteredCount"
              :maxcount="maxcount"
              :colorActive="'#000'"
              :backgroundActive="'#ffc107'"
              :post="post"
              @post="post = $event"
              @reload="reload"
            >
              <button type="button" class="btn btn-primary" @click="showAdd">
                <i class="fa fa-fw fa-plus" />Dodaj pracownika
              </button>
            </app-pages>
          </div>

          <div class="table-responsive mailbox-messages">
            <div v-if="!employees.length" class="alert alert-warning text-center">Brak pracowników</div>

            <div v-for="employee in employees" :key="employee.id">
              <app-employee-item
                :employee="employee"
                @delete="showDelete(employee)"
                @change="showChangePassword(employee)"
                @details="showChangeDetails(employee)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <app-delete v-if="deleteModal" :show="deleteModal" :object="editObject" @cancel="cancel" @delete="deleteEmployee" />

    <app-add v-if="addModal" :show="addModal" @reload="reload" @cancel="cancel" />

    <app-change-password v-if="changePasswordModal" :user="editObject" :show="changePasswordModal" @cancel="cancel" />
    <app-change-details v-if="changeDetailsModal" :user="editObject" :show="changeDetailsModal" @cancel="cancel" />
  </div>
</template>

<script>
import loadingIcon from '../modules/LoadingIcon.vue';
import appPages from '../modules/Pages.vue';
import appSearch from '../modules/Search.vue';
import appEmployeeItem from './EmployeeItem.vue';
import appDelete from '../modules/Delete.vue';
import appAdd from './Add.vue';
import appChangePassword from './ChangePassword.vue';
import appChangeDetails from './ChangeDetails.vue';

// @vuese
// Lista pracowników
// @group employees
export default {
  components: {
    loadingIcon,
    appPages,
    appSearch,
    appEmployeeItem,
    appDelete,
    appAdd,
    appChangePassword,
    appChangeDetails
  },
  data() {
    return {
      employees: [],
      loading: false,
      deleteModal: false,
      addModal: false,
      changePasswordModal: false,
      changeDetailsModal: false,
      maxcount: 0,
      filteredCount: 0,
      post: { draw: 1, roleName: 'ADMIN' },
      editObject: {}
    };
  },
  mounted() {
    this.reloadLink();
    this.reload();
  },
  methods: {
    // @vuese
    // Pobiera dane z query
    reloadLink() {
      this.post.q = this.$route.query.q;
      this.post.page = this.$route.query.page;
      this.post.skip = this.$route.query.skip;
      this.post.count = this.$route.query.count;
      if (!this.post.count) {
        this.post.count = 50;
      }
      if (!this.post.skip) {
        this.post.skip = 0;
      }
      if (!this.post.page) {
        this.post.page = 1;
      }
    },

    // @vuese
    // Odświeżenie listy
    reload() {
      this.loading = true;
      this.$http.post('account/find', JSON.stringify(this.post), {}).then(response => {
        if (response && response.body && response.body.status === 1) {
          this.employees = response.data.data.data;
          this.maxcount = response.data.data.count;
          this.filteredCount = response.data.data.filteredCount;

          this.loading = false;
        } else {
          this.error = response.body.messages;
        }
      });
    },

    // @vuese
    // Usuwanie pracownika
    deleteEmployee(id) {
      this.$http.get('account/delete/' + id).then(response => {
        if (response && response.body && response.body.status === 1) {
          this.reload();
          this.$komunikat('success', 'Sukces', 'Pomyślnie usunięto pracownika', 3000);
        } else {
          this.$komunikat('error', 'Błąd', 'Błąd przy usuwaniu pracownika', 3000);
        }
        this.cancel();
      });
    },

    // @vuese
    // Usuwanie pracownika
    showDelete(employee) {
      this.editObject = employee;
      this.deleteModal = true;
    },

    // @vuese
    // Pokazuje modal dodawania pracownika
    showAdd() {
      this.addModal = true;
    },

    // @vuese
    // Pokazuje modal zmiany hasła
    showChangePassword(employee) {
      this.editObject = employee;
      this.changePasswordModal = true;
    },

    // @vuese
    // Pokazuje modal zmiany hasła
    showChangeDetails(employee) {
      this.editObject = employee;
      this.changeDetailsModal = true;
    },

    // @vuese
    // Chowa modal
    cancel() {
      this.deleteModal = false;
      this.addModal = false;
      this.changePasswordModal = false;
      this.changeDetailsModal = false;
    }
  }
};
</script>
