<template>
  <b-modal v-model="showLocal" @hidden="$emit('cancel')">
    <h5 slot="modal-title">Dodaj usługę</h5>
    <div slot="modal-header-close">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$emit('cancel')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="box-body">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label :class="{'text-danger': $v.name.$anyError}" for="surname">Nazwa usługi</label>
            <input :class="{'is-invalid': $v.name.$anyError}" class="form-control " id="surname" type="text" v-model.trim="$v.name.$model" />
            <small class="text-danger" v-if="$v.name.$anyError">Nazwa usługi jest wymagana</small>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label for="serviceType">Typ usługi</label>
            <select class="form-control " id="serviceType" type="text" v-model="type">
              <option value="DEFAULT">Domyślna</option>
              <option value="PASS">Karnet</option>
              <option value="CLASSES">Zajęcia</option>
              <option value="INDIVIDUAL_CLASSES">Zajęcia indywidualne</option>
              <option value="SUBSCRIPTION_7_DAYS">Abonament tygodniowy</option>
              <option value="SUBSCRIPTION_30_DAYS">Abonament miesięczny</option>
              <option value="SUBSCRIPTION_365_DAYS">Abonament roczny</option>
            </select>
          </div>
        </div>

        <div v-if="!$isSubscribtion(type)" class="col-md-12">
          <div class="form-group">
            <label :class="{'text-danger': $v.count.$anyError}" for="surname">Ilość wejść (zostaw puste gdy bez limitu)</label>
            <input :class="{'is-invalid': $v.count.$anyError}" class="form-control " id="surname" type="text" v-model.trim="$v.count.$model" />
            <small class="text-danger" v-if="$v.count.$anyError">Ilość wejść musi być liczbą</small>
          </div>
        </div>

        <div class="col-md-12">
          <div class="form-group">
            <label :class="{'text-danger': $v.price.$anyError}" for="surname">Cena</label>
            <span class="float-right">
              <input v-model="hidden" type="checkbox" class="mr-2" id="hidden" />
              <label for="hidden">Ukryj Cenę</label>
            </span>
            <input :class="{'is-invalid': $v.price.$anyError}" class="form-control " id="surname" type="text" v-model.trim="$v.price.$model" />
            <small class="text-danger" v-if="$v.price.$anyError">Cena musi być liczbą</small>
          </div>
        </div>

        <div v-if="!$isSubscribtion(type)" class="col-md-12">
          <div class="form-group">
            <label :class="{'text-danger': $v.daysLength.$anyError}" for="surname">Długość trwania usługi (dni)</label>
            <input :class="{'is-invalid': $v.daysLength.$anyError}" class="form-control " id="surname" type="text" v-model.trim="$v.daysLength.$model" />
            <small class="text-danger" v-if="$v.daysLength.$anyError">Długość twania usługi musi być liczbą</small>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal-footer -->
    <div slot="modal-footer">
      <button type="button" class="btn btn-default" data-dismiss="modal" @click="$emit('cancel')">Wyjdź</button>&nbsp;
      <b-button id="save" type="button" class="btn btn-secondary" data-dismiss="modal" @click="save()">Zapisz</b-button>
    </div>
    <!-- /Modal-footer -->
  </b-modal>
</template>
<script>
import {required, numeric} from 'vuelidate/lib/validators';

export default {
  props: {
    show: Boolean,
    data: Object
  },

  data() {
    return {
      showLocal: this.show,
      form: {...this.data},
      name: this.data.name,
      price: this.data.price,
      count: this.data.count,
      daysLength: this.data.daysLength,
      hidden: this.data.hidden,
      type: this.data.type || 'DEFAULT'
    };
  },
  validations: {
    name: {
      required
    },
    price: {
      numeric
    },
    count: {
      numeric
    },
    daysLength: {
      numeric
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    save() {
      if (this.$v.$invalid == true) {
        this.$v.$touch();
      } else {
        const subscriptionForm = this.$isSubscribtion(this.type);

        this.form = {
          name: this.name,
          price: this.price,
          count: subscriptionForm ? undefined : this.count,
          daysLength: subscriptionForm ? undefined : this.daysLength,
          type: this.type,
          hidden: this.hidden
        };

        this.form.id = this.data.id;
        this.$http.post('service/' + (this.form.id ? 'update' : 'add'), JSON.stringify(this.form), {}).then(
          response => {
            if (response && response.body && response.body.status == 1) {
              this.$emit('reload');
              this.$emit('cancel');
              this.$komunikat('success', 'Sukces', 'Pomyślnie dodano usługe', 3000);
            } else {
              this.error = response.body.messages;
              this.$komunikat('error', 'Błąd', 'Błąd przy dodawaniu usługi', 3000);
            }
          },
          response => {}
        );
      }
    }
  },
  watch: {
    showLocal: function(val) {
      if (this.showLocal == false) {
        this.$emit('cancel');
      }
    }
  }
};
</script>
