<template>
  <div>
    <div class="row">
      <details-service :service="serviceDetails" />

      <search :searchDateStart="searchDateStart" :searchDateEnd="searchDateEnd" @search="getSubscription" />
    </div>

    <periods :periods="subscriptionPeriod" @pay="showInfo" />

    <div class="row">
      <div v-if="loading" class="col-md-3">
        <loading-icon />
      </div>
    </div>

    <payment-modal text="Potwierdź płatność" :show="showModal" @cancel="hideModal" @submit="pay" />
  </div>
</template>

<script>
import loadingIcon from '../modules/LoadingIcon.vue';
import Periods from './subscription/Periods.vue';
import Search from './subscription/Search.vue';
import PaymentModal from '@/components/modules/PaymentModal.vue';
import detailsService from './subscription/Details.vue';

export default {
  data() {
    return {
      subscriptionPeriod: [],
      loading: true,
      searchDateStart: new Date(),
      searchDateEnd: new Date(),
      cardOwnerServiceId: undefined,
      serviceDetails: {},
      showModal: false,
      selectedPeriod: undefined
    };
  },

  mounted() {
    this.getService();
  },
  methods: {
    // Pobiera usługe
    getService() {
      this.$http
        .get(`cardOwner/service/${this.$route.params.cardOwnerServiceId}`)
        .then(data => data.body.data)
        .then(cardOwnerService => {
          this.cardOwnerServiceId = cardOwnerService.id;
          this.getSubscription(new Date(cardOwnerService.dateStart), cardOwnerService.dateEnd ? new Date(cardOwnerService.dateEnd) : new Date());
          this.getServiceDetails(cardOwnerService.serviceId).then(() => {
            this.serviceDetails = {...this.serviceDetails, ...cardOwnerService};
          });
        });
    },
    // Pobiera subskrypcje
    getSubscription(dateStart, dateEnd) {
      this.searchDateStart = dateStart;
      this.searchDateEnd = dateEnd;
      this.loading = true;
      this.$http
        .post('cardOwner/subscription', {
          cardOwnerServiceId: this.cardOwnerServiceId,
          dateEnd,
          dateStart
        })
        .then(subscription => {
          this.subscriptionPeriod = subscription.body.data;
          this.loading = false;
        });
    },
    // Pobiera szczegóły usługi
    getServiceDetails(id) {
      return this.$http.get(`service/get/${id}`).then(service => {
        this.serviceDetails = service.body.data;
      });
    },
    // Pokazuje modal
    showInfo(period) {
      this.selectedPeriod = period;
      this.showModal = true;
    },
    // Chowa modal
    hideModal() {
      this.$set(this, 'showModal', false);
    },
    // Opłaca okres
    pay(date) {
      this.$http
        .post('cardOwner/subscription/pay', {
          cardOwnerServiceId: this.cardOwnerServiceId,
          date,
          period: this.selectedPeriod
        })
        .then(() => {
          this.hideModal();
          this.getSubscription(this.searchDateStart, this.searchDateEnd);
        });
    }
  },
  components: {
    loadingIcon,
    Periods,
    Search,
    PaymentModal,
    detailsService
  }
};
</script>
