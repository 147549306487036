<template>
  <div>
    <div class="col-12">
      <div class="card card-warning card-outline">
        <!-- card-header -->
        <div class="card-header no-print">
          <h3 class="card-title row">
            <div class="btn-group">
              <button class="btn btn-dark btn-sm" @click.prevent="prevMonth()" title="poprzedni miesiąc">
                <i class="fa fa-angle-left" style="cursor: pointer" aria-hidden="true"></i>
              </button>
              <button class="btn btn-dark btn-sm" @click.prevent="thisMonth()">{{ monthsName }} {{ year }}</button>
              <button class="btn btn-dark btn-sm" @click.prevent="nextMonth()">
                <i class="fa fa-angle-right" style="cursor: pointer" aria-hidden="true" title="następny miesiąc"></i>
              </button>
            </div>
            &nbsp;
            <div style="padding-left: 5px">Podsumowanie wykorzystania w miesiącu {{ monthsName }} {{ year }}</div>

            <loading-icon v-if="loading"></loading-icon>&nbsp;
          </h3>
        </div>

        <div class="card-header">
          <div class="row text-right text-bold">
            <div class="col-4 text-left">Klient</div>
            <div class="col-2">Zarezerwowane</div>
            <div class="col-2">Wykorzystane</div>
            <div class="col-2">Godzin</div>
            <div class="col-2 text-center">Razem</div>
          </div>
        </div>
        <!-- /.card-header -->
        <div class="card-body" v-if="raports.byclient">
          <div class="row text-right" v-for="(raport, index) in raports.byclient" :key="index">
            <div style="border-bottom: 1px solid #eaeaea; border-left: 1px solid #eaeaea" class="col-4 text-left">{{ raport.clientName }}</div>
            <div style="border-bottom: 1px solid #eaeaea; border-left: 1px solid #eaeaea" class="col-2">{{ raport.count }}</div>
            <div style="border-bottom: 1px solid #eaeaea; border-left: 1px solid #eaeaea" class="col-2">{{ raport.usedCount }}</div>
            <div style="border-bottom: 1px solid #eaeaea; border-left: 1px solid #eaeaea" class="col-2">{{ raport.usedLength | hminutesum }}</div>
            <div style="border-bottom: 1px solid #eaeaea; border-left: 1px solid #eaeaea" class="col-2 text-right pr-4">{{ raport.usedPrice | money }}</div>
          </div>
        </div>
        <div class="card-footer">
          <div class="row text-right" v-if="raports.byclient && raports.byclient.length">
            <div class="col-4 text-left">
              <b>Razem</b>
            </div>
            <div class="col-2">{{ raports.total.count }}</div>
            <div class="col-2">{{ raports.total.usedCount }}</div>
            <div class="col-2">{{ raports.total.usedLength | hminutesum }}</div>
            <div class="col-2 text-right pr-4">{{ raports.total.usedPrice | money }}</div>
          </div>
          <div class="alert alert-error text-center" v-if="!hasPerm">Brak uprawnień do obiektu</div>
          <div class="alert alert-warning text-center" v-else-if="!raports.byclient || !raports.byclient.length">Brak raportów</div>
          <div class="table-responsive mailbox-messages"></div>
        </div>
      </div>
    </div>

    <div class="col-12" v-if="hasPerm">
      <h3>Szczegóły wykorzystania</h3>
      <div class="card card-warning card-outline" v-for="(raport, index) in raports.byclient" :key="index">
        <!-- card-header -->
        <div class="card-header">
          <h3 class="card-title">{{ raport.clientName }}</h3>
          <div class="row text-right text-bold">
            <div class="col-2 text-left">Obiekt</div>
            <div class="col-2 text-left">Typ</div>
            <div class="col-1">Cena</div>
            <div class="col-2 text-right">Zarezerwowane</div>
            <div class="col-2 text-right">Wykorzystane</div>
            <div class="col-1 text-center">h</div>
            <div class="col-2">Razem</div>
          </div>
        </div>
        <!-- /.card-header -->
        <div class="card-body">
          <div style="border-top: 1px solid #c4c4c4" class="text-center" v-for="(rtype, index) in raport.bytype" :key="index">
            <div class="row">
              <div class="col-2 text-left" style="border-bottom: 1px solid #eaeaea">{{ rtype.objectName }}</div>
              <div class="col-2 text-left" style="border-bottom: 1px solid #eaeaea; border-left: 1px solid #eaeaea">{{ rtype.reservationTypeName }}</div>
              <div class="col-1" style="border-bottom: 1px solid #eaeaea; border-left: 1px solid #eaeaea">{{ rtype.price | money }}</div>
              <div class="col-2" style="border-bottom: 1px solid #eaeaea">{{ rtype.count }}</div>
              <div class="col-2" style="border-bottom: 1px solid #eaeaea">{{ rtype.usedCount }}</div>
              <div class="col-1" style="border-bottom: 1px solid #eaeaea">{{ rtype.usedLength | hminutesum }}</div>

              <div class="col-2 text-right pr-4 pl-0" style="border-bottom: 1px solid #eaeaea; border-left: 1px solid #eaeaea">{{ rtype.usedPrice | money }}</div>
            </div>
            <template v-if="rtype.hours">
              <div v-for="(hour, index) in rtype.hours" :key="index" class="row clientitem2">
                <div class="col-2"></div>
                <div class="col-2"></div>
                <div class="col-2" style="border-left: 1px solid #eaeaea">{{ hour.start | hminute }} - {{ (hour.start + hour.length) | hminute }}</div>
                <div class="col-1 text-right" style="border-left: 1px solid #eaeaea">{{ (hour.usedPrice / hour.usedCount) | money }}</div>
                <div class="col-2" style="border-left: 1px solid #eaeaea">{{ hour.count }}</div>
                <div class="col-1" style="border-left: 1px solid #eaeaea">{{ hour.usedCount }}</div>
                <div class="col-1 text-right" style="border-left: 1px solid #eaeaea">{{ hour.usedPrice | money }}</div>
                <div class="col-1"></div>
              </div>
            </template>
          </div>
          <div class="table-responsive mailbox-messages">
            <div class="alert alert-warning text-center" v-if="!raports.byclient.length">Brak raportów</div>
          </div>
        </div>
        <div class="card-footer">
          <div class="row text-center">
            <div class="col-5 text-left pl-4"></div>

            <div class="col-2">{{ raport.count }}</div>
            <div class="col-2">{{ raport.usedCount }}</div>
            <div class="col-1">{{ raport.usedLength | hminutesum }}</div>
            <div class="col-2 text-right pr-4 pl-0">{{ raport.usedPrice | money }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import loadingIcon from '../modules/LoadingIcon.vue';
export default {
  components: {
    loadingIcon
  },
  data() {
    return {
      months: '',
      year: '',
      monthsName: '',
      raports: {details: [], query: {}, summary: []},
      loading: false,
      hasPerm: true,
      objectID: this.$route.params.id,
      objectPath: ''
    };
  },
  methods: {
    reload() {
      this.objectID = this.$route.params.id;
      this.hasPerm = this.$hasPerm(+this.objectID);
      this.months = this.$route.query.months;
      var date = new Date();
      this.year = this.$route.query.year;
      var fixRoute = false;
      if (!this.months) {
        this.months = date.getMonth() + 1;
        fixRoute = true;
      }
      if (!this.year) {
        this.year = date.getFullYear();
        fixRoute = true;
      }

      this.loading = true;
      this.$http.get('object/get/' + this.objectID).then((res1) => {
        var obj = res1.data.data;
        // console.log('object',obj);
        this.objectPath = obj.path;
        this.hasPerm = this.$hasPerm(+this.objectID,this.objectPath);
        if (this.hasPerm) {
          this.$http.get('object/monthreport/' + this.objectID + '?month=' + this.months + '&year=' + this.year).then((response) => {
            this.raports = response.data.data;
            this.months = this.raports.query.month;
            this.monthsName = this.raports.query.monthName;
            this.year = this.raports.query.year;
            this.loading = false;
          });
        } else {
          this.monthsName = this.$months[this.months - 1];
          // $months
        }
      });

      if (fixRoute) {
        this.$router.push({
          name: 'ObjectRaports',
          //params: {id: 100},
          query: {
            months: this.months,
            year: this.year
          }
        });
      }
    },
    prevMonth() {
      this.months--;
      if (this.months == 0) {
        this.months = 12;
        this.year--;
      }
      this.$router.push({
        name: 'ObjectRaports',
        //params: {id: 100},
        query: {
          months: this.months,
          year: this.year
        }
      });
    },
    nextMonth() {
      this.months++;
      if (this.months == 13) {
        this.months = 1;
        this.year++;
      }
      this.$router.push({
        name: 'ObjectRaports',
        //params: {id: 100},
        query: {
          months: this.months,
          year: this.year
        }
      });
    }
  },
  watch: {
    months: function (value) {
      //this.reload();
    },
    $route(to, from) {
      this.reload();
    }
  },
  created() {
    this.reload();
  }
};
</script>

<style scoped>
.clientitem2 {
  position: relative;
  border-bottom: 1px solid #eaeaea;
}
</style>
