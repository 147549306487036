<template>
  <div onselectstart="return false" onselect="return false" ondragstart="return false" ondrag="return false" class="row">
    <div class="col-3 print-only"><img src="/img/logo/sosir-logo-black.png" width="100%" /></div>
    <div class="col-9 print-only font-lg">
      <h1>GRAFIK</h1>
      <h2>
        {{ currentDate | fdate }} {{ currentDate | dayOfWeek }} <b v-if="object">{{ object.name }}</b>
      </h2>
    </div>

    <div class="col-12">
      <div class="card card-success card-outline">
        <!-- card-header -->

        <div class="card-header">
          <h3 class="card-title row">
            <div class="btn-group no-print">
              <button v-if="isAuth" class="btn btn-dark btn-sm" @click.prevent="prevWeek()" title="poprzedni tydzień">
                <i class="fa fa-angle-double-left" style="cursor: pointer" aria-hidden="true"></i>
              </button>
              <button v-if="canPrevDay" class="btn btn-dark btn-sm" @click.prevent="prevDay()" title="poprzedni dzień">
                <i class="fa fa-angle-left" style="cursor: pointer" aria-hidden="true"></i>
              </button>
              <button v-if="canToday" class="btn btn-dark btn-sm" @click.prevent="today()">Dziś</button>

              <datepicker
                :language="pl"
                :input-class="'btn btn-dark btn-sm'"
                :wrapper-class="'float-right'"
                :full-month-name="true"
                :disabled-dates="maxDate && minDate ? {to: minDate, from: maxDate} : undefined"
                v-model="currentDate"
                @input="selected"
                format="D dd.MM.yyyy"
              >
              </datepicker>
              <button v-if="canNextDay" class="btn btn-dark btn-sm" @click.prevent="nextDay()">
                <i class="fa fa-angle-right" style="cursor: pointer" aria-hidden="true" title="następny dzień"></i>
              </button>
              <button v-if="isAuth" class="btn btn-dark btn-sm" @click.prevent="nextWeek()" title="następny tydzień">
                <i class="fa fa-angle-double-right" style="cursor: pointer" aria-hidden="true"></i>
              </button>
            </div>

            <div class="btn-group no-print" style="margin-left: 5px">
              <button class="btn btn-danger btn-sm" v-if="(hasPerm || !isAuth) && displayTerms" @click="addTerm(selectedObject, selectedItem)">
                <i class="fa fa-plus"></i> Rezerwuj zaznaczone
              </button>

              &nbsp;
              <button class="btn btn-success btn-sm" v-if="hasPerm" @click="showPrice = !showPrice"><i class="fa fa-usd fa-fw"></i> Pokaż ceny</button>
              &nbsp;
              <button
                class="btn btn-success btn-sm"
                v-if="hasPerm"
                @click="
                  ungroupAll();
                  selectObjectMode = !selectObjectMode;
                "
              >
                <i class="fa fa-check"></i> Zaznacz rezerwacje
              </button>
              <button class="btn btn-danger btn-sm" v-if="hasPerm && selectObjectMode && selectedObjectCount > 0" @click="cancelSelected()">
                <i class="fa fa-cancel"></i> Anuluj zaznaczone ({{ selectedObjectCount }})
              </button>
            </div>

            <loading-icon v-if="loading" style="margin-left: 5px"></loading-icon> &nbsp;
          </h3>
          <div class="card-tools no-print">
            <router-link
              v-if="isAuth && objectId && objects.length && objectId == objects[0].id"
              class="btn btn-primary"
              :to="{name: isAuth ? 'TimelineWeek' : 'ExternTimelineWeek', params: {id: objectId}, query: {date: $fdate(currentDate)}}"
              >Tydzień</router-link
            >
            <div class="btn-group" role="group">
              
              <button
                v-if="!isAuth && (objectId == 411 || objectId == 412)"
                class="btn btn-default"
                @click="$router.push({name: 'ExternTimeline', params: {id: 410}, query: {date: $fdate(currentDate)}})"
              >
                <i class="fa fa-fw fa-arrow-left"></i> Korty
              </button>
              <button class="btn btn-default" type="button" :class="{active: minuteHeight == 0.7}" @click="minuteHeight = 0.7">
                <i class="fa fa-bars" style="font-size: 80%"></i>
              </button>
              <button class="btn btn-default" type="button" :class="{active: minuteHeight == 1}" @click="minuteHeight = 1">
                <i class="fa fa-bars"></i>
              </button>
              <button class="btn btn-default" type="button" :class="{active: minuteHeight == 2}" @click="minuteHeight = 2">
                <i class="fa fa-bars" style="font-size: 120%"></i>
              </button>
            </div>
          </div>

                    <div v-if="!isAuth" style="color: gray;position: absolute; right: 2px; top: 45px;">
           <small>ver {{ $appVersion }}</small>

          </div>

          
        </div>

        <!-- /.card-header -->
        <div class="card-body p-0" style="overflow: hidden; overflow-x: auto">
          <div class="row" style="position: relative; margin: 0; overflow: hidden; height: 3rem" :style="{minWidth: minTotalWidth + 'px'}">
            <div style="width: 50px; height: 30px"></div>
            <div class="row" style="position: absolute; left: 50px; right: 0px; margin: 0">
              <div v-for="object in objects" :key="'th' + object.id" class="col text-center objectTitle" style="position: relative">
                <input
                  v-if="selectObjectMode && object.reservations && object.reservations.length && hasRole('admin')"
                  type="checkbox"
                  @click="selectObject(object, $event)"
                  style="margin-right: 5px"
                />
                <router-link class="no-print" :title="object.name" :to="(isAuth ? '/objects/timeline/' : '/extern/timeline/') + object.id + '?date=' + $fdate(currentDate)">{{
                  object.name
                }}</router-link>
                <span class="print-only">{{ object.name }}</span>
              </div>
            </div>
          </div>
          <div class="row" v-bind:style="{position: 'relative', margin: 0, height: totalHeight + 'px', minWidth: minTotalWidth + 'px'}">
            <div style="width: 50px; padding-left: 3px; margin-top: -13px">
              <div v-for="hour in hours" :key="'hour' + hour" class="hourdesc" v-bind:style="{height: minuteHeight * 60 + 'px'}">{{ hour | hminute }}</div>
            </div>
            <div class="row" style="position: absolute; left: 50px; right: 0; margin: 0">
              <div v-if="currentMinute >= startMinute" class="calcurrentminute" :style="{top: (currentMinute - startMinute) * minuteHeight + 'px'}"></div>
              <div
                class="col calitemobject"
                v-for="object in objects"
                :key="'td' + object.id"
                :style="{position: 'relative', height: totalHeight + 'px'}"
                @mousedown="freeDown($event, object, null)"
              >
                <!--
                <div v-for="res in object.reservations" :key="'res'+res.id" v-bind:style="{backgroundColor: $rgba(res.clientColor),position: 'absolute',top: (res.start-startMinute)+'px',left: 0, height:(minuteHeight*res.length)+'px', width: '100%',overflow: 'hidden'}">
                  <div>{{res.clientName}}</div>
                  <div>{{res.start | hminute}} - {{(res.start+res.length) | hminute}}</div>
                </div>
                -->
                <div v-if="!object.timetable || object.timetable.notimetable">
                  <small>
                    <i v-if="isAuth">brak harmonogramu</i>
                    <i v-else>brak możliwości rezerwacji</i>
                  </small>
                </div>
                <div v-else-if="object.timetable && object.timetable.closed">
                  <small>
                    <i class="text-danger">zamknięte</i>
                  </small>
                </div>
                <!--
                <div v-for="hour in hours" :key="'hour'+object.id+hour" class="calitemhour" v-bind:style="{height:(minuteHeight*60)+'px',position: 'absolute',top:(hour-startMinute)*minuteHeight+'px'}"></div>
                -->
                <drag-select
                  :enabled="!selectObjectMode"
                  :object="object"
                  :oldObject="selectedObject.id"
                  @display="displayTerms = $event"
                  v-on:selected="saveSelected"
                  selectorClass="item"
                  v-if="object.timetable && object.timetable.timeunits"
                >
                  <template slot-scope="{selectedItems}">
                    <div
                      v-for="(ttitem, ttIndex) in object.timetable.timeunits"
                      :class="getClasses(ttitem.id, selectedItems)"
                      :data-item="ttitem.id"
                      :id="ttIndex"
                      :key="ttitem.id"
                      class="calitem free"
                      :style="{
                        height: ttitem.length * minuteHeight + 'px',
                        lineHeight: ttitem.length * minuteHeight + 'px',
                        top: (ttitem.start - startMinute) * minuteHeight + 'px'
                      }"
                    >
                      <span>{{ ttitem.start | hminute }} - {{ (ttitem.start + ttitem.length) | hminute }}</span>
                    </div>
                  </template>
                </drag-select>
                <timeline-item
                  v-for="res in object.reservations"
                  @click="itemclick(res, object.maxPersonCount)"
                  :key="'res_' + res.id + '_' + res.length"
                  :res="res"
                  :startMinute="startMinute"
                  :minuteHeight="minuteHeight"
                  :childs="object.childrenCount || 0"
                  :selectMode="selectObjectMode"
                  @select="selectItem(res)"
                  :showPrice="showPrice"
                  
                ></timeline-item>
                <timeline-item
                  v-if="newItem && newItem.objectId == object.id"
                  @click="newitemclick()"
                  :res="newItem"
                  :startMinute="startMinute"
                  :minuteHeight="minuteHeight"
                  :childs="object.childrenCount || 0"
                ></timeline-item>
              </div>
            </div>
          </div>
        </div>
        <!--
        <div v-if="loading" class="overlay">
                <i class="fa fa-refresh fa-spin"></i>
              </div>
-->
        <div class="card-footer no-print">Możesz wybrać jeden termin, klikając go, lub wybrać wiele przeciągając po nich myszką.</div>
        <timeline-ItemMore
          v-if="showMore"
          :res="resMore"
          :show="showMore"
          :has-perm="hasPerm"
          v-on:cancel="cancel()"
          v-on:reload="reload()"
          v-on:cancelItem="itemCancel($event)"
          @changePrice="changePrice($event)"
        ></timeline-ItemMore>
      </div>
    </div>
    <app-cancel :show="hasPerm && showCancel" :picked="pickedId" @cancel="cancel()" @reload="reload()" :option="Modaloption"></app-cancel>
    <change-price :show="hasPerm && showChangePrice" :res="resMore" @cancel="showChangePrice = false" @reload="reload()"></change-price>
  </div>
</template>
<script>
import timelineItem from './TimelineItem.vue';
import timelineItemMore from './TimelineItemMore.vue';
import loadingIcon from '../../modules/LoadingIcon.vue';
import {setTimeout} from 'timers';
import Datepicker from 'vuejs-datepicker';
import {pl} from 'vuejs-datepicker/dist/locale';
import DragSelect from '../../modules/DragSelect';
import appCancel from '../../reservations/CancelingReservation.vue';
import changePrice from '../../reservations/ChangePrice.vue';

export default {
  components: {
    timelineItem,
    timelineItemMore,
    loadingIcon,
    Datepicker,
    DragSelect,
    appCancel,
    changePrice
  },
  data() {
    this.$options.minObjectWidth = 150;

    return {
      currentDate: new Date(),
      maxDate: null,
      minDate: null,
      newItem: null,
      objectId: this.$route.params.id,
      object: null,
      objects: [],
      tdwidth: 0,
      startMinute: 600,
      endMinute: 1320,
      minTotalWidth: 0,
      minuteHeight: 0.7,
      totalHeight: 0,
      displayTerms: false,
      currentMinute: -1,
      loading: false,
      hours: [],
      resMore: {},
      showMore: false,
      showPrice: false,
      showChangePrice: false,
      pl,
      selectedObject: {},
      selectedItem: '',
      selectObjectMode: false,
      selectedObjectCount: 0,
      showCancel: false,
      pickedId: [],
      Modaloption: 'cancel',
      isAuth: false,
      hasPerm: false
    };
  },
  watch: {
    $route(to, from) {
      this.readRoute();
    },
    minuteHeight() {
      this.totalHeight = (this.endMinute - this.startMinute) * this.minuteHeight + 20;
      if (window.localStorage) {
        window.localStorage.setItem('minuteHeight', this.minuteHeight);
      }
    }
  },
  destroyed() {
    this.$store.commit('setTitle', null);
  },
  computed: {
    canNextDay() {
      let nextDate = this.$addDay(this.currentDate, 1);
      if (this.maxDate && nextDate > this.maxDate) {
        return false;
      }
      return true;
    },
    canPrevDay() {
      let nextDate = this.$addDay(this.currentDate, -1);
      if (this.minDate && nextDate < this.minDate) {
        return false;
      }
      return true;
    },
    canToday() {
      let newDate = new Date();
      if (this.minDate && newDate < this.minDate) {
        return false;
      } else if (this.maxDate && newDate > this.maxDate) {
        return false;
      }

      return true;
    }
  },
  methods: {
    readRoute() {
      this.isAuth = this.$route.fullPath.indexOf('/extern/') == -1;

      this.objectId = this.$route.params.id;
      var qdate = this.$route.query.date;
      if (qdate) {
        this.currentDate = new Date(qdate);
      }

      if (!this.isAuth) {
        let today = new Date();
        today.setMinutes(0);
        today.setSeconds(0);
        today.setMilliseconds(0);
        today.setHours(0);

        let maxDate = new Date(today);
        maxDate.setDate(today.getDate() + 7);
        // maxDate.setDate(today.getDate() + 30);
        this.maxDate = maxDate;
        this.minDate = today;
        if (this.objectId == 410 || this.objectId == 411 || this.objectId == 412) {
          let startHarm = new Date('2021-05-01T00:00:00');
          if (startHarm > this.minDate) {
            this.minDate = startHarm;
            this.maxDate = new Date('2021-05-08T00:00:00');
          }
        }
        if (this.currentDate < this.minDate) {
          this.currentDate = this.minDate;
        } else if (this.currentDate > this.maxDate) {
          this.currentDate = this.maxDate;
        }
      } else {
        this.minDate = null;
        this.maxDate = null;
      }

      this.reload();
    },
    clear() {
      for (let i = 0; i < this.objects.length; i++) {
        if (this.objects[i].timetable) this.objects[i].timetable = [];
        this.objects[i].reservations = [];
      }
    },
    getClasses(item, selectedItems) {
      const isActive = !!selectedItems.find((selectedItem) => {
        return parseInt(selectedItem.dataset.item, 10) === item;
      });

      return {
        item: true,
        active: isActive
      };
    },
    selected() {
      this.$router.push({
        name: this.isAuth ? 'Timeline' : 'ExternTimeline',
        //params: {id: 100},
        query: {date: this.$fdate(this.currentDate)}
      });
    },
    selectItem(res, value) {
      // debugger;
      if (value === undefined) {
        if (res.selected) {
          this.$set(res, 'selected', false);
          this.selectedObjectCount--;
        } else {
          this.$set(res, 'selected', true);
          this.selectedObjectCount++;
        }
      } else {
        //if (res.selected) {}
        if (value && !res.selected) {
          this.selectedObjectCount++;
        } else if (!value && res.selected) {
          this.selectedObjectCount--;
        }
        this.$set(res, 'selected', value);
      }
    },
    ungroupAll() {
      for (var o = 0; o < this.objects.length; o++) {
        var obj = this.objects[o];
        for (var i = 0; i < obj.reservations.length; i++) {
          this.ungroup(obj.reservations[i]);
        }
      }
    },
    selectObject(obj, event) {
      let checked = event.target.checked;
      //console.log(checked);
      for (let i = 0; i < obj.reservations.length; i++) {
        let res = obj.reservations[i];
        if (!res.group && !res.cols) {
          if (checked && !res.selected) {
            this.$set(res, 'selected', true);
            this.selectedObjectCount++;
          } else if (!checked && res.selected) {
            this.$set(res, 'selected', false);
            this.selectedObjectCount--;
          }
        }
      }
    },
    reload() {
      //this.clear();
      this.loading = true;
      this.newItem = null;
      //czysc gdy zbyt dlugo laduje
      setTimeout(() => {
        if (this.loading) this.clear();
      }, 1000);

      this.hasPerm = this.$hasPerm(+this.objectId);

      this.$http.get('reservation/timeline/' + this.objectId + '?date=' + this.$fdate(this.currentDate)).then((response) => {
        this.loading = false;
        this.selectedObjectCount = 0;
        this.selectObjectMode = false;
        var objects = response.data.data.objects;
        this.object = null;
        this.objects.splice(0);
        if (objects.length > 0) {
          this.$store.commit('setTitle', objects[0].name);
          this.object = objects[0];
        }
        this.tdwidth = 100 / objects.length;
        this.minTotalWidth = this.$options.minObjectWidth * this.objects.length + 50;

        let minstart = 9999;
        let minend = 0;

        //fixtimelines
        let totalrescount = 0;

        for (var i = 0; i < objects.length; i++) {
          var obj = objects[i];
          if (!this.hasPerm && i == 0 && obj.path) {
            var tpath = obj.path.split('.');
            for (var ii = 0; ii < tpath.length; ii++) {
              if (this.$hasPerm(+tpath[ii])) {
                this.hasPerm = true;
                break;
              }
            }
          }
          totalrescount += obj.reservations.length;
          let tt = response.data.data.timetables[obj.currentTimetable];

          if (tt && tt !== undefined) {
            obj.timetable = tt;
            if (obj.timetable.timeunits == undefined) {
              obj.timetable.timeunits = [];
            }

            var tu = obj.timetable.timeunits;

            if (tu[0]) {
              let start = tu[0].start;
              if (start < minstart) minstart = start;
              let end = tu[tu.length - 1].start + tu[tu.length - 1].length;
              if (end > minend) minend = end;
            }
          } else {
            //obj.timetable = undefined;
            obj.timetable = {
              notimetable: true,
              timeunits: []
            };
          }
        }
        //fix start
        if (/*minstart == 9999 &&*/ totalrescount > 0) {
          for (var i = 0; i < objects.length; i++) {
            for (var j = 0; j < objects[i].reservations.length; j++) {
              let res = objects[i].reservations[j];

              //delete res.price;

              this.$set(res, 'selected', false);
              this.$set(res, 'hidden', false);
              //res.selected = true;
              let start = res.start;
              let length = res.length;
              if (length) {
                if (start < minstart) minstart = start;
                if (start + length > minend) minend = start + length;
              }
            }
          }
        }
        if (minstart == 9999) minstart = 600;
        if (minend == 0) minend = 1200;

        if (this.$isToday(this.currentDate)) {
          let dt = new Date();
          this.currentMinute = dt.getHours() * 60 + dt.getMinutes();
        } else {
          this.currentMinute = -10;
        }

        this.startMinute = Math.floor(minstart / 60) * 60;
        this.endMinute = Math.ceil(minend / 60) * 60;
        this.totalHeight = (this.endMinute - this.startMinute) * this.minuteHeight + 20;
        this.hours.splice(0);
        for (let i = this.startMinute; i <= this.endMinute; i += 60) {
          this.hours.push(i);
        }

        //połącz rezerwacje z sąsiednich obiektów z obiektów
        if (false) {
          for (var i = 0; i < objects.length - 1; i++) {
            let obj = objects[i];
            if (obj.parentId && obj.parentId == objects[i + 1].parentId) {
              let joinelement = null;
              let joinelements = [];
              for (var j = 0; j < objects[i].reservations.length - 1; j++) {
                let res = objects[i].reservations[j];
                //szukaj w sąsiednich obiektach
                if (!res.hidden) {
                  for (let ii = i + 1; ii < objects.length; ii++) {
                    if (objects[ii].parentId != obj.parentId) break;
                    let qlist = objects[ii].reservations.filter((item) => {
                      return !item.hidden && item.reservationId == res.reservationId && item.start == res.start && item.length == res.length;
                    });
                    if (qlist.length) {
                      res2 = qlist[0];

                      if (joinelement == null) {
                        joinelement = Object.assign({}, res);
                        joinelement.cols = [res, res2];
                        res.hidden = true;
                        joinelement.width = 2;
                        joinelements.push(joinelement);
                      } else {
                        joinelement.cols.push(res2);
                        joinelement.width++;
                      }

                      if (joinelement.price !== undefined && res2.price) {
                        joinelement.price = (joinelement.price || 0) + res2.price;
                      }

                      //res2.reservationName = "...";
                      res2.hidden = true;
                    } else {
                      joinelement = null;
                      break;
                    }
                  }
                }
              }

              if (joinelements.length) {
                objects[i].reservations = objects[i].reservations.concat(joinelements);
                objects[i].reservations.sort(function (a, b) {
                  return a.start - b.start;
                });
              }
            }
          }
        }

        //join
        var joinmode = 'reservation';
        // joinmode = '';
        if (joinmode) {
          if (/*minstart == 9999 &&*/ totalrescount > 0) {
            for (var i = 0; i < objects.length; i++) {
              var joinelements = [];
              var joinelement = null;
              let reservations = objects[i].reservations.filter((x) => !x.hidden);
              for (var j = 0; j < reservations.length - 1; j++) {
                var res1 = reservations[j];
                var res2 = reservations[j + 1];
                var equal = false;
                let timeunits = objects[i].timetable ? objects[i].timetable.timeunits || [] : [];
                let betweenUnits = 5;
                if (timeunits && timeunits.length > 1) {
                  betweenUnits = timeunits[1].start - (timeunits[0].start + timeunits[0].length);
                }
                if (res2.start <= res1.start + res1.length + betweenUnits) {
                  if (joinmode == 'client') {
                    equal = res1.clientId == res2.clientId;
                  } else if (joinmode == 'reservation') {
                    equal = res1.reservationId == res2.reservationId && res1.width == res2.width && res1.status == res2.status;
                  }
                }
                if (equal) {
                  if (joinelement == null) {
                    joinelement = Object.assign({}, res1);
                    joinelement.group = [res1];
                    joinelement.hidden = false;

                    joinelements.push(joinelement);
                  }

                  joinelement.group.push(res2);

                  if (joinelement.price !== undefined && res2.price) {
                    joinelement.price = (joinelement.price || 0) + res2.price;
                  }

                  joinelement.length = res2.start + res2.length - joinelement.start;
                  res1.hidden = true;
                  res2.hidden = true;
                } else {
                  joinelement = null;
                }
              }
              if (joinelements) {
                objects[i].reservations = objects[i].reservations.concat(joinelements);
                objects[i].reservations.sort(function (a, b) {
                  return a.start - b.start;
                });
              }
            }
          }
        }

        for (var i = 0; i < objects.length; i++) {
          this.$set(this.objects, i, objects[i]);
          // this.objects.push(objects[i]);
        }

        // this.objects = objects;

        //przesuwaj pokrywające się
        for (var i = 0; i < this.objects.length; i++) {
          let obj = this.objects[i];
          let row = -1;
          let lastend = 0;
          for (var j = 0; j < obj.reservations.length; j++) {
            let res = obj.reservations[j];
            if (!res.hidden) {
              //res.totalrow = 1;
              let end = res.start + res.length;

              if (res.start < lastend) {
                var last = obj.reservations[j - 1];
                var prevrow = last.row;
                if (prevrow === undefined) prevrow = j - 1;

                var totalrow = j - prevrow + 1;
                for (var rr = prevrow; rr <= j; rr++) {
                  //obj.reservations[rr] =
                  obj.reservations[rr].row = prevrow;
                  obj.reservations[rr].rowlp = rr - prevrow + 1;
                  obj.reservations[rr].totalrow = totalrow;
                }
              }
              lastend = end;
            }
          }
        }
      });
    },
    nextDay() {
      let newDate = this.$addDay(this.currentDate, 1);
      if (this.maxDate && newDate > this.maxDate) {
        newDate = this.maxDate;
      }
      this.currentDate = newDate;

      //this.$route.replace({ name: this.$route.name, params: {id: 100}, query: {date: fdate()} })
      //this.$router.push({name: "Timeline",params: {id: 100},query: {date: fdate()}});
      //this.$router.push({ path: "./objects/timeline/" + this.objectId+"?date="+fdate() });

      this.$router.push({
        name: this.isAuth ? 'Timeline' : 'ExternTimeline',
        //params: {id: 100},
        query: {date: this.$fdate(newDate)}
      });

      //this.reload();
    },
    nextWeek() {
      let newDate = this.$addDay(this.currentDate, 7);
      if (this.maxDate && newDate < this.maxDate) {
        newDate = this.maxDate;
      }
      this.currentDate = newDate;
      this.$router.push({
        name: this.isAuth ? 'Timeline' : 'ExternTimeline',
        query: {date: this.$fdate(newDate)}
      });
    },
    today() {
      let newDate = new Date();
      if (this.minDate && newDate < this.minDate) {
        newDate = this.minDate;
      } else if (this.maxDate && newDate > this.maxDate) {
        newDate = this.maxDate;
      }

      this.currentDate = newDate;
      this.$router.push({
        name: this.isAuth ? 'Timeline' : 'ExternTimeline',
        //params: {id: 100},
        query: {date: this.$fdate(this.currentDate)}
      });
    },
    prevDay() {
      let newDate = this.$addDay(this.currentDate, -1);
      if (this.minDate && newDate < this.minDate) {
        newDate = this.minDate;
      }
      this.currentDate = newDate;
      this.$router.push({
        name: this.isAuth ? 'Timeline' : 'ExternTimeline',
        query: {date: this.$fdate(this.currentDate)}
      });

      //this.reload();
    },
    prevWeek() {
      let newDate = this.$addDay(this.currentDate, -7);
      if (this.minDate && newDate < this.minDate) {
        newDate = this.minDate;
      }
      this.currentDate = newDate;
      this.$router.push({
        name: this.isAuth ? 'Timeline' : 'ExternTimeline',
        query: {date: this.$fdate(this.currentDate)}
      });
    },
    ungroup(res) {
      if (res.group) {
        for (var i = 0; i < res.group.length; i++) {
          res.group[i].hidden = false;
          this.ungroup(res.group[i]);
        }
        // delete res.group;
        res.hidden = true;
        return true;
      }

      if (res.cols) {
        for (var i = 0; i < res.cols.length; i++) {
          res.cols[i].hidden = false;
        }
        //res.width=0;;
        //res.cols=null;;
        delete res.cols;
        res.hidden = true;
        return true;
      }
      return false;
    },

    itemclick(res, sec) {
      if (this.ungroup(res)) return;

      this.resMore = res;
      this.resMore.maxPersons = sec;
      this.showMore = true;
      this.showChangePrice = false;
      //this.$router.push({ name: "ReservationDetails", params: { id: id } });
    },
    changePrice(res) {
      if (!this.$isAdmin()) return;
      this.resMore = res;

      this.showChangePrice = true;
      this.showMore = false;
    },
    newitemclick() {
      this.$router.push({
        name: this.isAuth ? 'ObjectReservations' : 'ExternObjectReservations',
        params: {id: this.newItem.objectId},
        query: {
          // name:
          clientId: 0,
          type: 0,
          dateFrom: this.$fdate(this.currentDate),
          start: this.newItem.start,
          dateTo: this.$fdate(this.currentDate),
          end: this.newItem.start + this.newItem.length,
          frequency: 0
        }
      });
    },
    cancel() {
      this.showMore = false;
      this.showCancel = false;
    },
    itemCancel(id) {
      this.cancel();
      this.pickedId = [id];
      this.Modaloption = 'cancel';

      this.showCancel = true;
    },
    freeDown(event, object, freeItem) {
      //return; //na razie wyłączam, do dopracowania

      if (this.selectObjectMode) return;

      //właściwie powinien działać jedynie gdy jest jeden timeunit
      let tt = object.timetable.timeunits.length == 1 ? object.timetable.timeunits[0] : null;
      if (!tt) {
        return;
      }

      window.title = 'freeDown';

      let y = event.offsetY;
      let start = y / this.minuteHeight + this.startMinute;
      start = Math.floor(start / 15) * 15;
      if (start < tt.start) {
        start = tt.start;
      }
      length = 60;
      if (start + length > tt.start + tt.length) {
        start = tt.start + tt.length - length;
      }

      document.title = y;
      this.newItem = {
        objectId: object.id,
        start: start,
        length: length,
        clientColor: '#aaaaaa',
        reservationName: 'nowa rezerwacja',
        clientName: object.name
      };
    },
    addTerm(object, item) {
      this.$router.push({
        name: this.isAuth ? 'ObjectReservations' : 'ExternObjectReservations',
        params: {id: object.id},
        query: {
          name: object.name,
          clientId: 0,
          type: 1,
          dateFrom: this.$fdate(this.currentDate),
          start: item.start,
          dateTo: object.timetable.endDate || this.$fdate(this.currentDate),
          end: item.start + item.length,
          frequency: 0
        }
      });
    },
    saveSelected(object, item) {
      this.selectedObject = object;
      this.selectedItem = item;
    },
    cancelSelected() {
      var picked = [];
      for (var i = 0; i < this.objects.length; i++) {
        var obj = this.objects[i];
        for (var j = 0; j < obj.reservations.length; j++) {
          var item = obj.reservations[j];
          if (item.selected) {
            picked.push(item.id);
          }
        }
      }
      this.pickedId = picked;
      this.showCancel = true;
    },
    hasRole(role) {
      return this.$hasRole(role);
    }
  },
  created() {
    if (window.localStorage) {
      let mh = parseInt(window.localStorage.getItem('minuteHeight', this.minuteHeight));
      if (!isNaN(mh) && mh) {
        this.minuteHeight = mh;
      }
    }
    this.readRoute();
  }
};
</script>

<style scoped>
.lineDatepicker {
  margin-top: 0.5rem;
}
.objectTitle {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 3.3rem; /* Fallback for non-webkit */
}

.item.active {
  background-color: #a8d1ff;
  color: black;
}
.calitemobject {
  padding: 0;
}
</style>
