<template>
  <b-modal v-model="showLocal" @hidden="$emit('cancel')">
    <h5 slot="modal-title">Używanie usługi</h5>
    <div slot="modal-header-close">
      <button type="button" class="close" aria-label="Close" @click="$emit('cancel')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="box-body">
      <label v-if="false">Numer klucza</label>
      <input v-if="false" class="form-control" name="keyNumber" v-model="keyNumber" />
      <label>Obiekt</label>
      <customSelect :url="'object/find'" @picked="pickObject" :pickFirst="pickFirst">
        <template slot-scope="child">{{ child.child.name }}</template>
      </customSelect>
    </div>
    <!-- Modal-footer -->
    <div slot="modal-footer">
      <button type="button" class="btn btn-default" data-dismiss="modal" @click="$emit('cancel')">Anuluj</button>&nbsp;
      <button type="button" class="btn btn-info" data-dismiss="modal" @click="save()">Użyj usługi</button>
    </div>
    <!-- /Modal-footer -->
  </b-modal>
</template>
<script>
import customSelect from '../modules/CustomSelect.vue';

export default {
  components: {
    customSelect
  },
  name: 'appKeyNumber',
  props: {
    show: Boolean,
    model: Object
  },
  data() {
    return {
      showLocal: this.show,
      keyNumber: '',
      objectId: undefined,
      pickFirst: function(list) {
        let roots = list.filter(x => x.parentId == 0);
        if (roots.length == 1) {
          return roots[0];
        }
        return undefined;
      }
    };
  },

  methods: {
    pickObject(item) {
      this.objectId = item.id;
    },
    save() {
      this.$http.post('service/use', {id: this.model.id, keyNumber: this.keyNumber, objectId: this.objectId}).then(response => {
        if (response && response.body && response.body.status == 1) {
          this.$komunikat('success', 'Sukces', 'Pomyślnie użyto usługi', 3000);
          this.$emit('reload');
        } else {
          this.$komunikat('error', 'Błąd', 'Błąd przy użyciu usługi', 3000);
        }
      });
    }
  },

  watch: {
    showLocal: function(val) {
      if (this.showLocal == false) {
        this.$emit('cancel');
      }
    }
  }
};
</script>
