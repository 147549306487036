<template>
  <!-- Modal -->
  <b-modal v-model="show" @hidden="close">
    <div slot="modal-title">Potwierdź płatność</div>

    <div class="row">
      <div class="col-md-12">
        <label>Data płatności</label>

        <datepicker v-model="paymentDate" input-class="form-control" format="dd-MM-yyyy" :language="pl" :full-month-name="true" />
      </div>
    </div>

    <div slot="modal-footer">
      <button type="button" class="btn mr-2" data-dismiss="modal" @click="close">Anuluj</button>
      <button type="button" class="btn btn-success" data-dismiss="modal" @click="submit">Ok</button>
    </div>
  </b-modal>
</template>

<script>
import {pl} from 'vuejs-datepicker/dist/locale';
import datepicker from 'vuejs-datepicker';

export default {
  props: {
    // Tekst
    text: {
      type: String,
      required: true
    },
    // Czy wyświetlać
    show: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      showLocal: this.show,
      pl,
      paymentDate: new Date()
    };
  },
  methods: {
    // Zamyka modal
    close() {
      this.$emit('cancel');
    },
    // Potwierdza płatność
    submit() {
      this.$emit('submit', this.paymentDate);
    }
  },
  components: {
    datepicker
  }
};
</script>
