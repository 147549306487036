<template>
  <div onselectstart="return false" onselect="return false" ondragstart="return false" ondrag="return false" class="row">
    <div class="col-12">
      <div class="card card-success card-outline">
        <!-- card-header -->

        <div class="card-header">
          <h3 class="card-title row">
            <div class="btn-group">
              <button class="btn btn-dark btn-sm" @click.prevent="prevWeek()">
                <i class="fa fa-chevron-left" style="cursor: pointer" aria-hidden="true"></i>
              </button>
              <button class="btn btn-dark btn-sm" @click.prevent="today()">Dziś</button>
              <button class="btn btn-dark btn-sm" @click.prevent="nextWeek()">
                <i class="fa fa-chevron-right" style="cursor: pointer" aria-hidden="true"></i></button
              >&nbsp;
              <datepicker
                :monday-first="true"
                :language="pl"
                :input-class="'btn btn-dark btn-sm'"
                :wrapper-class="'float-right'"
                :full-month-name="true"
                v-model="currentDate"
                @input="selected"
                format="D dd.MM.yyyy"
              >
              </datepicker>
              &nbsp;
              <button class="btn btn-danger btn-sm" v-if="hasPerm && displayTerms" @click="addTerm(selectedObject, selectedItem, selectedTimetable)">
                <i class="fa fa-plus"></i> Dodaj zaznaczone terminy
              </button>
            </div>
            &nbsp;

            <!-- <span> &nbsp; {{currentDate | fdate}} {{currentDate | dayOfWeek}}</span> -->

            <loading-icon v-if="loading"></loading-icon> &nbsp;
          </h3>
          <div class="card-tools">
            <router-link class="btn btn-primary" :to="{name: isAuth ? 'Timeline' : 'ExternTimeline', params: {id: objectId}, query: {date: $fdate(currentDate)}}"
              >Obiekt</router-link
            >
            <div class="btn-group" role="group">
              <button class="btn btn-default" type="button" :class="{active: minuteHeight == 0.7}" @click="minuteHeight = 0.7">
                <i class="fa fa-bars" style="font-size: 80%"></i>
              </button>
              <button class="btn btn-default" type="button" :class="{active: minuteHeight == 1}" @click="minuteHeight = 1">
                <i class="fa fa-bars"></i>
              </button>
              <button class="btn btn-default" type="button" :class="{active: minuteHeight == 2}" @click="minuteHeight = 2">
                <i class="fa fa-bars" style="font-size: 120%"></i>
              </button>
            </div>
          </div>
        </div>

        <!-- /.card-header -->
        <div class="card-body p-0" style="overflow: hidden; overflow-x: auto">
          <div class="row" style="position: relative; margin: 0; overflow: hidden; height: 3rem" :style="{minWidth: minTotalWidth + 'px'}">
            <div style="width: 50px; height: 30px"></div>
            <div class="row" style="position: absolute; left: 50px; right: 0px; margin: 0">
              <div v-for="objectday in days" :key="'th' + objectday.date" class="col text-center objectTitle">
                <router-link class="objectTitle" :title="objectday.date" :to="'/objects/timeline/' + object.id + '?date=' + objectday.date"
                  >{{ objectday.date | dayOfWeek }}<br />{{ objectday.date }}</router-link
                >
              </div>
            </div>
          </div>
          <div class="row" v-bind:style="{position: 'relative', margin: 0, height: totalHeight + 'px', minWidth: minTotalWidth + 'px'}">
            <div style="width: 50px; padding-left: 3px; margin-top: -13px">
              <div v-for="hour in hours" :key="'hour' + hour" class="hourdesc" v-bind:style="{height: minuteHeight * 60 + 'px'}">{{ hour | hminute }}</div>
            </div>
            <div class="row" style="position: absolute; left: 50px; right: 0; margin: 0">
              <div v-if="currentMinute >= startMinute" class="calcurrentminute" :style="{top: (currentMinute - startMinute) * minuteHeight + 'px'}"></div>
              <div
                class="col calitemobject"
                v-for="objectday in days"
                :key="'td' + objectday.date"
                :style="{position: 'relative', height: totalHeight + 'px'}"
                @mousedown="freeDown($event, objectday, null)"
              >
                <!--
                <div v-for="res in object.reservations" :key="'res'+res.id" v-bind:style="{backgroundColor: $rgba(res.clientColor),position: 'absolute',top: (res.start-startMinute)+'px',left: 0, height:(minuteHeight*res.length)+'px', width: '100%',overflow: 'hidden'}">
                  <div>{{res.clientName}}</div>
                  <div>{{res.start | hminute}} - {{(res.start+res.length) | hminute}}</div>
                </div>
                -->
                <div v-if="objectday.timetable.notimetable">
                  <small>
                    <i>brak harmonogramu</i>
                  </small>
                </div>
                <div v-else-if="objectday.timetable.closed">
                  <small>
                    <i class="text-danger">zamknięte</i>
                  </small>
                </div>
                <!--
                <div v-for="hour in hours" :key="'hour'+object.id+hour" class="calitemhour" v-bind:style="{height:(minuteHeight*60)+'px',position: 'absolute',top:(hour-startMinute)*minuteHeight+'px'}"></div>
                -->
                <drag-select
                  :object="object"
                  :timetable="objectday.timetable"
                  :oldObject="selectedObject.id"
                  @display="displayTerms = $event"
                  v-on:selected="saveSelected"
                  selectorClass="item"
                >
                  <template slot-scope="{selectedItems}">
                    <div
                      v-for="(ttitem, ttIndex) in objectday.timetable.timeunits"
                      :class="getClasses(ttitem.id, selectedItems)"
                      :data-item="ttitem.id"
                      :id="ttIndex"
                      :key="ttitem.id"
                      class="calitem free"
                      :style="{
                        height: ttitem.length * minuteHeight + 'px',
                        lineHeight: ttitem.length * minuteHeight + 'px',
                        top: (ttitem.start - startMinute) * minuteHeight + 'px'
                      }"
                    >
                      {{ ttitem.start | hminute }} - {{ (ttitem.start + ttitem.length) | hminute }}
                    </div>
                  </template>
                </drag-select>
                <timeline-item
                  v-for="(res, index) in objectday.reservations"
                  @click="itemclick(res, object.maxPersonCount)"
                  :key="'res' + index"
                  :res="res"
                  :startMinute="startMinute"
                  :minuteHeight="minuteHeight"
                  :childs="0"
                ></timeline-item>
                <timeline-item
                  v-if="newItem && newItem.objectId == object.id"
                  @click="newitemclick()"
                  :res="newItem"
                  :startMinute="startMinute"
                  :minuteHeight="minuteHeight"
                  :childs="object.childrenCount || 0"
                ></timeline-item>
              </div>
            </div>
          </div>
        </div>
        <!--
        <div v-if="loading" class="overlay">
                <i class="fa fa-refresh fa-spin"></i>
              </div>
-->
        <div class="card-footer">Możesz wybrać jeden termin, klikając go, lub wybrać wiele przeciągając po nich myszką.</div>
        <timeline-ItemMore v-if="showMore" :res="resMore" :show="showMore" :has-perm="hasPerm" @cancel="cancel()" @reload="reload()"></timeline-ItemMore>
      </div>
    </div>
  </div>
</template>
<script>
import timelineItem from './TimelineItem.vue';
import timelineItemMore from './TimelineItemMore.vue';
import loadingIcon from '../../modules/LoadingIcon.vue';
import {setTimeout} from 'timers';
import Datepicker from 'vuejs-datepicker';
import {pl} from 'vuejs-datepicker/dist/locale';
import DragSelect from '../../modules/DragSelect';

export default {
  components: {
    timelineItem,
    timelineItemMore,
    loadingIcon,
    Datepicker,
    DragSelect
  },
  data() {
    this.$options.minObjectWidth = 150;

    return {
      currentDate: new Date(),
      newItem: null,
      objectId: this.$route.params.id,
      objects: [],
      object: null,
      days: [],
      tdwidth: 0,
      startMinute: 600,
      endMinute: 1320,
      minTotalWidth: 0,
      minuteHeight: 1,
      totalHeight: 0,
      displayTerms: false,
      currentMinute: -1,
      loading: false,
      hours: [],
      resMore: {},
      showMore: false,
      pl,
      selectedObject: {},
      selectedItem: '',
      selectedTimetable: null,
      hasPerm: false,
      isAuth: false
    };
  },
  watch: {
    $route(to, from) {
      this.readRoute();
    },
    minuteHeight() {
      this.totalHeight = (this.endMinute - this.startMinute) * this.minuteHeight + 20;
      if (window.localStorage) {
        window.localStorage.setItem('minuteHeight', this.minuteHeight);
      }
    }
  },
  destroyed() {
    this.$store.commit('setTitle', null);
  },
  methods: {
    readRoute() {
      this.objectId = this.$route.params.id;
      this.isAuth = this.$route.fullPath.indexOf('/extern/') == -1;
      var qdate = this.$route.query.date;
      if (qdate) {
        var cdate = new Date(qdate);
        var dw = cdate.getDay();
        console.log(dw);
        if (dw != 1) {
          console.log(cdate);
          if (dw == 0) {
            cdate.setDate(cdate.getDate() - 6);
          } else {
            cdate.setDate(cdate.getDate() - (dw - 1));
          }
          console.log(cdate);
        }

        this.currentDate = cdate;
      }

      this.reload();
    },
    clear() {
      for (let i = 0; i < this.objects.length; i++) {
        if (this.objects[i].timetable) this.objects[i].timetable = [];
        this.objects[i].reservations = [];
      }
    },
    getClasses(item, selectedItems) {
      const isActive = !!selectedItems.find((selectedItem) => {
        return parseInt(selectedItem.dataset.item, 10) === item;
      });

      return {
        item: true,
        active: isActive
      };
    },
    selected() {
      this.$router.push({
        name: 'TimelineWeek',
        //params: {id: 100},
        query: {date: this.$fdate(this.currentDate)}
      });
    },
    reload() {
      //this.clear();
      this.loading = true;
      this.newItem = null;
      //czysc gdy zbyt dlugo laduje
      setTimeout(() => {
        if (this.loading) this.clear();
      }, 1000);

      this.hasPerm = this.$hasPerm(+this.objectId);

      this.$http.get('reservation/timelineweek/' + this.objectId + '?start=' + this.$fdate(this.currentDate)).then((response) => {
        this.loading = false;
        this.objects = response.data.data.objects;
        this.object = this.objects[0];
        if (this.object) {
          this.$store.commit('setTitle', this.object.name);
        }
        var days = response.data.data.days;
        this.tdwidth = 100 / this.days.length;
        this.minTotalWidth = this.$options.minObjectWidth * this.days.length + 50;

        let minstart = 9999;
        let minend = 0;

        //fixtimelines
        let totalrescount = 0;
        for (var i = 0; i < days.length; i++) {
          var obj = days[i];
          obj.timetable = {
            notimetable: true,
            timeunits: []
          };
          totalrescount += obj.reservations.length;
          let tt = response.data.data.timetables[obj.currentTimetable];
          if (tt && tt !== undefined) {
            obj.timetable = tt;
            if (obj.timetable.timeunits == undefined) {
              obj.timetable.timeunits = [];
            }

            var tu = obj.timetable.timeunits;

            if (tu[0]) {
              let start = tu[0].start;
              if (start < minstart) minstart = start;
              let end = tu[tu.length - 1].start + tu[tu.length - 1].length;
              if (end > minend) minend = end;
            }
          } else {
            //obj.timetable = undefined;
            obj.timetable = {
              notimetable: true,
              timeunits: []
            };
          }
        }
        //fix start
        if (/*minstart == 9999 &&*/ totalrescount > 0) {
          for (var i = 0; i < days.length; i++) {
            for (var j = 0; j < days[i].reservations.length; j++) {
              this.$set(days[i].reservations[j], 'hidden', false);

              let start = days[i].reservations[j].start;
              let length = days[i].reservations[j].length;
              if (length) {
                if (start < minstart) minstart = start;
                if (start + length > minend) minend = start + length;
              }
            }
          }
        }

        if (minstart == 9999) minstart = 600;
        if (minend == 0) minend = 1200;

        //join
        var joinmode = 'reservation';
        if (/*minstart == 9999 &&*/ totalrescount > 0) {
          for (var i = 0; i < days.length; i++) {
            var joinelements = [];
            var joinelement = null;
            for (var j = 0; j < days[i].reservations.length - 1; j++) {
              var res1 = days[i].reservations[j];
              var res2 = days[i].reservations[j + 1];
              var equal = false;
              if (res2.start <= res1.start + res1.length + res1.length / 2) {
                if (joinmode == 'client') {
                  equal = res1.clientId == res2.clientId;
                } else if (joinmode == 'reservation') {
                  equal = res1.reservationId == res2.reservationId;
                }
              }
              if (equal) {
                if (joinelement == null) {
                  joinelement = Object.assign({}, res1);
                  joinelement.group = [res1];
                  joinelements.push(joinelement);
                }
                joinelement.group.push(res2);
                joinelement.length = res2.start + res2.length - joinelement.start;
                res1.hidden = true;
                res2.hidden = true;
              } else {
                joinelement = null;
              }
            }
            if (joinelements) {
              days[i].reservations = days[i].reservations.concat(joinelements);
              days[i].reservations.sort(function (a, b) {
                return a.start - b.start;
              });
            }
          }
        }

        if (this.$isToday(this.currentDate)) {
          let dt = new Date();
          this.currentMinute = dt.getHours() * 60 + dt.getMinutes();
        } else {
          this.currentMinute = -10;
        }

        this.startMinute = Math.floor(minstart / 60) * 60;
        this.endMinute = Math.ceil(minend / 60) * 60;
        this.totalHeight = (this.endMinute - this.startMinute) * this.minuteHeight + 20;
        this.hours = [];
        for (let i = this.startMinute; i <= this.endMinute; i += 60) {
          this.hours.push(i);
        }

        //przesuwaj pokrywające się
        for (var i = 0; i < days.length; i++) {
          let obj = days[i];

          let row = -1;
          let lastend = 0;
          for (var j = 0; j < obj.reservations.length; j++) {
            let res = obj.reservations[j];
            if (!res.hidden) {
              //res.totalrow = 1;
              let end = res.start + res.length;

              if (res.start < lastend) {
                var last = obj.reservations[j - 1];
                var prevrow = last.row;
                if (prevrow === undefined) prevrow = j - 1;

                var totalrow = j - prevrow + 1;
                for (var rr = prevrow; rr <= j; rr++) {
                  //obj.reservations[rr] =
                  obj.reservations[rr].row = prevrow;
                  obj.reservations[rr].rowlp = rr - prevrow + 1;
                  obj.reservations[rr].totalrow = totalrow;
                }
              }
              lastend = end;
            }
          }
        }
        this.days = days;
      });
    },
    nextWeek() {
      this.currentDate = this.$addDay(this.currentDate, 7);
      //this.$route.replace({ name: this.$route.name, params: {id: 100}, query: {date: fdate()} })
      //this.$router.push({name: "Timeline",params: {id: 100},query: {date: fdate()}});
      //this.$router.push({ path: "./objects/timeline/" + this.objectId+"?date="+fdate() });

      this.$router.push({
        name: this.isAuth ? 'TimelineWeek' : 'ExternTimelineWeek',
        //params: {id: 100},
        query: {date: this.$fdate(this.currentDate)}
      });

      //this.reload();
    },
    today() {
      this.currentDate = this.$monday(new Date());
      this.$router.push({
        name: this.isAuth ? 'TimelineWeek' : 'ExternTimelineWeek',
        //params: {id: 100},
        query: {date: this.$fdate(this.currentDate)}
      });
    },
    prevWeek() {
      this.currentDate = this.$addDay(this.currentDate, -7);
      //this.$router.replace({ name: this.$route.name, params: this.$route.params, query: {date: fdate()} })
      this.$router.push({
        name: this.isAuth ? 'TimelineWeek' : 'ExternTimelineWeek',
        //params: {id: 100},
        query: {date: this.$fdate(this.currentDate)}
      });

      //this.reload();
    },
    itemclick(res, sec) {
      if (res.group) {
        for (var i = 0; i < res.group.length; i++) {
          res.group[i].hidden = false;
        }
        res.hidden = true;
        return;
      }

      this.resMore = res;
      this.resMore.maxPersons = sec;
      this.showMore = true;
      //this.$router.push({ name: "ReservationDetails", params: { id: id } });
    },
    newitemclick() {
      this.$router.push({
        name: 'ObjectReservations',
        params: {id: this.newItem.objectId},
        query: {
          clientId: 0,
          type: 0,
          dateFrom: this.$fdate(this.currentDate),
          start: this.newItem.start,
          dateTo: this.$fdate(this.currentDate),
          end: this.newItem.start + this.newItem.length,
          frequency: 0
        }
      });
    },
    cancel() {
      this.showMore = false;
    },
    freeDown(event, dayobject, freeItem) {
      //return; //na razie wyłączam, do dopracowania

      //właściwie powinien działać jedynie gdy jest jeden timeunit
      let tt = dayobject.timetable.timeunits.length == 1 ? dayobject.timetable.timeunits[0] : null;
      if (!tt) {
        return;
      }

      let y = event.offsetY;
      let start = y / this.minuteHeight + this.startMinute;
      start = Math.floor(start / 15) * 15;
      if (start < tt.start) {
        start = tt.start;
      }
      length = 60;
      if (start + length > tt.start + tt.length) {
        start = tt.start + tt.length - length;
      }

      document.title = y;
      this.newItem = {
        objectId: object.id,
        start: start,
        length: length,
        clientColor: '#aaaaaa',
        reservationName: 'nowa rezerwacja',
        clientName: object.name
      };
    },
    addTerm(object, item, timetable) {
      var tt = object.timetable;
      if (timetable) tt = timetable;
      this.$router.push({
        name: 'ObjectReservations',
        params: {id: object.id},
        query: {
          clientId: 0,
          type: 1,
          dateFrom: this.$fdate(this.currentDate),
          start: item.start,
          dateTo: tt.endDate || this.$fdate(this.currentDate),
          end: item.start + item.length,
          frequency: 0
        }
      });
    },
    saveSelected(object, item, timetable) {
      this.selectedObject = object;
      this.selectedItem = item;
      if (timetable) {
        this.selectedTimetable = timetable;
      }
    }
  },
  created() {
    if (window.localStorage) {
      let mh = parseInt(window.localStorage.getItem('minuteHeight', this.minuteHeight));
      if (!isNaN(mh) && mh) {
        this.minuteHeight = mh;
      }
    }
    this.readRoute();
  }
};
</script>

<style scoped>
.lineDatepicker {
  margin-top: 0.5rem;
}
.objectTitle {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 3.3rem; /* Fallback for non-webkit */
}

.item.active {
  background-color: #a8d1ff;
  color: black;
}
.calitemobject {
  padding: 0;
}
</style>
