<template>
  <div class="login-box">
    <div class="login-logo">
      <router-link :to="{path: '/'}">
        <img :src="'/img/logo/' + $config.current.logo" />
      </router-link>
    </div>
    <div v-if="!activated" class="card bg-danger ">
      <div class="card-body">
        Nie udało się aktywować konta
      </div>
    </div>
    <div v-if="activated" class="card bg-success ">
      <div class="card-body">
        Konto aktywowano
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activated: false
    };
  },
  methods: {
    Activate() {
      this.$http.get('account/activate?hashcode=' + this.$route.params.hash).then(response => {
        if (response.body.status !== 0) {
          this.activated = true;
        }
      });
    }
  },
  mounted() {
    this.Activate();
  },
  watch: {
    $route(to, from) {
      this.Activate();
    }
  }
};
</script>
<style scoped>
img {
  filter: brightness(2) invert(1);
}
</style>
