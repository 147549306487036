<template>
  <div class="row">
    <!-- errors -->
    <!-- /errors -->
    <div class="col-12">
      <div class="card card-info card-outline">
        <!-- card-header -->
        <div class="card-header">
          <h3 class="card-title">
            {{titles[filter]}}
            <loading-icon v-if="loading"></loading-icon>&nbsp;
          </h3>

          <app-search
            :color="'btn-info'"
            :post="post"
            @reload="reload"
            @post="post=$event"
            @searchKey="onSearchKey"
          ></app-search>
        </div>
        <!-- /.card-header -->
        <div class="card-body p-0">
          <div class="mailbox-controls clientitem top" style="height:90px;">
            <div>
              <app-pages
                :filteredCount="filteredCount"
                :maxcount="maxcount"
                :colorActive="'#fff'"
                :backgroundActive="'#17a2b8'"
                :post="post"
                @post="post=$event"
                @reload="reload()"
                :colsLeft="8"
              >
                <button type="button" class="btn btn-info" @click="add()">
                  <i class="fa fa-fw fa-plus"></i>Dodaj klienta
                </button>
                <b-dropdown class="ml-2" id="ddown1" :text="buttonText" variant="info">
                  <b-dropdown-item @click="filter=0; buttonText=buttonTitles[0]">Wszyscy</b-dropdown-item>
                  <b-dropdown-item @click="filter=1; buttonText=buttonTitles[1]">Wygasające usługi</b-dropdown-item>
                  <b-dropdown-item @click="filter=2; buttonText=buttonTitles[2]">Zawieszone usługi</b-dropdown-item>
                  <b-dropdown-item @click="filter=3; buttonText=buttonTitles[3]">Niewznowione usługi</b-dropdown-item>
                </b-dropdown>
                <b-dropdown
                  class="ml-2"
                  v-if="filter==1 || filter==3"
                  :text="days + ' dni'"
                  variant="info"
                >
                  <b-dropdown-item v-if="filter==1" @click="days=3">3 dni</b-dropdown-item>
                  <b-dropdown-item @click="days=7">7 dni</b-dropdown-item>
                  <b-dropdown-item v-if="filter==1" @click="days=14">14 dni</b-dropdown-item>
                  <b-dropdown-item @click="days=30">30 dni</b-dropdown-item>
                  <b-dropdown-item v-if="filter==3" @click="days=90">90 dni</b-dropdown-item>
                </b-dropdown>
                <b-dropdown class="ml-2" :text="serviceSelected" variant="info">
                  <b-dropdown-item
                    @click="serviceId=undefined; serviceSelected='Wszystkie usługi'"
                  >Wszystkie usługi</b-dropdown-item>

                  <b-dropdown-item
                    v-for="(service, index) in serviceList"
                    :key="index"
                    @click="serviceId=service.id; serviceSelected=service.name"
                  >{{service.name}}</b-dropdown-item>
                </b-dropdown>

                <!-- <select
                  class="form-control d-inline-block"
                  id="serviceId"
                  type="text"
                  v-model="serviceId"
                  style="width:auto; padding-top:1px; height:40px;"
                  @change="reload"
                  v-if="filter==3"
                >
                  <option value>Wybierz usługę</option>
                  <option
                    v-for="(service, index) in serviceList"
                    :key="index"
                    :value="service.id"
                  >{{service.name}}</option>
                </select>-->
              </app-pages>
            </div>
          </div>
          <!-- /buttons -->
          <!--list -->
          <div class="table-responsive mailbox-messages">
            <div class="alert alert-info text-center" v-if="! users.length">Brak klientów</div>
            <div v-for="user in users" :key="user.id">
              <!-- userItem -->
              <div class="clientitem">
                <div class="mailbox-name">
                  <router-link
                    :to="{ name: 'ClientsProfile', params: { id: user.id}}"
                    class="text-bold"
                  >{{user.firstName}} {{user.lastName}}</router-link>
                  <span class="clientspan ml-4" v-if="user.email">
                    <i class="fa fa-envelope fa-fw"></i>
                    {{user.email}}
                  </span>
                  <span class="clientspan ml-4" v-if="user.mobilePhone">
                    <i class="fa fa-phone"></i>
                    {{user.mobilePhone}}
                  </span>
                </div>
                <b-row style="width: 90%;">
                  <b-col cols="12">
                    <span style="cursor:pointer" @click="selectUser(user)" class="clientspan">
                      <span class="d-block text-truncate" v-if="user.note">
                        <i class="fa fa-comment pr-2"></i>
                        {{user.note}}
                      </span>
                      <span class v-else>
                        <i class="fa fa-comment pr-2"></i>Dodaj notatkę
                      </span>
                    </span>
                  </b-col>
                </b-row>
                <div></div>

                <div class="itembuttons btn-group menu">
                  <router-link
                    title="Edytuj"
                    :to="{ name: 'ClientsEdit', params: { id: user.id}}"
                    class="btn btn-primary"
                  >
                    <i class="fa fa-fw fa-lg fa-edit"></i>
                  </router-link>

                  <button class="btn btn-danger" title="Usuń" @click="deleteButton(user) ">
                    <i class="fa fa-fw fa-trash"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- /list -->
        </div>
        <div class="card-footer p-0">
          <div class="mailbox-controls">
            <!-- switch buttons -->

            <app-pages
              style="padding-right:15px"
              :filteredCount="filteredCount"
              :maxcount="maxcount"
              :colorActive="'#fff'"
              :backgroundActive="'#17a2b8'"
              :post="post"
              @post="post=$event"
              @reload="reload()"
            ></app-pages>
          </div>
        </div>
      </div>
      <!-- editApp -->
      <!--
      <app-edit :user="user" @edit="edit=$event" :changed="changed" @changed="changed=$event"></app-edit>
      -->

      <app-add v-if="showAdd " :show="showAdd " v-on:reload="reload()" v-on:cancel="cancel "></app-add>
      <app-delete
        v-if="showDelete "
        :show="showDelete "
        v-on:delete="del "
        :object="EditObject"
        v-on:cancel="cancel"
      ></app-delete>
    </div>

    <b-modal
      v-model="noteEditModal"
      :title="noteUserName"
      ok-title="Zapisz"
      cancel-title="Anuluj"
      @cancel="hideNoteModal"
      @hidden="hideNoteModal"
      @ok="saveNote"
    >
      <b-form-textarea v-model="noteToEdit" rows="6"></b-form-textarea>
    </b-modal>
  </div>
</template>

<script>
import appAdd from "./Add.vue";
import loadingIcon from "../modules/LoadingIcon.vue";
import appPages from "../modules/Pages.vue";
import appDelete from "../modules/Delete.vue";
import appSearch from "../modules/Search.vue";
import { setTimeout } from "timers";
export default {
  components: {
    appDelete,
    appAdd,
    loadingIcon,
    appPages,
    appSearch
  },
  data() {
    return {
      users: [],
      loading: false,
      changed: 0,
      deleteUser: {},
      edit: 0,
      showAdd: false,
      showDelete: false,
      timerQuery: undefined,
      maxcount: 0,
      filteredCount: 0,
      post: { draw: 1 },
      enterPressed: false,
      postDefaults: { q: "", count: 50, skip: 0, page: 1 },
      filter: 0,
      titles: [
        "Lista wszytkich klientów",
        "Lista klientów z wygasającymi usługami",
        "Lista klientów z zawieszonymi usługami",
        "Lista klientów którzy nie wznowili usługi"
      ],
      buttonTitles: ["Wszyscy", "Wygasające usługi", "Zawieszone usługi", "Niewznowione usługi"],
      buttonText: "Wszyscy",
      days: 7,
      serviceList: [],
      serviceId: undefined,
      serviceSelected: "Wszystkie usługi",
      noteEditModal: false,
      noteToEdit: undefined,
      noteUserId: undefined,
      noteUserName: undefined
    };
  },
  mounted() {
    this.reloadLink();
    this.getServiceList();
    this.handleQueries();
  },
  methods: {
    cancel() {
      this.showAdd = false;
      this.showDelete = false;
    },
    add() {
      this.showAdd = true;
    },
    more(user) {
      this.$router.push({ path: "./clients/" + user.id });
    },
    editButton(user) {
      this.$router.push({ path: "./clients/" + user.id + "/edit" });
    },
    del(id) {
      this.$http.get("cardowner/delete/" + id).then(response => {
        if (response && response.body && response.body.status == 1) {
          this.showDelete = false;
          this.reload();
          this.$komunikat("success", "Sukces", "Pomyślnie usunięto klienta", 3000);
        } else {
          this.$komunikat("error", "Błąd", "Błąd przy usuwaniu klienta", 3000);
        }
      });
    },
    deleteButton(user) {
      this.EditObject = user;
      this.showDelete = true;
    },
    reloadLink() {
      this.post = Object.assign({ draw: this.post.draw }, this.postDefaults, this.$route.query);

      /*
      this.post.q = this.$route.query.q;
      this.post.page = this.$route.query.page;
      this.post.count = this.$route.query.count;
      this.post.skip = this.$route.query.skip;
      if (!this.post.count) {
        this.post.count = 50;
      }
      if (!this.post.skip) {
        this.post.skip = 0;
      }
      if (!this.post.page) {
        this.post.page = 1;
      }
*/
    },
    onSearchKey(keyCode) {
      this.enterPressed = keyCode == "Enter";
    },
    async reload() {
      let query = "?";
      this.loading = true;
      this.post.serviceId = this.serviceId;
      if (this.filter == 0) {
        query = "";
      } else if (this.filter == 1) {
        query += `servicesNearEnd=${this.days}`;
      } else if (this.filter == 2) {
        query += "suspended=true";
      } else if (this.filter == 3) {
        query += `notresumed=${this.days}`;
      }
      const response = await this.$http.post("cardowner/find" + query, JSON.stringify(this.post), {}).then(response => {
        if (response && response.body && response.body.status == 1 && this.post.draw == response.data.draw) {
          this.users = response.data.data.data;
          if (this.enterPressed && this.users.length == 1) {
            this.$router.push({ name: "ClientsProfile", params: { id: this.users[0].id } });
          }

          this.maxcount = response.data.data.count;
          this.filteredCount = response.data.data.filteredCount;
          this.loading = false;
        } else {
          this.error = response.body.messages;
        }
        this.enterPressed = false;
      });
    },
    getServiceList() {
      this.$http.get("service/find").then(response => {
        this.serviceList = response.data.data.data;
      });
    },
    handleQueries() {
      this.filter = +this.$route.query.filter || 0;
      this.days = +this.$route.query.days || 7;
      this.serviceId = +this.$route.query.serviceId || undefined;
      this.buttonText = this.buttonTitles[this.filter];
      this.reload();
    },
    hideNoteModal() {
      this.noteEditModal = false;
      this.noteToEdit = undefined;
      this.noteUserId = undefined;
      this.noteUserName = undefined;
    },
    selectUser(user) {
      this.noteEditModal = true;
      this.noteToEdit = user.note;
      this.noteUserId = user.id;
      this.noteUserName = `${user.firstName} ${user.lastName}`;
    },

    saveNote() {
      let post = {
        id: this.noteUserId,
        note: this.noteToEdit
      };
      this.users[this.users.map(item => item.id).indexOf(this.noteUserId)].note = this.noteToEdit;
      this.$http.post("cardowner/update", JSON.stringify(post), {}).then(res => {
        if ((res.status === 200, res.data.status === 1)) {
          this.$komunikat("success", "Sukces", "Poprawnie zapisano notatkę.", 3000);
        } else {
          this.$komunikat("error", "Sukces", "Błąd zapisu notatki", 3000);
          console.warn(res.data);
        }
      });
    }
  },
  watch: {
    filter: function(val) {
      if (this.filter > -1) {
        this.$router.push({
          query: { filter: this.filter || 0, days: this.days || 7, serviceId: this.serviceId || undefined }
        });
      }
    },
    days: function() {
      if (this.days > -1) {
        this.$router.push({
          query: { filter: this.filter || 0, days: this.days || 7, serviceId: this.serviceId || undefined }
        });
      }
    },
    serviceId: function() {
      if (this.serviceId > -1 || this.serviceId === undefined) {
        this.$router.push({
          query: { filter: this.filter || 0, days: this.days || 7, serviceId: this.serviceId || undefined }
        });
      }
    },
    $route: function() {
      this.handleQueries();
    },
    serviceList: function() {
      if (this.serviceList.length > 0 && this.serviceId) {
        this.serviceSelected = this.serviceList[this.serviceList.map(item => item.id).indexOf(this.serviceId)].name;
      }
    }
  }
};
</script>

<style>
</style>
