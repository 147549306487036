<template>
  <!-- Modal -->
  <b-modal v-model="showLocal" @hidden="$emit('cancel')">
    <h5 v-if="option == 'edit'" slot="modal-title">Edytuj harmonogram {{ object.name }}</h5>
    <h5 v-if="option != 'edit'" slot="modal-title">Dodaj harmonogram</h5>
    <div slot="modal-header-close">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$emit('cancel')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="box-body">
      <div class="row">
        <div class="col-md-6">
          <label for="type">Typ</label>
          <select class="form-control" name="type" v-model="typeId">
            <!--
            <option v-for="(pick,index) in $timetabletypes" :value="index" :key="index"> {{pick}}</option>
            -->
            <option v-for="ttype in ttypes" :value="ttype.id" :key="'typeid_' + ttype.id"> {{ ttype.name }}</option>
          </select>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label :class="{'text-danger': $v.name.$anyError}" for="name">Nazwa </label>
            <input :class="{'is-invalid': $v.name.$anyError}" class="form-control" type="text" v-model.trim="$v.name.$model" />
            <small class="text-danger" v-if="$v.name.$anyError">Nazwa jest wymagana</small>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label :class="{'text-danger': $v.startDate.$anyError || $v.endDate.$anyError}">Data rozpoczęcia</label>
            <datepicker
              :language="pl"
              :input-class="{'form-control is-invalid': $v.startDate.$anyError || $v.endDate.$anyError, 'form-control': !($v.startDate.$anyError || $v.endDate.$anyError)}"
              :full-month-name="true"
              format="dd-MM-yyyy"
              v-model.trim="$v.startDate.$model"
            ></datepicker>
            <small v-if="$v.startDate.$anyError || $v.endDate.$anyError" class="text-danger">Proszę poprawnie wpełnić pole</small>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label :class="{'text-danger': $v.startDate.$anyError || $v.endDate.$anyError}">Data zakończenia</label>
            <datepicker
              :language="pl"
              :input-class="{'form-control is-invalid': $v.startDate.$anyError || $v.endDate.$anyError, 'form-control': !($v.startDate.$anyError || $v.endDate.$anyError)}"
              :full-month-name="true"
              format="dd-MM-yyyy"
              v-model.trim="$v.endDate.$model"
            ></datepicker>
            <small v-if="$v.startDate.$anyError || $v.endDate.$anyError" class="text-danger">Proszę poprawnie wpełnić pole</small>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <div class="form-check">
              <input type="checkbox" class="form-check-input" v-model="closed" id="closed" />
              <label class="form-check-label" for="closed">Zamknięte</label>
            </div>
          </div>
        </div>
      </div>

      <div class="row" v-show="!closed">
        <div class="col-md-12">
          <div class="form-group">
            <label>Harmonogram godzinowy</label>
            <div class="form-check">
              <input type="checkbox" class="form-check-input" v-model="useTimeunits" id="useTimeunits" />
              <label class="form-check-label" for="useTimeunits">Używaj jednostek czasowych harmonogramu</label>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="!closed && !useTimeunits">
        <div class="col-md-6">
          <div class="form-group">
            <label :class="{'text-danger': $v.start.$anyError || $v.end.$anyError}" for="surname">Godzina otwarcia</label>
            <input
              class="form-control"
              type="time"
              :class="{'form-control is-invalid': $v.start.$anyError || $v.end.$anyError, 'form-control': !($v.start.$anyError || $v.end.$anyError)}"
              v-model.trim="$v.start.$model"
            />
            <small v-if="$v.start.$anyError || $v.end.$anyError" class="text-danger">Proszę poprawnie wpełnić pole</small>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label :class="{'text-danger': $v.start.$anyError || $v.end.$anyError}" for="surname">Godzina zamknięcia</label>
            <input
              class="form-control"
              type="time"
              :class="{'form-control is-invalid': $v.start.$anyError || $v.end.$anyError, 'form-control': !($v.start.$anyError || $v.end.$anyError)}"
              v-model.trim="$v.end.$model"
            />
            <small v-if="$v.start.$anyError || $v.end.$anyError" class="text-danger">Proszę poprawnie wpełnić pole</small>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="name">Opis</label>
        <input class="form-control" type="text" v-model="description" />
      </div>
      <button v-if="!closed && useTimeunits && option == 'edit'" class="btn btn-success btn-block" @click="timeunitsButton()">Dodaj jednostki harmonogramu</button>
    </div>
    <!-- Modal-footer -->
    <div slot="modal-footer">
      <button type="button" class="btn btn-default" data-dismiss="modal" @click="$emit('cancel')">Wyjdź</button>&nbsp;
      <button v-if="option == 'edit'" type="button" class="btn btn-success" data-dismiss="modal" @click="update()">Zapisz</button>
      <button v-if="option != 'edit'" type="button" class="btn btn-success" data-dismiss="modal" @click="save()">Zapisz</button>
    </div>
    <!-- /Modal-footer -->
  </b-modal>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import {pl} from 'vuejs-datepicker/dist/locale';
import {required} from 'vuelidate/lib/validators';

function dataHarmonogramuStart(start) {
  return this.$dateValidate(start, this.endDate);
}

function dataHarmonogramuEnd(end) {
  return this.$dateValidate(this.startDate, end);
}

function hourStart(hour) {
  return this.$hourValidate(hour, this.end);
}
function hourEnd(hour) {
  return this.$hourValidate(this.end, hour);
}
export default {
  name: 'appAdd',
  props: ['show', 'object', 'option', 'objectIdAdd'],

  data() {
    return {
      name: this.object.name || '',
      showLocal: this.show,
      id: this.object.id,
      useTimeunits: this.object.useTimeunits || false,
      closed: this.object.closed || false,
      pl,
      startDate: this.object.startDate || new Date(),
      endDate: this.object.endDate || new Date(),
      description: this.object.description,
      typeId: this.object.typeId || 1,
      objectIdUpdate: this.object.objectId,

      start: this.$convert(this.$hminute(this.object.start)),
      end: this.$convert(this.$hminute(this.object.end)),

      //ttypes: [],
      ttypes: [
        {id: 1, typeIdent: 'EVERY', name: 'Każdy dzień', priority: 0},
        {id: 9, typeIdent: 'W15', name: 'Dzien powszedni', priority: 10},
        {id: 10, typeIdent: 'W67', name: 'Weekend', priority: 10},
        {id: 2, typeIdent: 'D1', name: 'Poniedziałek', priority: 20},
        {id: 3, typeIdent: 'D2', name: 'Wtorek', priority: 20},
        {id: 4, typeIdent: 'D3', name: 'Środa', priority: 20},
        {id: 5, typeIdent: 'D4', name: 'Czwartek', priority: 20},
        {id: 6, typeIdent: 'D5', name: 'Piątek', priority: 20},
        {id: 7, typeIdent: 'D6', name: 'Sobota', priority: 20},
        {id: 8, typeIdent: 'D7', name: 'Niedziela', priority: 20},
        {id: 11, typeIdent: 'HOL', name: 'Święto', priority: 50},
        {id: 12, typeIdent: 'DAY', name: 'Wybrany dzień', priority: 100}
      ]
      /*      
      ttypes: [
        {id: 1, typeIdent: "EVERY", name: "Każdy dzień",priority: 0},
        {id: 9, typeIdent: "W15", name: "Dzień powszedni",priority: 0},
        {id: 1, typeIdent: "EVERY", name: "Weekend",priority: 0},
        {id: 1, typeIdent: "EVERY", name: "Każdy dzień",priority: 0},
        {id: 1, typeIdent: "EVERY", name: "Każdy dzień",priority: 0},
        {id: 1, typeIdent: "EVERY", name: "Każdy dzień",priority: 0},
        {id: 1, typeIdent: "EVERY", name: "Każdy dzień",priority: 0},
        {id: 1, typeIdent: "EVERY", name: "Każdy dzień",priority: 0},
        {id: 1, typeIdent: "EVERY", name: "Każdy dzień",priority: 0},
        {id: 1, typeIdent: "EVERY", name: "Każdy dzień",priority: 0},
        {id: 1, typeIdent: "EVERY", name: "Każdy dzień",priority: 0},
        {id: 1, typeIdent: "EVERY", name: "Każdy dzień",priority: 0},
        {id: 1, typeIdent: "EVERY", name: "Każdy dzień",priority: 0},
      ]
*/
    };
  },
  components: {
    Datepicker
  },
  created() {
    this.$http.get('timetable/types').then(
      response => {
        this.ttypes = response.data.data;
      },
      response => {}
    );
  },
  validations: {
    name: {
      required
    },
    startDate: {
      dataHarmonogramuStart
    },
    endDate: {
      dataHarmonogramuEnd
    },
    start: {
      hourStart
    },
    end: {
      hourEnd
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    update() {
      if (this.$v.$invalid == true) {
        this.$v.$touch();
      } else {
        let post = {
          name: this.name,
          startDate: new Date(this.$fdate(this.startDate)),
          endDate: new Date(this.$fdate(this.endDate)),
          objectId: this.objectIdUpdate,
          description: this.description,
          typeId: this.typeId,
          start: this.$mhour(this.start),
          end: this.$mhour(this.end),
          id: this.id,
          closed: this.closed || false,
          useTimeunits: this.useTimeunits || false
        };

        this.$http.post('timetable/update', JSON.stringify(post), {}).then(
          response => {
            if (response && response.body && response.body.status == 1) {
              this.$emit('reload');
              this.showLocal = false;
              this.$komunikat('success', 'Sukces', 'Pomyślnie edytowano harmonogram', 3000);
            } else {
              this.$komunikat('error', 'Błąd', 'Błąd przy edytowanu harmonogramu', 3000);
            }
          },
          response => {}
        );
      }
    },
    save() {
      if (this.$v.$invalid == true) {
        this.$v.$touch();
      } else {
        let post = {
          name: this.name,
          startDate: new Date(this.$fdate(this.startDate)),
          endDate: new Date(this.$fdate(this.endDate)),
          objectId: this.objectIdAdd,
          description: this.description,
          typeId: this.typeId,
          start: this.$mhour(this.start),
          end: this.$mhour(this.end),
          closed: this.closed || false,
          useTimeunits: this.useTimeunits || false
        };

        this.$http.post('timetable/add', JSON.stringify(post), {}).then(
          response => {
            if (response && response.body && response.body.status == 1) {
              this.$emit('reload');
              this.showLocal = false;
              this.$komunikat('success', 'Sukces', 'Pomyślnie dodano harmonogram', 3000);
            } else {
              this.$komunikat('error', 'Błąd', 'Błąd przy dodawaniu harmonogramu', 3000);
            }
          },
          response => {}
        );
      }
    },

    timeunitsButton() {
      this.$router.push({name: 'Timeunits', params: {id: this.id}});
    }
  },

  watch: {
    showLocal: function(val) {
      if (this.showLocal == false) {
        this.$emit('cancel');
      }
    },
    typeId: function(newValue, oldValue) {
      let prevName = '';
      let prevType = this.ttypes.filter(x => x.id == oldValue)[0];
      if (prevType) prevName = prevType.name;
      if (this.name == prevName || this.name == '') {
        let newtype = this.ttypes.filter(x => x.id == newValue)[0];
        if (newtype) {
          this.name = newtype.name;
        }
      }
    }
  }
};
</script>

<style>
#error {
  color: red;
  margin-top: 1em;
}
</style>
