<template>
  <!-- Modal -->
  <b-modal v-model="showLocal" @hidden="$emit('cancel')">
    <h5 v-if="option != 'add'" slot="modal-title">Edytuj kartę</h5>
    <h5 v-if="option == 'add'" slot="modal-title">Dodaj kartę</h5>

    <div slot="modal-header-close">
      <div slot="modal-header-close">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$emit('cancel')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div class="box-body">
      <div v-if="option == 'add'" class="form-group">
        <label :class="{'text-danger': $v.cardNumber.$anyError}" for="surname">Numer karty</label>
        <input :class="{'is-invalid': $v.cardNumber.$anyError}" class="form-control" type="text" v-model.trim="$v.cardNumber.$model" />
        <small class="text-danger" v-if="!$v.cardNumber.required && $v.cardNumber.$anyError">Numer karty jest wymagany</small>
        <small class="text-danger" v-if="!$v.cardNumber.integer">Numer karty musi być liczbą</small>
      </div>

      <div class="form-group">
        <label :class="{'text-danger': $v.title.$anyError}" for="surname">Nazwa karty</label>
        <input :class="{'is-invalid': $v.title.$anyError}" class="form-control" type="text" v-model.trim="$v.title.$model" />
        <small class="text-danger" v-if="$v.title.$anyError">Nazwa karty jest wymagana</small>
      </div>
    </div>
    <!-- Modal-footer -->
    <div slot="modal-footer">
      <button type="button" class="btn btn-default" data-dismiss="modal" @click="$emit('cancel')">Wyjdź</button>&nbsp;
      <button v-if="option != 'add'" type="button" class="btn btn-info" data-dismiss="modal" @click="update()">Zapisz</button>
      <button v-if="option == 'add'" type="button" class="btn btn-info" data-dismiss="modal" @click="addCard()">Dodaj</button>
    </div>
    <!-- /Modal-footer -->
  </b-modal>
</template>

<script>
import {required, integer} from 'vuelidate/lib/validators';

export default {
  name: 'appEdit',
  props: ['show', 'option', 'card'],
  data() {
    return {
      showLocal: this.show,
      title: this.card.cardTitle || '',
      cardNumber: this.card.cardNumber || '',
      keyUpCard: event => {
        if (event.target.localName != 'input') {
          if ('0' <= event.key && event.key <= '9') {
            this.cardNumber += event.key;
          } else if (event.key == 'Enter') {
            if (this.cardNumber.length > 0) {
              this.title = 'Karta dodana czytnikiem';
            }
          } else {
            this.cardNumber = '';
          }
        }
      }
    };
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },

    update() {
      if (this.$v.$invalid == true) {
        this.$v.$touch();
      } else {
        let post = {
          id: this.card.id,
          title: this.title,
          cardOwnerId: this.card.cardOwnerId,
          cardNumber: this.cardNumber
        };
        this.$http.post('card/update/', JSON.stringify(post)).then(response => {
          if (response && response.body && response.body.status == 1) {
            this.cancel();

            this.$komunikat('success', 'Sukces', 'Pomyślnie edytowano kartę', 3000);
          } else {
            this.$komunikat('error', 'Błąd', 'Błąd przy edytowaniu karty', 3000);
          }
        });
      }
    },
    addCard() {
      if (this.$v.$invalid == true) {
        this.$v.$touch();
      } else {
        let card = {
          cardNumber: this.cardNumber,
          title: this.title,
          cardOwnerId: this.$route.params.id
        };
        {
          this.$http.post('card/add', JSON.stringify(card), {}).then(response => {
            if (response && response.body && response.body.status == 1) {
              this.cancel();
              this.$komunikat('success', 'Sukces', 'Pomyślnie dodano kartę', 3000);
            } else {
              this.$komunikat('error', 'Błąd', 'Błąd przy dodawaniu karty', 3000);
            }
          });
        }
      }
    }
  },
  validations: {
    cardNumber: {
      required,
      integer
    },
    title: {
      required
    }
  },
  watch: {
    showLocal: function(val) {
      if (this.showLocal == false) {
        this.$emit('cancel');
      }
    }
  },
  mounted() {
    this.$root.$emit('disablemodal');
    document.addEventListener('keyup', this.keyUpCard);
  },
  beforeDestroy() {
    this.$root.$emit('enablemodal');
    document.removeEventListener('keyup', this.keyUpCard);
  }
};
</script>

<style></style>
