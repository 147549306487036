export enum Service {
  DEFAULT = 'DEFAULT',
  PASS = 'PASS',
  CLASSES = 'CLASSES',
  INDIVIDUAL_CLASSES = 'INDIVIDUAL_CLASSES'
}

export enum Subscription {
  SUBSCRIPTION_7_DAYS = 'SUBSCRIPTION_7_DAYS',
  SUBSCRIPTION_30_DAYS = 'SUBSCRIPTION_30_DAYS',
  SUBSCRIPTION_365_DAYS = 'SUBSCRIPTION_365_DAYS'
}

export const SubscribtionDays = {
  [Subscription.SUBSCRIPTION_7_DAYS]: 7,
  [Subscription.SUBSCRIPTION_30_DAYS]: 30,
  [Subscription.SUBSCRIPTION_365_DAYS]: 365
};

export const ServiceType = {...Service, ...Subscription};
export type ServiceType = typeof ServiceType;

export const ServiceTypeTranslation = {
  [ServiceType.DEFAULT]: 'Domyślna',
  [ServiceType.PASS]: 'Karnet',
  [ServiceType.CLASSES]: 'Zajęcia',
  [ServiceType.INDIVIDUAL_CLASSES]: 'Zajęcia indywidualne',
  [ServiceType.SUBSCRIPTION_7_DAYS]: 'Abonament tygodniowy',
  [ServiceType.SUBSCRIPTION_30_DAYS]: 'Abonament miesięczny',
  [ServiceType.SUBSCRIPTION_365_DAYS]: 'Abonament roczny'
};
