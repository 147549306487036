<template>
  <!-- Modal -->
  <b-modal v-model="showLocal" @hidden="$emit('cancel')">
    <div slot="modal-title">
      Rezerwacja ({{ res.reservationId }}): {{ res.reservationName
      }}<i v-if="res.reservationDesc"
        ><br /><small>{{ res.reservationDesc }}</small></i
      >
    </div>

    <div slot="modal-header-close">
      <button type="button" class="close" aria-label="Close" @click="$emit('cancel')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="box-body">
      <div class="row mb-1">
        <div class="col-md-6">
          <b>Nazwa podmiotu: </b>
        </div>
        <div class="col-md-6">
          <router-link :to="{name: 'UsersProfile', params: {id: res.clientId}}">{{ res.clientName || '[brak nazwy podmiotu]' }}</router-link>
        </div>
      </div>
      <div v-if="res.reservationTypeName" class="row mb-1">
        <div class="col-md-6">
          <b>Typ rezerwacji: </b>
        </div>
        <div class="col-md-6">
          {{ res.reservationTypeName }}
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-md-6">
          <b>Czas:</b>
        </div>
        <div class="col-md-6">{{ res.start | hminute }} - {{ (res.start + res.length) | hminute }}</div>
      </div>
      <div class="row mb-1" v-if="hasPerm">
        <div class="col-md-6">
          <b>Cena:</b>
        </div>
        <div class="col-md-6">
          {{ (res.price || 0) | money }}
          <small v-if="res.resPrice && (res.resPrice == res.resPayPrice)"><i class="fa fa-fw fa-credit-card"></i>opłacona</small>
        </div>
      </div>
      <div v-if="this.res.status === 3" class="row mb-1">
        <div class="col-md-6">
          <b>Status:</b>
        </div>
        <div class="col-md-3">
          {{ this.res.status | statusText }}
        </div>
        <div class="col-md-3">
          <button v-if="hasPerm" @click="confirm(res)" class="btn btn-primary btn-block btn-sm">Potwierdź</button>
          <button v-if="hasPerm" @click="cancelItem(res)" class="btn btn-danger btn-block btn-sm">Anuluj</button>
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-md-6">
          <b>Liczba osób:</b>
        </div>
        <div class="col-md-3">
          <input class="form-control" :class="{'is-invalid': $v.personCount.numeric == false}" v-model.trim="$v.personCount.$model" type="text" />
          <small v-if="$v.personCount.numeric == false" class="text-danger">Proszę poprawnie wpełnić pole</small>
          <small v-if="$v.personCount.maxpersons == false" class="text-warning">Maksymalna ilość osób to {{ res.maxPersons }}</small>
        </div>
        <div class="col-md-3">
          <button @click="save()" class="btn btn-success btn-block btn-sm">Zmień</button>
        </div>
      </div>
      <div class="row">
        <!--
        <div class="col-md-6">
          <div class="form-group">
            <label :class="{'text-danger': $v.price.numeric == false}" for="name">Cena: </label>

            <input class="form-control" :class="{'is-invalid':$v.price.numeric == false}" v-model.trim="$v.price.$model" type="text">
            <small v-if="$v.price.numeric == false" class="text-danger">Proszę poprawnie wpełnić pole</small>

          </div>
        </div>
        -->
      </div>
    </div>
    <div slot="modal-footer">
      <button type="button " class="btn-default btn btn-sm" data-dismiss="modal " @click="$emit('cancel')">Anuluj</button>&nbsp;
      <button v-if="this.hasPerm && this.$hasRole('admin')" @click="changePrice()" class="btn btn-success btn-sm"><i class="fa fa-fw fa fa-usd"></i>Cena</button>&nbsp;
      <router-link
        v-if="hasPerm"
        :to="{
          name: 'ObjectReservations',
          params: {id: this.res.objectId},
          query: {
            clientId: this.res.clientId,
            type: this.res.reservationTypeId,
            start: this.res.start,
            end: this.res.start + this.res.length,
            frequency: 1,
            option: 'update',
            reservationId: this.res.reservationId
          }
        }"
        class="btn btn-success btn-sm"
      >
        <i class="fa fa-fw fa-plus"></i>Termin </router-link
      >&nbsp;

      <router-link :to="{name: 'ReservationDetails', params: {id: res.reservationId}}" class="btn btn-success btn-sm"> <i class="fa fa-search fa-fw"></i>Szczegóły </router-link>
    </div>
  </b-modal>
</template>

<script>
function maxpersons(persons) {
  if (this.res.maxPersons < persons) {
    return false;
  } else {
    return true;
  }
}
import {numeric} from 'vuelidate/lib/validators';
export default {
  validations: {
    personCount: {
      numeric,
      maxpersons
    },
    price: {
      numeric
    }
  },
  props: ['res', 'show', 'hasPerm'],
  data() {
    return {
      showLocal: this.show,
      personCount: this.res.personCount,
      price: this.res.price
    };
  },
  methods: {
    save() {
      if (this.$v.personCount.numeric == false) {
        this.$v.$touch();
        return;
      }
      if (this.$v.price.numeric == false) {
        this.$v.$touch();
        return;
      }

      let post = {
        objectReservationId: this.res.id,
        personCount: this.personCount,
        price: this.price
      };
      this.$http.post('reservation/changepersoncount', JSON.stringify(post), {}).then(
        (response) => {
          if (response && response.body && response.body.status == 1) {
            this.$emit('reload');
            this.$emit('cancel');
            this.$komunikat('success', 'Sukces', 'Pomyślnie edytowano rezerwacje', 3000);
          } else {
            this.$komunikat('error', 'Błąd', 'Błąd przy edytowaniu rezerwacji', 3000);
          }
        },
        (response) => {}
      );
    },
    changePrice() {
      this.$emit('changePrice', this.res);
      this.$emit('cancel');
    },
    confirm(res) {
      let post = {
        clientId: res.clientId,
        reason: '',
        status: 1,
        objectListItemId: [res.id]
      };
      this.$http.post('reservation/changestatus', JSON.stringify(post)).then((response) => {
        if (response && response.body && response.body.status == 1) {
          this.$emit('reload');
          this.$emit('cancel');
          this.$komunikat('success', 'Sukces', 'Pomyślnie potwierdzono rezerwacje', 3000);
        } else {
          this.$komunikat('error', 'Błąd', 'Błąd przy zmianie statusu', 3000);
        }
      });
    },
    cancelItem(res) {
      // alert(JSON.stringify(this.res.id));
      this.$emit('cancelItem', this.res.id);
    }
  },
  watch: {
    showLocal: function (val) {
      if (this.showLocal == false) {
        this.$emit('cancel');
      }
    }
  }
};
</script>

<style></style>
