<template>
  <!-- Modal -->
  <b-modal v-model="showLocal" @hidden="$emit('cancel')">
    <div slot="modal-title">Dodaj klienta do rezerwacji</div>

    <div class="modal-body">
      <div class="box box-success min-height-1">
        <div class="box-body">
          <div class="form-group">
            <label for="type">Wybierz klienta</label>
            <customSelect :url="'cardowner/find/'" @picked="pick" :doValidation="true" :resultFields="['firstName', 'lastName']" :itemId="cardOwner">
              <template slot-scope="child">{{ child.child.firstName + ' ' + child.child.lastName }}</template>
            </customSelect>
          </div>
        </div>
      </div>
    </div>

    <div slot="modal-footer">
      <button type="button " class="btn btn-default" @click="$emit('cancel')">Anuluj</button>&nbsp;
      <button @click="save()" class="btn btn-danger">Dodaj</button>
    </div>
  </b-modal>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import {pl} from 'vuejs-datepicker/dist/locale';
import customSelect from '../modules/CustomSelect.vue';
export default {
  props: {
    show: Boolean,
    picked: Array,
    cardOwner: Number
  },
  data() {
    return {
      reservationId: this.$route.params.id,
      CardOwners: [],
      pickedCardOwner: '',
      pl,
      endDate: '',
      startDate: '',
      showLocal: this.show
    };
  },
  components: {
    Datepicker,
    customSelect
  },

  mounted() {
    this.reservationId = this.$route.params.id;
  },
  watch: {
    $route(to, from) {
      this.reservationId = this.$route.params.id;
      this.getCardOwners();
    }
  },
  methods: {
    pick(user) {
      this.pickedCardOwner = user;
    },
    save() {
      let post = {
        reservationId: this.reservationId,
        cardOwnerId: this.pickedCardOwner.id,
        dateStart: new Date(this.$fdate(this.startDate)),
        dateEnd: new Date(this.$fdate(this.endDate)),
        terms: this.picked
      };
      this.$http.post('reservation/addcardowner', JSON.stringify(post), {}).then(
        response => {
          if (response && response.body && response.body.status == 1) {
            this.$emit('reload');
            this.$emit('cancel');
            this.$komunikat('success', 'Sukces', 'Pomyślnie dodano klienta', 3000);
          } else {
            this.$komunikat('error', 'Błąd', 'Błąd przy dodawaniu klienta', 3000);
          }
        },
        response => {}
      );
    }
  },
  watch: {
    showLocal: function(val) {
      if (this.showLocal == false) {
        this.$emit('cancel');
      }
    }
  }
};
</script>

<style></style>
