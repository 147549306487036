<template>
  <div class="row">
    <div class="col-12">
      <div class="card card-warning card-outline">
        <!-- card-header -->
        <div class="card-header">
          <h3 class="card-title">Lista rezerwacji <loading-icon v-if="loading"></loading-icon> &nbsp;</h3>
          <app-search :color="'btn-warning'" :post="post" @reload="reload" @post="post = $event"></app-search>
        </div>
        <!-- /card-header -->
        <div class="card-body p-0" v-if="clientRezer">
          <div class="mailbox-controls clientitem top" style="height: 90px">
            <!-- switch-buttons -->

            <app-pages
              :filteredCount="filteredCount"
              :maxcount="maxcount"
              :colorActive="'#000'"
              :backgroundActive="'#ffc107'"
              :post="post"
              @post="post = $event"
              @reload="reload()"
            >
              <customSelect v-if="this.$hasRole('admin')" :url="'client/find'" @picked="pickUser" :itemId="+this.clientID">
                <template slot-scope="child">{{ child.child.name }}</template>
              </customSelect>
            </app-pages>
          </div>
          <!-- /switch-buttons -->
        </div>
        <div class="table-responsive mailbox-messages">
          <!-- list -->
          <div class="alert alert-warning text-center" v-if="!clientRezer.length">Brak rezerwacji</div>

          <div style="padding-left: 7px" v-for="(rezer, index) in clientRezer" :key="index" class="clientitem">
            <div class="mailbox-name">
              <b>
                <router-link :to="{name: 'ReservationDetails', params: {id: rezer.reservationId}}" style="color: black"
                  >{{ rezer.reservationName || '[brak nazwy rezerwacji]' }}
                </router-link>
              </b>
              <b>
                <b-badge :class="{'bg-warning': rezer.status == 1, 'bg-primary': rezer.status == 3, 'bg-danger': rezer.status == 0}" style="color: black">{{
                  rezer.status | status
                }}</b-badge>
              </b>
            </div>
            <div style="padding-left: 2px">
              <span class="clientspan">
                <i class="fa fa-calendar fa-fw"></i> {{ rezer.startDate | moment('LL ') || '[brak daty rezerwacji]' }}
                <span v-if="rezer.startDate != rezer.endDate"> - {{ rezer.endDate | moment('LL ') }} </span>
              </span>
              <span class="clientspan"> <i class="fa fa-circle-o fa-fw"></i> {{ rezer.reservationTypeName || '[brak typu rezerwacji]' }} </span>
            </div>
          </div>

          <!-- /list -->
        </div>

        <div class="card-footer">
          <div class="mailbox-controls">
            <!-- switch-buttons -->

            <app-pages
              style="padding-right: 15px"
              :filteredCount="filteredCount"
              :maxcount="maxcount"
              :colorActive="'#000'"
              :backgroundActive="'#ffc107'"
              :post="post"
              @post="post = $event"
              @reload="reload()"
            ></app-pages>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import loadingIcon from '../modules/LoadingIcon.vue';
import appPages from '../modules/Pages.vue';
import customSelect from '../modules/CustomSelect.vue';
import appSearch from '../modules/Search.vue';

export default {
  components: {
    loadingIcon,
    appPages,
    customSelect,
    appSearch
  },
  data() {
    return {
      clientID: this.$route.params.id,
      clientRezer: [],
      loading: false,
      maxcount: 0,
      filteredCount: 0,
      post: {q: '', count: 50, skip: 0, page: 1, draw: 1}
    };
  },
  created() {
    this.reloadLink();
    this.reload();
  },

  methods: {
    pickUser(user) {
      if (user && user.id) {
        this.clientID = user.id;
      }
    },
    reloadLink() {
      this.post.q = this.$route.query.q;
      this.post.page = this.$route.query.page;
      this.post.count = this.$route.query.count;
      this.post.skip = this.$route.query.skip;
      if (!this.post.count) {
        this.post.count = 50;
      }
      if (!this.post.skip) {
        this.post.skip = 0;
      }
      if (!this.post.page) {
        this.post.page = 1;
      }
    },
    reload() {
      debugger;
      this.loading = true;
      this.post.clientId = this.clientID;
      this.$http.post('reservation/find', JSON.stringify(this.post), {}).then(
        (response) => {
          if (response && response.body && response.body.status == 1 && this.post.draw == response.data.draw) {
            this.clientRezer = response.data.data.data;
            this.maxcount = response.data.data.count;
            this.filteredCount = response.data.data.filteredCount;

            this.loading = false;
          } else {
            this.error = response.body.messages;
          }
        },
        (response) => {}
      );
    }
  },
  watch: {
    $route(to, from) {
      this.reload();
    },
    clientID(val) {
      this.$router.push({name: 'ClientReservations', params: {id: this.clientID}});
    }
  },
  filters: {
    status: function (val) {
      var name = 'odwołana';
      if (val == 1) {
        name = 'Aktywna';
      }
      if (val == 3) {
        name = 'Oczekująca';
      }
      return name;
    }
  }
};
</script>

<style>
.przerwa {
  padding-right: 5px;
  padding-left: 5px;
}

.cursor {
  cursor: pointer;
}
.top {
  height: 60px;
}
</style>
