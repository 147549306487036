<template>
  <!-- Modal -->
  <b-modal v-model="showLocal" @hidden="$emit('cancel')">
    <h5 slot="modal-title">Dodaj jednostki czasu</h5>
    <div slot="modal-header-close">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$emit('cancel')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="box-body">
      <b-form-group>
        <b-form-radio-group id="btnradios1" v-model="selected" :options="options" size="sm" name="radiosBtnDefault" />
      </b-form-group>

      <div v-if="selected == 'one'" class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label :class="{'text-danger': $v.start.$anyError || $v.endOne.$anyError}" for="surname">Od</label>
            <input
              class="form-control"
              type="time"
              :class="{'form-control is-invalid': $v.start.$anyError || $v.endOne.$anyError, 'form-control': !($v.start.$anyError || $v.endOne.$anyError)}"
              v-model.trim="$v.start.$model"
            />
            <small v-if="$v.endOne.$anyError || $v.endOne.$anyError" class="text-danger">Proszę poprawnie wpełnić pole</small>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label :class="{'text-danger': $v.start.$anyError || $v.endOne.$anyError}" for="surname">Do</label>
            <input
              class="form-control"
              type="time"
              :class="{'form-control is-invalid': $v.start.$anyError || $v.endOne.$anyError, 'form-control': !($v.start.$anyError || $v.endOne.$anyError)}"
              v-model.trim="$v.endOne.$model"
            />
            <small v-if="$v.start.$anyError || $v.endOne.$anyError" class="text-danger">Proszę poprawnie wpełnić pole</small>
          </div>
        </div>
      </div>
      <template v-if="selected == 'more'">
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label :class="{'text-danger': $v.start.$anyError || $v.end.$anyError}" for="surname">Od</label>
              <input
                class="form-control"
                type="time"
                :class="{'form-control is-invalid': $v.start.$anyError || $v.end.$anyError, 'form-control': !($v.start.$anyError || $v.end.$anyError)}"
                v-model.trim="$v.start.$model"
              />
              <small v-if="$v.start.$anyError || $v.end.$anyError" class="text-danger">Proszę poprawnie wpełnić pole</small>
            </div>
          </div>

          <div class="col-6">
            <div class="form-group">
              <label :class="{'text-danger': $v.start.$anyError || $v.end.$anyError}" for="surname">Do</label>
              <input
                class="form-control"
                type="time"
                :class="{'form-control is-invalid': $v.start.$anyError || $v.end.$anyError, 'form-control': !($v.start.$anyError || $v.end.$anyError)}"
                v-model.trim="$v.end.$model"
              />
              <small v-if="$v.start.$anyError || $v.end.$anyError" class="text-danger">Proszę poprawnie wpełnić pole</small>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label for="surname">Długość jednostki</label>
              <input class="form-control " type="time" v-model="length" />
            </div>
          </div>

          <div class="col-6">
            <div class="form-group">
              <label for="surname">Przerwa między jednostkami</label>
              <input class="form-control " type="time" v-model="pause" />
            </div>
          </div>
        </div>
      </template>
    </div>
    <!-- Modal-footer -->
    <div slot="modal-footer">
      <button type="button" class="btn btn-default" data-dismiss="modal" @click="$emit('cancel')">Wyjdź</button>&nbsp;
      <button v-if="selected == 'one'" type="button" class="btn btn-success" data-dismiss="modal" @click="addOne()">Zapisz</button>
      <button v-if="selected == 'more'" type="button" class="btn btn-success" data-dismiss="modal" @click="addMore()">Zapisz</button>
    </div>
    <!-- /Modal-footer -->
  </b-modal>
</template>

<script>
function hourStart(hour) {
  return this.$hourValidate(hour, this.end);
}
function hourEnd(hour) {
  return this.$hourValidate(this.start, hour);
}
function hourEndOne(hour) {
  return this.$hourValidate(this.start, hour);
}
export default {
  props: ['show', 'pstart', 'pend'],
  data() {
    return {
      showLocal: this.show,
      timetableId: this.$route.params.id,
      selected: 'one',
      options: [
        {text: 'Pojedyncza', value: 'one'},
        {text: 'Wiele', value: 'more'}
      ],
      start: this.pstart,
      endOne: this.pend,
      end: '23:59',
      length: '01:00',
      pause: '00:00'
    };
  },
  validations: {
    start: {
      hourStart
    },
    endOne: {
      hourEndOne
    },
    end: {
      hourEnd
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    addOne() {
      let post = {
        start: this.$mhour(this.start),
        timetableId: this.timetableId,
        length: this.$mhour(this.endOne) - this.$mhour(this.start)
      };

      this.$http.post('timetable/addunit', JSON.stringify(post), {}).then(
        response => {
          if (response && response.body && response.body.status == 1) {
            this.$emit('reload');
            this.$emit('cancel');
            this.$komunikat('success', 'Sukces', 'Pomyślnie dodano jednostke czasu', 3000);
          } else {
            this.$komunikat('error', 'Błąd', 'Błąd przy dodawaniu jednostki czasu', 3000);
          }
        },
        response => {}
      );
    },
    addMore() {
      let post = {
        start: this.$mhour(this.start),
        end: this.$mhour(this.end),
        timetableId: this.timetableId,
        length: this.$mhour(this.length),
        pause: this.$mhour(this.pause)
      };

      this.$http.post('timetable/addunits', JSON.stringify(post), {}).then(
        response => {
          if (response && response.body && response.body.status == 1) {
            this.$emit('reload');
            this.$emit('cancel');
            this.$komunikat('success', 'Sukces', 'Pomyślnie dodano jednostki czasu', 3000);
          } else {
            this.$komunikat('error', 'Błąd', 'Błąd przy dodawaniu jednostek czasu', 3000);
          }
        },
        response => {}
      );
    }
  },
  watch: {
    showLocal: function(val) {
      if (this.showLocal == false) {
        this.$emit('cancel');
      }
    },
    start(nextvalue, prevvalue) {
      //jezeli się zmieni start a end jest wypełnine to o tyle zmień end
      if (this.end) {
        let startp = this.$mhour(prevvalue);
        let startn = this.$mhour(nextvalue);
        let endi = this.$mhour(this.endOne);
        let diff = startn - startp;
        endi += diff;
        if (endi < 1440) {
          this.endOne = this.$hminute(endi, true);
        }
      }
    }
  }
};
</script>

<style>
#error {
  color: red;
}
</style>
