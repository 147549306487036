<template>
  <div>
    <div class="row">
      <div class="col-md-4">
        <!-- Profile Image -->
        <div class="card card-info card-outline">
          <div class="card-body box-profile">
            <div class="text-center">
              <h1>
                <i
                  class="fa"
                  v-bind:class="{
                    'fa-male': user.sex == 'M',
                    'fa-female': user.sex != 'M'
                  }"
                ></i>
              </h1>
            </div>

            <h3 class="profile-username text-center">
              {{ user.firstName || "[imie]" }} {{ user.lastName || "[nazwisko]" }}
            </h3>

            <div>
              <ul class="list-group list-group-unbordered mb-3">
                <li class="list-group-item">
                  <b>Ilość kart</b>
                  <a class="float-right">{{ cards.length }}</a>
                </li>
                <li class="list-group-item">
                  <b>Historia</b>
                  <a class="float-right">{{
                    logs.length ? logs.map(x => x.items.length).reduce((a, b) => a + b) : 0
                  }}</a>
                </li>
                <li class="list-group-item">
                  <div class="mb-3"><b>Notatka</b> <button title="Edytuj notatkę" style="float: right;" class="extra-small-btn btn btn-info" @click="showNoteModal()"> <i  class="fa fa-sm fa-edit"> </i> </button></div>
                  <div >
                  <p> {{ note ? note : "[Brak notatki]" }}</p>
                  </div>
                </li>
                <!-- <li class="list-group-item">
                                <b>Grupy</b>
                                <a class="float-right">{{groups.length}}</a>
              </li>-->
              </ul>
            </div>
            <div>
              <button class="btn btn-info btn-block" @click="edytuj()">
                <b>Edytuj</b>
              </button>
              <button class="btn btn-info btn-block" @click="addAcount()">
                <b>
                  Utwórz konto na
                  <br />podstawie danych
                </b>
              </button>
            </div>
          </div>
          <!-- /.card-body -->
        </div>
        <!-- /.card -->

        <!-- About Me Box -->

        <!-- /.card-header -->
        <b-card no-body>
          <b-tabs pills card>
            <b-tab :title-link-class="'btab'" title="Informacje" active>
              <div class="active tab-pane" id="info">
                <strong> <i class="fa fa-calendar mr-1"></i> Data urodzenia </strong>

                <p v-if="user.dateOfBirth" class="text-muted">{{ user.dateOfBirth | moment("LL") }}</p>

                <p v-else class="text-muted">[brak daty urodzenia]</p>

                <hr />

                <strong> <i class="fa fa-mars-double mr-1"></i> Płeć </strong>

                <p class="text-muted">{{ user.sex == "M" ? "Mężczyzna" : "Kobieta" }}</p>

                <hr />

                <strong> <i class="fa fa-phone mr-1"></i> Telefon </strong>
                <p class="text-muted">{{ user.mobilePhone || "brak numeru telefonu" }}</p>
              </div>
            </b-tab>
            <b-tab :title-link-class="'btab'" title="Karty">
              <div class="tab-panel" id="cards">
                <div v-for="(card, index) in cards" :key="index">
                  <h5>
                    <i class="icon fa fa-fw fa-credit-card"></i>
                    {{ card.cardNumber }}
                  </h5>
                  <div class="text-muted" v-if="card.title" style="margin-left: 15px;">{{ card.title }}</div>
                  <hr />
                </div>
                <div v-if="!cards.length" class="text-center">
                  Brak kart
                  <br />
                </div>
                <div class="text-center">
                  <button class="btn btn-info" @click="addButton()"><i class="fa fa-plus fa-fw"></i>Dodaj kartę</button>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </b-card>

        <!-- /.card -->
      </div>
      <!-- /.col -->
      <div class="col-md-8">
        <b-card no-body>
          <b-tabs pills card v-model="currentTab">
            <b-tab :title-link-class="'btab'" title="Historia">
              <div class="tab-panel" id="timeline">
                <!-- The timeline -->
                <!--
								<i @click="refreshLog()" class="pull-right fa fa-refresh"  :class="{'fa-spin': refreshing}"></i>
                -->
                <div class="alert alert-info" v-if="!logs.length">Brak historii klienta</div>
                <ul class="timeline timeline-inverse" v-for="item in logs" :key="item.date">
                  <!-- timeline time label -->
                  <li class="time-label">
                    <span class="bg-info">{{ new Date(item.date) | moment("YYYY-MM-DD") }}</span>
                  </li>
                  <!-- /.timeline-label -->

                  <!-- timeline item -->
                  <li v-for="(log, index) in item.items" :key="index">
                    <i
                      class="fa bg-info"
                      v-bind:class="{
                        'fa-plus': log.type == 'ADDED_TO_CLIENT' || log.type == 'ADDED_TO_RESERVATION',
                        'fa-check': log.type == 'SERVICE_USED',
                        'fa-user-plus': log.type == 'CREATED',
                        'fa-ticket': log.icon == 'fa-ticket',
                        'fa-money': log.icon == 'fa-money'
                      }"
                    ></i>
                    <div class="timeline-item">
                      <h3 class="timeline-header no-border">
                        <span class="pull-right">
                          <i class="fa fa-clock-o fa-fw"></i>
                          {{ log.date | moment("HH:mm") }}
                        </span>
                        <span style="overflow: hidden">{{ log.text }}</span>
                      </h3>
                    </div>
                  </li>
                </ul>
              </div>
            </b-tab>
            <b-tab :title-link-class="'btab'" title="Dostępne usługi" active ref="avaiable_services">
              <div class="tab-pane" id="services">
                <div class="alert alert-info" v-if="!userServices || !userServices.length">
                  Ten użytkownik nie ma przypisanych żadnych usług
                </div>
                <ul class="todo-list" v-if="userServices && userServices.length">
                  <template v-for="userService in userServices">
                    <li
                      v-if="userService.count != userService.used && $fdate(userService.dateEnd) >= $fdate(new Date())"
                      class="time-label"
                      :key="userService.id"
                    >
                      <b-badge variant="info">
                        <span style="font-size: 1.15em;">{{ userService.serviceName }}</span> </b-badge
                      >&nbsp;
                      <b-badge variant="danger" v-if="userService.suspended">
                        <span style="font-size: 1.15em;">Zawieszona</span> </b-badge
                      >&nbsp;
                      <button
                        type="button"
                        class="btn btn-success btn-sm float-right ml-1"
                        @click="useService(userService)"
                      >
                        Użyj
                      </button>

                      <router-link v-if="$isSubscribtion(userService.serviceType)" 
                      class="btn btn-success btn-sm float-right ml-1" 
                      :to="{ name: 'ClientsSubscription', params: { cardOwnerServiceId: userService.id}}">Abonament</router-link>

                      <button
                        type="button"
                        class="btn btn-info btn-sm float-right ml-1"
                        @click="editService(userService)"
                      >
                        Edytuj
                      </button>

                      <button
                        type="button"
                        class="btn btn-danger btn-sm float-right ml-1"
                        @click="deleteService(userService)"
                      >
                        Usuń
                      </button>

                      <button
                        v-if="!userService.suspended && !$isSubscribtion(userService.serviceType)"
                        type="button"
                        class="btn btn-warning btn-sm float-right"
                        @click="suspendService(userService)"
                      >
                        Zawieś</button
                      >&nbsp;
                      <br />
                      <small>
                        <span class="clientspan" v-if="userService.dateStart">
                          <i class="fa fa-fw fa-calendar"></i>
                          {{ userService.dateStart | moment("LL") }}
                        </span>
                        <span class="clientspan" v-if="userService.dateEnd">
                          <template v-if="userService.dateStart != userService.dateEnd">
                            -
                            <i class="fa fa-fw fa-calendar"></i>
                            <span
                              class="rounded ml-1 mr-1"
                              :class="{
                                'bg-secondary': daysBefore(userService.dateEnd, 7),
                                'bg-warning': daysBefore(userService.dateEnd, 3)
                              }"
                              >{{ userService.dateEnd | moment("LL") }}</span
                            >
                          </template>
                        </span>
                        <span v-if="userService.price" class="clientspan">
                          &nbsp;
                          <i class="fa fa-money"></i>
                          {{
                            userService.debit
                              ? userService.price - userService.price * (userService.debit / 100)
                              : userService.price
                          }}
                          zł
                        </span>
                        <span v-if="!userService.price" class="clientspan">
                          &nbsp;
                          <i class="fa fa-money"></i>&nbsp; Za darmo
                        </span>
                        <span class="clientspan">
                          &nbsp;
                          <i class="fa fa-check-square-o"></i>
                          Użyto: {{ userService.used }}
                          <template v-if="userService.count">/&nbsp;{{ userService.count }}</template>
                        </span>
                      </small>
                    </li>
                  </template>
                  <hr />

                  <template v-for="(userServiceUsed, index) in userServices">
                    <li
                      v-if="
                        userServiceUsed.count == userServiceUsed.used ||
                          $fdate(userServiceUsed.dateEnd) < $fdate(new Date())
                      "
                      class="time-label"
                      :key="index"
                    >
                      <b-badge variant="info">
                        <span style="font-size: 1.15em;">{{ userServiceUsed.serviceName }}</span> </b-badge
                      >&nbsp;
                      <b-badge variant="danger" v-if="userServiceUsed.count == userServiceUsed.used">
                        <span style="font-size: 1.15em;">Wykorzystano</span> </b-badge
                      >&nbsp;
                      <b-badge variant="danger" v-if="$fdate(userServiceUsed.dateEnd) < $fdate(new Date())">
                        <span style="font-size: 1.15em;">Przedawniony</span>
                      </b-badge>
                      <button
                        type="button"
                        class="btn btn-info btn-sm float-right ml-1"
                        @click="editService(userServiceUsed)"
                      >
                        Edytuj
                      </button>
                      <br />
                      <small>
                        <span class="clientspan" v-if="userServiceUsed.dateStart">
                          <i class="fa fa-fw fa-calendar"></i>
                          {{ userServiceUsed.dateStart | moment("LL") }}
                        </span>
                        <span class="clientspan" v-if="userServiceUsed.dateEnd">
                          <template v-if="userServiceUsed.dateStart != userServiceUsed.dateEnd">
                            -
                            <i class="fa fa-fw fa-calendar"></i>
                            {{ userServiceUsed.dateEnd | moment("LL") }}
                          </template>
                        </span>
                        <span v-if="userServiceUsed.price" class="clientspan">
                          &nbsp;
                          <i class="fa fa-money"></i>
                          {{
                            userServiceUsed.debit
                              ? userServiceUsed.price - userServiceUsed.price * (userServiceUsed.debit / 100)
                              : userServiceUsed.price
                          }}
                          zł
                        </span>
                        <span v-if="!userServiceUsed.price" class="clientspan">
                          &nbsp;
                          <i class="fa fa-money"></i>&nbsp; Za darmo
                        </span>
                        <span class="clientspan">
                          &nbsp;
                          <i class="fa fa-check-square-o"></i>
                          Użyto: {{ userServiceUsed.used }}
                          <template v-if="userServiceUsed.count">/&nbsp;{{ userServiceUsed.count }}</template>
                        </span>
                      </small>
                    </li>
                  </template>
                  <!-- timeline time label -->
                </ul>
              </div>
            </b-tab>
            <b-tab :title-link-class="'btab'" title="Rezerwacje / zajęcia">
              <div class="alert alert-info" v-if="!reservations.length">
                Ten użytkownik nie jest przypisany do żadnych rezerwacji / zajęć
              </div>

              <ul class="todo-list">
                <template v-for="(reservation, index) in reservations">
                  <li class="time-label" :key="index">
                    <div class="mailbox-name">
                      <b>
                        <router-link
                          style="color:black;"
                          class="text-bold"
                          :to="{ name: 'ReservationDetails', params: { id: reservation.id } }"
                          >{{ reservation.name || "[brak nazwy rezerwacji]" }}</router-link
                        >
                        <small>{{ reservation.description || "[brak opisu rezerwacji]" }}</small>
                      </b>
                      <b-badge variant="info">{{ reservation.status | status }}</b-badge>
                      <loading-icon v-if="attendance_loading"></loading-icon>
                      <template v-if="!attendance_loading">
                        <span
                          class="pull-right text-danger text-bold mr-1"
                          v-if="reservation.terms.length > 0 && reservation.terms.filter(x => $isToday(x)).length == 0"
                          >dziś brak zajęć</span
                        >
                        <button
                          class="pull-right btn btn-primary btn-sm"
                          @click="checkReservationActivity(reservation)"
                          v-else-if="attendance_reservation_list.filter(x => x == reservation.id).length == 0"
                        >
                          zaznacz obecność
                        </button>
                        <span class="pull-right text-success text-bold mr-1" v-else>obecny</span>
                      </template>
                    </div>
                    <div>
                      <small>
                        <template v-if="!reservation.terms.length">
                          <span class="clientspan" v-if="reservation.startDate">
                            <i class="fa fa-calendar fa-fw"></i>
                            {{ reservation.startDate | moment("LL") }}
                          </span>
                          <span class="clientspan" v-if="reservation.endDate != reservation.startDate"
                            >- {{ reservation.endDate | moment("LL") }}</span
                          >
                        </template>
                        <template v-else>
                          <span class="clientspan" v-if="reservation.terms.length <= 3 || reservation.showTerms">
                            <i class="fa fa-calendar fa-fw"></i>
                            <template v-for="(term, index) in reservation.terms">
                              <template v-if="index != 0">, {{ term | moment("LL") }}</template>
                              <template v-else>{{ term | moment("LL") }}</template>
                            </template>
                            &nbsp;
                            <a
                              href="javascript:void(0)"
                              v-if="reservation.showTerms"
                              @click="
                                () => {
                                  reservation.showTerms = false;
                                }
                              "
                              >zwiń</a
                            >
                          </span>
                          <span class="clientspan" v-else>
                            <i class="fa fa-calendar fa-fw"></i>
                            <a
                              href="javascript:void(0)"
                              @click="
                                () => {
                                  reservation.showTerms = true;
                                }
                              "
                              >{{ reservation.terms.length }} terminy/ów</a
                            >
                          </span>
                        </template>
                        <span class="clientspan">
                          <router-link
                            v-if="reservation.objectId"
                            style="color: #a0a0a0;"
                            :to="{
                              name: 'Timeline',
                              params: { id: reservation.objectId },
                              query: { date: reservation.startDate }
                            }"
                          >
                            <i class="nav-icon fa fa-sitemap fa-fw"></i>
                            {{ reservation.objectName }}
                          </router-link>
                        </span>
                        <span class="clientspan">
                          <span v-if="!reservation.clientId">[brak nazwy klienta]</span>
                          &nbsp;
                          <router-link
                            v-if="reservation.clientName"
                            style="color: #a0a0a0;"
                            :to="{ name: 'UsersProfile', params: { id: reservation.clientId } }"
                          >
                            <i class="nav-icon fa fa-users fa-fw"></i>
                            {{ reservation.clientName || "[brak nazwy klienta]" }}
                          </router-link>
                        </span>
                        <span class="clientspan">
                          &nbsp;
                          <i class="nav-icon fa fa-address-book fa-fw"></i>
                          {{ reservation.reservationTypeName || "[brak typu rezerwacji]" }}
                        </span>
                      </small>
                    </div>
                  </li>
                </template>
              </ul>
            </b-tab>
            <b-tab :title-link-class="'btab'" title="Sprzedaj usługę" @click="sellService">
              <div class="alert alert-info" v-if="!serviceList.length">
                Nie możesz dokonać sprzedaży usługi, ponieważ żadna nie została zdeklarowana
              </div>
              <service
                v-if="serviceList.length"
                :serviceList="serviceList"
                @onServiceSubmit="onServiceSubmit"
                :model="{ cardOwnerId: userID }"
              ></service>
            </b-tab>
            <b-tab :title-link-class="'btab'" title="Dodaj do rezerwacji">
              <div class="form-group">
                <label>Wybierz rezerwację</label>
                <customSelect :url="'reservation/find'" @picked="reservationPicked" :resultFields="['reservationName']">
                  <template slot-scope="child">
                    <b>{{ child.child.reservationName || "[brak nazwy rezerwacji]" }}</b>
                    - {{ child.child.clientName }}
                  </template>
                </customSelect>
              </div>
            </b-tab>
          </b-tabs>
        </b-card>

        <!-- /.nav-tabs-custom -->
      </div>
      <!-- /.col -->
    </div>

    <app-add
      v-if="showmodalAcount"
      :show="showmodalAcount"
      v-on:reload="logRefresh()"
      v-on:cancel="cancel()"
      :data="user"
    ></app-add>

    <app-edit-service
      v-if="showModalService"
      :show="showModalService"
      :serviceList="serviceList"
      v-on:reload="reload()"
      v-on:cancel="cancel()"
      :id="editServiceId"
    ></app-edit-service>
    
    <app-suspend
      v-if="suspendStarted"
      :show="suspendStarted"
      v-on:reload="getUserServices()"
      v-on:cancel="cancelSuspend()"
      :data="suspendModel"
    ></app-suspend>

    <b-modal
      v-model="noteEditModal"
      title="Notatka"
      ok-title="Zapisz"
      cancel-title="Anuluj"
      @cancel="hideNoteModal"
      @hidden="hideNoteModal"
      @ok="saveNote"
    >
      <b-form-textarea v-model="noteFull" rows="6"></b-form-textarea>
    </b-modal>

    <!-- /.row -->
    <card-edit
      v-if="showEdit"
      :show="showEdit"
      :card="ObjectEdit"
      @reload="getUserCards()"
      @cancel="cancel()"
      :option="modalOption"
    ></card-edit>
    <key-number
      v-if="keyNumberEditor"
      :show="keyNumberEditor"
      :model="keyNumberModel"
      @reload="getUserServices()"
      @cancel="cancelKeyNumber()"
    ></key-number>
  </div>
  <!-- /.container-fluid -->
</template>

<script>
import datepicker from "vuejs-datepicker";
import { pl } from "vuejs-datepicker/dist/locale";
import customSelect from "../modules/CustomSelect.vue";
import appSuspend from "./ModalSuspend.vue";
import appEditService from "./ModalService.vue";
import cardEdit from "./cardEdit.vue";
import appAdd from "./ModalAccount.vue";
import keyNumber from "./KeyNumber.vue";
import service from "./Service.vue";
import websocket from "@/websocket";
import loadingIcon from "../modules/LoadingIcon.vue";

export default {
  components: {
    datepicker,
    customSelect,
    cardEdit,
    appAdd,
    keyNumber,
    appSuspend,
    service,
    appEditService,
    loadingIcon
  },

  data() {
    return {
      userID: this.$route.params.id,
      userPick: {},
      user: {},
      cards: [],
      groups: [],
      group: {},
      partners: [],
      pl: pl,
      currentTab: 0,
      logs: [],
      refreshing: false,
      treners: [],
      trenerPick: {},
      timer: null,
      serviceList: [],
      showmodalAcount: false,
      keyNumberEditor: false,
      keyNumberModel: undefined,
      userServices: [],
      userServicesUsed: [],
      reservations: [],
      addService: {
        cardOwnerId: this.userID,
        serviceId: "",
        reservationId: 0,
        startDate: new Date(),
        endDate: new Date()
      },
      attendance_loading: false,
      attendance_reservation_list: [],
      service: {},
      serviceSelected: false,
      showInfo: false,
      modalOption: "add",
      showEdit: false,
      suspendStarted: false,
      suspendModel: {},
      cardNumber: "",
      editServiceId: 0,
      showModalService: false,
      note: null,
      noteFull: null,
      noteEditModal: false
    };
  },
  created() {
    this.reload();
    //websock events
    websocket.on("addCard", "profile", cardNumber => {
      //alert("karta2: "+cardNumber)
      this.addCard(cardNumber);
      //router.push("/clients/"+clientId);
    });
  },
  filters: {
    status: function(val) {
      switch (val) {
        case 0:
          return "Odwołana";
          break;
        case 1:
          return "Aktywna";
          break;
      }
    }
  },

  methods: {
    refreshAttendanceList() {
      this.attendance_loading = true;
      this.$http
        .get(
          `reservation/attendancelist?cardOwnerId=${this.userID}&date=${this.$moment(this.attence_date).format(
            'YYYY-MM-DD'
          )}`
        )
        .then(response => {
          this.attendance_reservation_list = response.body.data;
          this.attendance_loading = false;
        });
    },
    checkReservationActivity(reservation) {
      this.$http
        .post('reservation/attendancelist', {
          reservationId: reservation.id,
          date: new Date(),
          list: [this.userID]
        })
        .then(response => {
          if (response.body.status == 1) {
            this.$komunikat('success', 'Zapis', 'Poprawnie odnotowano obecność na zajęciach');
            this.refreshAttendanceList();
          } else {
            this.$komunikat('error', 'Błąd', response.body.messages[0]);
          }
        });
    },
    reload() {
      this.$http.get('cardowner/get/' + this.userID).then(response => {
        this.user = response.data.data;
        this.setNote();
      });
      this.editServiceId = 0;
      this.showModalService = false;
      this.logRefresh();
      this.getUserCards();
      this.getUserServices();
      this.getReservations();
      this.refreshAttendanceList();
    },
    // Ustawia notatke
    setNote() {
      if (this.user.note.trim().length > 200) {
        this.note = this.user.note.substring(0, 200);
        this.note += '...';
      } else {
        this.note = this.user.note;
      }
      this.noteFull = this.user.note;
    },
    suspendService(item) {
      this.suspendModel = {};
      this.suspendModel.id = item.id;
      this.suspendStarted = true;
    },
    cancelSuspend() {
      this.suspendModel = {};
      this.suspendStarted = false;
    },
    // Zapisuje notatkę
    saveNote() {
      let post = {
        id: this.user.id,
        note: this.noteFull
      };
      this.$http.post('cardowner/update', JSON.stringify(post), {}).then(res => {
        if ((res.status == 200, res.data.status == 1)) {
          this.$komunikat('success', 'Sukces', 'Poprawnie zapisano notatkę.', 3000);
          this.user.note = this.noteFull;
          this.setNote();
        } else {
          this.$komunikat('error', 'Sukces', 'Błąd zapisu notatki', 3000);
          console.warn(res.data);
        }
      });
    },
    hideNoteModal() {
      this.noteEditModal = false;
    },
    showNoteModal() {
      this.noteEditModal = true;
    },
    getUserCards() {
      this.cards = [];
      this.$http.post('card/find/', JSON.stringify({ cardOwnerId: this.userID }), {}).then(response => {
        this.cards = response.data.data.data;
        //this.loading = false;userID
      });
    },
    reservationPicked(reservation) {
      this.$router.push({
        name: 'Terms',
        params: { id: reservation.reservationId },
        query: { addClient: true, cardOwnerId: this.userID }
      });
    },
    userPicked(user) {
      this.userPick = user;
      this.$http.post('client/findCardOwners', JSON.stringify({ clientId: this.userPick.id })).then(response => {
        this.treners = response.data.data.data;
      });
    },
    logRefresh() {
      this.logs = [];
      this.$http.get("cardowner/logs/" + this.userID).then(response => {
        this.logs = response.data.data;
      });
      return true;
    },
    getReservations() {
      this.reservations = [];
      this.$http.get('cardowner/reservations/' + this.userID).then(response => {
        let tmp = response.data.data;
        tmp.forEach(x => {
          x.showTerms = false;
        });
        this.reservations = tmp;
      });
    },
    getUserServices() {
      this.userServices = [];
      var post = {
        cardOwnerId: this.userID,
        onlyActive: false,
        displayDeleted: true
      };
      this.cancelKeyNumber();
      this.cancelSuspend();
      this.$http.post('cardowner/services', JSON.stringify(post), {}).then(response => {
        if (response && response.body && response.body.status == 1) {
          this.userServices = response.data.data;
        }
      });
    },
    onServiceSubmit(service) {
      this.$http.post('service/addcardowner', JSON.stringify(service)).then(response => {
        if (response && response.body && response.body.status == 1) {
          this.addService.serviceId = '';
          this.currentTab = 1;
          this.service = [];
          this.logRefresh();
          this.getUserServices();
          this.$komunikat('success', 'Sukces', 'Pomyślnie sprzedano usługę', 3000);
        } else {
          this.$komunikat('error', 'Błąd', 'Błąd przy sprzedawaniu usługi', 3000);
        }
      });
    },
    addButton() {
      this.modalOption = 'add';
      this.ObjectEdit = {};
      this.showEdit = true;
    },
    addCard(cardNumber) {
      this.modalOption = 'add';
      this.ObjectEdit = {
        cardNumber: cardNumber,
        cardTitle: 'Karta'
      };
      this.showEdit = true;
    },
    sellService() {
      this.addService.cardOwnerId = this.userID;
      if (!this.serviceList.length) {
        this.$http.get('service/find').then(response => {
          this.serviceList = response.data.data.data;
          this.logRefresh();
          this.getUserServices();

        });
      }
    },
    edytuj() {
      var path = "./" + this.userID + "/edit";
      this.$router.push({ path: path });
    },
    cancelKeyNumber() {
      this.keyNumberEditor = false;
      this.keyNumberModel = undefined;
    },
    deleteService(service) {
      if (confirm("Czy na pewno chcesz usunac usługę?")) {
        this.$http.post("cardOwner/deleteService", { id: service.id }).then(response => {
          if (response && response.body && response.body.status == 1) {
            this.getUserServices();
            this.$komunikat("success", "Sukces", "Pomyślnie usunięto usługę", 3000);
          } else {
            this.$komunikat("error", "Błąd", "Błąd przy usuwaniu usługi", 3000);
          }
        });
      }
    },
    editService(service) {
      this.sellService();
      this.editServiceId = service.id;
      this.showModalService = true;
    },
    useService(service) {
      if (service.count != service.used) {
        this.keyNumberModel = { id: service.id };

        this.keyNumberEditor = true;
        // this.$http.post("service/use", { id: service.id }).then(response => {
        //   if (response && response.body && response.body.status == 1) {
        //     this.getUserServices();
        //     this.$komunikat("success", "Sukces", "Pomyślnie użyto usługi", 3000);
        //   } else {
        //     this.$komunikat("error", "Błąd", "Błąd przy użyciu usługi", 3000);
        //   }
        // });
      } else {
        this.$komunikat("error", "Błąd", "Limit wejść został już wykorzystany", 3000);
      }
    },
    cancel() {
      this.showModalService = this.showmodalAcount = false;
      this.ObjectEdit = {};
      this.showInfo = false;
      this.showEdit = false;
    },
    addAcount() {
      this.showmodalAcount = true;
    },
    daysBefore(date, number) {
      return new Date(date) <= this.$addDay(new Date(), number);
    },
  },
  watch: {
    $route(to, from) {
      this.userID = to.params.id;
      this.reload();
    }
  }
};
</script>

<style>
.btab.active {
  background-color: #17a2b8 !important;
}
.btab:hover:not(.active) {
  color: #17a2b8 !important;
}

.card .extra-small-btn {
  line-height: 1 ;
  padding: 0.2rem ;
  min-height: 0 !important;
  font-size: 0.9rem ;
}
</style>
