<template>
  <!-- Navbar -->
  <nav class="main-header navbar navbar-expand bg-white navbar-light border-bottom">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#" @click.prevent="$emit('sbtoggle')">
          <i class="fa fa-bars"></i>
        </a>
      </li>
    </ul>
    <!--       <li class="nav-item d-none d-sm-inline-block">
        <router-link to="/home" class="nav-link">Strona główna</router-link>
      </li>
      <li class="nav-item d-none d-sm-inline-block">
        <router-link to="/about" class="nav-link">About</router-link>
      </li> -->

    <ul class="navbar-nav ml-auto">
      <li class="nav-item mr-auto">
        <router-link v-if="$store.state.user.role == 'CLIENT'" class="nav-link" :to="{name: 'ClientsProfile', params: {id: $store.state.user.id}}">
          <i class="fa fa-user-circle-o" aria-hidden="true"></i>
          {{ $store.state.user.email }}
        </router-link>
        <router-link v-if="$store.state.user.role == 'PARTNER'" class="nav-link" :to="{name: 'UsersProfile', params: {id: $store.state.user.id}}">
          <i class="fa fa-user-circle-o" aria-hidden="true"></i>
          {{ $store.state.user.email }}
        </router-link>
        <span v-if="$store.state.user.role == 'ADMIN'" class="nav-link">
          <i class="fa fa-user-circle-o fa-fw" aria-hidden="true"></i><span>{{ $store.state.user.name || $store.state.user.email }} (ADMIN)</span>
        </span>
      </li>
      <li style="margin-left:10px; cursor: pointer;" @click="modal()" class="nav-item mr-auto">
        <span class="nav-link" href="#">
          <i class="fa fa-sign-out"></i>
        </span>
      </li>
    </ul>
    <div>
      <!-- Modal Component -->
      <b-modal v-model="showLocal" @hidden="$emit('cancel')">
        <center>
          <i id="warningIcon" class="fa fa-warning text-warning"></i><br />
          <h1>Wyloguj</h1>
          <br />
          Czy na pewno chcesz się wylogować?
        </center>

        <div slot="modal-footer">
          <button on type="button " class="btn" data-dismiss="modal " @click="close()">Anuluj</button>&nbsp;
          <button type="button " class="btn btn-warning" data-dismiss="modal " @click="log_out()">Wyloguj</button>
        </div>
      </b-modal>
    </div>
  </nav>
  <!-- /.navbar -->
</template>

<script lang="js">
import websocket from "@/websocket";
export default {
  //name: "Header",
  data() {
    return {
      showLocal: false
    };
  },

  methods: {
    modal() {
      this.showLocal = true;
    },

    close() {
      this.showLocal = false;
    },
    log_out() {
      this.$http.get("logout", {}, {}).then(
        response => {
          localStorage.removeItem("apikey");
          localStorage.removeItem("username");
          websocket.disconnect();
          this.$router.push({ path: "/login" });
        },
        response => {}
      );
    }
  }
};
</script>

<style></style>
