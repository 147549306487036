<template>
  <div class="col-12">
    <div class="card card-success card-outline">
      <div class="card-header no-print sticky">
        <h3 class="card-title row">
          <div class="btn-group">
            <button class="btn btn-dark btn-sm" @click.prevent="prevDate()" title="poprzedni dzień">
              <i class="fa fa-angle-left" style="cursor: pointer;" aria-hidden="true"></i>
            </button>
            <button class="btn btn-dark btn-sm" @click.prevent="thisMonth()">
              Bieżacy miesiac
            </button>
            <button class="btn btn-dark btn-sm" @click.prevent="nextDate()">
              <i class="fa fa-angle-right" style="cursor: pointer;" aria-hidden="true" title="następny dzień"></i>
            </button>
            &nbsp;
            <button class="btn btn-success btn-sm" @click="selectObjectMode = !selectObjectMode"><i class="fa fa-check"></i> Zaznacz rezerwacje</button>
            <button class="btn btn-danger btn-sm" v-if="selectObjectMode && selectedObjectCount > 0" @click="cancelSelected()">
              <i class="fa fa-cancel"></i> Anuluj zaznaczone ({{ selectedObjectCount }}) ...
            </button>
          </div>

          <loading-icon v-if="loading"></loading-icon> &nbsp;
        </h3>
        <div class="card-tools">
          <router-link class="btn btn-primary" :to="{name: 'ClientRaports', params: {id: clientID}, query: {months: months, year: year}}">
            Raport miesięczny
          </router-link>
        </div>
      </div>
      <div class="card-body p-0 ">
        <table class="table table-sm table-bordered table-hover">
          <thead>
            <tr>
              <th>Nazwa obiektu</th>
              <th>Rezerwacja / Typ</th>
              <th class="text-right">Początek</th>
              <th class="text-right">Koniec</th>
              <th class="text-center">Status</th>
              <th class="text-right">Cena</th>
            </tr>
          </thead>
          <tbody v-for="dateitem in dates" :key="dateitem.date">
            <tr>
              <th colspan="6" class="text-left">
                <input v-if="selectObjectMode" :disabled="!dateitem.canCancel" type="checkbox" style="margin-right: 5px;" @click="selectDate(dateitem, $event)" />
                <span><i class="fa fa-calendar fa-fw"></i> {{ dateitem.date }} {{ dateitem.date | dayOfWeek }}</span>
                &nbsp;
                <span><i class="fa fa-clock-o fa-fw"></i>{{ dateitem.usedLength | hminute }}</span>
                &nbsp;
                <span>{{ dateitem.usedPrice | money }}</span>
              </th>
            </tr>
            <tr v-for="item in dateitem.items" :key="item.id" @click22="itemClick(item)" :class="{'text-muted': item.status != 1}">
              <td>
                <input
                  v-if="selectObjectMode"
                  :disabled="!dateitem.canCancel"
                  v-model="item.selected"
                  @click.prevent="itemClick(item)"
                  type="checkbox"
                  style="margin-right: 5px;"
                />

                <span>{{ item.objectName }}</span>
              </td>
              <td>
                <span>{{ item.reservationName }}</span> &nbsp; <span v-if="item.reservationTypeName" class="badge bg-primary">{{ item.reservationTypeName }}</span>
              </td>
              <td class="text-right">
                <small v-if="item.start == null" class="text-danger">nieprawidłowy</small><span v-else>{{ item.start | hminute }}</span>
              </td>
              <td class="text-right">
                <small v-if="item.length == null" class="text-danger">nieprawidłowy</small><span v-else>{{ (item.start + item.length) | hminute }}</span>
              </td>
              <td class="text-center">
                <span v-if="item.status == 1" class="badge bg-success">{{ item.status | statusText }}</span>
                <span v-else-if="item.status == 3" class="badge bg-primary">{{ item.status | statusText }}</span>
                <span v-else class="badge bg-warning">{{ item.status | statusText }}</span>
              </td>
              <td class="text-right">
                <span class="price" @click="changePrice(item)" :class="{zero: item.price == 0}" style="cursor: pointer">{{ item.price | money }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <app-cancel :show="showCancel" :picked="pickedId" @cancel="cancel" @reload="reload" :res="null" :option="Modaloption"></app-cancel>
    <change-price :show="showChangePrice" :res="resMore" @cancel="showChangePrice = false" @reload="reload()"></change-price>
  </div>
</template>

<script>
import appCancel from '../reservations/CancelingReservation.vue';
import loadingIcon from '../modules/LoadingIcon.vue';
import changePrice from '../reservations/ChangePrice.vue';
import * as methods from '../../methods';

export default {
  components: {
    loadingIcon,
    appCancel,
    changePrice
  },
  data() {
    return {
      months: '',
      year: '',
      monthsName: '',
      details: [],
      dates: {},
      client: null,

      loading: false,
      selectObjectMode: false,
      showChangePrice: false,
      resMore: null,
      selectedObjectCount: 0,
      clientID: this.$route.params.id,
      showCancel: false,
      Modaloption: 'cancel',
      pickedId: []
    };
  },
  methods: {
    cancel() {
      this.showCancel = false;
    },
    reload() {
      this.loading = true;
      this.clientID = this.$route.params.id;
      this.months = this.$route.query.months;
      this.selectedObjectCount = 0;
      this.monthsName = this.$monthName(this.months - 1);
      var date = new Date();
      this.year = this.$route.query.year;
      if (!this.months) {
        this.months = date.getMonth() + 1;
      }
      if (!this.year) {
        this.year = date.getFullYear();
      }
      if (this.client == null) {
        this.$http.get('client/get/' + this.clientID).then(response => {
          this.client = response.data.data;
          //console.log("store",this.$store);
          this.$store.commit('setTitle', this.client.name + ' - ' + this.$monthName(this.months - 1) + ' ' + this.year);
        });
      } else {
        this.$store.commit('setTitle', this.client.name + ' - ' + this.$monthName(this.months - 1) + ' ' + this.year);
      }
      this.$http.get('client/report/' + this.clientID + '?month=' + this.months + '&year=' + this.year).then(response => {
        //this.raports = response.data.data;
        //this.months = this.raports.query.month;
        //this.monthsName = this.raports.query.monthName;
        //this.year = this.raports.query.year;

        //grupowanko po dacie
        var data = response.data.data;

        /*
          //sortowanko po dacie i godzinie
          data.sort(function(a,b) {
              if (a.date<b.date) {
                  return -1;
              } else if (a.date>b.date) {
                  return 1;
              } else {
                  if (a.start<b.start) {
                      return -1
                  } else if (a.start>b.start) {
                      return 1;
                  } else {
                      return 0;
                  }
              }
          })


          var dates = {};
          var dates2 = [];
          for (let i=0;i<data.length;i++) {
              var item = data[i];
              item.selected = false;
              var dataitem  = dates[item.date];
              if (dataitem===undefined) {
                  dataitem={
                      date: data[i].date,
                      count: 0,
                      usedCount: 0,
                      price: 0,
                      usedPrice: 0,
                      length: 0,
                      usedLength: 0,

                      items: []
                  };
                  dates[data[i].date]=dataitem;
                  dates2.push(dataitem);

              }
              
              dataitem.count++;
              dataitem.price+=(item.price || 0);
              dataitem.length+=(item.length || 0);
              if (item.status==1) {
                  dataitem.usedCount++;
                  dataitem.usedPrice+=(item.price || 0);
                  dataitem.usedLength+=(item.length || 0);

              }
              dataitem.items.push(item);
          }

          */

        this.dates = this.$groupReservationItemByDate(data);
        this.details = data;
        this.loading = false;
      });
      this.$router.push({
        name: 'ClientRaportsDetail',
        //params: {id: 100},
        query: {
          months: this.months,
          year: this.year
        }
      });
    },
    selectDate(dateitem, $event) {
      var checked = $event.target.checked;
      for (var i = 0; i < dateitem.items.length; i++) {
        var item = dateitem.items[i];
        if (checked) {
          if (!item.selected) {
            item.selected = true;
            this.selectedObjectCount++;
          }
        } else {
          if (item.selected) {
            item.selected = false;
            this.selectedObjectCount--;
          }
        }
      }
    },
    itemClick(item, event) {
      //item is
      if (event) {
        console.log(event.target);
        //return;
      }

      if (this.selectObjectMode) {
        if (item.selected) {
          item.selected = false;
          this.selectedObjectCount--;
        } else {
          item.selected = true;
          this.selectedObjectCount++;
        }
      }
    },
    changePrice(item) {
      if (!this.$isAdmin()) return;
      this.resMore = item;
      this.showChangePrice = true;
    },
    cancelSelected() {
      var idlist = [];

      idlist = this.details.filter(x => x.selected).map(x => x.id);

      this.pickedId = idlist;

      this.showCancel = true;
    },
    prevDate() {
      this.months--;
      if (this.months == 0) {
        this.months = 12;
        this.year--;
      }
      this.$router.push({
        name: 'ClientRaportsDetail',
        //params: {id: 100},
        query: {
          months: this.months,
          year: this.year
        }
      });
    },
    nextDate() {
      this.months++;
      if (this.months == 13) {
        this.months = 1;
        this.year++;
      }
      this.$router.push({
        name: 'ClientRaportsDetail',
        //params: {id: 100},
        query: {
          months: this.months,
          year: this.year
        }
      });
    },
    thisMonth() {
      var td = new Date();
      this.months = td.getMonth() + 1;
      this.year = td.getFullYear();
      this.$router.push({
        name: 'ClientRaportsDetail',
        //params: {id: 100},
        query: {
          months: this.months,
          year: this.year
        }
      });
    }
  },
  watch: {
    months: function(value) {
      //this.reload();
    },
    $route(to, from) {
      this.reload();
    }
  },
  created() {
    this.reload();
  },
  destroyed() {
    this.$store.commit('setTitle', null);
  }
};
</script>
