<template>
  <div class="row">
    <div>
      <!-- <button class="btn btn-danger" @click="$komunikat('error', 'Błąd ', 'Wystąpił bład przy dodawaniu usługi ', 3000)">Komunikat</button>
     -->
    </div>
    <!-- /errors -->
    <div class="col-12">
      <div class="card card-secondary card-outline">
        <!-- card-header -->
        <div class="card-header">
          <h3 class="card-title">Lista usług <loading-icon v-if="loading"></loading-icon> &nbsp;</h3>

          <app-search :color="'btn-secondary'" :post="post" @reload="reload" @post="post = $event"></app-search>
        </div>
        <!-- /.card-header -->
        <div class="card-body p-0">
          <div class="mailbox-controls clientitem top" style="height:90px;">
            <div>
              <app-pages
                :filteredCount="filteredCount"
                :maxcount="maxcount"
                :colorActive="'#fff'"
                :backgroundActive="'#6c757d'"
                :post="post"
                @post="post = $event"
                @reload="reload()"
              >
                <button id="add" type="button" class="btn btn-secondary" @click="add()"><i class="fa fa-fw fa-plus"></i>Dodaj usługę</button>
              </app-pages>
            </div>
          </div>
          <!-- /buttons -->
          <!--list -->
          <div class="table-responsive mailbox-messages">
            <div class="alert alert-secondary text-center" v-if="!services.length">Brak zdefiniowanych usług</div>
            <div v-for="service in services" :key="service.id">
              <div class="clientitem">
                <div class="mailbox-name">
                  <span class="text-bold">{{ service.name }}</span>
                  <span class="text-muted">
                    <template v-if="service.type === 'DEFAULT'">
                      domyślna
                    </template>
                    <template v-if="service.type === 'PASS'">
                      karnet
                    </template>
                    <template v-if="service.type === 'CLASSES'">
                      zajęcia
                    </template>
                    <template v-if="service.type === 'INDIVIDUAL_CLASSES'">
                      zajęcia indywidualne
                    </template>
                  </span>
                </div>
                <div>
                  <span class="clientspan" v-if="service.price">
                    <i class="fa fa-fw fa-money"></i>
                    {{ service.price }} zł
                  </span>

                  <span class="clientspan" v-if="service.count"> <i class="fa fa-share fa-fw"></i> Ilość wejść: {{ service.count }} </span>
                  <span class="clientspan" v-if="service.daysLength"> <i class="fa fa-fw fa-calendar"></i> {{ service.daysLength }} dni </span>
                </div>
                <div class="itembuttons btn-group menu ">
                  <router-link :to="{name: 'ServicesUsage', params: {id: service.id}}" title="Aktualnie korzystający z usług" class="btn btn-primary">
                    <i class="fa fa-fw  fa-list "></i>
                  </router-link>
                  <button @click="startEdit(service)" title="Edytuj" class="btn btn-primary">
                    <i class="fa fa-fw  fa-edit "></i>
                  </button>
                  <button class="btn btn-danger" title="Usuń" @click="deleteButton(service)">
                    <i class="fa fa-fw  fa-trash "></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- /list -->
        </div>
        <div class="card-footer p-0">
          <div class="mailbox-controls">
            <!-- switch buttons -->

            <app-pages
              style="padding-right:15px"
              :filteredCount="filteredCount"
              :maxcount="maxcount"
              :colorActive="'#fff'"
              :backgroundActive="'#6c757d'"
              :post="post"
              @post="post = $event"
              @reload="reload()"
            ></app-pages>
          </div>
        </div>
      </div>
      <app-add v-if="showAdd" :show="showAdd" v-on:reload="reload()" v-on:cancel="cancel" :data="editService"></app-add>
      <app-delete v-if="showDelete" :show="showDelete" v-on:delete="del" :object="EditObject" v-on:cancel="cancel"></app-delete>
    </div>
  </div>
</template>

<script>
import appAdd from './Add.vue';
import loadingIcon from '../modules/LoadingIcon.vue';
import appPages from '../modules/Pages.vue';
import appSearch from '../modules/Search.vue';
import appDelete from '../modules/Delete.vue';
import {setTimeout} from 'timers';
export default {
  components: {
    appDelete,
    appAdd,
    loadingIcon,
    appPages,
    appSearch
  },

  data() {
    return {
      services: [],
      loading: false,
      changed: 0,
      deleteService: {},
      edit: 0,
      showAdd: false,
      showDelete: false,
      timerQuery: undefined,
      maxcount: 0,
      filteredCount: 0,
      post: {q: '', count: 50, skip: 0, page: 1, draw: 1},
      editService: {}
    };
  },
  mounted() {
    this.reloadLink();
    this.reload();
  },
  methods: {
    reloadLink() {
      this.post.q = this.$route.query.q;
      this.post.page = this.$route.query.page;
      this.post.count = this.$route.query.count;
      this.post.skip = this.$route.query.skip;
      if (!this.post.count) {
        this.post.count = 50;
      }
      if (!this.post.skip) {
        this.post.skip = 0;
      }
      if (!this.post.page) {
        this.post.page = 1;
      }
    },
    cancel() {
      this.showAdd = false;
      this.showDelete = false;
    },
    add() {
      this.editService = {
        type: 'DEFAULT',
        price: 0
      };
      this.showAdd = true;
    },
    deleteButton(service) {
      this.EditObject = service;
      this.showDelete = true;
    },
    startEdit(service) {
      this.$http.get('service/get/' + service.id).then(response => {
        this.editService = response.data.data;
        this.showAdd = true;
      });
    },
    del(id) {
      this.$http.get('service/delete/' + id).then(response => {
        this.showDelete = false;
        this.reload();
      });
    },
    reload() {
      this.loading = true;

      this.$http.post('service/find', JSON.stringify(this.post), {}).then(
        response => {
          if (response && response.body && response.body.status == 1 && this.post.draw == response.data.draw) {
            this.services = response.data.data.data;
            this.maxcount = response.data.data.count;
            this.filteredCount = response.data.data.filteredCount;
            this.loading = false;
          } else {
            this.error = response.body.messages;
          }
        },
        response => {}
      );
    }
  }
};
</script>
<style></style>
