import Vue from 'vue';
import Vuex from 'vuex';
import websocket from './websocket';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {
      role: '',
      roles: [],
      objectPermission: 'NONE',
      objectPermissionList: [],
      email: '',
      id: '',
      fullName: '',
      name: '',
      minDaysBeforeCancellation: 0
    },
    allObjects: {} as any,
    title: ''
  },
  mutations: {
    saveUser(state, newUser) {
      state.user.role = newUser.role;
      state.user.email = newUser.email;
      state.user.id = newUser.id;
      state.user.roles = newUser.roles;
      state.user.fullName = newUser.fullName;
      state.user.minDaysBeforeCancellation = newUser.minDaysBeforeCancellation;
      state.user.name = newUser.name;

      state.user.objectPermission = newUser.objectPermission || 'ALL';
      state.user.objectPermissionList = state.user.objectPermission === 'ALL' ? [] : JSON.parse(newUser.objectPermission);
    },
    setTitle(state, newTitle) {
      state.title = newTitle;
    },
    setObjects(state, objects) {
      debugger;
      state.allObjects = {};
      for (var i = 0; i < objects.length; i++) {
        state.allObjects[objects[i].id] = objects[i];
      }

    }
  },
  actions: {
    getUser(context) {
      console.log('getuser');
      websocket.disconnect();
      Vue.http.get('account/getuserinfo').then(response => {
        const role = response.data.data.roles[0];
        console.log('roles', response.data.data.roles);
        console.log('objectPermission', response.data.data.objectPermission);
        // if ((role=="ADMIN") && (!websocket.isConnected())) {

        if (false) {
          if (role === 'ADMIN' && !websocket.isConnected()) {
            const apikey = '' + localStorage.getItem('apikey');
            if (apikey) {
              console.log('websocket:start', apikey);
              websocket.start(apikey);
            }
          }
        }
        context.commit('saveUser', {
          fullName: response.data.data.nameFull || response.data.data.name || '',
          role: response.data.data.roles[0],
          roles: response.data.data.roles.map((role2: string) => role2.toLowerCase()),
          email: response.data.data.email || response.data.data.name,
          id: response.data.data.id,
          name: response.data.data.name,
          objectPermission: response.data.data.objectPermission || 'ALL',
          minDaysBeforeCancellation: response.data.data.minDaysBeforeCancellation
        });
        context.dispatch('getObjects');
      });
    },
    getObjects(context) {
      Vue.http.get('object/find').then(response => {
        console.log('object/find', response.data.data);
        context.commit('setObjects', response.data.data.data);
      });

    }
  }
});
