<template>
  <div class="col-md-6">
    <div class="card card-info card-outline">
      <div class="card-header">
        <div class="card-title">
          Data
        </div>
      </div>

      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <label>Od</label>

            <datepicker v-model="dateStart" input-class="form-control" format="dd-MM-yyyy" :language="pl" :full-month-name="true" />
          </div>

          <div class="col-md-6">
            <label>Do</label>

            <datepicker v-model="dateEnd" input-class="form-control" format="dd-MM-yyyy" :language="pl" :full-month-name="true" />
          </div>
        </div>
      </div>

      <div class="card-footer">
        <button class="btn btn-info float-right" @click="search">Wyszukaj okresy</button>
      </div>
    </div>
  </div>
</template>

<script>
import {pl} from 'vuejs-datepicker/dist/locale';
import datepicker from 'vuejs-datepicker';

export default {
  data() {
    return {
      dateStart: undefined,
      dateEnd: undefined,
      pl
    };
  },
  props: {
    // Początek trwania abonamentu
    searchDateStart: {
      required: true,
      type: Date
    },
    // Koniec trwania abonamentu
    searchDateEnd: {
      required: true,
      type: Date
    }
  },
  components: {
    datepicker
  },
  watch: {
    searchDateStart() {
      this.dateStart = this.searchDateStart;
      this.dateEnd = this.searchDateEnd;
    }
  },
  methods: {
    search() {
      this.$emit('search', this.dateStart, this.dateEnd);
    }
  }
};
</script>
