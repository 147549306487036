<template>
  <div>
    <div class="row">
      <div class="col-md-3">
        <!-- Profile Image -->
        <div class="card card-warning card-outline">
          <div class="card-body box-profile">
            <div class="text-center">
              <h1>
                <i class="nav-icon fa fa-user"></i>
              </h1>
            </div>

            <h3 class="profile-username text-center"></h3>

            <ul class="list-group list-group-unbordered mb-3">
              <li class="list-group-item">
                <b>Nazwa skrócona:</b> {{ user.name || '[brak nazwy organizacjii]' }}
                <a class="float-right"></a>
              </li>
              <li class="list-group-item" v-if="user.nameFull">
                <b>Pełna nazwa:</b> {{ user.nameFull }}
                <a class="float-right"></a>
              </li>
              <li class="list-group-item" v-if="user.address">
                <i class="fa fa-home"></i> {{ user.address }}
                <a class="float-right"></a>
              </li>

              <li v-if="user.firstname" class="list-group-item">
                <b>Imię:</b> {{ user.firstname || '[brak imienia]' }}
                <a class="float-right"></a>
              </li>
              <li v-if="user.surname" class="list-group-item">
                <b>Nazwisko:</b> {{ user.surname || '[brak nazwiska]' }}
                <a class="float-right"></a>
              </li>
              <li v-if="user.nip" class="list-group-item">
                <b>NIP:</b> {{ user.nip }}
                <a class="float-right"></a>
              </li>
              <li v-if="user.umowa" class="list-group-item">
                <b>Umowa:</b> {{ user.umowa }}
                <a class="float-right"></a>
              </li>
            </ul>
            <span class="btn-group text-center" style="width: 100%">
              <router-link title="Rezerwacje" :to="{name: 'ClientReservations', params: {id: user.id}}" class="col-3 btn btn-warning">
                <i class="fa fa-lg fa-address-book"></i>
              </router-link>
              <router-link title="Raporty" :to="{name: 'ClientRaports', params: {id: user.id}}" class="col-3 btn btn-warning">
                <i class="fa fa-lg fa-bars"></i>
              </router-link>
              <button v-if="this.$hasRole('admin')" title="Edytuj" class="col-3 btn btn-primary" @click="editUserButton">
                <i class="fa fa-lg fa-edit"></i>
              </button>
              <button v-if="this.$hasRole('admin')" title="Usuń" class="col-3 btn btn-danger" @click="deleteButton2(user)">
                <i class="fa fa-lg fa-trash"></i>
              </button>
            </span>
          </div>
          <!-- /.card-body -->
        </div>
        <!-- /.card -->

        <!-- About Me Box -->

        <!-- /.card-header -->
        <div class="card card-warning card-outline">
          <b-tabs>
            <div class="card-header">
              <div class="card-title">Informacje</div>
            </div>
            <div class="card-body" id="info ">
              <strong> <i class="fa fa-phone mr-1"></i> Telefon</strong>

              <p class="text-muted">
                {{ user.phone || '[brak nr telefonu]' }}
              </p>

              <hr />

              <strong> <i class="fa fa-at mr-1"></i> E-mail</strong>

              <p class="text-muted">{{ user.email || '[brak adresu email]' }}</p>
              <hr />

              <strong> <i class="fa fa-link mr-1"></i> Link do logowania</strong>
              <a :href="link">
                <small class="text-muted"> {{ link }} </small>
              </a>
            </div>
          </b-tabs>
        </div>

        <!-- /.card -->
      </div>
      <!-- /.col -->
      <div class="col-md-9">
        <b-tabs>
          <b-tab title="Rezerwacje">
            <div class="card">
              <div class="card-header">
                <div class="card-title">
                  <i class="fa fa-address-book"></i> Nadchodzące rezerwacje (7 dni)<span v-if="showDatePicker">, od </span>
                  <div class="btn-group">
                    <datepicker
                      v-if="showDatePicker"
                      :language="pl"
                      :input-class="'btn btn-dark btn-sm'"
                      :wrapper-class="'float-right'"
                      :full-month-name="true"
                      v-model="resDate"
                      @input="setResDate"
                      format="D dd.MM.yyyy"
                    >
                    </datepicker>
                  </div>

                  <button type="button " class="btn btn-warning btn-sm float-right" v-if="false" @click="addReservationButton()">
                    <i class="fa fa-fw fa-plus"></i> Dodaj rezerwację
                  </button>

                  <button class="btn btn-danger btn-sm float-right" v-if="selectObjectMode && selectedObjectCount > 0" @click="cancelSelected()">
                    <i class="fa fa-cancel"></i> Anuluj zaznaczone ({{ selectedObjectCount }}) ...
                  </button>
                </div>
              </div>
              <div class="card-body">
                <table class="table table-sm table-bordered table-hover">
                  <thead>
                    <tr>
                      <th>Nazwa obiektu</th>
                      <th>Rezerwacja / Typ</th>
                      <th class="text-right">Początek</th>
                      <th class="text-right">Koniec</th>
                      <th class="text-center">Status</th>
                      <th class="text-right">Cena</th>
                    </tr>
                  </thead>
                  <tbody v-for="dateitem in dates" :key="dateitem.date">
                    <tr>
                      <th colspan="6" class="text-left">
                        <input
                          v-if="selectObjectMode && dateitem.canCancel"
                          type="checkbox"
                          :disabled="!dateitem.canCancel"
                          style="margin-right: 5px"
                          @click="selectDate(dateitem, $event)"
                        />
                        <span><i class="fa fa-calendar fa-fw"></i>{{ dateitem.date }} {{ dateitem.date | dayOfWeek }}</span>
                        <!--
                      &nbsp;
                      <span><i class="fa fa-clock-o fa-fw"></i>{{dateitem.usedLength | hminute}}</span>
                      &nbsp;
                      <span>{{dateitem.usedPrice | money}}</span>
                      --></th>
                    </tr>
                    <tr v-for="item in dateitem.items" :key="item.id" @click.prevent="dateitem.canCancel ? itemClick(item) : false">
                      <td>
                        <input
                          v-if="selectObjectMode && dateitem.canCancel"
                          v-model="item.selected"
                          :disabled="!dateitem.canCancel"
                          @chenge.prevent="itemClick(item)"
                          type="checkbox"
                          style="margin-right: 5px"
                        />

                        <span>{{ item.objectName }}</span>
                      </td>
                      <td>
                        <span>{{ item.reservationName }}</span> &nbsp; <span v-if="item.reservationTypeName" class="badge bg-primary">{{ item.reservationTypeName }}</span>
                      </td>
                      <td class="text-right">
                        <small v-if="item.start == null" class="text-danger">nieprawidłowy</small><span v-else style="white-space: nowrap">{{ item.start | hminute }}</span>
                      </td>
                      <td class="text-right">
                        <small v-if="item.length == null" class="text-danger">nieprawidłowy</small
                        ><span v-else style="white-space: nowrap">{{ (item.start + item.length) | hminute }}</span>
                      </td>
                      <td class="text-center">
                        <span v-if="item.status == 1" class="badge bg-success">{{ item.status | statusText }}</span>
                        <span v-else-if="item.status == 3" class="badge bg-primary">{{ item.status | statusText }}</span>
                        <span v-else class="badge bg-warning">{{ item.status | statusText }}</span>
                      </td>
                      <td class="text-right">
                        <span class="price" @click="changePrice(item)" :class="{zero: item.price == 0}" style="cursor: pointer; white-space: nowrap">{{ item.price | money }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p></p>
                <button @click="reloadReservations(true)" class="btn btn-primary btn-sm pull-right">Pokaz kolejne 7 dni</button>
              </div>
            </div>
          </b-tab>
          <b-tab title="Klienci" v-if="$useClients()">
            <div class="card">
              <div class="card-header">
                <div class="card-title">
                  <i class="fa fa-user"></i> Klienci
                  <button type="button " class="btn btn-warning btn-sm float-right" @click="addClientButton()"><i class="fa fa-fw fa-plus"></i> Dodaj klienta</button>
                </div>
              </div>
              <div class="card-body">
                <div v-if="!clients.length > 0" class="alert alert-warning">Brak przypisanych klientów</div>

                <ul class="todo-list" style="padding: 10px">
                  <li v-for="(client, index) in clients" :key="index">
                    <b
                      ><router-link :to="{name: 'ClientsProfile', params: {id: client.cardOwnerId}}">{{ client.firstName }} {{ client.lastName }}</router-link></b
                    >
                    <div class="tools">
                      <i class="fa fa-edit" title="Edytuj" @click="editClientButton(client)"></i>
                      <i class="fa fa-trash-o" title="Usuń" @click="deleteButton(client)"></i>
                    </div>
                    <br />
                    <small> <i class="fa fa-drivers-license-o"></i> &nbsp;{{ client.role }}</small>
                  </li>
                </ul>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>

    <client-add v-if="showAdd" :show="showAdd" v-on:reload="reload" v-on:cancel="cancel" :option="option" :object="editClient"></client-add>
    <app-delete v-if="showDelete" :show="showDelete" :object="editClient" @cancel="cancel()" v-on:delete="del"></app-delete>
    <app-edit v-if="showEdit" :show="showEdit" :user="user" @save="saveUser()" @cancel="cancelEdit()"></app-edit>
    <app-cancel :show="showCancel" :picked="pickedId" @cancel="showCancel = false" @reload="reloadReservations" :res="null" :option="Modaloption"></app-cancel>
    <change-price :show="showChangePrice" :res="resMore" @cancel="showChangePrice = false" @reload="reloadReservations"></change-price>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import {pl} from 'vuejs-datepicker/dist/locale';

import clientAdd from './AddCardOwner.vue';
import appDelete from '../modules/Delete.vue';
import appEdit from './Edit.vue';
import appCancel from '../reservations/CancelingReservation.vue';
import loadingIcon from '../modules/LoadingIcon.vue';
import changePrice from '../reservations/ChangePrice.vue';

export default {
  components: {
    clientAdd,
    appDelete,
    appEdit,
    appCancel,
    changePrice,
    Datepicker
  },
  data() {
    return {
      showDatePicker: true,
      userId: this.$route.params.id,
      user: {},
      clients: [],
      dates: [],
      details: [],
      showAdd: false,
      showDelete: false,
      showEdit: false,
      selectObjectMode: true,
      editClient: {},
      selectedObjectCount: 0,
      id: 0,
      pl,
      option: 'add',
      showCancel: false,
      showChangePrice: false,
      Modaloption: 'cancel',
      pickedId: [],
      resMore: null,
      resDate: null
    };
  },

  created() {
    this.reload(true);
  },
  methods: {
    reload(full) {
      this.$http.get('client/get/' + this.userId).then((response) => {
        this.user = response.data.data;
      });
      if (full) {
        this.reloadClients();
        this.reloadReservations();
      }
    },
    reloadClients() {
      if (!this.$useClients()) {
        return;
      }
      this.$http.post('client/findCardOwners', JSON.stringify({clientId: this.userId})).then((response) => {
        this.clients = response.data.data.data;
      });
    },
    setResDate(date) {
      // alert(date);
      this.reloadReservations(false, this.$fdate(date));
    },
    reloadReservations(more, start) {
      if (!start) {
        start = this.$fdate(new Date());
      }

      if (more) {
        let prevStart = this.resDate;
        if (prevStart) {
          start = this.$fdate(this.$addDay(new Date(prevStart), 7));
          console.log('start', start, 'prevStart', prevStart);
        } else {
          more = false;
        }
      }
      this.resDate = start;
      var end = this.$fdate(this.$addDay(new Date(start), 6));

      this.$http.get('client/report/' + this.userId + '?start=' + start + '&end=' + end).then((response) => {
        var data = response.data.data;
        var nDates = this.$groupReservationItemByDate(data);
        if (more) {
          for (var i = 0; i < nDates.length; i++) {
            this.dates.push(nDates[i]);
          }
          for (var i = 0; i < data.length; i++) {
            this.details.push(data[i]);
          }
        } else {
          this.dates = nDates;
          this.details = data;
        }
      });
    },
    addClientButton() {
      this.showAdd = true;
      this.option = 'add';
    },
    cancel() {
      this.showAdd = false;
      this.showDelete = false;
      this.editClient = {};
    },
    cancelEdit() {
      this.showEdit = false;
      this.reload();
    },
    deleteButton(client) {
      this.editClient = {
        id: client.id,
        name: client.firstName + ' ' + client.lastName,
        type: 'client'
      };
      this.showDelete = true;
    },
    deleteButton2(user) {
      this.editClient = {
        id: user.id,
        name: user.name,
        type: 'user'
      };
      this.showDelete = true;
    },
    editClientButton(user) {
      this.editClient = user;
      this.option = 'update';
      this.showAdd = true;
    },
    editUserButton() {
      this.showEdit = true;
    },
    del(id) {
      if (this.editClient.type == 'client') {
        this.$http.get('client/deleteCardOwner/' + id).then((response) => {
          if (response && response.body && response.body.status == 1) {
            this.reload();
            this.cancel();
            this.$komunikat('success', 'Sukces', 'Pomyślnie usunięto klienta', 3000);
            this.editClient = {};
          } else {
            this.$komunikat('error', 'Błąd', 'Błąd przy usuwaniu klienta', 3000);
            this.editClient = {};
          }
        });
      } else if (this.editClient.type == 'user') {
        this.$http.get('client/delete/' + id).then((response) => {
          if (response && response.body && response.body.status == 1) {
            this.deleteShow = false;
            this.reload();
            this.cancel();
            this.$komunikat('success', 'Sukces', 'Pomyślnie usunięto podmiot', 3000);
            this.$router.push({name: 'users'});
          } else {
            this.$komunikat('error', 'Błąd', 'Błąd przy usuwaniu podmiotu', 3000);
            this.editClient = {};
          }
        });
      }
    },
    saveUser() {
      this.user.minDaysBeforeCancellation = this.user.minDaysBeforeCancellation || 0;
      this.$http.post('client/update', JSON.stringify(this.user), {}).then((response) => {
        if (response && response.body && response.body.status == 1) {
          this.cancelEdit();
          this.$komunikat('success', 'Sukces', 'Pomyślnie edytowano podmiot', 3000);
        } else {
          this.$komunikat('error', 'Błąd', 'Błąd przy edytowaniu podmiotu', 3000);
        }
      });
    },
    selectDate(dateitem, $event) {
      var checked = $event.target.checked;
      for (var i = 0; i < dateitem.items.length; i++) {
        var item = dateitem.items[i];
        if (checked) {
          if (!item.selected) {
            item.selected = true;
            this.selectedObjectCount++;
          }
        } else {
          if (item.selected) {
            item.selected = false;
            this.selectedObjectCount--;
          }
        }
      }
    },
    cancelSelected() {
      var idlist = [];

      idlist = this.details.filter((x) => x.selected).map((x) => x.id);

      this.pickedId = idlist;

      this.showCancel = true;
    },

    itemClick(item, event) {
      //item is
      if (event) {
        console.log(event.target);
        //return;
      }
      if (this.selectObjectMode) {
        if (item.selected) {
          item.selected = false;
          this.selectedObjectCount--;
        } else {
          item.selected = true;
          this.selectedObjectCount++;
        }
      }
    },
    changePrice(item) {
      if (this.$isAdmin()) {
        this.resMore = item;
        this.showChangePrice = true;
      }
    }
  },
  watch: {
    $route(to, from) {
      this.reload();
    }
  },
  computed: {
    link: function () {
      if (this.user.accessKey) {
        return window.location.origin + '/login/key/' + this.user.accessKey;
      } else {
        return '[brak klucza dostępu]';
      }
    }
  }
};
</script>

<style></style>
