<template>
  <div class="col-md-6">
    <div class="card card-info card-outline">
      <div class="card-header">
        <div class="card-title">
          {{ service.name }}
        </div>
      </div>

      <div class="card-body">
        <span><b>Typ: </b> {{ service.type | serviceType }}</span
        ><br />

        <span><b>Cena za okres: </b> {{ service.price }} zł</span><br />

        <span><b>Początek obowiązywania: </b> {{ service.dateStart | moment('DD-MM-YYYY') }}</span
        ><br />

        <span v-if="service.dateEnd"><b>Koniec obowiązywania: </b> {{ service.dateEnd | moment('DD-MM-YYYY') }}</span>
      </div>

      <div class="card-footer"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // Szczegóły usługi
    service: {
      type: Object,
      required: true
    }
  }
};
</script>
