<template>
  <!-- Modal -->
  <b-modal v-model="showLocal" size="lg" @hidden="$emit('cancel')">
    <h5 slot="modal-title" id="edituserModalLabel" v-if="user.id">Edycja podmiotu {{ user.name }}</h5>
    <h5 slot="modal-title" id="edituserModalLabel" v-else>Dodawanie podmiotu</h5>
    <div slot="modal-header-close">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$emit('cancel')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="box-body">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label :class="{'text-danger': $v.user.name.$anyError}" for="name">Nazwa skrócona</label>
            <input :class="{'is-invalid': $v.user.name.$anyError}" class="form-control" type="text" v-model.trim="$v.user.name.$model" />
            <small class="text-danger" v-if="$v.user.name.$anyError">Nazwa skrócona jest wymagana</small>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label :class="{'text-danger': $v.user.nameFull.$anyError}" for="nameFull">Pełna nazwa</label>
            <input :class="{'is-invalid': $v.user.nameFull.$anyError}" class="form-control" type="text" id="nameFull" v-model.trim="$v.user.nameFull.$model" />
            <small class="text-danger" v-if="$v.user.nameFull.$anyError">Pełna nazwa jest wymagana</small>
          </div>
        </div>
                <div class="col-md-12">
          <div class="form-group">
            <label :class="{'text-danger': $v.user.address.$anyError}" for="address">Adres</label>
            <input :class="{'is-invalid': $v.user.address.$anyError}" class="form-control" type="text" id="address" v-model.trim="$v.user.address.$model" />
            <small class="text-danger" v-if="$v.user.address.$anyError">Adres jest wymagany</small>
          </div>
        </div>

        <div class="col-md-12">
          <div class="form-group">
            <label for="color">Kolor </label>
            <!-- colors -->
            <ul class="fc-color-picker">
              <li v-for="(kolor, id) in $colors" :key="id">
                <a :style="{color: kolor}" @click="user.color = kolor">
                  <i class="fa fa-square" v-if="user.color == kolor"></i>
                  <i class="fa fa-square-o" v-else></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="firstName">Imię</label>
            <input class="form-control" id="firstName" type="text" v-model="user.firstname" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="surname">Nazwisko</label>
            <input class="form-control" id="surname" type="text" v-model="user.surname" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label :class="{'text-danger': $v.user.phone.$anyError}" for="phone">Nr telefonu</label>
            <input :class="{'is-invalid': $v.user.phone.$anyError}" class="form-control" id="phone" type="text" v-model="$v.user.phone.$model" />
            <small class="text-danger" v-if="$v.user.phone.$anyError">Tylko cyfry</small>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label :class="{'text-danger': $v.user.email.$anyError}" for="email">Adres e-mail</label>
            <input :class="{'is-invalid': $v.user.email.$anyError}" class="form-control" id="email" type="text" v-model="$v.user.email.$model" />
            <small class="text-danger" v-if="$v.user.email.$anyError">Niepoprawny adres email</small>
          </div>
        </div>
      </div>
            <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label :class="{'text-danger': $v.user.nip.$anyError}" for="nip">NIP</label>
            <input :class="{'is-invalid': $v.user.nip.$anyError}" class="form-control" id="nip" type="text" v-model="$v.user.nip.$model" />
            <small class="text-danger" v-if="$v.user.nip.$anyError">Niepoprawny nip</small>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label :class="{'text-danger': $v.user.umowa.$anyError}" for="umowa">Numer umowy</label>
            <input :class="{'is-invalid': $v.user.umowa.$anyError}" class="form-control" id="umowa" type="text" v-model="$v.user.umowa.$model" />
            <small class="text-danger" v-if="$v.user.umowa.$anyError">Niepoprawny numer umowy</small>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label :class="{'text-danger': $v.user.minDaysBeforeCancellation.$anyError}" for="mindays">Minimalna wyprzedzenie aby anulować rezerwację</label>
            <input
              :class="{'is-invalid': $v.user.minDaysBeforeCancellation.$anyError}"
              class="form-control"
              id="mindays"
              type="text"
              v-model="$v.user.minDaysBeforeCancellation.$model"
            />
            <small class="text-danger" v-if="$v.user.minDaysBeforeCancellation.$anyError">Tylko cyfry</small>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-9">
          <div class="form-group">
            <label :class="{'text-danger': $v.user.accessKey.$anyError}" for="accessKey">Klucz dostępu</label>
            <input :class="{'is-invalid': $v.user.accessKey.$anyError}" class="form-control" id="accessKey" type="text" v-model.trim="$v.user.accessKey.$model" />
            <small class="text-danger" v-if="$v.user.accessKey.$anyError">Klucz dostępu jest wymagany</small>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label>&nbsp;</label>
            <button class="form-control btn btn-warning" @click="losuj">Losuj</button>
          </div>
        </div>
      </div>
      <div class="row" v-if="user.accessKey">
        <div class="col-md-12">
          <label> Link do logowania </label><br />
          <a :href="link">
            <small>
              {{ link }}
            </small>
          </a>
        </div>
      </div>
    </div>
    <!-- Modal-footer -->
    <div slot="modal-footer">
      <button type="button" class="btn btn-default" data-dismiss="modal" @click="$emit('cancel')">Wyjdź</button>&nbsp;
      <button type="button" class="btn btn-warning" data-dismiss="modal" @click="saveButton()">Zapisz</button>
    </div>
    <!-- /Modal-footer -->
  </b-modal>
</template>

<script>
import {email, required, numeric} from 'vuelidate/lib/validators';

export default {
  props: {
    user: {},
    show: Boolean
  },

  data() {
    return {
      userid: '',
      showLocal: this.show
    };
  },
  validations: {
    user: {
      accessKey: {
        required
      },
      minDaysBeforeCancellation: {
        numeric
      },
      name: {
        required
      },
      nameFull: {
        required
      },
      email: {
        email
      },
      phone: {
        numeric
      },
      nip: {},
      umowa: {},
      address: {},

    }
  },
  watch: {
    showLocal: function(val) {
      if (this.showLocal == false) {
        this.$emit('cancel');
      }
    }
  },

  methods: {
    losuj() {
      this.$http.get('client/renderKey').then(response => {
        //this.$set(this.user,"accessKey",response.data.data);
        this.user.accessKey = response.data.data;
      });
    },
    saveButton() {
      if (this.$v.$invalid == true) {
        this.$v.$touch();
      } else {
        //this.user.email = this.email;
        //this.user.phone = this.phone;
        this.$emit('save');
      }
    }
  },
  computed: {
    link: function() {
      return window.location.origin + '/login/key/' + this.user.accessKey;
    }
  }
};
</script>

<style></style>
