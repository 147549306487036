<template>
  <div class="row text-right" style="padding:5px">
    <div class="text-left" :class="colsLeft ? 'col-' + colsLeft : 'col-6'">
      <slot></slot>
    </div>
    <div :class="colsRight ? 'col-' + colsRight : colsLeft ? 'col-' + (12 - colsLeft) : 'col-6'">
      <div class="btn-group text-right">
        <select style="height:39px" class="form-control" v-model="count">
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
        &nbsp;
        <ul v-if="maxpage > 1" role="menubar" aria-label="Pagination" aria-disabled="false" class="pagination b-pagination pagination-md">
          <li class="page-item" @click="minus()">
            <i class="fa fa-chevron-left page-link" :style="{color: color}"></i>
          </li>
          <template v-if="maxpage <= 10">
            <li v-for="page in maxpage" role="none presentation " :key="page" class="page-item" :style="{color: color}" @click="choose(page)">
              <span v-if="page == post.page" class="page-link active" :style="{color: colorActive, backgroundColor: backgroundActive}">{{ page }}</span>
              <span class="page-link" v-else :style="{color: color}">{{ page }}</span>
            </li>
          </template>
          <template v-if="maxpage > 10">
            <li v-for="page in pagesstart" role="none presentation " :key="page" class="page-item" :style="{color: color}" @click="choose(page)">
              <span v-if="page == post.page" class="page-link active" :style="{color: colorActive, backgroundColor: backgroundActive}">{{ page }}</span>
              <span class="page-link" v-else :style="{color: color}">{{ page }}</span>
            </li>
          </template>
          <li v-if="pagesstart.length > 0 && maxpage > 10" role="none presentation  " class="page-item" :style="{color: color}">
            <span class="page-link" :style="{color: color}">...</span>
          </li>
          <template v-if="maxpage > 10">
            <li v-for="page in pagesmiddle" role="none presentation " :key="page" class="page-item" :style="{color: color}" @click="choose(page)">
              <span v-if="page == post.page" class="page-link active" :style="{color: colorActive, backgroundColor: backgroundActive}">{{ page }}</span>
              <span class="page-link" v-else :style="{color: color}">{{ page }}</span>
            </li>
          </template>
          <li v-if="pagesend.length > 0 && maxpage > 10" role="none presentation  " class="page-item" :style="{color: color}">
            <span class="page-link" :style="{color: color}">...</span>
          </li>
          <template v-if="maxpage > 10">
            <li v-for="page in pagesend" role="none presentation " :key="page" class="page-item" :style="{color: color}" @click="choose(page)">
              <span v-if="page == post.page" class="page-link active" :style="{color: colorActive, backgroundColor: backgroundActive}">{{ page }}</span>
              <span class="page-link" v-else :style="{color: color}">{{ page }}</span>
            </li>
          </template>

          <li class="page-item" @click="plus()">
            <i class="fa fa-chevron-right page-link" :style="{color: color}"></i>
          </li>
        </ul>
      </div>
      <div class="text-right" v-if="maxcount > 0 && post.skip + post.count < filteredCount">
        Pozycje od {{ parseInt(post.skip) + 1 }} do {{ parseInt(post.skip) + parseInt(post.count) }} z łącznie {{ filteredCount }}
        <span v-if="filteredCount != maxcount">
          (filtrowanie spośród {{ maxcount }})
          <!-- dostępnych pozycji -->
        </span>
      </div>
      <div class="text-right" v-bind:class="{paddingTop: maxpage == 1}" v-else-if="maxcount > 0 && post.skip + post.count >= filteredCount">
        Pozycje od {{ parseInt(post.skip) + 1 }} do {{ filteredCount }} z łącznie {{ filteredCount }}
        <span v-if="filteredCount != maxcount">
          (filtrowanie spośród {{ maxcount }})
          <!-- dostępnych pozycji -->
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    colorActive: String,
    post: {
      type: Object,
      skip: Number,
      count: Number,
      draw: Number,
      page: Number,
      q: String,
      required: true
    },
    colsLeft: Number,
    colsRight: Number,
    backgroundActive: String,
    maxcount: Number,
    filteredCount: Number
  },
  data() {
    return {
      color: '#000',
      count: this.post.count
      // filteredCount: 10000
      //maxcount: 320
    };
  },
  methods: {
    minus() {
      if (parseInt(this.post.skip) - parseInt(this.post.count) >= 0) {
        this.post.skip = parseInt(this.post.skip) - parseInt(this.post.count);
        this.$emit('post', this.post);
        this.post.page--;
        this.post.draw++;

        this.$emit('reload');
      }
    },
    plus() {
      if (parseInt(this.post.page) + 1 <= this.maxpage) {
        this.post.skip = parseInt(this.post.skip) + parseInt(this.post.count);
        this.$emit('post', this.post);
        this.post.page++;
        this.post.draw++;

        this.$emit('reload');
      }
    },
    choose(page) {
      this.post.page = page;
      this.post.skip = parseInt(this.post.count) * (parseInt(page) - 1);
      this.post.draw++;
      this.$emit('post', this.post);

      this.$emit('reload');
    },
    Round(n, k) {
      var factor = Math.pow(10, k);
      return Math.round(n * factor) / factor;
    }
  },
  computed: {
    pagesstart: function(val) {
      var table = [];
      if (parseInt(this.post.page) > 4) {
        for (var i = 1; i <= 2; i++) {
          table.push(i);
        }
        return table;
      } else {
        return table;
      }
    },
    pagesmiddle: function(val) {
      var firstpage = parseInt(this.post.page) - 2;
      if (firstpage < 1) {
        firstpage = 1;
      }
      var lastpage = parseInt(this.post.page) + 2;
      if (lastpage > parseInt(this.maxpage)) {
        lastpage = parseInt(this.maxpage);
      }
      var table = [];
      for (var i = firstpage; i <= lastpage; i++) {
        if (i < parseInt(this.filteredCount)) {
          table.push(i);
        }
      }
      return table;
    },
    maxpage: function(val) {
      let pages = Math.ceil(+this.filteredCount / this.post.count);
      if (!pages) {
        return 1;
      }
      return pages;
    },
    pagesend: function(val) {
      var dlugosc = parseInt(this.maxpage);
      var table = [];
      if (dlugosc - 3 > parseInt(this.post.page)) {
        for (var i = 1; i >= 0; i--) {
          table.push(parseInt(dlugosc) - i);
        }
        return table;
      } else {
        return table;
      }
    }
  },
  watch: {
    count: function() {
      if (this.count != this.post.count) {
        //zabezpieczenie żeby na starcie nie było przeładowania
        this.post.count = this.count;
        this.post.skip = parseInt(this.post.count) * (this.post.page - 1);
        if (this.post.draw !== undefined) this.post.draw++;
        this.post.page = 1;
        this.post.skip = 0;

        this.$emit('post', this.post);
        this.$emit('reload');
      }
    },
    post: function() {
      this.count = this.post.count;
    }
  },
  updated() {
    this.count = this.post.count;
  }
};
</script>

<style scoped>
.paddingTop {
  padding-top: 1rem;
}
</style>
