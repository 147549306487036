<template>
  <b-modal v-model="showLocal" @hidden="$emit('cancel')">
    <h5 slot="modal-title">Zawieś usługę</h5>
    <div slot="modal-header-close">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$emit('cancel')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="box-body">
      <div class="form-group">
        <label>Data rozpoczęcia zawieszenia</label>
        <datepicker v-model="startDate" :language="pl" input-class="form-control" :full-month-name="true" format="yyyy-MM-dd"></datepicker>
      </div>
      <div class="form-group">
        <label>Długość zawieszenia</label>
        <input v-model="daysLength" class="form-control" />
      </div>
    </div>
    <div slot="modal-footer">
      <button type="button" class="btn btn-default" data-dismiss="modal" @click="$emit('cancel')">Anuluj</button>&nbsp;
      <b-button id="save" type="button" class="btn btn-info" data-dismiss="modal" @click="save()">Zapisz</b-button>
    </div>
  </b-modal>
</template>
<script>
import datepicker from 'vuejs-datepicker';
import {pl} from 'vuejs-datepicker/dist/locale';
export default {
  components: {
    datepicker
  },
  props: {
    show: Boolean,
    data: Object
  },

  data() {
    return {
      showLocal: this.show,
      pl: pl,
      daysLength: 7,
      startDate: new Date()
    };
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    save() {
      this.$http
        .post('cardowner/suspendService/' + this.data.id, {
          dateStart: this.startDate,
          days: this.daysLength
        })
        .then(response => {
          if (response && response.body && response.body.status == 1) {
            this.$komunikat('success', 'Sukces', 'Usługa została zawieszona', 3000);
            this.$emit('reload');
            this.$emit('cancel');
          } else {
            let msg = 'Błąd przy zawieszaniu usługi';

            if (response.body.messages && response.body.messages.length) {
              msg = response.body.messages[0];
            }

            this.$komunikat('error', 'Błąd', msg, 3000);
          }
        });
    }
  },
  watch: {
    showLocal: function(val) {
      if (this.showLocal == false) {
        this.$emit('cancel');
      }
    }
  }
};
</script>
<style></style>
