<template>

<div v-if="needInput">
      <div class="col-12">
        <div class="card card-success card-outline">
          <div class="card-header text-center">
            <div class="card-title">
              Szukaj rezerwacji
            </div>
            
          </div>
         <div class="card-body">
             <div class="form-group">
                 <label>Numer rezerwacji</label>
                 <input class="form-control" type="text" v-model="inputOrderId" />

             </div>
             <div class="form-group">
                 <label> Adres email</label>
                 <input class="form-control" type="text" v-model="inputEmail" />

             </div>

             <button class="btn btn-primary" @click="load">Pokaż status</button>


             
         </div>
      </div> 
      </div>

</div>

<div v-else>

<div class="row" v-if="process">
      <div class="col-12">
        <div class="card card-success card-outline">
          <div class="card-header text-center">
            <div class="card-title">
              Trwa pobieranie danych rezerwacji .....
            </div>
          </div>
        </div>
      </div>
</div>
<div v-else>
    <div v-if="reservation" class="row" >

      <div class="col-12">
        <div class="card card-success card-outline">
          <div class="card-header text-center">
            <div class="card-title">
              Rezerwacja na nazwisko <b>{{ reservation.name }}</b>
            </div>
            
          </div>
         <div class="card-body">
            <h3>Zarezerwowane terminy na obiekcie: <b>{{ reservation.objectName }}</b></h3>
            <ul>
              <li v-for="unit in reservation.units" :key="'sunit_' + unit.id">
                <b>{{ reformatDate(unit.date) }}</b
                >: {{ unit.start | hminute }} - {{ (unit.start + unit.length) | hminute }}
                <span v-if="unit.light"  style="margin-left: 10px;">+ oświetlenie</span>
                <span v-if="unit.price!==undefined" class="price" style="margin-left: 10px;">{{ unit.price }}zł</span>
              </li>
            </ul>
            <p>Numer rezerwacji: {{ reservation.id }}</p>
            <p v-if="reservation.totalPrice">Całkowity koszt: <b>{{ reservation.totalPrice }} zł</b></p>
            <p v-if="reservation.totalPrice">Zapłacono: <b>{{ reservation.payPrice ? reservation.payPrice : 0}} zł</b> <button v-if="reservation.payPrice!=reservation.totalPrice" class="btn btn-sm btn-warning" @click="load"><i class="fa fa-fw fa-refresh"></i> Odśwież</button></p>

<div v-if="reservation.lastPayment">

   <div v-if="reservation.lastPayment.status=='SUCESS' || reservation.lastPayment.status=='CONFIRMED'">Status płatności: <b style="color: green">ZAKOŃCZONA</b> <small>{{reformatDateAndTime(reservation.lastPayment.paymentDate)}}</small></div>
   <div v-if="reservation.lastPayment.status=='FAILURE' || reservation.lastPayment.status=='REJECTED' || reservation.lastPayment.status=='ERROR' || reservation.lastPayment.status=='EXPIRED'">Status płatności: <b style="color: red">ODRZUCONA</b> <small>{{reformatDateAndTime(reservation.lastPayment.paymentDate)}}</small></div>
   <div v-else>Status płatności: <b>W trakcie</b></div>
</div>

            <div v-if="reservation.payHash">
             <form target="_blank" action="https://pay.autopay.eu/payment" method="POST">
              <input type="hidden" name="ServiceID" :value="reservation.payServiceID">
              <input type="hidden" name="OrderID" :value="reservation.id">
              <input type="hidden" name="Amount" :value="reservation.toPayPrice">
              <input type="hidden" name="Description" :value="reservation.payDescription">
              
              <input v-if="reservation.email" type="hidden" name="CustomerEmail" :value="reservation.email">
            
              <input type="hidden" name="Hash" :value="reservation.payHash">

              <button v-if="reservation.lastPayment" type="submit" class="btn btn-primary">Zapłać ponownie (Blue Media)</button>
              <button v-else type="submit" class="btn btn-primary">Zapłać online (Blue Media)</button>

            </form>
            </div>

           

            
          </div>
        </div>
      </div>
    </div>
    <div v-else class="row" >
      <div class="col-12">
        <div class="card card-success card-outline">
          <div class="card-header text-center">
            <div class="card-title">
              {{error}}
            </div>
          </div>
          <div class="card-body">
              <button @click="needInput=true" class="btn btn-primary">Szukaj rezerwacji</button>
          </div>
        </div>
      </div>
    </div>
</div>


</div>




</template>

<script>
export default {
    data() {
        return {
            reservation: null,
            process: true,
            needInput: true,
            
            inputOrderId: '',
            inputEmail: '',
            error: 'Nieprawidłowe dane rezerwacji'


        }
        
    },
    methods: {
        reformatDate(date) {
            if (date.indexOf('T')>-1) {
                let dt = new Date(date);
                let s = dt.getFullYear()+'-';
                if (dt.getMonth()<9) {
                    s+='0';
                }
                s+=(dt.getMonth()+1)+'-';
                if (dt.getDate()<10) {
                    s+='0';
                }
                s+=(dt.getDate());
                return s;
            }
            return date
        },
        reformatDateAndTime(date) {
            if (date.indexOf('T')>-1) {
                let dt = new Date(date);
                let s = '';
                if (dt.getHours()<10) {
                    s+='0';
                }
                s+=(dt.getHours())+':';
                if (dt.getMinutes()<10) {
                    s+='0';
                }
                s+=(dt.getMinutes());
                return this.reformatDate(date)+' '+s;
            }
            return date
        },
        load() {
            this.needInput = false;
            this.process = true;
            this.reservation = null;
            //let reservationId = this.$route.query.OrderID;

            // PayNow
            let paymentId = this.$route.query.paymentId
            // let paymentStatus = this.$route.query.paymentStatus




            let reservationId = this.inputOrderId;
            
            let serviceID = this.$route.query.ServiceID;
            let hash = this.$route.query.Hash;
            // let email = this.$route.query.Email;
            let email = this.inputEmail;
            if (hash || email) {
                this.$http.post('reservation/status', JSON.stringify({
                    id: parseInt(reservationId),
                    email,
                    hash,
                    paymentId

                }), {}).then(
                     (response) => {
          if (response && response.body && response.body.status == 1) {
              // debugger;

            this.reservation = response.body.data;
            this.process = false;

          } else {
            this.reservation = null;
            this.process = false;
            if (response.body.messages && response.body.messages.length) {
              this.error = response.body.messages[0];
            }

          }})

            } else {
                  setTimeout(() => {
                    this.reservation = null;
                this.process = false;
            },1000);

            }

          
        }
        
    },

    mounted() {
        // this.load();
    },
    watch: {
        "$route.query": {
            immediate: true,
            handler(newVal) {
                if (this.$route.query.Email) {
                    this.inputEmail = this.$route.query.Email;
                } 
                if (this.$route.query.OrderID) {
                    this.inputOrderId = this.$route.query.OrderID;
                }
                this.needInput =  !((this.inputEmail && this.inputEmail) || this.$route.query.Hash);
                if (!this.needInput) {
                   this.load();
                }
            }
        }

    }
}

</script>