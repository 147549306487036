interface IConfig {
  api: string;
  websock: string;
  logo: string;
  hosts: string[];

  title: string;
  //czy klient może się logować
  clientLogin: boolean;
  //czy jest dostępne zarządzanie usługami
  services: boolean;
  stats: boolean;
  //czy dostępne jest zarządzanie klientami
  clients: boolean;
}

let config = {
  current: <IConfig>{},
  dev: <IConfig>{
    //api: "http://ngo.witkac.pl:30001/api",
    //websock: "http://ngo.witkac.pl:30001",
    api: 'http://localhost:30004/api',
    websock: 'http://localhost:30004',
    logo: 'sosir-logo.png',
    title: 'Dev',
    clientLogin: false,
    clients: false,
    stats: false,
    services: false,
    hosts: ['localhost:8080', 'localhost:8081', 'localhost:8085']
  },
  test: <IConfig>{
    api: 'http://ngo.witkac.pl:30010/api',
    websock: 'http://ngo.witkac.pl:30010',
    logo: 'witkac-logo.png',
    title: 'TEST',
    clientLogin: true,
    clients: true,
    stats: true,
    services: true,
    hosts: ['objectmanager.test.witkac.pl']
  },
  sosir: <IConfig>{
    api: 'https://api.sosir.apps.witkac.pl/api',
    websock: 'https://api.sosir.apps.witkac.pl',
    logo: 'sosir-logo.png',
    title: 'SOSIR',
    clientLogin: false,
    clients: false,
    stats: false,
    services: false,
    hosts: ['localhost:8082', 'localhost:8084', 'sosirdev.test.witkac.pl', 'sosir.test.witkac.pl', 'sosir.objectmanager.test.witkac.pl', 'sosir.apps.witkac.pl']
  },
  garage: <IConfig>{
    api: 'https://panel-api.fitgaraz.pl/api',
    websock: 'https://panel-api.fitgaraz.pl',
    logo: 'garage-logo.png',
    title: 'Fit Garaż',
    clientLogin: false,
    stats: true,
    clients: true,
    services: true,
    hosts: ['panel.fitgaraz.pl', 'localhost:8083', 'garagedev.test.witkac.pl', 'garagedev.test.witkac.pl', 'garage.objectmanager.test.witkac.pl']
  },
  karta: <IConfig>{
    api: 'http://ngo.witkac.pl:30005/api',
    websock: 'http://ngo.witkac.pl:30005',
    logo: 'sosir-logo.png',
    title: 'Karta',
    clientLogin: false,
    stats: true,
    clients: true,
    services: true,
    hosts: ['karta.test.witkac.pl', 'karta.test.witkac.pl', 'karta.objectmanager.test.witkac.pl']
  }
};

config.current = config.dev;

let host = window.location.host;

//szukaj po hoście
for (let confname in config) {
  let cc = <IConfig>(<any>config)[confname];
  let hosts = cc.hosts;
  if (hosts) {
    for (let i = 0; i < hosts.length; i++) {
      if (hosts[i] == host) {
        config.current = cc;

        break;
      }
    }
  }
}

export default config;
