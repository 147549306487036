<template>
  <div class="row">
    <div>
      <!-- <button class="btn btn-danger" @click="$komunikat('error', 'Błąd ', 'Wystąpił bład przy dodawaniu usługi ', 3000)">Komunikat</button>
     -->
    </div>
    <!-- /errors -->
    <div class="col-12">
      <div class="card card-secondary card-outline">
        <!-- card-header -->
        <div class="card-header">
          <h3 class="card-title">Lista usług <loading-icon v-if="loading"></loading-icon> &nbsp;</h3>
          <app-search :color="'btn-secondary'" :post="post" @reload="reloadUsing" @post="post = $event"> </app-search>
        </div>
        <!-- /.card-header -->
        <div class="card-body p-0">
          <div v-if="usingService.length > 0" class="m-2">
            <b-btn @click="stopUsingAll" variant="secondary" size="sm">Odnotuj wyjście wszystkich</b-btn>
          </div>
          <div class="table-responsive mailbox-messages">
            <div class="alert alert-secondary text-center" v-if="!usingService.length">Brak korzystających z usług</div>
          </div>
          <div v-for="usr in usingService" :key="usr.id">
            <div class="clientitem">
              <div class="mailbox-name">
                <span class="text-bold font-lg">{{ usr.firstName }} {{ usr.lastName }}</span>
                <span class="text-muted" v-if="!service"> {{ usr.serviceName }}</span>
              </div>
              <div>
                <span class="clientspan" v-if="usr.keyNumber">
                  <i class="fa fa-fw fa-key"></i>
                  {{ usr.keyNumber }}
                </span>
                <span class="clientspan" v-if="usr.date">
                  <i class="fa fa-fw fa-calendar"></i>
                  {{ usr.date | moment('LL HH:mm') }}
                </span>
                <span class="clientspan" v-if="usr.objectName">
                  <i class="fa fa-fw fa-building"></i>
                  {{ usr.objectName }}
                </span>
              </div>
              <div class="itembuttons btn-group menu ">
                <button class="btn btn-secondary" title="Odbierz klucz" @click="getKey(usr)"><i class="fa fa-fw  fa-check "></i> Odnotuj wyjście</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import appSearch from '../modules/Search.vue';
import loadingIcon from '../modules/LoadingIcon.vue';

export default {
  components: {
    appSearch,
    loadingIcon
  },
  data() {
    return {
      serviceId: 0,
      service: undefined,
      usingService: [],
      post: {
        q: ''
      },
      loading: true
    };
  },
  mounted() {
    this.reloadLinks();
  },
  methods: {
    getKey(using) {
      this.$http.get('service/stopusing/' + using.id).then(response => {
        this.reloadUsing();
      });
    },
    reloadLinks() {
      this.serviceId = this.$route.params.id;

      if (this.serviceId > 0) {
        this.$http.get('service/get/' + this.serviceId).then(response => {
          this.service = response.data.data;
          if (this.service) {
            this.reloadUsing();
          }
        });
      } else {
        this.reloadUsing();
      }
    },
    reloadUsing() {
      this.loading = true;
      this.$http.get('service/usingNow' + (this.serviceId ? '/' + this.serviceId : '') + '?q=' + this.post.q).then(response => {
        this.usingService = response.data.data;
        this.loading = false;
      });
    },
    stopUsingAll() {
      this.$http
        .get('service/stopusingall')
        .then(res => res.data)
        .then(res => {
          if (res.status == 1) {
            this.$komunikat('success', 'Sukces', 'Pomyślnie zakończono używanie usługi wszystkich klientów.', 3000);
          } else {
            this.$komunikat('error', 'Błąd', 'Błąd przy odnotowywaniu wyjścia.', 3000);
          }
        });
    }
  }
};
</script>
