<template>
  <div>
    <b-modal v-model="showEditor" @hidden="showEditor=false">
    <h5 slot="modal-title" id="edituserModalLabel">Edycja podmiotu</h5>
   
    <div slot="modal-header-close">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$emit('cancel')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="box-body">
                  <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label  for="address">Adres</label>
            <input class="form-control" id="address" type="text" v-model="address" />
          
          </div>
        </div>
       
      </div>


            <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label  for="nip">NIP</label>
            <input class="form-control" id="nip" type="text" v-model="nip" />
          
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="umowa">Numer umowy</label>
            <input class="form-control" id="umowa" type="text" v-model="umowa" />
           
          </div>
        </div>
      </div>

    </div>
    <!-- Modal-footer -->
    <div slot="modal-footer">
      <button type="button" class="btn btn-default" data-dismiss="modal" @click="showEditor=false">Wyjdź</button>&nbsp;
      <button type="button" class="btn btn-warning" data-dismiss="modal" @click="saveDB()">Zapisz do bazy</button>
    </div>
    <!-- /Modal-footer -->
  </b-modal>
  <div class="print-only">
  <div class="row">
      <div class="col-6"><img src="/img/logo/sosir-logo-black.png" width="50%"></div>
      <div class="col-6 text-right">data raportu: {{ dataRaportu | fdate }}</div>
      <div class="col-12"><p></p></div>
  </div>
  </div>

    <div class="col-12">
      <div class="card card-warning card-outline no-print">
        <!-- card-header -->
        <div class="card-header no-print">
          <h3 class="card-title row">
            <div class="btn-group">
              <button class="btn btn-dark btn-sm" @click.prevent="prevDate()" title="poprzedni dzień">
                <i class="fa fa-angle-left" style="cursor: pointer;" aria-hidden="true"></i>
              </button>
              <button class="btn btn-dark btn-sm" @click.prevent="thisMonth()">{{ monthsName }} {{ year }}</button>
              <button class="btn btn-dark btn-sm" @click.prevent="nextDate()">
                <i class="fa fa-angle-right" style="cursor: pointer;" aria-hidden="true" title="następny dzień"></i>
              </button>
            </div>
            &nbsp;
            <div style="margin-left: 10px;"> Podsumowanie wykorzystania w miesiącu: {{ monthsName }} {{ year }}</div>


            <loading-icon v-if="loading"></loading-icon> &nbsp;
          </h3>
          <div class="card-tools">
            <router-link class="btn btn-primary" :to="{name: 'ClientRaportsDetail', params: {id: clientID}, query: {months: months, year: year}}">
              Raport dzienny
            </router-link>
          </div>
        </div>

        <div class="card-header">
          <div class="row text-right text-bold">
            <div class="col-2 text-center">Typ</div>
            <div class="col-2 text-center">Cena jednostkowa</div>
            <div class="col-2">Rezerw</div>
            <div class="col-4">Użycie</div>
            <div class="col-2 text-center">Razem</div>
          </div>
        </div>
        <!-- /.card-header -->
        <div class="card-body p-0 ">
          <div class="row text-right" v-for="(raport, index) in raports.summary" :key="index">
            <div class="col-2 text-center">{{ raport.reservationTypeName }}</div>
            <div class="col-2 text-right">{{ raport.price | money }}</div>
            <div class="col-2">{{ raport.count }}</div>
            <div class="col-2">
              <div class="progress-group">
                &nbsp;

                
                <div class="progress progress-sm">
                  <div class="progress-bar bg-warning" :style="{width: (raport.usedCount / raport.count) * 100 + '%'}"></div>
                </div>
              </div>
            </div>
            <div class="col-2"><span class="float-right"><b>{{ raport.usedCount }}</b>/{{ raport.count }} <span v-if="raport.usedLength"> ({{ raport.usedLength | hminutesum }} h)</span></span></div>
            <div class="col-2 text-right pr-4">{{ raport.usedPrice | money }}</div>
            
          </div>
          <div class="alert alert-warning text-center" v-if="!raports.summary.length">Brak raportów</div>
          <div class="table-responsive mailbox-messages"></div>
        </div>
      </div>
    </div>
    <div v-if="client" class="col-12">
      <h3 class="no-print">RAPORT WYKORZYSTANIA OBIEKTU </h3>
      <h3 class="print-only text-center">RAPORT WYKORZYSTANIA OBIEKTU</h3>
      <table class="table table-bordered" style="background-color: white">
        <tr>
          <td style="width: 50%">
            <div><b>{{client.nameFull}}</b></div>
            <div v-if="address">{{address}}</div>

            <div v-if="nip">NIP: {{nip}}</div>
          </td>
          <td style="width: 50%">
            <div class="no-print">
            
              <div v-for="(typ,index) in objTypeList" :key="'r'+index">
                <input type="checkbox" v-model="typ.enabled" /><span> {{typ.objectName}}: {{typ.typ}} (<b>{{typ.sumUsedCount}}</b>/{{typ.sumCount}})</span>
                
                </div>
            </div>
                        <div class="print-only">
            
              <template v-for="(typ,index) in objTypeList">
                <div v-if="typ.enabled" :key="'r2'+index">{{typ.objectName}}: {{typ.typ}} (<b>{{typ.sumUsedCount}}</b>/{{typ.sumCount}})</div>
                
                
                </template>
            </div>
           
            
          </td>
        </tr>
                <tr>
          <td style="width: 50%">

            <div v-if="umowa">Podstawa wykorzystania obiektu: <b>{{umowa}}</b></div>
           
            
          </td>
          <td style="width: 50%">
            <div>Raport za okres: Od <b>{{startDate | fdate}}</b> do <b>{{endDate | fdate}}</b></div>
            
          </td>
        </tr>
      </table>
      <button class="btn btn-sm btn-primary no-print" @click="showEditor = true"><i class="fa fa-pencil"></i> Zmień adres, nip i numer umowy</button>
      <button class="btn btn-sm btn-primary no-print" style="margin-left: 5px" @click="printWindow()"><i class="fa fa-print"></i> Drukuj</button>


    </div>

    <div class="col-12 print-only">
      <table  class="table table-bordered table-sm" style="background-color: white">
            <thead>
            <tr>
              <th class="text-left">Typ</th>
              
              
              <th class="text-right">Cena</th>
              <th class="text-center">Rezerw</th>
              <th class="text-center">Użycie</th>
              <th class="text-right">Czas</th>
              <th class="text-right">Razem</th>
                  
            </tr>
            </thead >
            <tr v-for="(raport, index) in raports.summary" :key="index">
              <td>{{ raport.reservationTypeName }}</td>
              <td class="text-right">{{ raport.price | money }}</td>
              <td class="text-center">{{ raport.count }}</td>
              <td class="text-center">{{ raport.usedCount }}</td>
              <td class="text-right"><span v-if="raport.usedLength">{{ raport.usedLength | hminutesum }} h</span></td>
              <td class="text-right">{{ raport.usedPrice | money }}</td>
            </tr>
            
          
       </table>
       <br/>
    </div>
    
    <div class="col-12">
      <h3 class="text-center">Szczegóły wykorzystania obiektu</h3>
      <template  v-for="(raport, index) in raports.details">
      <div class="card card-warning card-outline" v-if="anyDetail(raport)"  :key="index">
        
        <!-- card-header -->
        <div class="card-header">
          <h3 class="card-title">{{ raport.objectFullName }}</h3>
         
        </div>
        <!-- /.card-header -->
        <div class="card-body p-0 ">
          <table class="table table-bordered table-sm">
            <thead>
            <tr>
              <th class="text-left">Typ</th>
              <th>Dzień tygodnia</th>
              <th class="text-center">Godziny</th>
              <th class="text-right">Cena</th>
              <th class="text-center">Rezerw</th>
              <th class="text-center">Użycie</th>
              <th class="text-right">Wartość</th>
              <th class="text-right">Razem</th>
                  
            </tr>
            </thead>
            <template v-for="(day, index) in raport.daytype">
              <template v-if="day.indxobj.enabled">
                <tr :key="index" style="background-color: #F0F0F0">
                   <td>{{ day.reservationTypeName }}</td>
                   <td colspan="3">{{ day.dayofWeek | dayOfWeek }}</td>
                   
                   <td class="text-center">{{ day.count }}</td>
                   <td class="text-center">{{ day.usedCount }}</td>
                   <td></td>
                   <td class="text-right">{{ day.usedPrice | money }}</td>
                   

                </tr>
                <tr v-for="(hour, index2) in day.hours" :key="'day'+index+'_'+index2">
                  <td colspan="2"></td>
                  <td class="text-center">{{ hour.start | hminute }} - {{ (hour.start + hour.length) | hminute }}</td>
                  <td class="text-right">{{ (hour.usedPrice / hour.usedCount) | money }}</td>
                   <td class="text-center">{{ hour.count }}</td>
                    <td class="text-center">{{ hour.usedCount }}</td>
                     <td class="text-right">{{ hour.usedPrice | money }}</td>
                     <td></td>

                </tr>
              </template>
              
            </template>
            <tfoot style="backgorund-color: rgba(0,0,0,0.003)">
                          <tr>
              <th colspan="4">Razem</th>
              <th class="text-center">{{ sumCount(raport) }}</th>
              <th class="text-center">{{ sumUsedCount(raport) }}</th>
              <th>&nbsp;</th>
              <th class="text-right">{{ sumUsedPrice(raport) | money }}</th>
                  
            </tr>
            </tfoot>
            
          </table>

<!--

          <div class="row text-center text-bold" style="background-color: gray;">
            <div class="col-2" style="">Typ</div>
            <div class="col-2" style="border-left: 1px solid #eaeaea">Dzień tygodnia</div>
            <div class="col-2" style="border-left: 1px solid #eaeaea">Godziny</div>
            <div class="col-1" style="border-left: 1px solid #eaeaea">Cena</div>
            <div class="col-1" style="border-left: 1px solid #eaeaea">Rezerw.</div>
            <div class="col-1" style="border-left: 1px solid #eaeaea">Uzycie</div>
            <div class="col-1" style="border-left: 1px solid #eaeaea">Wartość</div>
            <div class="col-2" style="border-left: 1px solid #eaeaea">Razem</div>
          </div>
          <div style="border-top: 1px solid #c4c4c4" class="text-center" v-for="(day, index) in raport.daytype" :key="index">
            <div v-if="day.indxobj.enabled">
            <div class="row">
              <div class="col-lg-2" style="border-bottom: 1px solid #eaeaea">{{ day.reservationTypeName }}</div>
              <div class="col-2" style="border-bottom: 1px solid #eaeaea;border-left: 1px solid #eaeaea">{{ day.dayofWeek | dayOfWeek }}</div>
              <div class="col-2" style="border-bottom: 1px solid #eaeaea;border-left: 1px solid #eaeaea"></div>
              <div class="col-1" style="border-bottom: 1px solid #eaeaea"></div>
              <div class="col-1" style="border-bottom: 1px solid #eaeaea">{{ day.count }}</div>
              <div class="col-1" style="border-bottom: 1px solid #eaeaea">{{ day.usedCount }}</div>
              <div class="col-1" style="border-bottom: 1px solid #eaeaea"></div>
              <div class="col-lg-2 text-right pr-4 pl-0" style="border-bottom: 1px solid #eaeaea;border-left: 1px solid #eaeaea">{{ day.usedPrice | money }}</div>
            </div>
            <div v-for="(hour, index) in day.hours" :key="index" class="row clientitem2 ">
              <div class="col-2"></div>
              <div class="col-2"></div>
              <div class="col-2" style="border-left: 1px solid #eaeaea">{{ hour.start | hminute }} - {{ (hour.start + hour.length) | hminute }}</div>
              <div class="col-1 text-right" style="border-left: 1px solid #eaeaea">{{ (hour.usedPrice / hour.usedCount) | money }}</div>
              <div class="col-1" style="border-left: 1px solid #eaeaea">{{ hour.count }}</div>
              <div class="col-1" style="border-left: 1px solid #eaeaea">{{ hour.usedCount }}</div>
              <div class="col-1 text-right" style="border-left: 1px solid #eaeaea">{{ hour.usedPrice | money }}</div>
              <div class="col-1"></div>
            </div>
            </div>
          </div>
          <div class="table-responsive mailbox-messages">
            <div class="alert alert-warning text-center" v-if="!raports.details.length">Brak raportów</div>
          </div>
          -->
        </div>
        
        <!--
        <div class="card-footer p-0">

          <div class=" row text-center">
            <div class="col-2 text-left margin-left-5 pl-4">Razem:</div>
            <div class="col-5"></div>
            <div class="col-1">{{ sumCount(raport) }}</div>
            <div class="col-1">{{ sumUsedCount(raport) }}</div>
            <div class="col-1"></div>
            <div class="col-2 text-right pr-4 pl-0">{{ sumUsedPrice(raport) | money }}</div>
          </div>
        </div>
        -->
        
      </div>
      </template>
    </div>
    <div class="col-12">
    <table class="table table-bordered">
      <tr>
        <td>Podsumowanie: do zapłaty/kwota</td>
        <td class="text-center"><b>{{totalUsedCount}}</b> / {{totalCount}}</td>
        <td class="text-right">{{ totalUsedPrice  | money }}</td>
        </tr>

    </table>
        <table class="table table-bordered  print-only">
      <tr>
        <td width="33%">Sporządził:<br/></td>
        <td width="33%">Podpis kierownika obiektu:<br/></td>
        <td width="33%">&nbsp;</td>
        </tr>

    </table>
    </div>
  </div>
</template>

<script>
import loadingIcon from '../modules/LoadingIcon.vue';
export default {
  components: {
    loadingIcon
  },
  data() {
    return {
      months: '',
      year: '',
      monthsName: '',
      raports: {details: [], query: {}, summary: []},
      loading: false,
      clientID: this.$route.params.id,
      client: null,
      nip: '',
      umowa: '',
      address: '',
      startDate: '',
      endDate: '',
      dataRaportu: null,
      showEditor: false,
      objTypeList: []
    };
  },
  methods: {
    reload() {
      this.dataRaportu = new Date();
      this.loading = true;
      this.clientID = this.$route.params.id;
      this.months = this.$route.query.months;
      var date = new Date();
      this.year = this.$route.query.year;
      var needReroute = false;
      if (!this.months) {
        this.months = date.getMonth() + 1;
        needReroute = true;
      }
      if (!this.year) {
        this.year = date.getFullYear();
        needReroute = true;
      }
      if (needReroute) {
              this.$router.push({
        name: 'ClientRaports',
        //params: {id: 100},
        query: {
          months: this.months,
          year: this.year
        }
      });

      } else {
      this.$http.get('client/monthreport/' + this.clientID + '?month=' + this.months + '&year=' + this.year).then(response => {
        var raports = response.data.data;
        this.objTypeList = this.getObjTypeList(raports);
        this.raports = raports;
        this.months = this.raports.query.month;
        this.monthsName = this.raports.query.monthName;
        this.year = this.raports.query.year;
        this.loading = false;
        var dt1 = new Date(this.year,this.months-1,1);
        var dt2 = new Date(dt1);
        dt2.setMonth(dt2.getMonth()+1);
        dt2.setDate(0);
        this.startDate = dt1;
        this.endDate=dt2;
        // this.objTypeList = this.getObjTypeList();
      });
      if ((this.client===null) || (this.client.id !== this.clientID)) {
        this.$http.get('client/get/' + this.clientID).then(response => {
        this.client = response.data.data;
        this.nip = this.client.nip || '';
        this.umowa = this.client.umowa || '';
        this.address = this.client.address || '';
        
      });
      }
      }

    },
    prevDate() {
      this.months--;
      if (this.months == 0) {
        this.months = 12;
        this.year--;
      }
      this.$router.push({
        name: 'ClientRaports',
        //params: {id: 100},
        query: {
          months: this.months,
          year: this.year
        }
      });
    },
    nextDate() {
      this.months++;
      if (this.months == 13) {
        this.months = 1;
        this.year++;
      }
      this.$router.push({
        name: 'ClientRaports',
        //params: {id: 100},
        query: {
          months: this.months,
          year: this.year
        }
      });
    },
    saveDB() {
      
      var saveData = {
        id: this.clientID,
        nip: this.nip,
        umowa: this.umowa,
        address: this.address
      }
      this.$http.post('client/update', JSON.stringify(saveData), {}).then(response => {
        if (response && response.body && response.body.status == 1) {
          this.showEditor = false;
          this.$komunikat('success', 'Sukces', 'Pomyślnie zaktualizowano podmiot', 3000);
        } else {
          this.$komunikat('error', 'Błąd', 'Błąd przy aktualizacji podmiotu', 3000);
        }
      });

    },
    getObjTypeList(raports) {
      var result = [];
      var indxobj = {};
      if (raports && raports.details) {
        var summ = raports.details;
        // console.log('summ',summ);
        
        for (var i=0;i<summ.length;i++) {
          // result+=summ[i].usedPrice
          var objname = summ[i].objectFullName;
          summ[i].enabled = true;
          var types = [];
          var typobj = [];

          for (var j=0;j<summ[i].daytype.length;j++) {
            var typ = summ[i].daytype[j].reservationTypeName;
            var indx = types.indexOf(typ);

            if (indx==-1) {
              indxobj = {
                objId: summ[i].objectId,
                objectName: objname,
                sumCount: 0,
                sumUsedCount: 0,
                sumUsedPrice: 0,
                typ,
                enabled: true
              };
              result.push(indxobj);
              types.push(typ);
              typobj.push(indxobj);
              
            } else {
              indxobj = typobj[indx];
             
            }
            indxobj.sumCount+=summ[i].daytype[j].count;
            indxobj.sumUsedCount+=summ[i].daytype[j].usedCount;
            indxobj.sumUsedPrice+=summ[i].daytype[j].usedPrice;
            summ[i].daytype[j].indxobj = indxobj

          }           
        }

      }
      return result;

    },
    anyDetail(obj) {
      
      for (var i=0;i<obj.daytype.length;i++) {
        if (obj.daytype[i].indxobj.enabled) {
          return true;
        }
      }
      return false;
    },
    sumEnabled(obj,field) {
      var result = 0;
      for (var i=0;i<obj.daytype.length;i++) {
        if (obj.daytype[i].indxobj.enabled) {
          result+=obj.daytype[i][field];
        }
      }
      return result;
    },
    sumCount(obj) {
      return this.sumEnabled(obj,"count");

    },
    sumUsedCount(obj) {
          return this.sumEnabled(obj,"usedCount");

    },
            sumUsedPrice(obj) {
              return this.sumEnabled(obj,"usedPrice");

    },
    printWindow() {
      window.print();
    }
  },
  computed: {
    totalUsedPrice() {
      var result = 0;
      if (this.raports && this.raports.details) {
        var summ = this.raports.details;
        for (var i=0;i<summ.length;i++) {
          // result+=summ[i].usedPrice
          result+=this.sumUsedPrice(summ[i]);
        }

      }
      
      return result;
    },
        totalCount() {
      var result = 0;
      if (this.raports && this.raports.details) {
        var summ = this.raports.details;
        for (var i=0;i<summ.length;i++) {
          // result+=summ[i].usedPrice
          result+=this.sumCount(summ[i]);
        }

      }
      
      return result;
    },
        totalUsedCount() {
      var result = 0;
      if (this.raports && this.raports.details) {
        var summ = this.raports.details;
        for (var i=0;i<summ.length;i++) {
          // result+=summ[i].usedPrice
          result+=this.sumUsedCount(summ[i]);
        }

      }
      
      return result;
    }


  },
  watch: {
    months: function(value) {
      //this.reload();
    },
    $route(to, from) {
      this.reload();
    }
  },
  created() {
    this.reload();
  }
};
</script>

<style scoped>
.clientitem2 {
  position: relative;
  border-bottom: 1px solid #eaeaea;
}
</style>
