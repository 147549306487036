<template>
  <div class="row">
    <div class="col">
      <div class="card card-info card-outline">
        <!-- card-header -->

        <div class="card-header">
          <h3 class="card-title">
            <button class="btn btn-dark btn-sm" @click.prevent="addMonth(-1)">
              <i class="fa fa-chevron-left" aria-hidden="true"></i>
              <i class="fa fa-chevron-left" aria-hidden="true"></i>
            </button>
            <button class="btn btn-dark btn-sm" @click.prevent="addDay(-1)">
              <i class="fa fa-chevron-left" aria-hidden="true"></i>
            </button>
            <button class="btn btn-dark btn-sm" @click.prevent="today()">
              Dziś
            </button>
            <button class="btn btn-dark btn-sm" @click.prevent="addDay(1)">
              <i class="fa fa-chevron-right" aria-hidden="true"></i>
            </button>
            <button class="btn btn-dark btn-sm" @click.prevent="addMonth(1)">
              <i class="fa fa-chevron-right" aria-hidden="true"></i>
              <i class="fa fa-chevron-right" aria-hidden="true"></i>
            </button>
            <span> &nbsp; {{ currentDate | fdate }}</span>
          </h3>
          <div class="card-tools">
            <div class="btn-group" role="group">
              <button class="btn btn-default" type="button" :class="{active: minuteHeight == 0.7}" @click="minuteHeight = 0.7">
                <i class="fa fa-bars" style="font-size: 80%"></i>
              </button>
              <button class="btn btn-default" type="button" :class="{active: minuteHeight == 1}" @click="minuteHeight = 1"><i class="fa fa-bars"></i></button>
              <button class="btn btn-default" type="button" :class="{active: minuteHeight == 2}" @click="minuteHeight = 2">
                <i class="fa fa-bars" style="font-size: 120%"></i>
              </button>
            </div>
          </div>
        </div>

        <!-- /.card-header -->
        <div class="card-body p-0 " style="overflow: hidden;">
          <div class="row" style="position: relative;margin: 0">
            <div style="width: 50px;height:30px;"></div>
            <div class="row" style="position: absolute;left: 50px;right: 0px;margin: 0">
              <div v-for="tt in timetables" :key="'th' + tt.id" class="col text-center">{{ tt.name }}</div>
            </div>
          </div>
          <div class="row" v-bind:style="{position: 'relative', margin: 0, height: totalHeight + 'px'}">
            <div style="width: 50px;padding-left: 3px;margin-top: -13px;">
              <div v-for="hour in hours" :key="'hour' + hour" class="hourdesc" v-bind:style="{height: minuteHeight * 60 + 'px'}">{{ hour | hminute }}</div>
            </div>
            <div class="row" style="position: absolute;left: 50px;right: 0px;margin: 0">
              <div v-if="currentMinute >= startMinute" class="calcurrentminute" :style="{top: (currentMinute - startMinute) * minuteHeight + 'px'}"></div>
              <div class="col calitemobject" v-for="tt in timetables" :key="'td' + tt.id" :style="{position: 'relative', height: totalHeight + 'px'}">
                <!--
                <div v-for="res in object.reservations" :key="'res'+res.id" v-bind:style="{backgroundColor: $rgba(res.clientColor),position: 'absolute',top: (res.start-startMinute)+'px',left: 0, height:(minuteHeight*res.length)+'px', width: '100%',overflow: 'hidden'}">
                  <div>{{res.clientName}}</div>
                  <div>{{res.start | hminute}} - {{(res.start+res.length) | hminute}}</div>
                </div>
                -->
                <div v-if="!tt.end">
                  <small><i>brak harmonogramu</i></small>
                </div>
                <!--
                <div v-for="hour in hours" :key="'hour'+object.id+hour" class="calitemhour" v-bind:style="{height:(minuteHeight*60)+'px',position: 'absolute',top:(hour-startMinute)*minuteHeight+'px'}"></div>
                -->
                <div
                  v-for="ttitem in tt.units"
                  @click="freeclick(tt, ttitem)"
                  :key="ttitem.id"
                  class="freev"
                  :style="{height: ttitem.length * minuteHeight + 'px', lineHeight: ttitem.length * minuteHeight + 'px', top: (ttitem.start - startMinute) * minuteHeight + 'px'}"
                >
                  {{ ttitem.start | hminute }} - {{ (ttitem.start + ttitem.length) | hminute }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--
        <div v-if="loading" class="overlay">
                <i class="fa fa-refresh fa-spin"></i>
              </div>

          </div>
          -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      objectId: this.$route.params.id,
      timetables: [],
      minuteHeight: 1,
      startMinute: 360,
      endMinute: 1320,
      totalHeight: 0,
      currentMinute: -1,
      //currentMonth: (new Date()).getMonth(),
      //currentYear: (new Date()).getFullYear(),
      currentDate: new Date(),
      hours: []
    };
  },
  computed: {
    owntimetables() {
      return this.timetables.filter(x => x.objectId == this.objectId);
    },
    parenttimetables() {
      return this.timetables.filter(x => x.objectId != this.objectId);
    }
  },

  mounted() {
    this.objectId = this.$route.params.id;
    this.reload();
  },
  watch: {
    $route(to, from) {
      this.objectId = this.$route.params.id;
      this.reload();
    },
    minuteHeight() {
      this.totalHeight = (this.endMinute - this.startMinute) * this.minuteHeight + 20;
    }
  },

  methods: {
    fixHours(units) {
      if (units.length > 0) {
        let minminute = Math.floor(units[0].start / 60) * 60;
        if (minminute < this.startMinute) this.startMinute = minminute;
        let maxminute = Math.ceil((units[units.length - 1].start + units[units.length - 1].length) / 60) * 60;
        if (maxminute > this.endMinute) this.endMinute = maxminute;
        this.totalHeight = (this.endMinute - this.startMinute) * this.minuteHeight + 20;
        this.hours = [];
        for (let j = this.startMinute; j <= this.endMinute; j += 60) {
          this.hours.push(j);
        }
      }
    },
    reload() {
      //this.$http.get("timetable/list/" + this.objectId+"?units=true&date="+this.$fdate(new Date(this.currentYear,this.currentMonth,1))).then(response => {
      this.$http
        .get('timetable/list/' + this.objectId, {
          params: {
            units: true,
            date: this.$fdate(this.currentDate)
          }
        })
        .then(response => {
          this.timetables = response.data.data;
          this.startMinute = 600;
          this.endMinute = 1200;
          for (let i = 0; i < this.timetables.length; i++) {
            if (this.timetables[i].units == null) {
              this.$http.get('timetable/listunits/' + this.timetables[i].id).then(response => {
                let units = response.data.data;
                this.timetables[i].units = units;
                this.fixHours(units);
              });
            } else {
              this.fixHours(this.timetables[i].units);
            }
          }
          if (this.$isToday(this.currentDate)) {
            let dt = new Date();
            this.currentMinute = dt.getHours() * 60 + dt.getMinutes();
          } else {
            this.currentMinute = -10;
          }
        });
    },
    addMonth(amount) {
      this.currentDate = this.$addMonth(this.currentDate, amount);
      this.reload();
    },
    addDay(amount) {
      this.currentDate = this.$addDay(this.currentDate, amount);
      this.reload();
    },
    today() {
      this.currentDate = new Date();
      this.reload();
    }
  }
};
</script>

<style>
.HarmsItemBox {
  border: 1px solid #dedede;
  border-radius: 2px;
  margin-right: 5px;
  font-weight: 400;
  margin-bottom: 8px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.12);
  transition: all 0.2s;
}
</style>
