import Vue from 'vue';
import Router, { RouteRecord, RawLocation, Route } from 'vue-router';
import Home from './views/Home.vue';
import users from './components/users/List.vue';
import ClientReservations from './components/users/Reservations.vue';
import ClientRaports from './components/users/Raports.vue';
import ClientRaportsDetail from './components/users/RaportsDetail.vue';
import ClientsSubscription from './components/clients/Subscription.vue';
import Employees from './components/employees/List.vue';
import ObjectsList from './components/objects/List.vue';
import ServicesList from './components/services/List.vue';
import StatsIndex from './components/stats/Index.vue';
import ServicesUsage from './components/services/Usage.vue';
import ObjectReservations from './components/reservations/objectFindFreeTerms.vue';
import ObjectRaports from './components/objects/Raports.vue';
import Timeline from './components/objects/timeline/Timeline.vue';
import TimelineWeek from './components/objects/timeline/TimelineWeek.vue';
import Timetable from './components/objects/timetable/Timetable.vue';
import TimetablePreview from './components/objects/timetable/TimetablePreview.vue';
import Timeunits from './components/objects/timetable/Timeunits.vue';
import Reservations from './components/reservations/List.vue';
import ReservationsDetails from './components/reservations/ReservationDetails.vue';
import ReservationsEdit from './components/reservations/Edit.vue';
import AppLayout from './views/AppLayout.vue';
import AppLogin from './views/account/Login.vue';
import AppLoginKey from './views/account/LoginKey.vue';
import AppCreate from './views/account/Create.vue';
import ReservationsType from './components/reservations/Types.vue';
import Clients from './components/clients/List.vue';
import ClientsProfile from './components/clients/Profile.vue';
import ClientsEdit from './components/clients/Edit.vue';
import Terms from './components/reservations/Terms.vue';
import UsersProfile from './components/users/Profile.vue';
import AccountActivate from './views/account/Activate.vue';

// extern

import ExternObjectReservations from './views/extern/objectFindFreeTerms.vue';
import ExternObjectReservationManage from './views/extern/reservationManage.vue';


import config from './config';

Vue.use(Router);
const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/login/key/:key',
      component: AppLoginKey
    },
    {
      path: '/login/:admin',
      component: AppLogin
    },
    {
      path: '/login',
      component: AppLogin
    },
    {
      path: '/create',
      component: AppCreate
    },
    {
      path: '/account/activation/:hash',
      component: AccountActivate
    },

    {
      path: '/extern/timeline/:id',
      name: 'ExternTimeline',
      //component: ExternTimeLine,
      component: Timeline
    },
    {
      path: '/extern/timelineweek/:id',
      name: 'ExternTimelineWeek',
      component: TimelineWeek,
    },
    {
      path: '/extern/freeterms/:id',
      name: 'ExternObjectReservations',
      component: ExternObjectReservations,
      meta: {
        title: 'Rezerwacje'
      }
    },
    {
      path: '/extern/manage',
      name: 'ExternReservationsManage',
      component: ExternObjectReservationManage,
      meta: {
        title: 'Zarządzanie rezerwacją'
      }
    },

    {
      path: '/',
      component: AppLayout,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: '',
          redirect: { name: 'home' }
        },

        {
          path: 'home',
          name: 'home',
          component: Home,
          meta: {
            title: 'Strona główna'
          }
        },
        {
          path: 'users',
          name: 'users',
          component: users,
          meta: {
            title: 'Podmioty'
          }
        },
        {
          path: 'clients/list',
          name: 'clients',
          component: Clients,
          meta: {
            title: 'Klienci'
          }
        },
        {
          path: 'clients/profile/:id',
          name: 'ClientsProfile',
          component: ClientsProfile,
          meta: {
            title: {
              bc: [
                {
                  title: 'Klienci',
                  name: 'clients'
                },
                {
                  asyncTitle: 'cardowner:id:firstName,lastName'
                },
                {
                  title: 'Profil'
                }
              ]
            }
          }
        },
        {
          path: 'clients/profile/:id/edit',
          name: 'ClientsEdit',
          component: ClientsEdit,
          meta: {
            title: {
              bc: [
                {
                  title: 'Klienci',
                  name: 'clients'
                },
                {
                  asyncTitle: 'cardOwner:id:firstName,lastName',
                  name: 'ClientsProfile',
                  params: ['id']
                },
                {
                  title: 'Edycja profilu'
                }
              ]
            }
          }
        },
        {
          path: 'clients/profile/:id/subscription/:cardOwnerServiceId',
          name: 'ClientsSubscription',
          component: ClientsSubscription,
          meta: {
            title: {
              bc: [
                {
                  title: 'Klienci',
                  name: 'clients'
                },
                {
                  asyncTitle: 'cardOwner:id:firstName,lastName',
                  name: 'ClientsProfile',
                  params: ['id']
                },
                {
                  title: 'Abonament'
                }
              ]
            }
          }
        },
        {
          path: 'stats',
          name: 'StatsIndex',
          component: StatsIndex,
          meta: {
            title: 'Statystyki'
          }
        },
        {
          path: 'employees',
          name: 'employees',
          component: Employees,
          meta: {
            title: 'Pracownicy'
          }
        },
        {
          path: 'users/:action',
          name: 'usersAction',
          component: users,
          meta: {
            title: 'Podmioty'
          }
        },
        {
          path: 'users/reservations/:id',
          name: 'ClientReservations',
          component: ClientReservations,
          meta: {
            title: {
              bc: [
                {
                  title: 'Podmioty',
                  name: 'users'
                },
                {
                  asyncTitle: 'client:id:name',
                  name: 'UsersProfile'
                },
                {
                  title: 'Rezerwacje'
                }
              ]
            }
          }
        },
        {
          path: 'users/raports/:id',
          name: 'ClientRaports',
          component: ClientRaports,
          meta: {
            title: {
              bc: [
                {
                  title: 'Podmioty',
                  name: 'users'
                },
                {
                  asyncTitle: 'client:id:name',
                  name: 'UsersProfile'
                },
                {
                  title: 'Raporty podmiotu'
                }
              ]
            }
          }
        },
        {
          path: 'users/raportsdetail/:id',
          name: 'ClientRaportsDetail',
          component: ClientRaportsDetail,
          meta: {
            title: {
              bc: [
                {
                  title: 'Podmioty',
                  name: 'users'
                },
                {
                  asyncTitle: 'client:id:name',
                  name: 'UsersProfile'
                },
                {
                  title: 'Szczegóły'
                }
              ]
            }
          }
        },
        {
          path: 'users/profile/:id',
          name: 'UsersProfile',
          component: UsersProfile,
          meta: {
            title: {
              bc: [
                {
                  title: 'Podmioty',
                  name: 'users'
                },
                {
                  asyncTitle: 'client:id:name'
                },
                {
                  title: 'Profil'
                }
              ]
            }
          }
        },
        {
          path: 'reservations/object/freeterms/:id',
          name: 'ObjectReservations',
          component: ObjectReservations,
          meta: {
            title: 'Rezerwacje'
          }
        },
        {
          path: 'objects',
          redirect: 'objects/0/all',
          name: 'objects'
        },
        {
          path: 'objects/timeline/:id',
          name: 'Timeline',
          component: Timeline,
          meta: {
            title: {
              bc: [
                {
                  title: 'Obiekty',
                  name: 'objects'
                },
                {
                  asyncTitle: 'object:id:name',
                  recurse: 'parentId',
                  parentsObjects: true,
                  title: '[brak nazwy]',
                  name: 'Timeline'
                },
                {
                  title: 'Terminarz'
                }
              ]
            }
          }
        },
        {
          path: 'objects/timelineweek/:id',
          name: 'TimelineWeek',
          component: TimelineWeek,
          meta: {
            title: {
              bc: [
                {
                  title: 'Obiekty',
                  name: 'objects'
                },
                {
                  asyncTitle: 'object:id:name',
                  title: '[brak nazwy]'
                },
                {
                  title: 'Terminarz'
                }
              ]
            }
          }
        },
        {
          path: 'objects/raports/:id',
          name: 'ObjectRaports',
          component: ObjectRaports,
          meta: {
            title: {
              bc: [
                {
                  title: 'Obiekty',
                  name: 'objects'
                },
                {
                  asyncTitle: 'object:id:name',
                  title: '[brak nazwy]'
                },
                {
                  title: 'Raport'
                }
              ]
            }
          }
        },

        {
          path: 'reservations/terms/:id',
          name: 'Terms',
          component: Terms,
          meta: {
            title: {
              bc: [
                {
                  title: 'Rezerwacje',
                  name: 'Reservations'
                },
                {
                  asyncTitle: 'reservation:id:reservationName',
                  title: '[brak nazwy]',
                  name: 'ReservationDetails'
                },
                {
                  title: 'Terminy'
                }
              ]
            }
          }
        },
        {
          path: 'objects/timetable/:id',
          name: 'Timetable',
          component: Timetable,
          meta: {
            title: {
              bc: [
                {
                  title: 'Obiekty',
                  name: 'objects'
                },
                {
                  asyncTitle: 'object:id:name',
                  title: '[brak nazwy]'
                },
                {
                  title: 'Harmonogram'
                }
              ]
            }
          }
        },
        {
          path: 'objects/timetable/timeunits/:id',
          name: 'Timeunits',
          component: Timeunits,
          meta: {
            title: 'Jednostki czasu'
          }
        },
        {
          path: 'objects/:id/:edit',
          name: 'ObjectsList',
          component: ObjectsList,
          meta: {
            title: {
              when: [
                {
                  param: 'id',
                  equals: '0',
                  then: 'Lista obiektów',
                  else: 'Lista podobiektów'
                }
              ],
              bc: [
                {
                  asyncTitle: 'object:id:name',
                  recurse: 'path',
                  name: 'ObjectsList',
                  title: 'Obiekty nadrzędne',
                  params: ['id']
                }
              ]
            }
          }
        },

        {
          path: 'objects/timetablepreview/:id',
          name: 'TimetablePreview',
          component: TimetablePreview,
          meta: {
            title: 'Obiekt harmonogram'
          }
        },
        {
          path: 'services',
          redirect: 'services/list'
        },
        {
          path: 'clients/usageServices',
          name: 'ServicesUsageAll',
          component: ServicesUsage,
          meta: {
            title: 'Korzystajacy z usługi'
          }
        },
        {
          path: 'services/:id/usage',
          name: 'ServicesUsage',
          component: ServicesUsage,
          meta: {
            title: 'Korzystajacy z usługi'
          }
        },
        {
          path: 'services/list',
          name: 'Services',
          component: ServicesList,
          meta: {
            title: 'Usługi'
          }
        },
        {
          path: 'reservations',
          redirect: 'reservations/list'
        },
        {
          path: 'reservations/list',
          name: 'Reservations',
          component: Reservations,
          meta: {
            title: 'Rezerwacje'
          }
        },
        {
          path: 'reservations/details/:id',
          name: 'ReservationDetails',
          component: ReservationsDetails,
          meta: {
            title: {
              bc: [
                {
                  title: 'Rezerwacje',
                  name: 'Reservations'
                },
                {
                  asyncTitle: 'reservation:id:reservationName',
                  title: '[brak nazwy]'
                },
                {
                  title: 'Szczegóły'
                }
              ]
            }
          }
        },
        {
          path: 'reservations/edit/:id',
          name: 'ReservationsEdit',
          component: ReservationsEdit,
          meta: {
            title: {
              bc: [
                {
                  title: 'Rezerwacje',
                  name: 'Reservations'
                },
                {
                  asyncTitle: 'reservation:id:reservationName',
                  title: '[brak nazwy]'
                },
                {
                  title: 'Szczegóły'
                }
              ]
            }
          }
        },
        {
          path: 'reservations/type',
          name: 'ReservationsType',
          component: ReservationsType,
          meta: {
            title: {
              bc: [
                {
                  title: 'Rezerwacje'
                },
                {
                  title: 'Typy rezerwacji'
                }
              ]
            }
          }
        },
        {
          path: 'reservations/object',
          redirect: 'reservations/object/0/reservation'
        },
        {
          path: 'reservations/object/:id/:edit',
          name: 'ReservationsObjectsList',
          component: ObjectsList,
          meta: {
            title: {
              bc: [
                {
                  asyncTitle: 'object:id:name',
                  recurse: 'path',
                  name: 'ReservationsObjectsList',
                  title: 'Obiekty nadrzędne',
                  params: ['id']
                },
                {
                  title: 'Dodawanie rezerwacji'
                }
              ]
            }
          }
        }


      ]
    },

    {
      path: '**',
      redirect: { name: 'home' }
    }
  ]
});
router.beforeEach((to: Route, from: Route, next: (to?: RawLocation | false | ((vm: Vue) => any) | void) => void) => {
  // debugger;
  if (to.matched.some((record: RouteRecord) => record.meta.requiresAuth)) {
    if (!localStorage.getItem('apikey')) {
      let loginUrl = '/login';
      if (!config.current.clientLogin) {
        loginUrl = '/login/admin';
      }
      next({
        path: loginUrl,
        query: {
          redirect: to.fullPath
        }
      });
    } else {
      next();
    }
  } else {
    next();
  }
});
export default router;
