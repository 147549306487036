<template>
  <!-- Modal -->
  <b-modal v-model="showLocal" @hidden="$emit('cancel')">
    <h5 slot="modal-title">{{ data.date | moment('LL') }}</h5>
    <div slot="modal-header-close">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$emit('cancel')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="box-body">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label :class="{'text-danger': $v.start.$anyError}">Godzina rozpoczęcia</label>
            <input :class="{'form-control is-invalid': $v.start.$anyError, 'form-control': !$v.start.$anyError}" type="time" v-model.trim="$v.start.$model" />
            <small v-if="!$v.start.newUnitStart" class="text-danger">Minimalna godzina rozpoczęcia to {{ data.minStart | hminute }}</small>
            <small v-if="!$v.start.hourStart && $v.start.newUnitEnd" class="text-danger">Proszę wypełnić poprawnie pole</small>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label :class="{'text-danger': $v.end.$anyError}">Godzina zakończenia</label>
            <input :class="{'form-control is-invalid': $v.end.$anyError, 'form-control': !$v.end.$anyError}" type="time" v-model.trim="$v.end.$model" />
            <small v-if="!$v.end.newUnitEnd" class="text-danger">Maksymalna godzina zakończenia to {{ data.maxEnd | hminute }}</small>
            <small v-if="!$v.end.hourEnd && $v.end.newUnitEnd" class="text-danger">Proszę wypełnić poprawnie pole</small>
          </div>
        </div>
      </div>
    </div>
    <b-form-checkbox id="checkbox1" v-model="all" value="true" unchecked-value="false">
      Zmień wszystkie podobne
    </b-form-checkbox>
    <div slot="modal-footer">
      <button type="button " class="btn" data-dismiss="modal " @click="close()">Anuluj</button>&nbsp;
      <button type="button " class="btn btn-success" data-dismiss="modal" @click="save()">Zapisz</button>
    </div>
  </b-modal>
</template>

<script>
import {required} from 'vuelidate/lib/validators';

function newUnitStart(start) {
  if (this.$mhour(start) < this.data.minStart) {
    return false;
  } else {
    return true;
  }
}
function newUnitEnd(end) {
  if (this.$mhour(end) > this.data.maxEnd) {
    return false;
  } else {
    return true;
  }
}
function hourStart(hour) {
  return this.$hourValidate(hour, this.end);
}
function hourEnd(hour) {
  return this.$hourValidate(this.start, hour);
}
export default {
  props: ['data', 'show'],
  data() {
    return {
      showLocal: this.show,
      end: '',
      start: '',
      all: false
    };
  },
  methods: {
    close() {
      this.$emit('cancel');
    },
    save() {
      if (this.$v.$invalid == true) {
        this.$v.$touch();
      } else {
        if (this.all) {
          let newUnits = {
            start: this.$mhour(this.start),
            length: this.$mhour(this.end) - this.$mhour(this.start),
            timetableId: this.data.timetableId
          };
          this.$emit('saveAll', newUnits);
        } else {
          let newUnit = {
            start: this.$mhour(this.start),
            length: this.$mhour(this.end) - this.$mhour(this.start),
            dzien: this.data.dzien
          };
          this.$emit('save', newUnit);
        }
      }
    }
  },
  validations: {
    start: {
      required,
      newUnitStart,
      hourStart
    },
    end: {
      required,
      newUnitEnd,
      hourEnd
    }
  },
  watch: {
    showLocal: function(val) {
      if (this.showLocal == false) {
        this.$emit('cancel');
      }
    }
  },
  mounted() {
    this.start = this.$convert(this.$hminute(this.data.items[0].start));
    this.end = this.$convert(this.$hminute(this.data.items[0].start + this.data.items[0].length));
  }
};
</script>

<style></style>
