<template>
  <!-- Trigger the modal with a button -->

  <!-- Modal -->
  <b-modal v-model="showLocal" @hidden="$emit('cancel')">
    <h5 slot="modal-title">Dodaj obiekt</h5>
    <div slot="modal-header-close">
      <button type="button" class="close" aria-label="Close" @click="cancel()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <!-- Content modalu -->

    <div class="box-body">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label :class="{'text-danger': $v.name.$anyError}" for="surname">Nazwa obiektu</label>
            <input :class="{'is-invalid': $v.name.$anyError}" class="form-control" id="surname" type="text" v-model.trim="$v.name.$model" />
            <small class="text-danger" v-if="$v.name.$anyError">Nazwa obiektu jest wymagana</small>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label :class="{'text-danger': $v.maxPersonCount.$anyError}" for="surname">Maksymalna ilość osób</label>
            <input :class="{'is-invalid': $v.maxPersonCount.$anyError}" class="form-control" id="surname" type="text" v-model.trim="$v.maxPersonCount.$model" />
            <small class="text-danger" v-if="$v.maxPersonCount.$anyError">Ilość osób musi być liczbą</small>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label :class="{'text-danger': $v.maxReservationCount.$anyError}" for="surname">Maksymalna liczba jednoczesnych rezerwacji</label>
            <input :class="{'is-invalid': $v.maxReservationCount.$anyError}" class="form-control" id="surname" type="text" v-model.trim="$v.maxReservationCount.$model" />
            <small class="text-danger" v-if="$v.maxReservationCount.$anyError">Ilość rezewacji musi być liczbą</small>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label class="checkbox-bootstrap checkbox-lg" style="padding-right: 5px">
              <input type="checkbox" v-model="$v.canCardOwnerReservation.$model" style="margin-right: 5px" />
              <span>Może być rezerwowany przez osoby fizyczne</span>
            </label>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label for="color">Opis</label>
            <textarea class="form-control" type="text" v-model="description"></textarea>
          </div>
        </div>
      </div>
    </div>

    <div slot="modal-footer">
      <button type="button" class="btn btn-default" data-dismiss="modal" @click="cancel()">Zamknij</button>
      <button type="button" class="btn btn-success" data-dismiss="modal" @click="save()">Zapisz</button>
    </div>
  </b-modal>
</template>
<script>
import {required, numeric} from 'vuelidate/lib/validators';
export default {
  name: 'AddModal',
  props: ['show'],
  data() {
    return {
      name: '',
      description: '',
      maxPersonCount: '',
      maxReservationCount: '',
      canCardOwnerReservation: false,
      showLocal: this.show
    };
  },
  validations: {
    name: {
      required
    },
    maxPersonCount: {
      numeric
    },
    maxReservationCount: {
      numeric
    },
    canCardOwnerReservation: {}
  },
  methods: {
    save() {
      if (this.$v.$invalid == true) {
        this.$v.$touch();
      } else {
        this.$emit('name', this.name);
        this.$emit('description', this.description);
        this.$emit('maxPersonCount', this.maxPersonCount);
        this.$emit('maxReservationCount', this.maxReservationCount);
        this.$emit('canCardOwnerReservation', this.canCardOwnerReservation);
        this.$emit('save');
      }
    },
    cancel() {
      this.$emit('cancel');
    }
  },
  watch: {
    showLocal: function (val) {
      if (this.showLocal == false) {
        this.$emit('cancel');
      }
    }
  }
};
</script>
<style></style>
