<template>
  <div class="card card-info ">
    <div class="card-header">
      <h3 class="card-title">Okresy płatności</h3>
    </div>

    <div class="card-body" style="padding-top:0">
      <div class="table-responsive">
        <table class="table no-margin">
          <thead>
            <tr>
              <th>Od</th>

              <th>Do</th>

              <th>Status</th>

              <th>Data płatności</th>

              <th></th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(period, index) in periods" :key="index">
              <td>{{ period.dateStart | moment('DD-MM-YYYY') }}</td>

              <td>{{ period.dateEnd | moment('DD-MM-YYYY') }}</td>

              <td>{{ period.paid ? 'Opłacony' : getUnpaidState(period) }}</td>

              <td>
                <span v-if="period.paymentDay">{{ period.paymentDay | moment('DD-MM-YYYY') }}</span>

                <span v-else>Brak</span>
              </td>

              <td>
                <button v-if="!period.paid" class="btn btn-success btn-sm" @click="pay(period.period)">Opłać</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // Okresy płatności
    periods: {
      required: true,
      type: Array
    }
  },
  methods: {
    // Opłacenie okresu
    pay(period) {
      this.$emit('pay', period);
    },
    // Sprawdza czy opłata jest zaległa
    getUnpaidState(period) {
      return this.$moment(period.dateStart).isBefore(new Date()) ? 'Zaległy' : 'Nie opłacony';
    }
  }
};
</script>
