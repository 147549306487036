import config from './config';
import io from 'socket.io-client';
import router from './router';

export class Websocket {
  public socket: any = null;
  private callbackFunctions: any = {};

  public disconnect() {
    if (this.socket) {
      if (this.socket.close) {
        this.socket.close();
      }
      this.callbackFunctions = {};
    }
    this.socket = null;
  }

  public isConnected(): boolean {
    return this.socket && this.socket.connected;
  }

  public on(eventName: string, name: string, cb: any) {
    this.off(eventName, name);
    const socket = this.socket as SocketIOClient.Socket;

    const fullname = eventName + ':' + name;
    this.callbackFunctions[fullname] = cb;
    // console.log("callbackFunctions",this.callbackFunctions);
    return socket.on(eventName, cb);
  }
  public off(eventName: string, name: string) {
    const socket = this.socket as SocketIOClient.Socket;
    const fullname = eventName + ':' + name;
    if (name) {
      const prevcb = this.callbackFunctions[fullname];
      if (prevcb) {
        delete this.callbackFunctions[fullname];
        console.log('callbackFunctions', this.callbackFunctions);
        return socket.off(eventName, prevcb);
      }
    } else {
      return socket.off(eventName);
    }
  }

  public start(apiKey: string) {
    const socket = this.socket as SocketIOClient.Socket;
    this.disconnect();

    /*
    let io: any = null;
    let w: any = window;
    if (w.io) {
      console.log("io from window, "+config.current.winsock)
      io=w.io;
    }
    */

    if (config.current.websock && io) {
      const socket2 = io(config.current.websock);
      this.socket = socket2;
      // on connect
      socket2.on('connect', () => {
        console.log('winsock connected');
        // this.socket=socket;
        socket2.emit('key', apiKey);
      });
      // on disconnect
      socket2.on('disconnect', () => {
        this.socket = null;
      });

      // showclient
      this.on('showClient', '@', (clientId: any) => {
        console.log('websocket:routeto', '/clients/' + clientId);
        router.push({name: 'ClientsProfile', params: {id: clientId}});
        // router.push("/clients/"+clientId);
      });
      /*
      this.on("addCard","@",(cardNumber : any)=>{
        console.log("websocket:routeto","/clients/"+clientId);
        router.push({name: "ClientsProfile", params: {id: clientId} });
        //router.push("/clients/"+clientId);
      });
      */
      /*
      socket.on("showClient",(clientId : any)=>{
        console.log("routeto","/clients/"+clientId)
        router.push("/clients/"+clientId);
      });
      */
    }
  }
}

const websocket = new Websocket();
export default websocket;
