<template>
  <aside class="main-sidebar sidebar-dark-primary elevation-4 no-print" id="side_bar">
    <router-link to="/home" class="nav-link brand-link router-link-active text-center" active-class="router-link-active active">
      <span class="brand-text font-weight-light"><img :src="logoUrl" /></span>
    </router-link>
    <div class="sidebar">
      <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          <li class="nav-item">
            <router-link to="/home" class="nav-link" active-class="router-link-active active">
              <i class="nav-icon fa fa-home fa-fw"></i>
              <p>Strona główna</p>
            </router-link>
          </li>
          <li v-if="this.$hasRole('SuperAdmin')" class="nav-item">
            <router-link to="/employees" class="nav-link" active-class="router-link-active active bg-secondary">
              <i class="nav-icon fa fa-user-circle" />

              <p>Pracownicy</p>
            </router-link>
          </li>
          <li class="nav-item" v-if="$config.current.stats && this.$hasRole('SuperAdmin')">
            <router-link to="/stats" class="nav-link" active-class="router-link-active active bg-secondary">
              <i class="nav-icon fa fa-line-chart"></i>
              <p>Statystyki</p>
            </router-link>
          </li>
          <li class="nav-item" v-if="$config.current.services && this.$hasRole('admin')">
            <router-link to="/services" class="nav-link" active-class="router-link-active active bg-secondary">
              <i class="nav-icon fa fa-briefcase"></i>
              <p>Usługi</p>
            </router-link>
          </li>
          <li class="nav-item" v-if="this.$hasRole('admin')">
            <router-link to="/users" class="nav-link" active-class="router-link-active active bg-warning">
              <i class="nav-icon fa fa-users fa-fw"></i>
              <p>Podmioty</p>
            </router-link>
          </li>
          <li class="nav-item" v-if="this.$hasRole('Partner')">
            <router-link :to="{name: 'UsersProfile', params: {id: $store.state.user.id}}" class="nav-link" active-class="router-link-active active bg-warning">
              <i class="nav-icon fa fa-user fa-fw"></i>
              <p>
                {{ $store.state.user.fullName }}
              </p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/objects/" class="nav-link" active-class="router-link-active active bg-success">
              <i class="nav-icon fa fa-sitemap fa-fw"></i>
              <p>Obiekty</p>
            </router-link>
          </li>
          <li class="nav-item has-treeview" v-if="this.$hasRole('admin') && $useClients()" :class="{'menu-open active': isActivePathOrOpen('/clients')}">
            <router-link to="/clients/list" class="nav-link" active-class="router-link-active active">
              <i class="nav-icon fa fa-user fa-fw"></i>
              <p>Klienci</p>
              <div @click="(event) => toggle(event, '/clients')">
                <i class="right fa fa-angle-left"></i>
              </div>
            </router-link>
            <ul class="nav nav-treeview">
              <li class="nav-item" v-if="$useClients()">
                <router-link to="/clients/list" class="nav-link" active-class="router-link-active active bg-info">
                  <i class="nav-icon fa fa-list fa-fw"></i>
                  <p>Lista klientów</p>
                </router-link>
              </li>
              <li class="nav-item" v-if="$config.current.services">
                <router-link :to="{name: 'ServicesUsageAll'}" class="nav-link" active-class="router-link-active active bg-info">
                  <i class="nav-icon fa fa-key"></i>
                  <p>Kluczyki</p>
                </router-link>
              </li>
            </ul>
          </li>

          <li v-if="this.$hasRole('admin')" class="nav-item has-treeview" :class="{'menu-open active': isActivePathOrOpen('/reservations')}">
            <router-link :to="{name: 'Reservations'}" href="javascript:void(0)" class="nav-link" active-class="router-link-active active">
              <i class="nav-icon fa fa-address-book fa-fw"></i>
              <p>Rezerwacje</p>
              <div @click="(event) => toggle(event, '/reservations')">
                <i class="right fa fa-angle-left"></i>
              </div>
            </router-link>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <router-link to="/reservations/object/" class="nav-link" active-class="router-link-active active bg-danger">
                  <i class="fa fa-circle-o nav-icon"></i>
                  <p>
                    <b>Dodaj rezerwację</b>
                  </p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/reservations/list" class="nav-link" active-class="router-link-active active bg-danger">
                  <i class="fa fa-circle-o nav-icon"></i>
                  <p>Lista rezerwacji</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/reservations/type" class="nav-link" active-class="router-link-active active bg-danger">
                  <i class="fa fa-circle-o nav-icon"></i>
                  <p>Typy rezerwacji</p>
                </router-link>
              </li>
            </ul>
          </li>
          <li v-if="this.$hasRole('Partner')" class="nav-item has-treeview" :class="{'menu-open active': isActivePathOrOpen('/reservations')}">
            <router-link :to="{name: 'ClientReservations', params: {id: $store.state.user.id}}" href="javascript:void(0)" class="nav-link" active-class="router-link-active active">
              <i class="nav-icon fa fa-address-book fa-fw"></i>
              <p>Rezerwacje</p>
              <div @click="(event) => toggle(event, '/reservations')">
                <i class="right fa fa-angle-left"></i>
              </div>
            </router-link>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <router-link to="/reservations/object/" class="nav-link" active-class="router-link-active active bg-danger">
                  <i class="fa fa-circle-o nav-icon"></i>
                  <p>
                    <b>Dodaj rezerwację</b>
                  </p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  v-if="this.$hasRole('Partner')"
                  :to="{name: 'ClientReservations', params: {id: $store.state.user.id}}"
                  class="nav-link"
                  active-class="router-link-active active bg-warning"
                >
                  <i class="fa fa-circle-o nav-icon"></i>
                  <p>Lista rezerwacji</p>
                </router-link>
              </li>
            </ul>
          </li>

          <li><p></p></li>

          <li class="nav-item">
            <router-link to="/objects/timeline/410" class="nav-link" active-class="router-link-active active bg-primary">
              <i class="fa fa-calendar nav-icon"></i>
              <p>Korty tenisowe</p>
            </router-link>
          </li>
        </ul>
      </nav>
    </div>
  </aside>
</template>

<script>
export default {
  name: 'Sidebar',
  data() {
    return {
      openedDropdowns: {},
      logoUrl: '/img/logo/' + this.$config.current.logo,
      fastlinks: []
    };
  },
  watch: {},
  mounted() {
    let path = this.$route.path.substring(1);
    let index = path.indexOf('/');
    if (index != -1) {
      path = path.substring(0, index);
    }
    path = '/' + path;
    this.toggle(undefined, path);
  },
  methods: {
    toggle(event, input) {
      if (event) {
        event.preventDefault();
      }
      if (this.openedDropdowns[input]) {
        this.$set(this.openedDropdowns, input, false);
      } else {
        this.$set(this.openedDropdowns, input, true);
      }
    },
    isActivePathOrOpen(input) {
      const paths = Array.isArray(input) ? input : [input];
      return this.openedDropdowns[input] == true;
    }
  }
};
</script>

<style>
body.sidebar-collapse aside.main-sidebar {
  overflow-x: hidden;
}
aside {
  z-index: 1000 !important;
}
</style>
