<template>
  <b-modal no-fade size="lg" v-model="show" @hidden="$emit('cancel')">
    <div slot="modal-title">{{ modalTitle }}</div>
    <center>
      <ul class="list-group">
        <div v-if="!inUse">
          <li class="list-group-item text-left" v-for="(userService, index) in modalServices" :key="index">
            <span style="font-size: 1.15em;">{{ userService.name }}</span>
            <b-badge variant="danger ml-3" v-if="userService.suspended">
              <span style="font-size: 1.15em;">Zawieszona</span> </b-badge
            >&nbsp;
            <button type="button" class="btn btn-success float-right ml-1" @click="useService(userService)">Użyj</button>
            <br />
            <small>
              <span v-if="userService.dateStart">
                <i class="fa fa-fw fa-calendar"></i>
                {{ userService.dateStart | moment('LL') }}
              </span>
              <span v-if="userService.dateEnd">
                <template v-if="userService.dateStart != userService.dateEnd">
                  -
                  <i class="fa fa-fw fa-calendar"></i>
                  <span class="rounded ml-1 mr-1" :class="{'bg-secondary': daysBefore(userService.dateEnd, 7), 'bg-warning': daysBefore(userService.dateEnd, 3)}">{{
                    userService.dateEnd | moment('LL')
                  }}</span>
                </template>
              </span>
              <span>
                &nbsp;
                <i class="fa fa-check-square-o"></i>
                Użyto: {{ userService.used }}
                <template v-if="userService.count">/&nbsp;{{ userService.count }}</template>
              </span>
            </small>
            <b-progress class="mb-2" v-if="index == 0" variant="success" :max="maxSec" :value="countDown" height="6px"></b-progress>
          </li>
        </div>
        <div v-if="inUse">
          <li class="list-group-item text-left" v-for="(userService, index) in modalServices" :key="index">
            <span class="text-bold font-lg">{{ userService.name }}</span>
            <button class="btn btn-secondary float-right ml-1" title="Odbierz klucz" @click="stopService(userService)"><i class="fa fa-fw fa-check"></i> Odnotuj wyjście</button>
            <br />
            <span class="clientspan" v-if="userService.keyNumber">
              <i class="fa fa-fw fa-key"></i>
              {{ userService.keyNumber }}
            </span>
            <span class="clientspan" v-if="userService.date">
              <i class="fa fa-fw fa-calendar"></i>
              {{ userService.date | moment('LL HH:mm') }}
            </span>
            <span class="clientspan" v-if="userService.objectName">
              <i class="fa fa-fw fa-building"></i>
              {{ userService.objectName }}
            </span>
            <b-progress class="mb-2" v-if="index == 0" variant="secondary" :max="maxSec" :value="countDown" height="6px"></b-progress>
          </li>
        </div>
        <li class="list-group-item" v-if="modalServices.length == 0">Brak ważnych usług</li>
        <hr class="w-100" />
        <li class="list-group-item text-left">
          <h6 class="text-muted">Notatka:</h6>
          <div>
            <p class="m-0" style="white-space: pre-wrap;">{{ expand ? noteFull : note }}</p>
          </div>
          <div class="text-center" v-if="showExpandButton">
            <button class="btn btn-sm text-muted bg-white" @click="expand = !expand">
              {{ !expand ? 'Rozwiń' : 'Zwiń' }}
              <i v-if="!expand" class="fa fa-chevron-down"></i>
              <i v-if="expand" class="fa fa-chevron-up"></i>
            </button>
          </div>
        </li>
      </ul>
    </center>
    <div slot="modal-footer" class="w-100">
      <div class="float-left">
        <router-link :to="{name: 'ClientsProfile', params: {id: userId}}">
          <button @click="close()" class="btn" :class="{'btn-info': !inUse, 'btn-secondary': inUse}">Przejdź do profilu</button>
        </router-link>
      </div>
      <div class="float-right">
        <button v-if="modalServices.length > 0" @click="stopCountDown" class="btn btn-warning mr-2">Zatrzymaj</button>
        <button @click="close" class="btn btn-secondary">Zamknij</button>
      </div>
      <!-- <div class="float-left">
        <customSelect :url="'object/find'" @picked="pickObject">
          <template slot-scope="child">{{child.child.name}}</template>
        </customSelect>
      </div>-->
    </div>
  </b-modal>
</template>

<script>
// import customSelect from "../modules/CustomSelect.vue";
export default {
  components: {
    // customSelect
  },
  props: ['disabled'],
  data() {
    return {
      show: false,
      maxSec: 7,
      countDown: 0,
      interval: 0,
      cardNumber: '',
      modalServices: [],
      modalTitle: '',
      userId: 0,
      objectId: null,
      inUse: false,
      note: null,
      noteFull: null,
      expand: false,
      cardNumberInUse: null,
      showExpandButton: false
    };
  },
  methods: {
    daysBefore(date, number) {
      // Kolorowanie dat zakończenia na 7 i 3 dni przed końcem
      return new Date(date) <= this.$addDay(new Date(), number);
    },
    close() {
      this.stopCountDown();
      this.show = false;
      this.modalServices = [];
      this.modalTitle = '';
      this.cardNumberInUse = null;
      // this.userId = 0;
      this.note = null;
      this.noteFull = null;
      this.expand = false;
      this.showExpandButton = false;
    },
    startCountDown() {
      this.countDown = 0;
      this.interval = setInterval(() => {
        this.countDown += 1;
        if (this.countDown > this.maxSec) {
          this.useDefault(this.modalServices[0]);
          this.close();
        }
      }, 1000);
    },
    stopCountDown() {
      if (this.interval > 0) {
        this.countDown = 0;
        clearInterval(this.interval);
        this.interval = 0;
      }
    },
    useDefault(service, preventClose) {
      if (service) {
        if (this.inUse) {
          this.stopService(service);
        } else {
          this.useService(service);
        }
      }
    },
    // pickObject(object){
    //   this.objectId = object.id;
    // },
    // Zapytania do serwera
    useService(service) {
      this.close();
      this.$http.get('service/use/' + service.id).then(response => {
        if (response && response.body && response.body.status == 1) {
          this.$komunikat('success', 'Sukces', 'Pomyślnie użyto usługi.', 3000);
        } else {
          this.$komunikat('error', 'Błąd', 'Błąd przy użyciu usługi.', 3000);
        }
      });
    },
    stopService(service) {
      this.close();
      this.$http
        .get('service/stopusing/' + service.id)
        .then(response => response.data)
        .then(body => {
          if (body.status == 1) {
            this.$komunikat('success', 'Sukces', 'Pomyślnie zakończono używanie usługi.', 3000);
          } else {
            this.$komunikat('error', 'Błąd', 'Błąd przy odnotowywaniu wyjścia.', 3000);
          }
        })
        .catch(() => {
          this.$komunikat('error', 'Błąd', 'Błąd przy odnotowywaniu wyjścia.', 3000);
        });
    },
    getCardOwnersServices(cardNumber) {
      if (cardNumber == this.cardNumberInUse) {
        return;
      } else {
        this.cardNumberInUse = cardNumber;
        if (this.show && this.modalServices.length > 0) {
          this.useDefault(this.modalServices[0]);
          this.cardNumberInUse = cardNumber;
        }
      }
      // Jeżeli modal wyświetlony, użyj domyślnej usługi i przełącz na nowego użytkownika
      this.userId = 0;
      let clientsServices;
      this.$http
        .get('cardowner/checkout/' + cardNumber)
        .then(response => response.data.data)
        .then(body => {
          let user = body[0][0];
          if (user) {
            if (body[1].length == 0) {
              this.inUse = false;
              this.modalServices = body[2];
            } else {
              this.inUse = true;
              this.modalServices = body[1];
            }
            this.userId = user.id;
            this.note = user.note || '';
            if (this.note.trim().length > 200) {
              this.note = this.note.substring(0, 200);
              this.note += '...';
              this.showExpandButton = true;
            }
            this.noteFull = user.note || '';
            this.modalTitle = user.firstName + ' ' + user.lastName;
          }
        })
        .then(() => {
          this.startCountDown();
          this.show = true;
        })
        .catch(error => {
          console.warn(error);
          this.$komunikat('error', 'Błąd', 'Błąd odczytu karty.', 3000);
        });
    },
    // Obsługa Eventów
    keyUpCard(event) {
      if (event.target.localName != 'input') {
        if (event.key >= 0 && event.key <= 9) {
          this.cardNumber += event.key;
        } else {
          if (event.key == 'Enter') {
            if (this.cardNumber.length > 0) {
              this.getCardOwnersServices(this.cardNumber);
            }
          }
          // Jeżeli to nie Enter ani 0-9 - czyścić cardnumber
          this.cardNumber = '';
        }
      } else {
        // Jeżeli to input to czyścić cardnumber.
        this.cardNumber = '';
      }
    },
    addEventHandler() {
      document.addEventListener('keyup', this.keyUpCard);
    },
    removeEventHandler() {
      document.removeEventListener('keyup', this.keyUpCard);
    }
  },
  mounted() {
    this.addEventHandler();
  },
  watch: {
    disabled: function(value) {
      if (value == true) {
        this.removeEventHandler();
      } else if (value == false) {
        this.addEventHandler();
      }
    }
  }
};
</script>

<style></style>
