<template>
  <div class="card-tools">
    <div class="input-group input-group-sm">
      <!-- Search -->
      <input ref="search_input" type="text" class="form-control" v-model="searchText" @keyup="Search" placeholder="Szukaj" autofocus />
      <div class="input-group-append">
        <div class="btn" :class="[color]">
          <i class="fa fa-search"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    post: Object,
    color: String
  },
  data() {
    return {
      searchText: this.$route.query.q
    };
  },
  // mounted() {
  //  this.$refs.search_input.focus();
  // },
  methods: {
    Search(event) {
      if (this.timerQuery) {
        clearTimeout(this.timerQuery);
      }
      this.timerQuery = setTimeout(() => {
        if (!this.post.q) {
          this.post.q = '';
        }
        this.post.q = this.searchText.trim();
        this.post.draw++;
        this.$emit('searchKey', event.code);
        this.$emit('post', this.post);
        this.$emit('reload');
        //this.reload();
      }, 300);
    }
  }
};
</script>

<style></style>
