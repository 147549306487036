<template>
  <form v-on:submit.prevent="onServiceSubmit">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label for="serviceId">Wybierz usługę</label>

          <select v-model="addService.serviceId" class="form-control " id="serviceId" type="text" :disabled="selectDisabled" @change="onServiceChange">
            <option value="" disabled>Wybierz usługę</option>

            <option v-for="(service, index) in serviceList" :key="index" :value="service.id">{{ service.name }}</option>
          </select>
        </div>
      </div>

      <div class="col-md-12" v-if="service.type == 'INDIVIDUAL_CLASSES'">
        <div class="form-group">
          <label for="">Podmiot</label>
          <customSelect :url="'client/find'" @picked="userPicked" :resultFields="['name']">
            <template slot-scope="child">{{ child.child.name || '[brak nazwy rezerwacji]' }}</template>
          </customSelect>
        </div>

        <div class="form-group">
          <label for="">Trener</label>
          <select class="form-control " v-model="addService.trainerId" name="" id="">
            <option v-for="(trener, index) in treners" :key="index" :value="trener.id"> {{ trener.firstName }} {{ trener.lastName }} </option>
          </select>
        </div>
      </div>

      <div class="col-md-12" v-if="service.type == 'CLASSES'">
        <div class="form-group">
          <label for="">Rezerwacja</label>
          <customSelect :url="'reservation/find'" @picked="reservationPicked" :resultFields="['reservationName']">
            <template slot-scope="child">{{ child.child.reservationName || '[brak nazwy rezerwacji]' }}</template>
          </customSelect>
        </div>
      </div>

      <template v-if="addService.serviceId">
        <div class="col-md-6">
          <div class="form-group">
            <label>Data rozpoczęcia usługi <br /></label>

            <datepicker
              v-model="addService.dateStart"
              input-class="form-control"
              format="yyyy-MM-dd"
              :language="pl"
              :full-month-name="true"
              :disabled="selectDisabled"
              @input="setEndDate"
            ></datepicker>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label>Data zakończenia usługi {{ $isSubscribtion(service.type) ? '*' : '' }}</label>

            <datepicker
              v-model="addService.dateEnd"
              input-class="form-control"
              format="yyyy-MM-dd"
              :language="pl"
              :clear-button="true"
              :full-month-name="true"
              :disabled-dates="disabledDates"
            ></datepicker>

            <b-form-text v-if="$isSubscribtion(service.type)">
              *Zostaw puste, jeśli bez limitu
            </b-form-text>
          </div>
        </div>

        <div v-if="!$isSubscribtion(service.type)" class="col-md-6">
          <div class="form-group">
            <label for="">Ilość wejść</label>
            <input v-model="addService.count" class="form-control" />
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label for="">Cena {{ $isSubscribtion(service.type) ? 'za okres' : '' }}</label>
            <input v-model="addService.price" class="form-control" />
          </div>
        </div>

        <div class="col-md-12">
          <div class="form-group">
            <label for="">Rabat [%] (wpisz sama liczbę)</label>
            <input v-model="addService.debit" class="form-control" />
          </div>
        </div>

        <div class="col-md-12 text-right" v-if="!edit">
          <button class="btn btn-primary">Sprzedaj usługę</button>
        </div>
      </template>
    </div>
  </form>
</template>

<script>
import datepicker from 'vuejs-datepicker';
import {pl} from 'vuejs-datepicker/dist/locale';

import customSelect from '../modules/CustomSelect.vue';
import {SubscribtionDays} from '../../enums/serviceType.enum';

export default {
  components: {datepicker, customSelect},
  props: {
    // Lista usług
    serviceList: {
      type: Array,
      required: true
    },
    // Model
    model: {type: Object, required: true},
    // Czy edycja
    edit: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      service: {},
      addService: {
        serviceId: ''
      },
      initialService: undefined,
      pl: pl,
      disabledDates: {
        moment: this.$moment,
        step: undefined,
        dateStart: undefined,
        customPredictor: function(date) {
          if (this.step) {
            const days = this.moment(date).diff(this.dateStart, 'days');
            if (days > 0) {
              return (days + 1) % this.step;
            }

            return true;
          }
        }
      }
    };
  },
  mounted() {
    if (this.model) {
      this.addService = this.model;
    }

    if (this.edit) {
      this.service = this.serviceList.find(service => service.id === this.model.serviceId);
      this.initialService = this.service;
      this.setDisabledDates();
    }
  },
  methods: {
    // Wybranie rezerwacji
    reservationPicked(reservation) {
      this.addService.reservationId = reservation.reservationId;
    },
    // Ustawia usługe
    onServiceChange() {
      const service = this.serviceList.find(service => service.id === this.addService.serviceId);
      this.service = service;
      this.addService.count = service.count;
      this.addService.price = service.price;
      if (!this.edit) {
        this.addService.dateStart = this.$fdate();
      }
      this.setEndDate();
      this.setDisabledDates();
    },
    onServiceSubmit() {
      this.$emit('onServiceSubmit', this.addService);
    },
    // Ustawia date końcową
    setEndDate() {
      if (this.service.daysLength) {
        this.$set(
          this.addService,
          'dateEnd',
          this.$moment(this.addService.dateStart)
            .add(this.service.daysLength, 'd')
            .format('YYYY-MM-DD')
        );
      } else {
        this.setDisabledDates();

        this.$set(
          this.addService,
          'dateEnd',
          this.$isSubscribtion(this.service.type)
            ? this.$moment(this.addService.dateStart)
                .add(SubscribtionDays[this.service.type] - 1, 'd')
                .format('YYYY-MM-DD')
            : this.addService.dateStart
        );
      }
    },
    // Ustawia wyłączone daty
    setDisabledDates() {
      this.$set(this.disabledDates, 'step', this.$isSubscribtion(this.service.type) ? SubscribtionDays[this.service.type] : undefined);

      this.$set(this.disabledDates, 'dateStart', this.$moment(this.addService.dateStart));
    }
  },
  computed: {
    selectDisabled() {
      return this.edit ? this.$isSubscribtion(this.initialService.type) : false;
    }
  }
};
</script>
