<template>
  <!-- Modal -->
  <b-modal v-model="showLocal" @hidden="$emit('cancel')">
    <h5 slot="modal-title">Dodaj pracownika</h5>

    <div slot="modal-header-close">
      <button type="button" class="close" aria-label="Close" @click="$emit('cancel')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="box-body">
            <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label :class="{ 'text-danger': $v.name.$anyError }">Imię i nazwisko</label>

            <input
              v-model.trim="$v.name.$model"
              class="form-control"
              autocomplete="off"
              type="text"
              :class="{ 'is-invalid': $v.name.$anyError }"
            />

            <small class="text-danger" v-if="$v.name.$anyError">Błędna nazwa</small>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label :class="{ 'text-danger': $v.email.$anyError }">E-mail</label>

            <input
              v-model.trim="$v.email.$model"
              class="form-control"
              autocomplete="off"
              type="text"
              :class="{ 'is-invalid': $v.email.$anyError }"
            />

            <small class="text-danger" v-if="$v.email.$anyError">Błędny E-mail</small>
          </div>
        </div>
                <div class="col-md-12">
        <div class="form-check">
          <label class="form-check-label"><input class="form-check-input" type="checkbox" v-model="skipActivation" :value="true" />nie wymagaj potwierdzenia adresu email</label>
        </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label :class="{ 'text-danger': $v.password.$anyError }">Hasło</label>

            <input
              v-model.trim="$v.password.$model"
              class="form-control"
              autocomplete="new-password"
              type="password"
              :class="{ 'is-invalid': $v.password.$anyError }"
            />

            <small v-if="$v.password.$anyError" class="text-danger">Hasło jest za krótkie</small>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <div class="form-group">
              <label :class="{ 'text-danger': $v.repeatPassword.$anyError }">Powtórz hasło</label>

              <input
                v-model.trim="$v.repeatPassword.$model"
                class="form-control"
                autocomplete="new-password"
                type="password"
                :class="{ 'is-invalid': $v.repeatPassword.$anyError }"
              />

              <small class="text-danger" v-if="$v.repeatPassword.$anyError">Błędnie powtórzone hasło</small>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          
        <div class="form-check">
          <label class="form-check-label"><input class="form-check-input" type="radio" v-model="objectPermission" value="ALL" />zarządzanie wszystkimi obiektami</label>
        </div>
        <div class="form-check">
          <label class="form-check-label"><input class="form-check-input" type="radio" v-model="objectPermission" value="SELECTED" />zarządzanie wybranymi obiektami</label>
        </div>
        <div v-if="objectPermission!=='ALL'">
          <div class="form-check" v-for="obj in objects" :key="obj.id">
            <label class="form-check-label"><input class="form-check-input" type="checkbox" :checked="hasPerm(obj.id)" @change="changePerm($event,obj.id)"  />{{obj.name}}</label>
          </div>
        </div>


        </div>
      </div>
    </div>
    <!-- Modal-footer -->
    <div slot="modal-footer">
      <button type="button" class="btn btn-default" data-dismiss="modal" @click="$emit('cancel')">Wyjdź</button>&nbsp;

      <button type="button" class="btn btn-info" data-dismiss="modal" @click="save">Zapisz</button>
    </div>
    <!-- /Modal-footer -->
  </b-modal>
</template>

<script>
import { required, email, minLength, withParams } from 'vuelidate/lib/validators';
import datepicker from 'vuejs-datepicker';
import { pl } from 'vuejs-datepicker/dist/locale';

// @vuese
// Modal dodawania pracownika
// @group employees
export default {
  components: { datepicker },
  name: 'appAdd',
  props: {
    // Widoczność modala
    show: Boolean
  },
  data() {
    return {
      name: '',
      objectPermission: 'ALL',
      objectPermissionList: [],
      skipActivation: true,
      email: '',
      password: '',
      repeatPassword: '',
      pl: pl,
      showLocal: this.show,
      objects: []

    };
  },

  methods: {
    // @vuese
    // Zamyka modal
    cancel() {
      this.$emit('cancel');
    },
    // @vuese
    // Tworzy użytkownika
    save() {
      if (this.$v.$invalid === true) {
        this.$v.$touch();
      } else {
        const post = {
          password: this.password,
          repeatPassword: this.repeatPassword,
          objectPermission: this.objectPermission==='ALL' ? this.objectPermission : JSON.stringify(this.objectPermissionList),
          name: this.name,
          email: this.email,
          skipActivation: this.skipActivation,
          link: window.location.origin + '/account/activation'
        };

        this.$http.post('account/create', JSON.stringify(post), {}).then(res => {
          if (res.data.status === 1) {
            this.$komunikat('success', 'Sukces', 'Poprawnie dodano pracownika', 3000);
            this.$emit('reload');
            this.cancel();
          } else {
            this.$komunikat('error', 'Błąd', 'Błąd przy dodawaniu pracownika', 3000);
          }
        });
      }
    },
    reload() {
      
      this.$http.post('object/find', JSON.stringify({parentId: 0,count: 100}), {}).then(response => {
        //this.$post("object/find", this.post).then(response => {
        this.objects = response.data.data.data;
        // this.filteredCount = response.data.data.filteredCount;
        // this.maxcount = response.data.data.count;
        // this.$router.push({
        //   query: {
        //     reservationStatus: this.post.reservationStatus
        //   }
        // });
        // this.loading = false;
      });
    },
    hasPerm(objId) {
      return this.objectPermissionList.indexOf(objId)>-1; 
    },
    changePerm($event,objId) {
      // debugger;
      var enable = $event.target.checked;
      if (enable) {
        if (!this.hasPerm(objId)) {
          this.objectPermissionList.push(objId);
        }
      } else {
        var index = this.objectPermissionList.indexOf(objId);
        if (index>-1) {
          this.objectPermissionList.splice(index,1);
        }
      }
    }
  },
  mounted() {
    this.reload();

  },
  validations: {
    email: {
      email,
      required
    },
    password: {
      required,
      minLength: minLength(8)
    },
    name: {

    },
    repeatPassword: {
      required,
      minLength: minLength(8),
      withParams(value) {
        return this.repeatPassword === this.password;
      }
    }
  },
  watch: {
    showLocal: function(val) {
      if (this.showLocal === false) {
        this.$emit('cancel');
      }
    }
  }
};
</script>
