<template>
  <div class="row">
    <div class="col-12">
      <div class="card card-danger card-outline">
        <div class="card-header">
          <h3 class="card-title">
            Typy rezerwacji

            <loading-icon v-if="loading"></loading-icon>
          </h3>
          <div class="card-tools">
            <div class="input-group input-group-sm">
              <!-- Search -->
              <input type="text" class="form-control" v-model="post.q" @keyup="Search()" placeholder="Szukaj" />
              <div class="input-group-append">
                <div class="btn btn-danger">
                  <i class="fa fa-search"></i>
                </div>
              </div>
            </div>
          </div>
          <!-- /.card-tools -->
        </div>
        <!-- /.card-header -->

        <div class="card-body p-0">
          <div class="mailbox-controls clientitem top" style="height:90px;">
            <!-- Check all button -->

            <app-pages
              :filteredCount="filteredCount"
              :maxcount="maxcount"
              :colorActive="'#fff'"
              :backgroundActive="'#dc3545'"
              :post="post"
              @post="post = $event"
              @reload="reload()"
            >
              <button type="button" class="btn btn-danger" @click="add()"><i class="fa fa-fw fa-plus"></i>Dodaj typ</button>
            </app-pages>

            <!-- /.float-right -->
          </div>
          <div class="table-responsive mailbox-messages">
            <div class="alert alert-danger text-center" v-if="!types.length">Brak zdefiniowanych typów rezerwacji</div>

            <div v-for="(type, index) in types" :key="index">
              <div class="clientitem">
                <b>{{ type.name }}</b>
                <template v-if="type.objectName"> - {{ type.objectName }} </template>
                <div class="itembuttons btn-group menu ">
                  <button type="button " class="btn btn-primary btn-sm " title="Edytuj " data-toggle="modal " @click="editButton(type)">
                    <i class="fa fa-fw fa-edit "></i>
                  </button>
                  <button class="btn btn-danger btn-sm " title="Usuń" @click="deleteButton(type)">
                    <i class="fa fa-fw fa-trash "></i>
                  </button>
                </div>
              </div>
              <!-- /.table -->
            </div>
          </div>

          <!-- /.mail-box-messages -->
        </div>
        <div class="card-footer p-0">
          <div class="mailbox-controls">
            <app-pages
              style="padding-right:15px"
              :filteredCount="filteredCount"
              :maxcount="maxcount"
              :colorActive="'#fff'"
              :backgroundActive="'#dc3545'"
              :post="post"
              @post="post = $event"
              @reload="reload()"
            ></app-pages>
          </div>
        </div>
      </div>
    </div>
    <app-edit v-if="showEdit" :show="showEdit" v-on:reload="reload()" v-on:cancel="cancel" :object="EditObject" :option="option"></app-edit>
    <app-delete v-if="showDelete" :show="showDelete" v-on:delete="del" :object="EditObject" v-on:cancel="cancel"></app-delete>
  </div>
</template>

<script>
import appEdit from './EditTypes.vue';
import appDelete from '../modules/Delete.vue';
import appPages from '../modules/Pages.vue';
import loadingIcon from '../modules/LoadingIcon.vue';
export default {
  data() {
    return {
      types: [],
      showEdit: false,
      showDelete: false,
      EditObject: {},
      post: {q: '', count: 50, skip: 0, page: 1, draw: 1},
      filteredCount: 0,
      maxcount: 0,
      loading: false,
      option: 'add'
    };
  },
  components: {
    appEdit,
    appDelete,
    appPages,
    loadingIcon
  },
  created() {
    this.reloadLink();
    this.reload();
  },
  methods: {
    add() {
      this.option = 'add';
      this.showEdit = true;
      this.EditObject = {};
    },
    editButton(type) {
      this.option = 'edit';
      this.EditObject = type;
      this.showEdit = true;
    },
    del(id) {
      this.$http.get('reservationtype/delete/' + id).then(response => {
        if (response && response.body && response.body.status == 1) {
          this.showDelete = false;
          this.reload();
          this.$komunikat('success', 'Sukces', 'Pomyślnie usunięto typ', 3000);
        } else {
          this.$komunikat('error', 'Błąd', 'Błąd przy usuwaniu typu', 3000);
        }
      });
    },
    deleteButton(type) {
      this.EditObject = type;
      this.showDelete = true;
    },
    reload() {
      this.loading = true;
      this.$http.post('reservationtype/find', JSON.stringify(this.post), {}).then(response => {
        if (response && response.body && response.body.status == 1 && this.post.draw == response.data.draw) {
          this.types = response.data.data.data;

          this.maxcount = response.data.data.count;
          this.filteredCount = response.data.data.filteredCount;

          this.loading = false;
        }
      });
    },
    Search() {
      if (!this.timerQuery) {
        clearTimeout(this.timerQuery);
      }
      this.timerQuery = setTimeout(() => {
        if (!this.post.q) {
          this.post.q = '';
        }
        this.post.q = this.post.q.trim();
        this.post.draw++;
        this.reload();
        //this.reload();
      }, 300);
    },
    reloadLink() {
      this.post.q = this.$route.query.q;
      this.post.page = this.$route.query.page;
      this.post.count = this.$route.query.count;
      this.post.skip = this.$route.query.skip;
      if (!this.post.count) {
        this.post.count = 50;
      }
      if (!this.post.skip) {
        this.post.skip = 0;
      }
      if (!this.post.page) {
        this.post.page = 1;
      }
    },
    cancel() {
      this.showEdit = false;
      this.showDelete = false;
    }
  }
};
</script>

<style></style>
