import axios from 'axios';
import config from './config';
import Vue from 'vue';
import store from './store';
import { ServiceTypeTranslation, Subscription } from '@/enums/serviceType.enum';
class Methods {
  public colors: string[] = [
    '#ad1457',
    '#f4511e',
    '#e4c441',
    '#0b8043',
    '#3f51b5',
    '#8e24aa',
    '#d81b60',
    '#ef6c00',
    '#c0ca33',
    '#009688',
    '#7986cb',
    '#795548',
    '#d50000',
    '#f09300',
    '#7cb342',
    '#039be5',
    '#b39ddb',
    '#616161',
    '#e67c73',
    '#f6bf26',
    '#33b679',
    '#4285f4',
    '#9e69af',
    '#a79b8e',
    '#FF69B4'
  ];
  public days: string[] = ['poniedziałek', 'wtorek', 'środa', 'czwartek', 'piątek', 'sobota', 'niedziela'];
  public months: string[] = ['styczeń', 'luty', 'marzec', 'kwiecień', 'maj', 'czerwiec', 'lipiec', 'sierpień', 'wrzesień', 'październik', 'listopad', 'grudzień'];
  public timetabletypes: any = {
    EVERY: 'codziennie',
    W15: 'dni powszednie',
    W67: 'weekendy',
    D1: 'poniedziałki',
    D2: 'wtorki',
    D3: 'środy',
    D4: 'czwartki',
    D5: 'piątki',
    D6: 'soboty',
    D7: 'niedziele',
    HOL: 'święta',
    DAY: 'wybrany dzień'
  };
  private hminutecache: string[] = [];
  private rgbcache: any = {};

  // Sprawdza czy usługa jest subskrypcją
  public isSubscribtion(serviceType: string): boolean {
    return serviceType in Subscription;
  }

  public fdate(date?: Date | string): string {
    if (!date) {
      date = new Date();
    }
    if (typeof date === 'string') {
      if (date.length <= 10) {
        date = new Date(date + 'T00:00:00');
      } else {
        date = new Date(date);
      }
    }
    let result = '' + date.getFullYear() + '-';
    const m = date.getMonth() + 1;
    if (m < 10) {
      result += '0';
    }
    result += m + '-';
    const d = date.getDate();
    if (d < 10) {
      result += '0';
    }
    return result + d;
  }

  public hminute(minute: number, twodigitshour?: boolean): string {
    // jesli wiecej niz doba
    while (minute >= 1440) {
      minute -= 1440;
    }
    const res = this.hminutesum(minute);
    if (res.length < 5 && twodigitshour) {
      return '0' + res;
    }
    return res;
  }
  public hminutesum(minute: number): string {
    let res = this.hminutecache[minute];
    if (!res) {
      const h = Math.floor(minute / 60);
      const m = minute - h * 60;
      res = h + ':' + (m < 10 ? '0' : '') + m;
      this.hminutecache[minute] = res;
    }
    return res;
  }

  public mhour(hour: string): number {
    const res = hour.split(':');
    return parseInt(res[0], 10) * 60 + parseInt(res[1], 10);
  }
  public addDay(date: Date, days: number = 1): Date {
    const ndate = new Date(date);
    ndate.setDate(ndate.getDate() + days);
    return ndate;
  }
  public monday(date: Date, days: number = 1): Date {
    const ndate = new Date(date);
    if (ndate.getDay() === 0) {
      ndate.setDate(ndate.getDate() - 6);
    } else {
      ndate.setDate(ndate.getDate() - (ndate.getDay() - 1));
    }
    return ndate;
  }

  public addMonth(date: Date, months: number = 1): Date {
    const ndate = new Date(date);
    ndate.setMonth(ndate.getMonth() + months);
    return ndate;
  }

  public money(value: number) {
    const decimal = (n: number, factor: number) => {
      // var factor = Math.pow(10, k + 1);
      n = Math.round(Math.round(n * factor) / 10);
      return n / (factor / 10);
    };
    value = decimal(value, 100);
    if (value >= 0) {
      return value.toLocaleString('pl') + ' zł';
    }
  }
  public page(value: string) {
    return value.replace('of', '/');
  }

  public isToday(date: Date) {
    const today = new Date();
    date = new Date(date);
    return today.getFullYear() === date.getFullYear() && today.getMonth() === date.getMonth() && today.getDate() === date.getDate();
  }
  // zmienia stringa z czasem na dla takiego dla inputa
  public convert(time: string) {
    if (time[1] === ':') {
      return '0' + time;
    } else {
      return time;
    }
  }

  public dayOfWeek(day: number | Date | string): string {
    if (typeof day === 'string') {
      day = new Date(day);
    }
    if (day instanceof Date) {
      day = day.getDay();

    }
    if (day === 0) {
      day = 7;
    }


    return this.days[day - 1];
  }
  public monthName(month: number | Date): string {
    if (month instanceof Date) {
      month = month.getMonth();
    }
    return this.months[month];
  }

  public statusText(status: number): string {
    switch (status) {
      case 1:
        return 'aktywna';
      case 0:
        return 'odwołana';
      case 3:
        return 'oczekująca';
      case -1:
        return 'odwołana przez administratora';
      default:
        return '';
    }
  }

  public ttype(timetabletype: string): string {
    return this.timetabletypes[timetabletype];
  }

  public rgba(rgbcolor: string, a: number) {
    let rgb = this.rgbcache[rgbcolor];
    if (!rgb) {
      rgb = [parseInt(rgbcolor.substr(1, 2), 16), parseInt(rgbcolor.substr(3, 2), 16), parseInt(rgbcolor.substr(5, 2), 16)];
      this.rgbcache[rgbcolor] = rgb;
    }
    return `rgba(${rgb[0]},${rgb[1]},${rgb[2]},${a || 0.5}`;
  }

  public get(url: string, params?: any): Promise<any> {
    const config2: any = {};
    if (params) {
      config2.params = params;
    }
    return axios.get(url, config2);
  }
  public post(url: string, data?: any): Promise<any> {
    // const config: any = {};
    return axios.post(url, data);
  }
  public pesel(pesel: any) {
    if (pesel === '') {
      return true;
    }
    const digits = ('' + pesel).split('');
    if (parseInt(pesel.substring(4, 6), 10) > 31 || parseInt(pesel.substring(2, 4), 10) > 12) {
      return false;
    }
    let checksum =
      (1 * parseInt(digits[0], 10) +
        3 * parseInt(digits[1], 10) +
        7 * parseInt(digits[2], 10) +
        9 * parseInt(digits[3], 10) +
        1 * parseInt(digits[4], 10) +
        3 * parseInt(digits[5], 10) +
        7 * parseInt(digits[6], 10) +
        9 * parseInt(digits[7], 10) +
        1 * parseInt(digits[8], 10) +
        3 * parseInt(digits[9], 10)) %
      10;
    if (checksum === 0) {
      checksum = 10;
    }
    checksum = 10 - checksum;
    return parseInt(digits[10], 10) === checksum;
  }
  public hourValidate(start: any, end: any) {
    start = start.replace(':', '');
    end = end.replace(':', '');

    if (start > end) {
      return false;
    } else {
      return true;
    }
  }
  public dateValidate(start: any, end: any) {
    if (start > end) {
      return false;
    } else {
      return true;
    }
  }

  public groupReservationItemByDate(data: any[]): any[] {
    const dates: any = {};
    const dates2: any[] = [];
    const isAdmin = methods.isAdmin();

    data.sort((a, b) => {
      if (a.date < b.date) {
        return -1;
      } else if (a.date > b.date) {
        return 1;
      } else {
        if (a.start < b.start) {
          return -1;
        } else if (a.start > b.start) {
          return 1;
        } else {
          return 0;
        }
      }
    });

    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      item.selected = false;
      let dataitem = dates[item.date];
      if (dataitem === undefined) {
        dataitem = {
          date: data[i].date,
          count: 0,
          usedCount: 0,
          price: 0,
          usedPrice: 0,
          length: 0,
          usedLength: 0,

          items: []
        };
        dates[data[i].date] = dataitem;
        dates2.push(dataitem);

        // czy mona odwołać
        dataitem.canCancel = isAdmin || methods.canCancel(data[i].date);
      }

      dataitem.count++;
      dataitem.price += item.price || 0;
      dataitem.length += item.length || 0;
      if (item.status === 1) {
        dataitem.usedCount++;
        dataitem.usedPrice += item.price || 0;
        dataitem.usedLength += item.length || 0;
      }
      dataitem.items.push(item);
    }
    return dates2;
  }

  public komunikat(type: string, title: string, text: string, duration: number) {
    Vue.notify({
      // Content (will be wrapped in div.notification-content)
      text,
      // (optional)
      // Class that will be assigned to the notification
      type,
      // (optional)
      // Title (will be wrapped in div.notification-title)
      title,
      // (optional)
      // Overrides default/provided animation speed
      speed: 600,
      // (optional)
      // Overrides default/provided duration
      duration
      // (optional)
      // Maximum number of notifications that can be shown in notification holder
      // max: Infinity,
      //  (optional)
      // Width of notification holder, can be %, px string or number.
      // Valid values: '100%', '200px', 200
      // width: 300
    });
  }
  public isInRole(role: string) {
    // if ((<any>this).$store) {
    const user = store.state.user;

    return user.roles.filter((x: string) => x === role.toLowerCase()).length > 0;
    // }
    // return false;
  }
  public hasPerm(objectId: number, path?: string) {
    // if ((<any>this).$store) {
    // return true;


    const user = store.state.user;
    if (!user.id) {
      return false;
    }
    // console.log('hasPerm',objectId,path,user.objectPermission,typeof user.objectPermissionList,JSON.stringify(user.objectPermissionList));
    if (user.objectPermission === 'ALL') {
      return true;
    }
    let plist: number[] = user.objectPermissionList as number[];
    if (plist.indexOf(objectId) > -1) {
      return true;
    }
    if (!path) {
      var allObjects = store.state.allObjects;
      // console.log('allObjects', allObjects);
      if (allObjects) {
        var obj = allObjects[objectId];
        if (obj) {
          path = obj.path;
        }
      }
    }
    if (path) {
      var items = path.split('.');
      for (let i = 0; i < items.length; i++) {
        if (plist.indexOf(+(items[i])) > -1) {
          if (objectId) {
            plist.push(objectId); //zeby nastepnym razem przyspieszyc
          }

          return true;
        }
      }

    }
    return false;
    // return user.objectPermission==='ALL' || (user.objectPermissionList as number[]).indexOf(objectId)>-1

  }
  public isAdmin() {
    return methods.isInRole.call(this, 'admin');
  }
  public useClients(): boolean {
    return config.current.clients;
  }

  public canCancel(date: string | Date) {
    if (methods.isAdmin()) {
      // console.log("isAdmin");
      return true;
    } else {
      // console.log(date,store.state.user.minDaysBeforeCancellation,new Date(date) >= methods.addDay(new Date(), store.state.user.minDaysBeforeCancellation))
      return new Date(date) >= methods.addDay(new Date(), store.state.user.minDaysBeforeCancellation);
    }
  }

  // Tłumaczy nazwy usług
  public serviceType(serviceType: string): string {
    return (ServiceTypeTranslation as any)[serviceType];
  }
  // @ts-ignore
  public install(vue: any, options: any) {
    vue.prototype.$fdate = this.fdate;
    vue.filter('fdate', this.fdate);
    vue.prototype.$pesel = (pesel: any) => this.pesel(pesel);

    vue.prototype.$hminute = (minute: any, twodigitshour?: boolean) => this.hminute(minute, twodigitshour);
    vue.filter('hminute', (minute: any, twodigitshour?: boolean) => this.hminute(minute, twodigitshour));
    vue.prototype.$hminutesum = (minute: any) => this.hminutesum(minute);
    vue.filter('hminutesum', (minute: any) => this.hminutesum(minute));

    vue.prototype.$mhour = (hour: any) => this.mhour(hour);
    vue.filter('mhour', (hour: any) => this.mhour(hour));

    vue.prototype.$convert = (time: any) => this.convert(time);

    vue.prototype.$groupReservationItemByDate = this.groupReservationItemByDate;

    vue.prototype.$addDay = this.addDay;
    vue.prototype.$addMonth = this.addMonth;
    vue.prototype.$months = this.months;

    vue.prototype.$get = this.get;
    vue.prototype.$post = this.post;

    vue.prototype.$config = config;

    vue.prototype.$money = this.money;
    vue.filter('money', this.money);
    vue.filter('page', this.page);

    vue.prototype.$dayOfWeek = (day: any) => this.dayOfWeek(day);
    vue.filter('dayOfWeek', (day: any) => this.dayOfWeek(day));
    vue.prototype.$monday = (day: any) => this.monday(day);

    vue.prototype.$colors = this.colors;

    vue.prototype.$timetabletypes = this.timetabletypes;

    vue.filter('rgba', (color: any, a: number) => this.rgba(color, a));
    vue.prototype.$rgba = (color: any, a: number) => this.rgba(color, a);

    vue.prototype.$monthName = (month: any) => this.monthName(month);
    vue.filter('monthName', (month: any) => this.monthName(month));
    vue.prototype.$statusText = this.statusText;
    vue.filter('statusText', this.statusText);
    vue.prototype.$hourValidate = (start: any, end: any) => this.hourValidate(start, end);
    vue.prototype.$dateValidate = (start: any, end: any) => this.dateValidate(start, end);

    vue.prototype.$ttype = (timetabletype: string) => this.ttype(timetabletype);
    vue.filter('ttype', (timetabletype: string) => this.ttype(timetabletype));

    vue.prototype.$isToday = this.isToday;
    vue.prototype.$komunikat = (type: string, title: string, text: string, duration: number) => this.komunikat(type, title, text, duration);

    // sprawdzanie ról

    vue.prototype.$hasRole = vue.prototype.$isInRole = this.isInRole;
    vue.prototype.$hasPerm = this.hasPerm;

    vue.prototype.$isAdmin = this.isAdmin;
    // sprawdzenie daty
    vue.prototype.$canCancel = this.canCancel;

    // Wyświetlanie klientów
    vue.prototype.$useClients = this.useClients;

    // Sprawdza czy usługa jest subskrybcją
    vue.prototype.$isSubscribtion = (serviceType: string) => this.isSubscribtion(serviceType);

    // Tłumaczenie typów usług
    vue.prototype.$serviceType = (serviceType: string) => this.serviceType(serviceType);
    vue.filter('serviceType', (serviceType: string) => this.serviceType(serviceType));
  }
}

const methods = new Methods();
export default methods;
