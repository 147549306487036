<template>
  <span> <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i> <template v-if="this.full">trwa ładowanie...</template> </span>
</template>

<script>
export default {
  props: {
    full: Boolean
  }
};
</script>
