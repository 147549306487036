<template>
  <div>
    <div style="margin-left: 8px; width: 100%">
      <div class="card card-danger card-outline">
        <div class="card-header">
          <b>Terminy rezerwacji:</b>
          <router-link :to="{name: 'ReservationDetails', params: {id: res.reservationId}}"> {{ res.reservationName || '[brak nazwy rezerwacji]' }}</router-link>
          &nbsp;
          <div class="badge bg-primary">{{ res.reservationTypeName }}</div>

          <router-link
            :to="{
              name: 'ObjectReservations',
              params: {id: objectId},
              query: {
                clientId: this.res.clientId,
                type: this.res.reservationTypeId,
                dateFrom: this.$fdate(this.res.startDate),
                dateTo: this.$fdate(this.res.endDate),
                start: 480,
                end: 1200,
                frequency: 0,
                option: 'update',
                reservationId: this.res.reservationId
              }
            }"
            class="btn btn-success pull-right ml-1 btn-sm"
          >
            Dodaj termin
          </router-link>
        </div>
      </div>
    </div>
    <div style="margin-left: 8px; width: 100%">
      <div class="card card-danger card-outline">
        <div class="card-header sticky">
          <div class="card-title">
            <template v-if="!addClient">
              <button class="btn btn-primary" @click="toggleEdit()" v-if="!edit">Edycja terminów</button>
              <button class="btn btn-secondary" @click="toggleEdit()" v-if="edit">Anuluj edycję terminów</button>&nbsp;
              <template v-if="edit && pickedCount">
                <button class="btn btn-danger" @click="cancelResButton()">Anuluj zaznaczone ({{ pickedCount }}) ...</button>&nbsp;
                <button class="btn btn-success" @click="activateResButton()">Przywróć zaznaczone ({{ pickedCount }}) ...</button>&nbsp;
                <button class="btn btn-waring" @click="pickOffAll()">Wyczyść</button>&nbsp;
              </template>
            </template>

            <template v-if="!edit">
              <button class="btn btn-primary pull-right ml-1" @click="toggleAddClient" v-if="!addClient">Dodaj klienta</button>
              <button class="btn btn-primary pull-right ml-1" @click="clientForm" v-if="addClient">Wprowadź klienta</button>
              <button class="btn btn-secondary pull-right ml-1" @click="toggleAddClient" v-if="addClient">Anuluj wprowadzanie klienta</button>
            </template>
          </div>
        </div>
        <div class="card-body">
          <template v-if="addClient">
            <b-alert show variant="warning"
              >Aby dodać klienta, najpierw zaznacz terminy, które go dotycza. Jeżeli chcesz dodać klienta do wszystkich terminów - nie zaznaczaj niczego i kliknij "Wprowadź
              klienta"</b-alert
            >
            <h3>Wybierz termin/y</h3>
          </template>
          <h2 v-if="!res.reservations || res.reservations.length == 0">
            <b-badge style="width: 100%" variant="danger">Brak dodanych terminów</b-badge>
          </h2>

          <div v-if="false" class="row" style="padding: 8px">
            <div class="col-3" v-for="(term, index) in res.reservations" :key="index">
              <div class="card card-danger card-outline">
                <div class="card-header">
                  <h4 class="card-title">
                    <b>{{ term.date | moment('LL') }}</b>
                  </h4>
                </div>
                <div class="card-body p-0">
                  <b-alert show variant="warning" v-if="edit && !checkDate(term.date)">Nie można anulować</b-alert>
                  <div
                    v-if="(edit && term.items.length > 1 && checkDate(term.date)) || addClient"
                    style="border-bottom: 1px solid #ccc; padding-left: 6px; padding-top: 5px"
                    class="checkbox"
                  >
                    <label class="checkbox-bootstrap checkbox-lg" style="padding-right: 5px">
                      <!--
                        <input type="checkbox" :id="dzien+ 'checkbox' " @click="reserAll(dzien,data.date,data.items,data.timetableId) ">
                        -->

                      <input type="checkbox" v-model="term.picked" @click="checkAllDay(term)" />
                      <span> Zaznacz wszystkie</span>
                    </label>
                  </div>
                  <div style="padding-left: 6px" v-for="(unit, indexUnit) in term.items" :key="indexUnit">
                    <div style="display: inline">
                      <label style="padding-right: 5px" class="checkbox-bootstrap checkbox-lg">
                        <input v-if="(edit && checkDate(term.date)) || addClient" type="checkbox" v-model="unit.picked" @click="unitPicked(unit)" />
                        <i style="padding-left: 19px" v-else></i>
                        <span>
                          {{ unit.start | hminute }}-{{ (unit.start + unit.length) | hminute }}
                          <b-badge v-if="unit.status == 0" variant="danger ">Anulowana</b-badge>
                          <br />
                          <small class="clientspan" style="padding-left: 17px">
                            <i class="fa fa-sitemap fa-fw"></i> {{ unit.objectName }}
                            <span v-if="unit.price"> <i class="fa fa-money fa-fw"></i> {{ unit.price | money }} </span>
                          </small>
                        </span>
                      </label>
                      <label> </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <table class="table table-sm table-bordered table-hover">
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Godzina</th>
                  <th>Nazwa obiektu</th>
                  <th class="text-center">Status</th>
                  <th v-if="showPrice" class="text-right">Cena</th>
                </tr>
              </thead>
              <tbody v-for="term in res.reservations" :key="term.date">
                <tr v-for="(unit, index) in term.items" :key="unit.id">
                  <td v-if="index == 0" :rowspan="term.items.length">
                    <label>
                      <input
                        v-if="edit && ((checkDate(term.date) && unit.status != 3) || ($hasRole('admin') && unit.status == 3))"
                        :disabled="!checkDate(term.date)"
                        type="checkbox"
                        v-model="term.picked"
                        @click="checkAllDay(term)"
                      />
                      {{ term.date | fdate }}
                    </label>
                    <span class="text-danger" v-if="edit && !checkDate(term.date)"> &nbsp; Nie można edytować</span>
                  </td>
                  <td>
                    <label>
                      <input
                        v-if="edit && ((checkDate(term.date) && unit.status != 3) || ($hasRole('admin') && unit.status == 3))"
                        :disabled="!checkDate(term.date)"
                        type="checkbox"
                        v-model="unit.picked"
                        @click="unitPicked(unit, term, $event)"
                      />
                      {{ unit.start | hminute }} - {{ (unit.start + unit.length) | hminute }}
                    </label>
                  </td>
                  <td @click="unitPicked(unit, term)">{{ unit.objectName }}</td>
                  <td @click="unitPicked(unit, term)" class="text-center">
                    <span v-if="unit.status == 1" class="badge bg-success">{{ unit.status | statusText }}</span>
                    <span v-else-if="unit.status == 3" class="badge bg-primary">{{ unit.status | statusText }}</span>
                    <span v-else class="badge bg-warning">{{ unit.status | statusText }}</span>
                    <div v-if="unit.status == 3">
                      <button class="btn btn-primary btn-sm" @click="confirmWaiting(unit.id)">Potwierdź</button
                      ><button class="btn btn-danger btn-sm" @click="cancelWaiting(unit.id)">Anuluj</button>
                    </div>
                  </td>
                  <td @click="unitPicked(unit, term)" v-if="showPrice" class="text-right">
                    <span v-if="$hasRole('admin')" class="price" @click="changePrice(unit)" :class="{zero: unit.price == 0}" style="cursor: pointer">{{ unit.price | money }}</span>
                    <span v-else>{{ unit.price | money }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <app-cancel v-if="showCancel" :show="showCancel" :picked="pickedId" @cancel="cancel" @reload="reload" :res="res" :option="Modaloption"></app-cancel>
    <add-card-owner v-if="modalClient" :show="modalClient" @cancel="cancelClient" :cardOwner="cardOwnerId" :picked="pickedId"></add-card-owner>
    <change-price :show="showChangePrice" :res="resMore" @cancel="showChangePrice = false" @reload="reload()"></change-price>
  </div>
</template>

<script>
import appCancel from './CancelingReservation.vue';
import addCardOwner from './AddCardOwner.vue';
import changePrice from '../reservations/ChangePrice.vue';
export default {
  data() {
    return {
      res: {},
      reservationId: this.$route.params.id,
      objectId: 0,
      showCancel: false,
      cancelDate: new Date(),
      Modaloption: 'cancel',
      pickedId: [],
      pickedCount: 0,
      edit: false,
      addClient: false,
      cardOwnerId: 0,
      modalClient: false,
      showChangePrice: false,
      showPrice: true,
      resMore: null
    };
  },
  components: {
    appCancel,
    addCardOwner,
    changePrice
  },
  methods: {
    clientForm() {
      this.getPicked();
      this.modalClient = true;
    },
    cancelClient() {
      this.modalClient = false;
    },
    toggleAddClient() {
      this.pickOffAll();
      this.addClient = !this.addClient;

      if (!this.addClient) {
        if (this.cardOwnerId) {
          this.$router.push({name: 'ClientsProfile', params: {id: this.cardOwnerId}});
        } else if ('' + this.$route.query.addClient == 'true') {
          this.$router.push({name: 'ReservationDetails', params: {id: this.res.reservationId}});
        }
      }

      this.edit = false;
    },
    toggleEdit() {
      this.pickOffAll();
      this.edit = !this.edit;
      this.addClient = false;
    },
    pickOffAll() {
      if (this.res.reservations) {
        for (var i = 0; i < this.res.reservations.length; i++) {
          var res = this.res.reservations[i];
          res.picked = false;
          for (var j = 0; j < res.items.length; j++) {
            var item = res.items[j];
            item.picked = false;
          }
        }
      }
      this.pickedCount = 0;
    },
    unitPicked(unit, term, event) {
      if (!this.edit || !this.checkDate(term.date)) return;
      var picked = !unit.picked;

      unit.picked = picked;
      //console.log("picked",picked);
      if (picked) {
        this.pickedCount++;
      } else {
        this.pickedCount--;
      }

      return false;
    },
    reload() {
      //odznacza checkboxy
      this.pickedId = [];
      this.pickedCount = 0;

      this.$http.get('reservation/get/' + this.reservationId).then(
        (response) => {
          var res = response.body.data;
          this.objectId = res.objects.length == 0 ? 0 : res.objects[0].id;
          for (var i = 0; i < res.reservations.length; i++) {
            res.reservations[i].picked = false;
            for (var j = 0; j < res.reservations[i].items.length; j++) {
              res.reservations[i].items[j].picked = false;
            }
          }
          this.res = res;
          let today = new Date();

          //console.log("roles",this.$store.state.user.roles);
          this.cancelDate.setDate(today.getDate() + parseInt(this.res.minDaysBeforeCancellation));
        },
        (response) => {}
      );
    },
    checkDate(date) {
      if (new Date(date) > this.cancelDate) {
        return true;
      } else {
        return false;
      }
    },
    checkAllDay(term) {
      if (!this.edit || !this.checkDate(term.date)) return;
      var picked = term.picked;
      for (var i = 0; i < term.items.length; i++) {
        var resitem = term.items[i];
        if (picked) {
          if (resitem.picked) {
            resitem.picked = false;
            this.pickedCount--;
          }
          term.picked = false;
        } else {
          if (resitem.picked == false) {
            resitem.picked = true;
            this.pickedCount++;
          }
          term.picked = true;
        }
      }
    },
    changePrice(item) {
      if (!this.$isAdmin()) return;
      this.resMore = item;
      this.showChangePrice = true;
    },
    getPicked() {
      this.pickedId = [];
      for (let x in this.res.reservations) {
        for (let y in this.res.reservations[x].items) {
          if (this.res.reservations[x].items[y].picked == true) {
            this.pickedId.push(this.res.reservations[x].items[y].id);
          }
        }
      }
    },

    cancelResButton() {
      this.getPicked();
      this.Modaloption = 'cancel';
      this.showCancel = true;
    },
    activateResButton() {
      this.getPicked();
      this.Modaloption = 'add';
      this.showCancel = true;
    },
    confirmWaiting(id) {
      this.pickedId = [id];
      this.Modaloption = 'confirm';
      this.showCancel = true;
    },
    cancelWaiting(id) {
      this.pickedId = [id];
      this.Modaloption = 'cancel';
      this.showCancel = true;
    },
    cancel() {
      this.showCancel = false;
    }
  },
  mounted() {
    if ('' + this.$route.query.addClient == 'true') {
      this.toggleAddClient();
    }
    this.cardOwnerId = +this.$route.query.cardOwnerId;
    this.reload();
    window.document.body.classList.add('content-scroll');
  },
  destroyed() {
    window.document.body.classList.remove('content-scroll');
  },
  computed: {
    picked() {
      let tmp = [];
      for (let x in this.res.reservations) {
        for (let y in this.res.reservations[x].items) {
          if (this.res.reservations[x].items[y].picked == true) {
            tmp.push(this.res.reservations[x].items[y].id);
          }
        }
      }

      return tmp;
    }
  },
  watch: {
    $route(to, from) {
      this.reservationId = this.$route.params.id;
      this.reload();
      if (this.$route.query.addClient == 'true') {
        this.toggleAddClient();
      }
      this.cardOwnerId = +this.$route.query.cardOwnerId;
    }
  }
};
</script>

<style>
h3.card-title {
  font-size: 1.1rem;
}
</style>
