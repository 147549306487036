<template>
  <div id="wrapper">
    <app-header @sbtoggle="sbtoggle"></app-header>
    <app-sidebar></app-sidebar>
    <app-content :title="title"></app-content>
    <app-footer @sbtoggle="sbtoggle"></app-footer>
    <card-use-modal :disabled="modalDisabled" v-if="$hasRole('admin')"></card-use-modal>
  </div>
</template>
<script>
import AppHeader from './../components/layout/Header';
import AppSidebar from './../components/layout/Sidebar';
import AppContent from './../components/layout/Content';
import AppFooter from './../components/layout/Footer';
import CardUseModal from './../components/modules/CardUseModal';
export default {
  name: 'AppLayout',
  data() {
    return {
      title: null,
      modalDisabled: false
    };
  },
  components: {
    AppHeader,
    AppSidebar,
    AppContent,
    AppFooter,
    CardUseModal
  },
  methods: {
    sbtoggle() {
      const body = document.body;
      if (body) {
        if (document.getElementById('side_bar').offsetLeft >= 0 && body.className.indexOf('sidebar-collapse') === -1) {
          body.className = body.className.replace(' sidebar-open', '');
          body.className += ' sidebar-collapse';
        } else {
          body.className = body.className.replace(' sidebar-collapse', '');
          body.className += ' sidebar-open';
        }
      }
    }
  },
  mounted() {
    this.$root.$on('disablemodal', () => {
      this.modalDisabled = true;
    });
    this.$root.$on('enablemodal', () => {
      this.modalDisabled = false;
    });
  }
};
</script>
