<template>
  <!-- Modal -->
  <b-modal v-model="showLocal" @hidden="$emit('cancel')">
    <div v-if="option != 'update'" slot="modal-title">Dodaj klienta</div>
    <div v-if="option == 'update'" slot="modal-title">Edytuj {{ object.firstName }} {{ object.lastName }}</div>
    <div slot="modal-header-close">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$emit('cancel')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="box box-success min-height-1">
        <div class="box-body">
          <div v-if="option != 'update'" class="col-md-12">
            <div class="form-group">
              <label for="type">Klienci:</label>
              <customSelect :url="'cardowner/find/'" @picked="pick" :resultFields="['firstName', 'lastName']" :doValidation="true">
                <template slot-scope="child">{{ child.child.firstName }} {{ child.child.lastName }}</template>
              </customSelect>
            </div>
          </div>
          <div class="col-md-12">
            <div clas="form-group">
              <label>Stanowisko:</label>
              <input class="form-control" type="text" v-model="role" />
              <small class="text-danger" v-if="$v.role.$anyError">Stanowisko jest wymagane</small>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div slot="modal-footer">
      <button type="button " class="btn btn-default " data-dismiss="modal " @click="showLocal = false">Anuluj</button>&nbsp;
      <button v-if="option != 'update'" @click="save()" class="btn btn-warning">Dodaj</button>
      <button v-if="option == 'update'" @click="update()" class="btn btn-warning">Zapisz</button>
    </div>
  </b-modal>
</template>

<script>
import customSelect from '../modules/CustomSelect.vue';
import {required} from 'vuelidate/lib/validators';
export default {
  props: ['show', 'option', 'object'],
  data() {
    return {
      clientId: this.$route.params.id,
      showLocal: this.show,
      role: this.object.role
    };
  },
  validations: {
    role: {
      required
    }
  },
  components: {
    customSelect
  },

  mounted() {
    this.clientId = this.$route.params.id;
  },
  watch: {
    $route(to, from) {
      this.clientId = this.$route.params.id;
    }
  },
  methods: {
    pick(user) {
      this.pickedCardOwner = user;
    },
    save() {
      if (this.$v.$invalid == true) {
        this.$v.$touch();
      } else {
        let post = {
          clientId: this.clientId,
          cardOwnerId: this.pickedCardOwner.id,
          role: this.role
        };
        this.$http.post('client/addCardOwner', JSON.stringify(post), {}).then(
          response => {
            if (response && response.body && response.body.status == 1) {
              this.$emit('reload');
              this.$emit('cancel');
              this.$komunikat('success', 'Sukces', 'Pomyślnie dodano klienta', 3000);
            } else {
              this.$komunikat('error', 'Błąd', 'Błąd przy dodawaniu klienta', 3000);
            }
          },
          response => {}
        );
      }
    },
    update() {
      let post = {
        id: this.object.id,
        role: this.role
      };
      this.$http.post('client/updateCardOwner', JSON.stringify(post), {}).then(
        response => {
          if (response && response.body && response.body.status == 1) {
            this.$emit('reload');
            this.$emit('cancel');
            this.$komunikat('success', 'Sukces', 'Pomyślnie edytowano klienta', 3000);
          } else {
            this.$komunikat('error', 'Błąd', 'Błąd przy edytowaniu klienta', 3000);
          }
        },
        response => {}
      );
    }
  },
  watch: {
    showLocal: function(val) {
      if (this.showLocal == false) {
        this.$emit('cancel');
      }
    }
  }
};
</script>

<style></style>
