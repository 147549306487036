<template>
  <!-- Modal -->
  <b-modal v-model="showLocal" @hidden="$emit('cancel')">
    <h5 slot="modal-title">Zmiana danych użytkownika</h5>

    <div slot="modal-header-close">
      <button type="button" class="close" aria-label="Close" @click="$emit('cancel')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="box-body">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label :class="{ 'text-danger': $v.password.$anyError }">Nowe hasło</label>
            <input
              v-model.trim="$v.password.$model"
              class="form-control"
              autocomplete="new-password"
              type="password"
              :class="{ 'is-invalid': $v.password.$anyError }"
            />

            <small v-if="$v.password.$anyError" class="text-danger">Hasło jest za krótkie</small>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <div class="form-group">
              <label :class="{ 'text-danger': $v.repeatPassword.$anyError }">Powtórz hasło</label>

              <input
                v-model.trim="$v.repeatPassword.$model"
                class="form-control"
                autocomplete="new-password"
                type="password"
                :class="{ 'is-invalid': $v.repeatPassword.$anyError }"
              />

              <small class="text-danger" v-if="$v.repeatPassword.$anyError">Błędnie powtórzone hasło</small>
            </div>
          </div>
        </div>
      </div>



    </div>
    <!-- Modal-footer -->
    <div slot="modal-footer">
      <button type="button" class="btn btn-default" data-dismiss="modal" @click="$emit('cancel')">Wyjdź</button>&nbsp;

      <button type="button" class="btn btn-info" data-dismiss="modal" @click="save">Zapisz</button>
    </div>
    <!-- /Modal-footer -->
  </b-modal>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';
import datepicker from 'vuejs-datepicker';
import { pl } from 'vuejs-datepicker/dist/locale';

// @vuese
// Modal do zmiany hasła
// @group employees
export default {
  components: { datepicker },
  name: 'appAdd',
  props: {
    // Czy pokazać modal
    show: Boolean,
    // Użytkownik
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      password: '',
      repeatPassword: '',
      name: '',
      pl: pl,
      showLocal: this.show
    };
  },


  methods: {
    // @vuese
    // Zamyka modal
    cancel() {
      this.$emit('cancel');
    },
    // @vuese
    // Zmienia hasło
    save() {
      if (this.$v.$invalid === true) {
        this.$v.$touch();
      } else {
        const post = {
          newPassword: this.password,
          userId: this.user.id
        };

        this.$http.post('account/changePassword', JSON.stringify(post), {}).then(res => {
          if (res.data.status === 1) {
            this.$komunikat('success', 'Sukces', 'Zmieniono hasło', 3000);
            this.cancel();
          } else {
            this.$komunikat('error', 'Błąd', 'Błąd przy zmianie hasła', 3000);
          }
        });
      }
    },

    
  },
  validations: {

    password: {
      required,
      minLength: minLength(8)
    },
    repeatPassword: {
      required,
      minLength: minLength(8),
      withParams(value) {
        return this.repeatPassword === this.password;
      }
    }
  },

  watch: {
    showLocal: function(val) {
      if (this.showLocal === false) {
        this.$emit('cancel');
      }
    }
  }
};
</script>
