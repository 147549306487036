<template>
  <div class="search-box">
    <input
      :disabled="disabled"
      type="text"
      placeholder="Szukaj..."
      :class="{'is-invalid': $v.searchText.$anyError && doValidation}"
      class="form-control szukaj"
      v-model.trim="$v.searchText.$model"
      @click="refreshList"
      @focus="show()"
      @blur="hide()"
    />
    <div :class="inputStyles">
      <div v-for="(item, index) in list" :key="index" class="select-item" @click="pick(item)" :class="{selected: selected._selected}">
        <slot :child="item"></slot>
      </div>
    </div>
    <small class="text-danger" v-if="$v.searchText.$anyError && doValidation">Pole wymagane</small>
  </div>
</template>
<script>
function exist(val) {
  if (this.list.length) {
    return true;
  } else {
    return false;
  }
}
import {required} from 'vuelidate/lib/validators';
export default {
  props: {
    url: String,
    itemId: Number,
    query: Object,
    resultFields: Array,
    doValidation: Boolean,
    disabled: Boolean,
    id: Number,
    pickFirst: Function
  },
  validations: {
    searchText: {
      required,
      exist
    }
  },
  data() {
    return {
      styles: ['select-container', 'hide'],
      searchText: '',
      list: [],
      selected: -1,
      tmpSelected: -1,
      draw: 0,
      results: {}
    };
  },
  mounted() {
    this.tmpSelected = this.itemId;
    this.refreshList(true);
  },
  methods: {
    show() {
      this.styles = this.styles.filter(style => style !== 'hide');
      if (this.list.length == 0) {
        this.refreshList();
      }
    },
    pick(item) {
      let fields = [];
      if (this.resultFields && this.resultFields.length) {
        fields = this.resultFields;
      } else {
        fields.push('name');
      }
      this.searchText = fields.map(x => item[x]).join(' ');

      let selected = {...item};
      delete selected._selected;

      this.selected = item;
      this.selected._selected = true;
      this.$emit('picked', selected);
    },
    hide() {
      setTimeout(() => {
        this.styles.push('hide');
      }, 200);
    },
    refreshList(firstPick) {
      let text = (this.searchText || '').toLowerCase().trim();

      let qs = {...this.query};
      qs.q = text;
      qs.draw = ++this.draw;
      qs.clientId = this.id;
      let tmp = [];
      for (let item in qs) {
        tmp.push(`${item}=${qs[item]}`);
      }
      if (this.tmpSelected) {
        tmp.push(`reservationId=${this.tmpSelected}`);
      }
      this.$http.get(this.url + '?' + tmp.join('&') /* + "&count=10" */).then(response => {
        if (response.data.draw == this.draw) {
          this.results[text] = response.data.data.data;
          this.list = response.data.data.data;
          if (firstPick && this.pickFirst) {
            let picked = this.pickFirst(this.list);

            if (picked) {
              let fields = [];
              if (this.resultFields && this.resultFields.length) {
                fields = this.resultFields;
              } else {
                fields.push('name');
              }
              let tmp = [];
              tmp.push(picked);
              this.searchText = fields.map(x => tmp[0][x]).join(' ');

              this.$emit('picked', picked);
            }
          } else if (this.tmpSelected != -1 && this.tmpSelected) {
            let selectedItem = this.list.filter(x => x.id == this.tmpSelected || x.reservationId == this.tmpSelected);
            if (selectedItem.length) {
              let fields = [];
              if (this.resultFields && this.resultFields.length) {
                fields = this.resultFields;
              } else {
                fields.push('name');
              }
              this.searchText = fields.map(x => selectedItem[0][x]).join(' ');
            } else {
            }

            let selected = {...selectedItem[0]};
            delete selected._selected;
            this.$emit('picked', selected);
            this.tmpSelected = undefined;
          }
        }
      });
    }
  },
  watch: {
    searchText() {
      this.refreshList();
      if (this.$v.$invalid == true) {
        this.$emit('invalid', true);
      } else {
        this.refreshList();
        this.$emit('invalid', false);
      }
    },
    list() {
      if (!this.list.length) {
        this.$emit('invalid', true);
      }
    },
    id() {
      this.$emit('invalid', true);
      this.searchText = '';
    }
  },
  computed: {
    inputStyles() {
      return this.styles.join(' ');
    }
  }
};
</script>
<style scoped>
.search-box {
  position: relative;
}
.search-box .select-container {
  position: absolute;
  z-index: 999;
  border: 1px solid #ccc;
  border-top: none;
  width: 100%;
  background: white;
  max-height: 200px;
  overflow-y: auto;
}
.search-box .select-container .select-item {
  padding: 10px;
}
.search-box .select-container .select-item:hover,
.search-box .select-container .select-item.selected {
  background: #eee;
}
</style>
