<template>
  <div>
    <notifications position="top right" />
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  
  mounted() {

      if (navigator.serviceWorker) {
        navigator.serviceWorker.getRegistrations().then(function(registrations) {
            for (let idx in registrations) {
              console.log('unregister',idx);
                registrations[idx].unregister();
            }
        })
      };
    
  }
};
</script>
