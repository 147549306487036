<template>
  <!-- Modal -->
  <b-modal v-model="showLocal" @hidden="$emit('cancel')">
    <h5 slot="modal-title">Edycja obiektu {{ object.name }}</h5>
    <div slot="modal-header-close">
      <button type="button" class="close" aria-label="Close" @click="$emit('cancel')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <!-- modal content  -->
    <div class="box-body">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label :class="{'text-danger': $v.name.$anyError}" for="surname">Nazwa obiektu</label>
            <input :class="{'is-invalid': $v.name.$anyError}" class="form-control" id="surname" type="text" v-model.trim="$v.name.$model" />
            <small class="text-danger" v-if="$v.name.$anyError">Nazwa obiektu jest wymagana</small>
          </div>
        </div>

        <div class="col-md-12">
          <div class="form-group">
            <label :class="{'text-danger': $v.maxPersonCount.$anyError}" for="surname">Maksymalna ilość osób</label>
            <input :class="{'is-invalid': $v.maxPersonCount.$anyError}" class="form-control" id="surname" type="text" v-model.trim="$v.maxPersonCount.$model" />
            <small class="text-danger" v-if="$v.maxPersonCount.$anyError">Ilość osób musi być liczbą</small>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label :class="{'text-danger': $v.maxReservationCount.$anyError}" for="surname">Maksymalna liczba jednoczesnych rezerwacji</label>
            <input :class="{'is-invalid': $v.maxReservationCount.$anyError}" class="form-control" id="surname" type="text" v-model.trim="$v.maxReservationCount.$model" />
            <small class="text-danger" v-if="$v.maxReservationCount.$anyError">Ilość rezewacji musi być liczbą</small>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label class="checkbox-bootstrap checkbox-lg" style="padding-right: 5px">
              <input type="checkbox" v-model="$v.canCardOwnerReservation.$model" style="margin-right: 5px" />
              <span>Może być rezerwowany przez osoby fizyczne</span>
            </label>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label for="name">Opis</label>
            <textarea class="form-control" type="text" v-model="description"></textarea>
          </div>
        </div>
      </div>
    </div>
    <div slot="modal-footer">
      <button type="button" class="btn btn-default" @click="cancel()">Wyjdź</button>&nbsp;
      <button type="button" class="btn btn-success" @click="save()">Zapisz</button>
    </div>
  </b-modal>
</template>

<script>
import {required, numeric} from 'vuelidate/lib/validators';
export default {
  props: {
    changed: Number,
    object: {
      type: Object,
      required: true
    },
    show: Boolean
  },
  data() {
    return {
      showLocal: this.show,
      name: this.object.name,
      maxPersonCount: this.object.maxPersonCount,
      maxReservationCount: this.object.maxReservationCount,
      description: this.object.description,
      canCardOwnerReservation: this.object.canCardOwnerReservation
    };
  },
  validations: {
    name: {
      required
    },
    maxPersonCount: {
      numeric
    },
    maxReservationCount: {
      numeric
    },
    canCardOwnerReservation: {}
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    save() {
      if (this.$v.$invalid == true) {
        this.$v.$touch();
      } else {
        let post = {
          id: this.object.id,
          maxPersonCount: this.maxPersonCount,
          maxReservationCount: this.maxReservationCount,
          description: this.description,
          name: this.name,
          canCardOwnerReservation: this.canCardOwnerReservation
        };
        this.$http.post('object/update', JSON.stringify(post), {}).then((response) => {
          if (response && response.body && response.body.status == 1) {
            this.$store.dispatch('getObjects');
            this.cancel();
            this.$emit('reload');
            this.$komunikat('success', 'Sukces', 'Pomyślnie edytowano obiekt', 3000);
          } else {
            this.$komunikat('error', 'Błąd', 'Błąd przy edytowaniu obiektu', 3000);
          }
        });
      }
    }
  },

  watch: {
    showLocal: function (val) {
      if (this.showLocal == false) {
        this.$emit('cancel');
      }
    }
  }
};
</script>

<style></style>
