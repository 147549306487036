<template>
  <div class="row">
    <div class="col-12">
      <div class="card card-danger card-outline">
        <div class="card-header">
          <h3 v-if="status == '4'" class="card-title">
            Lista rezerwacji aktualnych
            <loading-icon v-if="loading"></loading-icon>
          </h3>
          <h3 v-if="status == '1'" class="card-title">
            Lista rezerwacji aktywnych
            <loading-icon v-if="loading"></loading-icon>
          </h3>
          <h3 v-if="status == '2'" class="card-title">
            Lista wszystkich rezerwacji
            <loading-icon v-if="loading"></loading-icon>
          </h3>
          <h3 v-if="status == '0'" class="card-title">
            Lista rezerwacji odwołanych
            <loading-icon v-if="loading"></loading-icon>
          </h3>
          <h3 v-if="status == '3'" class="card-title">
            Lista oczekujących rezerwacji
            <loading-icon v-if="loading"></loading-icon>
          </h3>
          <app-search :color="'btn-danger'" :post="post" @reload="reload" @post="post = $event"></app-search>

          <!-- /.card-tools -->
        </div>
        <!-- /.card-header -->

        <div class="card-body p-0">
          <div class="mailbox-controls clientitem top" style="height: 90px">
            <!-- switch-buttons -->

            <app-pages
              :filteredCount="filteredCount"
              :maxcount="maxcount"
              :colorActive="'#fff'"
              :backgroundActive="'#dc3545'"
              :post="post"
              @post="post = $event"
              @reload="reload()"
            >
              <b-dropdown id="ddown1" :text="buttonText" variant="danger">
                <b-dropdown-item
                  @click="
                    status = 4;
                    buttonText = 'Aktualne';
                  "
                  >Aktualne</b-dropdown-item
                ></b-dropdown-item>
                <b-dropdown-item
                  @click="
                    status = 1;
                    buttonText = 'Aktywne';
                  "
                  >Aktywne</b-dropdown-item
                >
                <b-dropdown-item
                  @click="
                    status = 0;
                    buttonText = 'Odwołane';
                  "
                >
                  Odwołane</b-dropdown-item
                >
                <b-dropdown-item
                  @click="
                    status = 3;
                    buttonText = 'Oczekujące';
                  "
                  >Oczekujące</b-dropdown-item
                >
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item
                  @click="
                    status = 2;
                    buttonText = 'Wszystkie';
                  "
                  >Wszystkie</b-dropdown-item
                >
              </b-dropdown>
            </app-pages>
          </div>
          <div class="table-responsive mailbox-messages">
            <div class="alert alert-danger text-center" v-if="!reservations.length">Brak rezerwacji</div>

            <div v-for="(reservation, index) in reservations" :key="index">
              <div class="clientitem">
                <div class="mailbox-name">
                  <b>
                    <router-link :to="{name: 'ReservationDetails', params: {id: reservation.reservationId}}" style="color: #dc3545">{{
                      reservation.reservationName || '[brak nazwy rezerwacji]'
                    }}</router-link>
                    &nbsp;
                  </b>
                </div>
                <div>
                  <span class="clientspan" v-if="reservation.startDate"> <i class="fa fa-calendar fa-fw"></i> {{ reservation.startDate | moment('LL ') }} </span>
                  <span class="clientspan" v-if="reservation.endDate != reservation.startDate"> - {{ reservation.endDate | moment('LL ') }} </span>
                  <span class="clientspan">
                    <router-link v-if="reservation.clientName" style="color: #a0a0a0" :to="{name: 'UsersProfile', params: {id: reservation.clientId}}">
                      <i class="nav-icon fa fa-users fa-fw"></i> {{ reservation.clientName }}</router-link
                    >
                  </span>
                  <span class="clientspan">
                    <span v-if="!reservation.clientName"> [brak nazwy podmiotu]</span>
                    &nbsp;
                  </span>
                </div>
              </div>
            </div>

            <!-- /.table -->
          </div>

          <!-- /.mail-box-messages -->
        </div>
        <div class="card-footer p-0">
          <div class="mailbox-controls">
            <!-- Check all button -->

            <app-pages
              style="padding-right: 15px"
              :filteredCount="filteredCount"
              :maxcount="maxcount"
              :colorActive="'#fff'"
              :backgroundActive="'#dc3545'"
              :post="post"
              @post="post = $event"
              @reload="reload()"
            ></app-pages>

            <!-- /.float-right -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import loadingIcon from '../modules/LoadingIcon.vue';
import appPages from '../modules/Pages.vue';
import appSearch from '../modules/Search.vue';

export default {
  data() {
    return {
      reservations: [],
      status: '4',
      loading: false,
      filteredCount: 0,
      maxcount: 0,
      post: {q: '', reservationStatus: 0, count: 10, skip: 0, page: 1, draw: 1},
      options: [
        {text: 'Aktywne', value: '1'},
        {text: 'Odwołane', value: '0'}
      ],
      buttonText: 'Aktualne'
    };
  },
  components: {
    loadingIcon,
    appPages,
    appSearch
  },
  created() {
    this.reloadLink();
    this.reload();
  },
  methods: {
    reloadLink() {
      this.post.q = this.$route.query.q;
      this.post.page = this.$route.query.page;
      this.post.count = this.$route.query.count;
      this.post.skip = this.$route.query.skip;
      this.post.reservationStatus = this.$route.query.reservationStatus;
      this.status = this.$route.query.reservationStatus;
      if (!this.post.count) {
        this.post.count = 50;
      }
      if (!this.post.skip) {
        this.post.skip = 0;
      }
      if (!this.post.page) {
        this.post.page = 1;
      }
      if (!this.status) {
        this.status = 4;
      }
    },
    reload() {
      this.loading = true;
      if (this.status == '1') {
        this.post.reservationStatus = 1;
      } else if (this.status == '0') {
        this.post.reservationStatus = 0;
        this.buttonText = 'Odwołane';
      } else if (this.status == '3') {
        this.buttonText = 'Oczekujące';
        this.post.reservationStatus = 3;
      } else {
        this.post.reservationStatus = '';
      }
      if (this.status == '4') {
        this.post.reservationStatus = 1;
        this.post.activeDate = this.$fdate(new Date());
      }
      this.$http.post('reservation/find', JSON.stringify(this.post), {}).then((response) => {
        this.reservations = response.data.data.data;
        this.filteredCount = response.data.data.filteredCount;
        this.maxcount = response.data.data.count;
        // this.$router.push({
        //   query: {
        //     reservationStatus: this.post.reservationStatus
        //   }
        // });
        this.loading = false;
      });
    }
  },

  watch: {
    $route(to, from) {
      this.reload();
    },
    status: function (val) {
      this.reload();
    }
  }
};
</script>

<style></style>
