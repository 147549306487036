<template>
  <div class="row">
    <div class="col-6">
      <div class="card card-success card-outline">
        <!-- card-header -->
        <div class="card-header">
          <h3 class="card-title">
            <i class="fa fa-clock-o"></i> Jednostki czasu harmonogramu
            <button type="button" class="btn btn-success btn-sm float-right" @click="addButton()"><i class="fa fa-fw fa-plus"></i> Dodaj</button>
          </h3>
        </div>
        <!-- /.card-header -->
        <div class="card-body" style="overflow: hidden">
          <div v-if="!timeUnits.length > 0" class="alert alert-success">Brak jednostek czasu</div>

          <ul class="todo-list">
            <li v-for="(timeUnit, index) in timeUnits" :key="index">
              {{ timeUnit.start | hminute }} - {{ (timeUnit.start + timeUnit.length) | hminute }}
              <div class="tools">
                <i title="Edytuj" class="fa fa-edit" @click="editButton(timeUnit)"></i>
                <i title="Usuń" class="fa fa-trash-o" @click="deleteButton(timeUnit)"></i>
              </div>
            </li>
          </ul>
          <div v-if="next">
            <br />
            <button v-if="next.start < 1440" @click="autoAdd()" class="btn btn-small btn-success pull-right">
              <i class="fa fa-plus fa-fw"></i>Szybko dodaj kolejną
              <small>{{ next.start | hminute }} - {{ (next.start + next.length) | hminute }}</small>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6"></div>

    <app-delete v-if="showDelete" :show="showDelete" :object="editObject" @cancel="cancel()" v-on:delete="del"></app-delete>
    <app-edit v-if="showEdit" :show="showEdit" v-on:cancel="cancel" v-on:reload="reload" :object="editObject"></app-edit>
    <app-add v-if="showAdd" :show="showAdd" @cancel="cancel" @reload="reload" :pstart="start" :pend="end"></app-add>
  </div>
</template>

<script>
import appDelete from '../../modules/Delete.vue';
import appEdit from './EditTimeunits.vue';
import appAdd from './TimeunitsAdd.vue';
export default {
  data() {
    return {
      timetableId: parseInt(this.$route.params.id),
      timeUnits: [],
      showDelete: false,
      showEdit: false,
      showAdd: false,
      editObject: '',
      start: '',
      end: '',
      next: null
    };
  },
  components: {
    appDelete,
    appEdit,
    appAdd
  },
  mounted() {
    this.timetableId = parseInt(this.$route.params.id);
    this.reload();
  },
  watch: {
    $route(to, from) {
      this.timetableId = parseInt(this.$route.params.id);
      this.reload();
    }
  },
  methods: {
    reload() {
      this.$http.get('timetable/listunits/' + this.timetableId).then((response) => {
        this.timeUnits = response.body.data;
        if (this.timeUnits.length > 0) {
          let last = this.timeUnits[this.timeUnits.length - 1];
          this.next = {
            start: last.start + last.length,
            length: last.length
          };
          if (this.timeUnits.length > 1) {
            //dodaj rózniecę mięczy ostatnim a przedostatnim
            this.next.start += last.start - (this.timeUnits[this.timeUnits.length - 2].start + this.timeUnits[this.timeUnits.length - 2].length);
          }
          this.start = this.$hminute(this.next.start, true);
          this.end = this.$hminute(this.next.start + this.next.length, true);
        } else {
          this.next = null;
          this.start = '08:00';
          this.end = '20:00';
        }
      });
    },
    cancel() {
      this.showDelete = false;
      this.showEdit = false;
      this.showAdd = false;
      this.editObject = {};
    },
    editButton(unit) {
      this.showEdit = true;
      this.editObject = unit;
    },
    addButton() {
      this.showAdd = true;
    },
    deleteButton(unit) {
      this.showDelete = true;
      this.editObject = {};
      this.editObject.id = unit.id;
      this.editObject.name = this.$hminute(unit.start) + ' - ' + this.$hminute(unit.start + unit.length);
    },
    del(id) {
      this.$http.get('timetable/deleteunit/' + id).then(
        (response) => {
          if (response && response.body && response.body.status == 1) {
            this.showDelete = false;
            this.reload();
            this.$komunikat('success', 'Sukces', 'Pomyślnie usunięto jednostke czasu', 3000);
          } else {
            this.$komunikat('error', 'Błąd', 'Błąd przy usuwaniu jednostki czasu', 3000);
          }
        },
        (response) => {
          //error
        }
      );
    },
    autoAdd() {
      //zobacz ostatnią

      let post = {
        start: this.next.start,
        timetableId: this.timetableId,
        length: this.next.length
      };
      this.$http.post('timetable/addunit', JSON.stringify(post), {}).then(
        (response) => {
          this.reload();
          if (response && response.body && response.body.status == 1) {
            this.reload();
            this.$komunikat('success', 'Sukces', 'Pomyślnie dodano jednostke czasu', 3000);
          } else {
            this.$komunikat('error', 'Błąd', 'Błąd przy dodawaniu jednostki czasu', 3000);
          }
        },
        (response) => {}
      );
      this.reload();
    }
  }
};
</script>

<style>
.HarmsItemBox {
  border: 1px solid #dedede;
  border-radius: 2px;
  margin-right: 5px;
  font-weight: 400;
  margin-bottom: 8px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.12);
  transition: all 0.2s;
}
</style>
