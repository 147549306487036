<template>
  <!-- Modal -->
  <b-modal v-model="showLocal" @hidden="$emit('cancel')">
    <h5 slot="modal-title">Dodaj klienta</h5>
    <div slot="modal-header-close">
      <button type="button" class="close" aria-label="Close" @click="$emit('cancel')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="box-body">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label :class="{'text-danger': $v.firstName.$anyError}" for="surname">Imię</label>
            <input :class="{'is-invalid': $v.firstName.$anyError}" class="form-control " id="surname" type="text" v-model.trim="$v.firstName.$model" />
            <small class="text-danger" v-if="$v.firstName.$anyError">Imię jest wymagane</small>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label :class="{'text-danger': $v.lastName.$anyError}" for="surname">Nazwisko</label>
            <input :class="{'is-invalid': $v.lastName.$anyError}" class="form-control " id="surname" type="text" v-model.trim="$v.lastName.$model" />
            <small class="text-danger" v-if="$v.lastName.$anyError">Nazwisko jest wymagane</small>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label :class="{'text-danger': $v.email.$anyError}" for="surname">Email</label>
            <input :class="{'is-invalid': $v.email.$anyError}" class="form-control " id="surname" type="text" v-model.trim="$v.email.$model" />
            <small class="text-danger" v-if="$v.email.$anyError">Błędny email</small>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="surname">Data urodzenia</label>
            <datepicker v-model="dateOfBirth" :language="pl" input-class="form-control" :full-month-name="true" format="dd-MM-yyyy"></datepicker>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label :class="{'text-danger': $v.pesel.$anyError}" for="surname">Pesel</label>
            <input :class="{'is-invalid': $v.pesel.$anyError}" class="form-control " id="surname" type="text" v-model.trim="$v.pesel.$model" />
            <small class="text-danger" v-if="$v.pesel.$anyError">Błędny pesel</small>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="surname">Płeć</label>
            <select class="form-control " id="surname" type="text" v-model="sex">
              <option value="M">M</option>
              <option value="K">K</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label :class="{'text-danger': $v.mobilePhone.$anyError}" for="surname">Nr telefonu</label>
            <input :class="{'is-invalid': $v.mobilePhone.$anyError}" class="form-control " id="surname" type="text" v-model.trim="$v.mobilePhone.$model" />
            <small class="text-danger" v-if="$v.mobilePhone.$anyError">Błędny numer telefonu</small>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal-footer -->
    <div slot="modal-footer">
      <button type="button" class="btn btn-default" data-dismiss="modal" @click="$emit('cancel')">Wyjdź</button>&nbsp;
      <button type="button" class="btn btn-info" data-dismiss="modal" @click="save()">Zapisz</button>
    </div>
    <!-- /Modal-footer -->
  </b-modal>
</template>

<script>
import {required, numeric, email, minLength, maxLength, helpers} from 'vuelidate/lib/validators';
import datepicker from 'vuejs-datepicker';
import {pl} from 'vuejs-datepicker/dist/locale';
function pesel(pesel) {
  return this.$pesel(pesel);
}

export default {
  components: {datepicker},
  name: 'appAdd',
  props: {
    show: Boolean,
    model: Object
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      dateOfBirth: '',
      mobilePhone: '',
      pesel: '',
      sex: '',
      pl: pl,
      showLocal: this.show,
      client: {...this.model}
    };
  },

  methods: {
    cancel() {
      this.$emit('cancel');
    },
    save() {
      if (this.$v.$invalid == true) {
        this.$v.$touch();
      } else {
        var client = {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          dateOfBirth: this.dateOfBirth,
          pesel: this.pesel,
          sex: this.sex,
          mobilePhone: this.mobilePhone,
          createDate: new Date(),
          verificationDate: new Date(),
          verificationInfo: 'informacja'
        };
        this.$http.post('cardowner/add', JSON.stringify(client), {}).then(
          response => {
            if (response && response.body && response.body.status == 1) {
              this.$emit('reload');
              this.$emit('cancel');
              this.$komunikat('success', 'Sukces', 'Pomyślnie dodano klienta', 3000);
            } else {
              this.$komunikat('error', 'Błąd', 'Błąd przy dodawaniu klienta', 3000);
            }
          },
          response => {}
        );
      }
    }
  },
  validations: {
    firstName: {
      required
    },
    lastName: {
      required
    },
    email: {
      email
    },
    pesel: {
      numeric,
      minLength: minLength(11),
      maxLength: maxLength(11),
      pesel
    },
    mobilePhone: {
      numeric
      //minLength: minLength(9)
    }
  },
  watch: {
    showLocal: function(val) {
      if (this.showLocal == false) {
        this.$emit('cancel');
      }
    }
  }
};
</script>

<style></style>
