import {Line} from 'vue-chartjs';

export default {
  extends: Line,
  props: ['data', 'names'],
  mounted() {
    this.renderChart(
      {
        labels: this.names,
        datasets: [
          {
            label: 'liczba sprzedanych usług',
            backgroundColor: '#17a2b8',
            data: this.data
          }
        ]
      },
      {responsive: true, maintainAspectRatio: false}
    );
  },
  watch: {
    data: function() {
      this.renderChart(
        {
          labels: this.names,
          datasets: [
            {
              label: 'liczba sprzedanych usług',
              backgroundColor: '#17a2b8',
              data: this.data
            }
          ]
        },
        {responsive: true, maintainAspectRatio: false}
      );
    }
  }
};
