<template>
  <!-- Modal -->
  <b-modal v-model="showLocal" @hidden="$emit('cancel')">
    <h5 slot="modal-title">Edytuj jednostke czasu</h5>
    <div slot="modal-header-close">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$emit('cancel')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="box-body">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label :class="{'text-danger': $v.start.$anyError || $v.end.$anyError}" for="surname">Godzina otwarcia</label>
            <input
              class="form-control"
              type="time"
              :class="{'form-control is-invalid': $v.start.$anyError || $v.end.$anyError, 'form-control': !($v.start.$anyError || $v.end.$anyError)}"
              v-model.trim="$v.start.$model"
            />
            <small v-if="$v.start.$anyError || $v.end.$anyError" class="text-danger">Proszę poprawnie wpełnić pole</small>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label :class="{'text-danger': $v.start.$anyError || $v.end.$anyError}" for="surname">Godzina zamknięcia</label>
            <input
              class="form-control"
              type="time"
              :class="{'form-control is-invalid': $v.start.$anyError || $v.end.$anyError, 'form-control': !($v.start.$anyError || $v.end.$anyError)}"
              v-model.trim="$v.end.$model"
            />
            <small v-if="$v.start.$anyError || $v.end.$anyError" class="text-danger">Proszę poprawnie wpełnić pole</small>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal-footer -->
    <div slot="modal-footer">
      <button type="button" class="btn btn-default" data-dismiss="modal" @click="$emit('cancel')">Wyjdź</button>&nbsp;
      <button type="button" class="btn btn-success" data-dismiss="modal" @click="update()">Zapisz</button>
    </div>
    <!-- /Modal-footer -->
  </b-modal>
</template>

<script>
function hourStart(hour) {
  return this.$hourValidate(hour, this.end);
}
function hourEnd(hour) {
  return this.$hourValidate(this.start, hour);
}
export default {
  name: 'appEdit',
  props: ['show', 'timetableId', 'object'],
  data() {
    return {
      showLocal: this.show,
      start: this.$hminute(this.object.start, true),
      end: this.$hminute(this.object.start + this.object.length, true)
    };
  },
  validations: {
    start: {
      hourStart
    },
    end: {
      hourEnd
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },

    update() {
      let post = {
        start: this.$mhour(this.start),
        timetableId: this.object.timetableId,
        length: this.$mhour(this.end) - this.$mhour(this.start),
        id: this.object.id
      };

      this.$http.post('timetable/updateunit', JSON.stringify(post), {}).then(
        response => {
          if (response && response.body && response.body.status == 1) {
            this.$emit('cancel');
            this.$emit('reload');
            this.$komunikat('success', 'Sukces', 'Pomyślnie edytowano jednostke czasu', 3000);
          } else {
            this.$komunikat('error', 'Błąd', 'Błąd przy edytowaniu jednostki czasu', 3000);
          }
        },
        response => {}
      );
    }
  },
  watch: {
    showLocal: function(val) {
      if (this.showLocal == false) {
        this.$emit('cancel');
      }
    },
    start(nextvalue, prevvalue) {
      if (this.end) {
        let startp = this.$mhour(prevvalue);
        let startn = this.$mhour(nextvalue);
        let endi = this.$mhour(this.end);
        let diff = startn - startp;
        endi += diff;
        if (endi < 1440) {
          this.end = this.$hminute(endi, true);
        }
      }
    }
  }
};
</script>

<style>
#error {
  color: red;
}
</style>
