<template>
  <div class="content-wrapper" style="min-height: 600px">
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-4">
            <h1 class="m-0 text-dark">
              {{ title.elems[title.elems.length - 1] ? title.elems[title.elems.length - 1].title : '[brak tytułu]' }}
              <span v-if="$store.state.title"> - {{ $store.state.title }}</span>
            </h1>
          </div>
          <!-- /.col -->
          <div class="col-sm-8">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item" v-if="currentRoute.name != 'home'">
                <router-link to="/" id="breadcrumb_home">Strona główna</router-link>
              </li>
              <li class="breadcrumb-item" v-for="(item, index) in title.elems" :class="{active: index + 1 == title.length}" :key="index">
                <router-link v-if="item.name && item.name.length" :to="{name: item.name, params: item.params}">{{ item.title }}</router-link>
                <template v-if="!item.name || !item.name.length">{{ item.title }}</template>
              </li>
            </ol>
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <section class="content">
      <router-view class="view" keep-alive transition transition-mode="out-in"> </router-view>
    </section>
  </div>
</template>

<script>
import config from '../../config';
export default {
  name: 'Content',
  data() {
    return {
      _data: {},
      title: {
        elems: []
      },
      currentRoute: {}
    };
  },
  mounted() {
    this.currentRoute = this.$route;
    this.cp(this.$route.meta.title);
    this.calcuateTitle(this.$route);
  },
  created() {
    //pobiera dane o zalogowanym user
    this.$store.dispatch('getUser');
  },
  methods: {
    cp(obj) {
      if (typeof obj === 'string') {
        this.tmpTitle = obj;
      } else {
        let tmp = {bc: [], when: []};

        if (obj.bc) {
          tmp.bc = obj.bc.map((item) => Object.assign({}, item));
        }
        if (obj.when) {
          tmp.when = obj.when.map((item) => Object.assign({}, item));
        }
        this.tmpTitle = tmp;
      }
    },
    calcuateTitle(to, noNeedRecurse) {
      let title = undefined;
      if (typeof this.tmpTitle === 'string') {
        title = this.tmpTitle;
      } else {
        title = {...this.tmpTitle};
      }
      let tmp = [];
      let calc = (elem, params, recurse) => {
        let obj = {title: '...'};
        let index = tmp.length;
        tmp.push(obj);
        if (typeof elem === 'string') {
          obj.title = elem;
        } else {
          if (elem.asyncTitle) {
            let arr = elem.asyncTitle.split(':');
            let key = `${arr[0]}:${params[arr[1]]}:${arr[2]}`;
            if (recurse && this._data[`recurse_${key}`]) {
              let recs = this._data[`recurse_${key}`];
              tmp = tmp.filter((x) => x !== obj);
              recs.forEach((x) => {
                let p = {};
                p[arr[1]] = x.split(':')[1];
                tmp.push({
                  name: elem.name,
                  params: p,
                  title: this._data[x]
                });
              });
            } else if (this._data[key]) {
              obj.title = this._data[key];
            } else {
              if (arr.length === 3) {
                // debugger;
                let ondata = (data) => {
                  let strs = [];
                  if (data) {
                    let fields = arr[2].split(',');
                    fields.forEach((x) => {
                      strs.push(data[x]);
                    });
                    if (recurse && data[recurse]) {
                      let path = data[recurse] + '.' + data.id;
                      let barray = title.bc.slice(0, title.bc.indexOf(elem));
                      let marray = [];
                      let aarray = title.bc.slice(title.bc.indexOf(elem) + 1, title.bc.length);
                      path.split('.').forEach((x) => {
                        marray.push({
                          title: elem.title,
                          asyncTitle: elem.asyncTitle,
                          name: elem.name,
                          to: {
                            id: x
                          },
                          params: elem.params
                        });
                      });
                      title = barray.concat(marray).concat(aarray);

                      if (marray.length) {
                        let keys = marray.map((x) => `${arr[0]}:${x.to.id}:${arr[2]}`);
                        this._data[`recurse_${key}`] = keys;
                      }

                      if (this.tmpTitle.length != title.length) {
                        this.tmpTitle.bc = title;
                        this.calcuateTitle(to, true);
                      }
                    }
                    this._data[key] = strs.join(' ') || elem.title;
                    obj.title = this._data[key];
                  } else {
                    this._data[key] = elem.title;
                    obj.title = this._data[key];
                  }
                };

                let obj2 = null;
                /*
                if (elem.asyncTitle === 'object:id:name') {
                  let allObjects = this.$store.state.allObjects;
                  obj2 = allObjects[params.id];
                  if (obj2) {
                    debugger;
                    ondata(obj2);
                  }
                }
                */
                if (!obj2) {
                  this.$http.get(arr[0] + '/get?' + arr[1] + '=' + params[arr[1]]).then((response) => {
                    debugger;
                    ondata(response.data.data);
                  });
                }
                // .catch(() => {
                //   this._data[key] = elem.title;
                //   obj.title = this._data[key];
                // });
              }
            }
          } else {
            obj.title = elem.title;
          }
          obj.name = elem.name;

          if (elem.params) {
            obj.params = {};
            for (var item of elem.params) {
              obj.params[item] = params[item];
            }
          }
        }
      };

      if (title.bc && title.bc.length) {
        title.bc.forEach((x) => calc(x, x.to ? x.to : to.params, noNeedRecurse ? false : x.recurse));
      }
      if (typeof title === 'string') {
        calc(title, to.params);
      } else if (title) {
        if (title.when) {
          title.when.forEach((item) => {
            if (item.param && to.params[item.param]) {
              let value = to.params[item.param];
              if (value === item.equals) {
                value = item.then;
              } else if (item.else) {
                value = item.else;
              }
              calc(value, to.params);
            }
          });
        }
      }
      document.title = (tmp[tmp.length - 1] ? tmp[tmp.length - 1].title : '') + ' - ' + config.current.title;
      this.title.elems = tmp;
    }
  },
  watch: {
    $route(to, from) {
      this.currentRoute = to;
      this.cp(to.meta.title);
      this.calcuateTitle(to);
      this.show = false;
      if (this.$store.state.user.role == '') {
        this.$store.dispatch('getUser');
      }
    }
  }
};
</script>

<style></style>
