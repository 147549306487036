<template>
  <div v-if="error" class="login-box">
    <div class="login-logo">
      <img :src="'/img/logo/' + $config.current.logo" />
    </div>
    <div class="card bg-danger ">
      <div class="card-body">
        Nie udało się zalogować do podmiotu
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      error: false
    };
  },
  methods: {
    checkKey() {
      this.$http.post('account/loginkey', JSON.stringify({key: this.$route.params.key})).then(response => {
        if (response.body.status !== 0) {
          localStorage.setItem('apikey', response.data.data.apikey);
          // this.$router.push({ name: "home" });

          this.$router.push({name: 'UsersProfile', params: {id: response.data.data.id}});
        } else {
          this.error = true;
        }
      });
    }
  },
  mounted() {
    this.checkKey();
  },
  watch: {
    $route(to, from) {
      this.checkKey();
    }
  }
};
</script>
<style scoped>
img {
  filter: brightness(2) invert(1);
}
</style>
