<template>
  <div class="login-box">
    <div class="login-logo">
      <img :src="'/img/logo/' + $config.current.logo" />
    </div>
    <div v-if="adminLogin" class="text-center">LOGOWANIE ADMINISTRATORA</div>
    <div class="card">
      <div class="card-body login-card-body">
        <form v-on:submit="login">
          <div v-if="!adminLogin" class="form-group has-feedback">
            <input id="nr_karty" v-model="input.username" type="text" class="form-control" placeholder="Numer karty" />
          </div>

          <div v-if="!adminLogin && selected == 'pesel'" class="form-group has-feedback">
            <input id="pesel" v-model="input.password" type="text" class="form-control" placeholder="Pesel" />
          </div>
          <div id="data" v-if="!adminLogin && selected == 'date'" class="form-group has-feedback">
            <datepicker
              :language="pl"
              :input-class="'form-control'"
              :full-month-name="true"
              format="dd-MM-yyyy"
              v-model="input.password"
              placeholder="Data urodzenia "
            ></datepicker>
          </div>
          <b-form-group v-if="!adminLogin">
            <b-form-radio-group id="btnradios1" v-model="selected" :options="options" size="sm" name="radiosBtnDefault" />
          </b-form-group>

          <div v-if="adminLogin" class="form-group has-feedback">
            <input id="Login" v-model="input.username" type="text" class="form-control" placeholder="Login" />
          </div>
          <div v-if="adminLogin" class="form-group has-feedback">
            <input id="haslo" v-model="input.password" type="password" class="form-control" placeholder="Hasło" />
          </div>
          <div class="row">
            <div class="col-7">
              <div class="checkbox icheck">
                <label> <input type="checkbox" /> Zapamiętaj mnie </label>
              </div>
            </div>
            <!-- /.col -->
            <div class="col-5">
              <button class="btn btn-primary btn-block btn-flat">Zaloguj się</button>
            </div>
            <!-- /.col -->
          </div>

          <span id="error" v-if="error">{{ error_text }}</span>
        </form>

        <div class="social-auth-links text-center mb-3" v-if="!adminLogin || $config.current.clientLogin">
          <div v-if="!adminLogin">
            <p>- nie masz konta ? - -</p>
            <router-link v-if="!adminLogin" :to="{path: '/create'}" class="btn btn-block btn-primary"> <i class="nav-icon fa fa-users"></i>&nbsp;Utwórz konto</router-link>
            <p>&nbsp;</p>
          </div>
          <p>- lub -</p>

          <router-link v-if="!adminLogin" :to="{path: '/login/admin', query: $route.query}" class="btn btn-block btn-primary">
            <i class="nav-icon fa fa-address-card"></i>&nbsp;Logowanie dla administratora</router-link
          >
          <router-link v-if="adminLogin && $config.current.clientLogin" :to="{path: '/login', query: $route.query}" class="btn btn-block btn-primary">
            <i class="nav-icon fa fa-users fa-fw-0"></i>&nbsp;Logowanie dla klienta</router-link
          >
        </div>
      </div>
      <!-- /.login-card-body -->
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import {pl} from 'vuejs-datepicker/dist/locale';
import methods from '@/methods';
import websocket from '@/websocket';

export default {
  name: 'Login',
  data() {
    let adm = false;
    if (!this.$config.current.clientLogin) {
      adm = true;
    }

    return {
      adminLogin: adm,
      input: {
        username: '',
        password: ''
      },
      pl,
      options: [
        {text: 'Pesel', value: 'pesel'},
        {text: 'Data urodzenia', value: 'date'}
      ],
      selected: 'pesel',
      error: false,
      error_text: ''
    };
  },
  components: {
    Datepicker
  },
  methods: {
    login(event) {
      event.preventDefault();
      if (this.input.username === '' || this.input.password === '') {
        this.error = true;
        this.input.username = '';
        this.input.password = '';
        this.error_text = 'Pola są puste';
      } else {
        if (this.adminLogin === true) {
          this.$http.post('account/login', JSON.stringify({email: this.input.username, password: this.input.password}), {}).then(
            (response) => {
              if (response.body.status !== 0) {
                localStorage.setItem('apikey', response.body.data.apikey);

                // methods.winsockStart(response.body.data.apikey);
                // websocket.start(response.body.data.apikey);

                if (this.$route.query.redirect) {
                  this.$router.push(this.$route.query.redirect);
                } else {
                  this.$router.push({name: 'home'});
                }
                // uruchom winsock
                // winsockstar
              } else {
                this.error = true;
                this.input.username = '';
                this.input.password = '';
                this.error_text = 'Zły login lub hasło';
              }
            }
            /*
            ,
            response => {}
            */
          );
        } else {
          if (this.selected === 'date') {
            this.input.password = new Date(this.$fdate(this.input.password));
          }
          this.$http.post('account/logincard', JSON.stringify({cardNumber: this.input.username, peseldt: this.input.password})).then((response) => {
            if (response.body.status !== 0) {
              localStorage.setItem('apikey', response.body.data.apikey);
              if (this.$route.query.redirect) {
                this.$router.push(this.$route.query.redirect);
              } else {
                this.$router.push({name: 'home'});
              }
            } else {
              this.error = true;
              this.input.username = '';
              this.input.password = '';
              this.error_text = 'Nieprawidłowy użytkownik';
            }
          });
        }
      }
    }
  },
  mounted() {
    if (this.$route.params.admin === 'admin') {
      this.adminLogin = true;
    }
  },
  watch: {
    $route(to, from) {
      if (this.$route.params.admin === 'admin') {
        this.adminLogin = true;
      } else {
        this.adminLogin = false;
      }
    },
    adminLogin(val) {
      this.error = false;
      this.input.username = '';
      this.input.password = '';
    },
    selected(val) {
      this.input.password = '';
    }
  }
};
</script>

<style scoped>
img {
  filter: brightness(2) invert(1);
}
#error {
  color: red;
}
</style>
