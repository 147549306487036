<template>
  <div class="row">
    <div class="col-12">
      <div class="card card-outline" v-bind:class="{'card-success': editButtons != 'reservation', 'card-danger': editButtons == 'reservation'}">
        <div class="card-header">
          <h3 class="card-title" v-if="this.parentID == 0">
            <span v-if="editButtons != 'reservation'">Lista obiektów nadrzędnych</span>
            <span v-else>Wybierz obiekt</span>
            <loading-icon v-if="loading"></loading-icon>&nbsp;
          </h3>
          <h3 class="card-title" v-if="this.parentID != 0">
            <span v-if="editButtons != 'reservation'">Lista podobiektów</span>
            <span v-else>Wybierz obiekt</span>&nbsp;
            <button
              type="button"
              class="btn btn-sm"
              v-bind:class="{'btn-success': editButtons != 'reservation', ' btn-danger': editButtons == 'reservation'}"
              @click="more(true, parent)"
            >
              <i class="fa fa-angle-up"></i>
            </button>
            <loading-icon v-if="loading"></loading-icon>&nbsp;
          </h3>
          <app-search :color="colorClass()" :post="post" @reload="reload" @post="post = $event"></app-search>
        </div>
        <div class="card-body p-0">
          <div class="mailbox-controls clientitem top" style="height: 90px">
            <app-pages
              style="padding-bottom: 0px"
              :filteredCount="filteredCount"
              :maxcount="maxcount"
              :colorActive="'#fff'"
              :backgroundActive="kolor"
              :post="post"
              @post="post = $event"
              @reload="reload()"
            >
              <button
                v-if="editButtons != 'reservation' && this.$hasRole('admin')"
                type="button"
                class="btn btn-success"
                @click="
                  clickedParentID = $route.params.id;
                  showAdd = true;
                "
              >
                <i class="fa fa-fw fa-plus"></i>Dodaj obiekt
              </button>
            </app-pages>
          </div>
          <div class="table-responsive mailbox-messages">
            <div
              class="alert alert-success text-center"
              v-bind:class="{'alert-success': editButtons != 'reservation', 'alert-danger': editButtons == 'reservation'}"
              v-if="!objects.length"
            >
              Brak obiektów
            </div>
            <div v-for="(object, index) in objects" :key="object.id">
              <div class="clientitem">
                <div class="mailbox-name">
                  <b v-if="!object.hasChildren">{{ object.name }}</b>

                  <b v-else id="ObjectLink" @click="more(object.hasChildren, object.id)">{{ object.name }}</b>
                  <i v-if="object.canCardOwnerReservation" class="fa fa-users" style="margin-left: 5px; color: gray" title="Może być rezerowany przez osoby fizyzczne"></i>
                  &nbsp;
                  <button class="btn btn-default btn-sm" v-if="object.hasChildren" @click="more(object.hasChildren, object.id)">
                    <i class="fa fa-sitemap"></i>
                  </button>
                </div>
                <div>
                  <span class="clientspan" v-if="object.description">
                    <i class="fa fa-align-left fa-fw"></i>
                    {{ object.description }}
                  </span>
                  <span class="clientspan" v-if="object.clientsUsageCount">
                    <i class="fa fa-users fa-fw"></i>
                    aktualnie na obiekcie: {{ object.clientsUsageCount }}
                  </span>
                </div>
                <div class="itembuttons menu">
                  <div class="btn-group">
                    <router-link
                      :to="{name: 'Timetable', params: {id: object.id}}"
                      title="Zobacz harmonogram "
                      class="btn btn-success"
                      v-if="editButtons != 'reservation' && hasRole('admin')"
                    >
                      <i class="fa fa-fw fa-columns fa-lg"></i>
                    </router-link>

                    <router-link
                      :to="{name: 'ObjectReservations', params: {id: object.id}}"
                      title="Dodaj rezerwacje "
                      v-bind:class="{'btn btn-success': editButtons != 'reservation', 'btn btn-danger': editButtons == 'reservation'}"
                      v-if="hasPerm(object)"
                    >
                      <i v-if="editButtons != 'reservation'" class="fa fa-fw fa-lg fa-address-book"></i>
                      <span v-else>
                        <b>Wybierz</b>
                      </span>
                    </router-link>
                    <router-link :to="{name: 'Timeline', params: {id: object.id}}" title="Zobacz terminarz " class="btn btn-success" v-if="editButtons != 'reservation'">
                      <i class="fa fa-fw fa-lg fa-calendar"></i>
                    </router-link>
                    <router-link
                      :to="{name: 'ObjectRaports', params: {id: object.id}}"
                      title="Raport obiektu "
                      class="btn btn-success"
                      v-if="editButtons != 'reservation' && hasRole('admin') && hasPerm(object)"
                    >
                      <i class="fa fa-fw fa-bars fa-lg"></i>
                    </router-link>
                  </div>
                  &nbsp;
                  <div class="btn-group" v-if="hasRole('admin')">
                    <template v-if="editButtons != 'reservation'">
                      <button class="btn btn-success btn-sm" title="Dodaj podobiekt do obiektu " data-toggle="modal " @click="save2(object.id)" v-if="hasPerm(object)">
                        <i class="fa fa-fw fa-plus"></i>
                      </button>
                      <button type="button " class="btn btn-primary btn-sm" title="Edytuj" data-toggle="modal " @click="editButton(index)" v-if="hasPerm(object)">
                        <i class="fa fa-fw fa-edit"></i>
                      </button>
                      <button class="btn btn-danger btn-sm" title="Usuń" @click="deleteButton(object)" v-if="hasPerm(object)">
                        <i class="fa fa-fw fa-trash"></i>
                      </button>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer p-0">
          <div class="mailbox-controls">
            <app-pages
              style="padding-right: 15px"
              :filteredCount="filteredCount"
              :maxcount="maxcount"
              :colorActive="'#fff'"
              :backgroundActive="kolor"
              :post="post"
              @post="post = $event"
              @reload="reload()"
            ></app-pages>
          </div>
        </div>
      </div>
    </div>
    <app-edit v-if="showEdit" :show="showEdit" :object="edit.object" v-on:cancel="cancel" @reload="reload"></app-edit>
    <app-delete v-if="showDelete" :show="showDelete" v-on:cancel="cancel" v-on:delete="deleteItem" :object="objectDelete"></app-delete>
    <app-add
      v-if="showAdd"
      :show="showAdd"
      @name="name = $event"
      @maxPersonCount="maxPersonCount = $event"
      @maxReservationCount="maxReservationCount = $event"
      @description="description = $event"
      @save="save()"
      v-on:cancel="cancel"
    ></app-add>
  </div>
</template>
<script>
import appAdd from './Add.vue';
import appEdit from './Edit.vue';
import appDelete from '../modules/Delete.vue';
import loadingIcon from '../modules/LoadingIcon.vue';
import appPages from '../modules/Pages.vue';
import routeMixin from '../../routemixin';
import appSearch from '../modules/Search.vue';

export default {
  mixins: [routeMixin],
  components: {
    appAdd,
    appEdit,
    loadingIcon,
    appDelete,
    appPages,
    appSearch
  },
  data() {
    return {
      object: [],
      objects: [],

      parentID: this.$route.params.id,
      EditParentID: this.$route.params.id,
      name: '',
      parent: 0,
      description: '',
      maxPersonCount: '',
      maxReservationCount: '',
      object: '',
      loading: '',
      edit: {
        object: {}
      },
      path: '',
      objectDelete: {},
      clickedParentID: 0,
      showEdit: false,
      showAdd: false,
      showDelete: false,
      editButtons: '',
      filteredCount: 0,
      maxcount: 0,
      kolor: '#28a745',
      post: {parentId: this.$route.params.id, q: '', reservationStatus: 0, count: 10, skip: 0, page: 1, draw: 1},
      second: false
    };
  },
  methods: {
    save2(id) {
      this.EditParentID = id;
      this.showAdd = true;
      this.second = true;
    },
    save() {
      this.EditParentID = parseInt(this.EditParentID);
      this.object = {
        name: this.name,
        parentId: this.EditParentID,
        description: this.description,
        maxPersonCount: this.maxPersonCount,
        maxReservationCount: this.maxReservationCount,
        hasChildren: false,
        price: 0
      };
      if (this.path) {
        if (this.second == true) {
          this.object.path = this.path + '.' + this.parentID + '.' + this.EditParentID;

          this.second = false;
        } else {
          this.object.path = this.path + '.' + this.EditParentID;
        }
      } else {
        if (this.second == true) {
          this.object.path = this.parentID + '.' + this.EditParentID;

          this.second = false;
        } else {
          this.object.path = this.EditParentID;
        }
      }
      this.EditParentID = this.parentID;
      this.showAdd = true;
      this.$http.post('object/add', JSON.stringify(this.object), {}).then(
        (response) => {
          if (response && response.body && response.body.status == 1) {
            this.showAdd = false;
            this.object.id = response.data;
            this.$emit('send', this.object);
            this.$store.dispatch('getObjects');
            this.reload();
            this.description = '';
            this.name = '';
            this.$komunikat('success', 'Sukces', 'Pomyślnie dodano obiekt', 3000);
          } else {
            this.$komunikat('error', 'Błąd', 'Błąd przy dodawaniu obiektu', 3000);
          }
        },
        (response) => {}
      );
    },
    reload() {
      this.loading = true;
      if (this.status == '1') {
        this.post.reservationStatus = 1;
      } else if (this.status == '0') {
        this.post.reservationStatus = 0;
      } else {
        this.post.reservationStatus = '';
      }
      this.$http.post('object/find', JSON.stringify(this.post), {}).then((response) => {
        //this.$post("object/find", this.post).then(response => {
        this.objects = response.data.data.data;
        this.filteredCount = response.data.data.filteredCount;
        this.maxcount = response.data.data.count;
        // this.$router.push({
        //   query: {
        //     reservationStatus: this.post.reservationStatus
        //   }
        // });
        this.loading = false;
      });
      if (!this.parentID) {
        this.parentID = 0;
      }
      this.$http.get('object/get/' + this.parentID).then((response) => {
        if (response.data.data == null || response.data.data == undefined) {
          this.parent = 0;
          this.path = '';
        } else {
          this.path = response.data.data.path;
          this.parent = response.data.data.parentId;
        }
      });
    },
    reloadLink() {
      this.post.q = this.$route.query.q;
      this.post.page = this.$route.query.page;
      this.post.count = this.$route.query.count;
      this.post.skip = this.$route.query.skip;
      this.post.reservationStatus = this.$route.query.reservationStatus;
      this.post.parentId = this.$route.params.id;
      if (!this.post.count) {
        this.post.count = 50;
      }
      if (!this.post.skip) {
        this.post.skip = 0;
      }
      if (!this.post.page) {
        this.post.page = 1;
      }
      if (!this.post.parentId) {
        this.post.parentId = 0;
      }
    },
    editButton(index) {
      this.edit.object = this.objects[index];
      this.showEdit = true;
    },
    colorClass() {
      if (this.editButtons != 'reservation') {
        return 'btn-success';
      } else {
        return 'btn-danger';
      }
    },
    deleteItem(id) {
      this.$http.get('object/delete/' + id).then(
        (response) => {
          if (response && response.body && response.body.status == 1) {
            this.objects.splice(id, 1);
            this.reload();
            this.showDelete = false;
            this.$komunikat('success', 'Sukces', 'Pomyślnie usunięto obiekt', 3000);
          } else {
            this.$komunikat('error', 'Błąd', 'Błąd przy usuwaniu obiektu', 3000);
          }
        },
        (response) => {
          //error
        }
      );
    },
    deleteButton(object) {
      this.showDelete = true;
      this.objectDelete = object;
    },
    hasRole(role) {
      return this.$hasRole(role);
    },
    hasPerm(object) {
      return this.$hasPerm(object.id, object.path);
    },
    more(hasChildren, id) {
      if (hasChildren) {
        if (id) {
          this.post.parentId = id;
        } else {
          this.post.parentId = 0;
        }
        if (this.editButtons != 'reservation') {
          this.$router.push({name: 'ObjectsList', params: {id: this.post.parentId, edit: this.editButtons}});
        } else {
          this.$router.push({
            name: 'ReservationsObjectsList',
            params: {id: this.post.parentId, edit: this.editButtons}
          });
        }
        this.parentID = id;
      }
    },

    cancel() {
      this.showAdd = false;
      this.showEdit = false;
      this.showDelete = false;
    }
  },
  created() {
    this.editButtons = this.$route.params.edit;

    this.reloadLink();
    this.reload();
    if (this.editButtons != 'reservation') {
      this.kolor = '#28a745';
    } else {
      this.kolor = '#dc3545';
    }
  },
  watch: {
    $route(to, from) {
      this.parentID = this.$route.params.id;
      this.post.parentId = this.$route.params.id;
      this.EditParentID = this.$route.params.id;
      this.editButtons = this.$route.params.edit;
      if (this.editButtons != 'reservation') {
        this.kolor = '#28a745';
      } else {
        this.kolor = '#dc3545';
      }
    },
    parentID: function (value) {
      this.reload();
    }
  }
};
</script>
<style scoped>
#ObjectLink:hover {
  text-decoration: underline;
  cursor: pointer;
}
</style>
