<template>
  <div class="vue-drag-select" @mousedown="onMouseDown">
    <slot :selectedItems="selectedItems" />
    <div v-if="mouseDown" class="vue-drag-select-box" :style="selectionBoxStyling"></div>
  </div>
</template>

<script>
export default {
  name: 'vue-drag-select',
  props: {
    selectorClass: {
      type: String,
      required: true
    },
    object: Object,
    timetable: Object,
    oldObject: Number,
    enabled: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      mouseDown: false,
      startPoint: null,
      endPoint: null,
      selectedItems: [],
      y: 0
    };
  },
  computed: {
    selectionBox() {
      // Only set styling when necessary
      let fix = 0;
      let fix2 = 0;
      if (!this.mouseDown || !this.startPoint || !this.endPoint) return {};
      const clientRect = this.$el.getBoundingClientRect();
      const scroll = this.getScroll();

      const left = Math.min(this.startPoint.x, this.endPoint.x);

      const top = Math.min(this.startPoint.y, this.endPoint.y);
      const width = Math.abs(this.startPoint.x - this.endPoint.x);
      const height = Math.abs(this.startPoint.y - this.endPoint.y);

      /*
      // Calculate position and dimensions of the selection box
      const left = Math.min(this.startPoint.x, this.endPoint.x) - clientRect.left - scroll.x;
      if (this.startPoint.y > this.endPoint.y) {
        if (scroll.y != this.y) {
          fix = scroll.y - this.y;
          fix2 = this.y - scroll.y;
        } else {
          fix = 0;
          fix2 = 0;
        }
      }
      const top = Math.min(this.startPoint.y, this.endPoint.y) - clientRect.top - scroll.y + this.y + fix;
      const width = Math.abs(this.startPoint.x - this.endPoint.x);
      const height = Math.abs(this.startPoint.y - this.endPoint.y) + scroll.y - this.y + 2 * fix2;

      */
      // Return the styles to be applied
      return {
        left,
        top,
        width,
        height
      };
    },
    selectionBoxStyling() {
      // Only set styling when necessary
      if (!this.mouseDown || !this.startPoint || !this.endPoint) return {};

      const {left, top, width, height} = this.selectionBox;
      // Return the styles to be applied
      return {
        left: `${left}px`,
        top: `${top}px`,
        width: `${width}px`,
        height: `${height}px`
      };
    }
  },
  watch: {
    enabled(val) {
      //console.log("enabled",val);
      if (val == false) {
        this.selectedItems = [];
        this.$emit('change', []);
        this.$emit('selected', {}, '');
      }
    },
    selectedItems(val) {
      this.$emit('change', val);
    },
    oldObject(val) {
      if (this.oldObject != this.object.id) {
        this.selectedItems = [];
      }
    },
    $route(to, from) {
      this.selectedItems = [];
      this.$emit('selected', {}, '');
      this.$emit('display', false);
    }
  },
  methods: {
    getScroll() {
      // If we're on the server, default to 0,0
      if (typeof document === 'undefined') {
        return {
          x: 0,
          y: 0
        };
      }

      return {
        x: this.$el.scrollLeft || document.body.scrollLeft || document.documentElement.scrollLeft,
        y: this.$el.scrollTop || document.body.scrollTop || document.documentElement.scrollTop
      };
    },
    onMouseDown(event) {
      // Ignore right clicks
      if (event.button === 2) return;
      if (!this.enabled) return;

      // Register begin point
      this.mouseDown = true;
      const scroll = this.getScroll();
      const clientRect = this.$el.getBoundingClientRect();
      /*
      const point = {
        x: event.pageX,
        y: event.pageY
      };
      */
      const point = {
        x: event.clientX,
        y: event.clientY
      };
      // debugger;
      // point.y -= this.$el.offsetTop;
      // point.y += scroll.y;
      point.y -= clientRect.top;
      point.x -= clientRect.left;

      console.log('startPoint', JSON.stringify(point), 'scroll', JSON.stringify(scroll));
      this.startPoint = point;
      this.$emit('selected', this.object, '', this.timetable || this.object.timetable);
      this.onMouseMove(event);
      this.$emit('display', true);
      // Start listening for mouse move and up events
      window.addEventListener('mousemove', this.onMouseMove);
      window.addEventListener('mouseup', this.onMouseUp);
    },
    onMouseMove(event) {
      // Update the end point position
      if (this.mouseDown) {
        this.endPoint = {
          x: event.pageX,
          y: event.pageY
        };

        const point = {
          x: event.clientX,
          y: event.clientY
        };
        const clientRect = this.$el.getBoundingClientRect();
        point.y -= clientRect.top;
        point.x -= clientRect.left;
        this.endPoint = point;

        const children = this.$children.length ? this.$children : this.$el.children;

        if (children) {
          this.selectedItems = Array.from(children).filter((item) => {
            return this.isItemSelected(item.$el || item);
          });
        }
      }
    },
    onMouseUp(event) {
      // Clean up event listeners
      window.removeEventListener('mousemove', this.onMouseMove);
      window.removeEventListener('mouseup', this.onMouseUp);

      // Reset state
      this.mouseDown = false;
      this.startPoint = null;
      this.endPoint = null;

      let timeUnits = (this.timetable || this.object.timetable).timeunits;
      let indexEnd = this.selectedItems[this.selectedItems.length - 1].id;
      let indexStart = this.selectedItems[0].id;
      let item = {
        start: timeUnits[indexStart].start,
        length: timeUnits[indexEnd].start + timeUnits[indexEnd].length - timeUnits[indexStart].start
      };

      this.$emit('selected', this.object, item);
    },
    isItemSelected(el, item) {
      if (el.classList.contains(this.selectorClass)) {
        const boxA = this.selectionBox;
        const boxB = {
          top: el.offsetTop,
          left: el.offsetLeft,
          width: el.clientWidth,
          height: el.clientHeight
        };

        let inside = !!(boxA.left <= boxB.left + boxB.width && boxA.left + boxA.width >= boxB.left && boxA.top <= boxB.top + boxB.height && boxA.top + boxA.height >= boxB.top);
        // console.log('inside', boxA, boxB, inside);

        return inside;
      }

      return false;
    }
  },
  mounted() {
    this.$children.forEach((child) => {
      child.$on('click', (event) => {
        const included = this.selectedItems.find((item) => {
          return child.$el === item.$el;
        });

        if (included) {
          this.selectedItems = this.selectedItems.filter((item) => {
            return child.$el !== item.$el;
          });
        } else {
          this.selectedItems.push(child);
        }
      });
    });
  },
  beforeDestroy() {
    // Remove event listeners
    window.removeEventListener('mousemove', this.onMouseMove);
    window.removeEventListener('mouseup', this.onMouseUp);

    this.$children.forEach((child) => {
      child.$off('click');
    });
  }
};
</script>

<style>
.vue-drag-select {
  position: relative;
  user-select: none;
}

.vue-drag-select-box {
  position: absolute;

  z-index: 99;
}
</style>
