<template>
  <!-- Modal -->
  <b-modal v-model="showLocal" @hidden="close()">
    <h5 v-if="option == 'cancel'" slot="modal-title">Anuluj rezerwacje ({{ picked.length }})</h5>
    <h5 v-if="option == 'add'" slot="modal-title">Przywróć rezerwacje</h5>
    <h5 v-if="option == 'confirm'" slot="modal-title">Potwierdź rezerwacje ({{ picked.length }})</h5>

    <div slot="modal-header-close">
      <button type="button" class="close" aria-label="Close" @click="close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="box-body">
      <div v-if="option == 'cancel'" class="form-group">
        <div class="form-check">
          <label class="form-check-label"><input class="form-check-input" type="radio" v-model="newStatus" :value="0" />Anulowanie przez podmiot</label>
        </div>
        <div class="form-check" v-if="isAdmin">
          <label class="form-check-label"
            ><input class="form-check-input" type="radio" v-model="newStatus" :value="-1" />Anulowanie administracyjne<br /><small class="note"
              >(np. obiekt nieczynny)</small
            ></label
          >
        </div>
        <div class="form-check" v-if="isAdmin">
          <label class="form-check-label"
            ><input class="form-check-input" type="radio" v-model="newStatus" :value="-2" />Całkowite usunięcie terminu<br /><small class="note">(np. pomyłka)</small></label
          >
        </div>
      </div>
      <div class="row" v-if="newStatus != -2 && option != 'confirm'">
        <div class="col-md-12">
          <div class="form-group">
            <label v-if="option == 'cancel'" for="surname">Podaj powód anulowania rezerwacji: </label>
            <label v-if="option == 'add'" for="surname">Podaj powód przywrócenia rezerwacji: </label>

            <textarea class="form-control" type="text" v-model="reason"></textarea>
          </div>
        </div>
      </div>
      <div class="form-group" v-if="newStatus != -2 && option != 'confirm'">
        <div class="form-check">
          <label class="form-check-label"><input class="form-check-input" type="checkbox" v-model="sendMail" />Wysłać powiadomienie email</label>
        </div>
      </div>
    </div>
    <div slot="modal-footer">
      <button type="button " class="btn" data-dismiss="modal " @click="close()">Cofnij</button>&nbsp;
      <button v-if="option == 'cancel'" type="button " class="btn btn-danger" data-dismiss="modal " @click="cancelRes()">Anuluj rezerwację</button>
      <button v-if="option == 'add'" type="button " class="btn btn-success" data-dismiss="modal " @click="addRes()">Przywróc rezerwację</button>
      <button v-if="option == 'confirm'" type="button " class="btn btn-primary" data-dismiss="modal " @click="confirmRes()">Potwierdź rezerwację</button>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: ['res', 'show', 'picked', 'option'],
  data() {
    return {
      showLocal: this.$props.show,
      sendMail: true,
      newStatus: 0,
      reason: '',
      isAdmin: false
    };
  },
  mounted() {
    if (this.$isAdmin) {
      this.isAdmin = this.$isAdmin();
    }
  },
  methods: {
    cancelRes() {
      let post = {
        objectListItemId: this.picked,
        status: this.newStatus,
        reason: this.newStatus == -2 ? '' : this.reason,

        sendMail: this.newStatus == -2 ? false : this.sendMail,
        clientId: this.res ? this.res.clientId : 0
      };
      console.log('port', post);
      //return;
      this.$http.post('reservation/changestatus', JSON.stringify(post)).then(
        (response) => {
          if (response && response.body && response.body.status == 1) {
            this.$emit('reload');
            this.$emit('cancel');
            if (this.option == 'cancel') {
              this.$komunikat('success', 'Sukces', 'Pomyślnie anulowano rezerwacje', 3000);
            } else {
              this.$komunikat('success', 'Sukces', 'Pomyślnie przywrócono rezerwacje', 3000);
            }
          } else {
            this.$komunikat('error', 'Błąd', 'Błąd przy zmianie statusu', 3000);
          }
        },
        (response) => {}
      );
    },
    addRes() {
      let post = {
        objectListItemId: this.picked,
        reason: this.reason,
        status: 1,
        clientId: this.res ? this.res.clientId : 0
      };
      this.$http.post('reservation/changestatus', JSON.stringify(post)).then(
        (response) => {
          if (response && response.body && response.body.status == 1) {
            this.$emit('reload');
            this.$emit('cancel');
            this.$komunikat('success', 'Sukces', 'Pomyślnie przywrócono rezerwacje', 3000);
          } else {
            this.$komunikat('error', 'Błąd', 'Błąd przy zmianie statusu', 3000);
          }
        },
        (response) => {}
      );
    },
    confirmRes() {
      let post = {
        objectListItemId: this.picked,
        reason: this.reason,
        status: 1,
        clientId: this.res ? this.res.clientId : 0
      };
      this.$http.post('reservation/changestatus', JSON.stringify(post)).then(
        (response) => {
          if (response && response.body && response.body.status == 1) {
            this.$emit('reload');
            this.$emit('cancel');
            this.$komunikat('success', 'Sukces', 'Pomyślnie potwierdzono rezerwacje', 3000);
          } else {
            this.$komunikat('error', 'Błąd', 'Błąd przy zmianie statusu', 3000);
          }
        },
        (response) => {}
      );
    },
    close() {
      this.$emit('cancel');
    }
  },
  watch: {
    show(newValue) {
      this.showLocal = newValue;
    }
  }
};
</script>

<style></style>
