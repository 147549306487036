<template>
  <!-- Modal -->
  <b-modal v-model="showLocal" @hidden="$emit('cancel')">
    <h5 slot="modal-title">Edycja użytkownika</h5>

    <div slot="modal-header-close">
      <button type="button" class="close" aria-label="Close" @click="$emit('cancel')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="box-body">

            <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label :class="{ 'text-danger': $v.name.$anyError }">Nazwa użytkownika</label>
            <input
              v-model.trim="$v.name.$model"
              class="form-control"
              
              type="text"
              :class="{ 'is-invalid': $v.name.$anyError }"
            />

            
          </div>
        </div>


      </div>

            <div class="row">
        <div class="col-md-12">
          
        <div class="form-check">
          <label class="form-check-label"><input class="form-check-input" type="radio" v-model="objectPermission" value="ALL" />zarządzanie wszystkimi obiektami</label>
        </div>
        <div class="form-check">
          <label class="form-check-label"><input class="form-check-input" type="radio" v-model="objectPermission" value="SELECTED" />zarządzanie wybranymi obiektami</label>
        </div>
        <div v-if="objectPermission!=='ALL'">
          <div class="form-check" v-for="obj in objects" :key="obj.id">
            <label class="form-check-label"><input class="form-check-input" type="checkbox" :checked="hasPerm(obj.id)" @change="changePerm($event,obj.id)"  />{{obj.name}}</label>
          </div>
        </div>


        </div>
      </div>
    </div>
    <!-- Modal-footer -->
    <div slot="modal-footer">
      <button type="button" class="btn btn-default" data-dismiss="modal" @click="$emit('cancel')">Wyjdź</button>&nbsp;

      <button type="button" class="btn btn-info" data-dismiss="modal" @click="save">Zapisz</button>
    </div>
    <!-- /Modal-footer -->
  </b-modal>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';
import datepicker from 'vuejs-datepicker';
import { pl } from 'vuejs-datepicker/dist/locale';

// @vuese
// Modal do zmiany hasła
// @group employees
export default {
  components: { datepicker },
  name: 'appAdd',
  props: {
    // Czy pokazać modal
    show: Boolean,
    // Użytkownik
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      password: '',
      repeatPassword: '',
      name: '',
      pl: pl,
      showLocal: this.show,
      objectPermission: 'ALL',
      objectPermissionList: [],
      objects: []
    };
  },


  methods: {
    // @vuese
    // Zamyka modal
    cancel() {
      this.$emit('cancel');
    },
    // @vuese
    // Zmienia hasło
    save() {
      if (this.$v.$invalid === true) {
        this.$v.$touch();
      } else {
        const post = {
          name: this.name,
          objectPermission: this.objectPermission==='ALL' ? this.objectPermission : JSON.stringify(this.objectPermissionList),
          userId: this.user.id
        };

        this.$http.post('account/changeDetails', JSON.stringify(post), {}).then(res => {
          if (res.data.status === 1) {
            this.$komunikat('success', 'Sukces', 'Zmieniono dane', 3000);
            this.cancel();
            this.user.name = post.name;
            this.user.objectPermission = post.objectPermission;
          } else {
            this.$komunikat('error', 'Błąd', 'Błąd przy zmianie dany', 3000);
          }
        });
      }
    },

    reload() {
      
      this.$http.post('object/find', JSON.stringify({parentId: 0,count: 100}), {}).then(response => {
        
        this.objects = response.data.data.data;
       
      });
      var perm = this.user.objectPermission;
      if (perm==='ALL') {
          this.objectPermission ='ALL'
        } else {
          this.objectPermission = 'SELECTED';
          try {
          this.objectPermissionList = JSON.parse(perm);
          } catch {
            this.objectPermissionList = [];
          }
        }
        this.name = this.user.name;
        /*
      this.$http.post('account/getByEmail', JSON.stringify({email: this.user.email}), {}).then(response => {
        debugger;
        var perm = response.data.objectPermission;
        if (perm==='ALL') {
          this.objectPermission ='ALL'
        } else {
          this.objectPermission = 'SELECTED';
          try {
          this.objectPermissionList = JSON.parse(perm);
          } catch {
            this.objectPermissionList = [];
          }
        }
       
      });
      */
    },
        hasPerm(objId) {
      return this.objectPermissionList.indexOf(objId)>-1; 
    },
    changePerm($event,objId) {
      // debugger;
      var enable = $event.target.checked;
      if (enable) {
        if (!this.hasPerm(objId)) {
          this.objectPermissionList.push(objId);
        }
      } else {
        var index = this.objectPermissionList.indexOf(objId);
        if (index>-1) {
          this.objectPermissionList.splice(index,1);
        }
      }
    }
  },
  validations: {
    name: {

    },
   
  },
  mounted() {
    this.reload();

  },
  watch: {
    showLocal: function(val) {
      if (this.showLocal === false) {
        this.$emit('cancel');
      }
    }
  }
};
</script>
