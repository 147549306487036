<template>
  <div>
    <div class="row">
      <div class="col-4">
        <div class="card card-primary card-outline">
          <div class="card-header">
            <div class="card-title">Rok którego dotyczą statystyki</div>

            <datepicker v-model="year" :language="pl" input-class="form-control" format="yyyy" minimum-view="year" />
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <div class="card card-primary card-outline">
          <div class="card-header">
            <h3 class="card-title">Liczba sprzedanych usług w {{ $route.query.year }} roku</h3>
          </div>
          <div class="card-body p-0">
            <chartjs-line :data="serviceDataLine" :names="months" v-if="serviceDataLine.length"></chartjs-line>
            <loading-icon :full="true" v-else></loading-icon>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="card card-primary card-outline">
          <div class="card-header">
            <h3 class="card-title">Wartość sprzedanych usług w {{ $route.query.year }} roku</h3>
          </div>
          <div class="card-body p-0">
            <chart-cash :data="cashDataLine" :names="months" v-if="serviceDataLine.length"></chart-cash>
            <loading-icon :full="true" v-else></loading-icon>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="card card-primary card-outline">
          <div class="card-header">
            <h3 class="card-title">Ilość wejść na obiekty w ostatnich 30 dniach</h3>

            <b-dropdown :text="objcetSelected.name" variant="info">
              <b-dropdown-item v-for="(object, index) in objectList" :key="index" @click="changeObject(object)">
                {{ object.name }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="card-body p-0">
            <chart-bar :data="objectUsage.data" :labels="objectUsage.labels"></chart-bar>
            <!-- <loading-icon :full="true" v-else></loading-icon> -->
          </div>
        </div>
      </div>

      <div class="col-6">
        <div class="card card-primary card-outline">
          <div class="card-header">
            <h3 class="card-title">Wartość usług według typów w {{ $route.query.year }} roku</h3>
          </div>
          <div class="card-body p-0">
            <chart-bar :data="cashServicesDataLine"></chart-bar>
            <!-- <loading-icon :full="true" v-else></loading-icon> -->
          </div>
        </div>
      </div>

      <div class="col-12" v-if="$config.current.clients && newClientMonths.length && newClientsDataLine.length">
        <div class="card card-primary card-outline">
          <div class="card-header">
            <h3 class="card-title">Nowi klienci miesięcznie w {{ $route.query.year }} roku</h3>

            <b-dropdown :text="serviceSelected.name" variant="info">
              <b-dropdown-item v-for="(service, index) in serviceList" :key="index" @click="changeService(service)">
                {{ service.name }}
              </b-dropdown-item>
            </b-dropdown>
          </div>

          <div class="card-body p-0">
            <chartjsLineNewClients :data="newClientsDataLine" :names="newClientMonths" />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card card-primary card-outline">
          <div class="card-header">
            <h3 class="card-title">Liczba oraz wartość sprzedanych usług w {{ $route.query.year }} roku</h3>
          </div>
          <div class="card-body p-0" v-if="cashDataLine && serviceDataLine">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>Miesiąc</th>
                  <th class="text-right">Liczba sprzedanych usług</th>
                  <th class="text-right">Wartość sprzedanych usług</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in cashDataLine" :key="index">
                  <template v-if="item > 0">
                    <td width="33%">{{ months[index] }}</td>
                    <td width="33%" class="text-right">{{ serviceDataLine[index] }}</td>
                    <td class="text-right">{{ item | money }}</td>
                  </template>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="card card-primary card-outline">
          <div class="card-header">
            <h3 class="card-title">Liczba oraz wartość usług według typów w {{ $route.query.year }} roku</h3>
          </div>
          <div class="card-body p-0" v-if="cashDataLine">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>Nazwa usługi</th>
                  <th class="text-right">Liczba sprzedanych usług</th>
                  <th class="text-right">Wartość sprzedanych usług</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(item, index) in cashServicesDataTable">
                  <template v-if="item.cash > 0 || item.count > 0">
                    <tr :key="'key' + index">
                      <td width="33%">
                        <span class="label-color" :style="{background: colors[index]}"></span>
                        {{ item.name }}
                        <button @click="toggleOpen(item)" class="btn btn-primary btn-sm pull-right">szczegóły</button>
                      </td>
                      <td width="33%" class="text-right">{{ item.count }}</td>
                      <td class="text-right">{{ item.cash | money }}</td>
                    </tr>
                    <tr v-if="item.opened" :key="index">
                      <td colspan="3" class="text-bold text-center">Szczegóły</td>
                    </tr>
                    <tr v-for="(month, monthIndex) in item.months" :key="index + '_' + monthIndex">
                      <template v-if="item.opened && (month.cash > 0 || month.count > 0)">
                        <td>{{ months[monthIndex] }}</td>
                        <td class="text-right">{{ month.count }}</td>
                        <td class="text-right">{{ month.cash | money }}</td>
                      </template>
                    </tr>
                  </template>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import datepicker from 'vuejs-datepicker';
import {pl} from 'vuejs-datepicker/dist/locale';

import chartjsLine from './chartServices.js';
import chartjsLineNewClients from '@/views/charts/chartLine';
import chartCash from './chartCash.js';
import loadingIcon from '../modules/LoadingIcon.vue';
import chartBar from './chartBar.js';

export default {
  components: {chartjsLine, loadingIcon, chartCash, chartBar, datepicker, chartjsLineNewClients},
  data() {
    return {
      year: '',
      newClientMonths: [],
      serviceDataLine: [],
      objcetSelected: {},
      cashDataLine: [],
      cashServicesDataLine: [],
      cashServicesDataTable: [],
      objectUsage: {data: [], labels: []},
      colors: [],
      objectList: [],
      pl,
      newClientsDataLine: [],
      serviceList: [],
      serviceSelected: {},
      months: []
    };
  },
  mounted() {
    this.setYear();
    this.getServiceList();
    this.months = [...this.$months];
  },
  methods: {
    toggleOpen(item) {
      this.cashServicesDataLine.forEach(x => (x != item ? (x.opened = false) : undefined));
      item.opened = !item.opened;
    },
    getCashServices() {
      this.$http
        .get('statistic/cashServices', {
          params: {
            year: this.$route.query.year
          }
        })
        .then(response => {
          let data = response.body.data;
          let tmp = [];

          for (let item of data) {
            let service = null;
            const tmpItem = tmp.filter(o => o.name == item.name);
            if (tmpItem.length) {
              service = tmpItem[0];
            } else {
              service = {
                name: item.name,
                months: [],
                opened: false
              };
              for (let i = 0; i < 12; i++) {
                service.months[i] = {
                  count: 0,
                  cash: 0
                };
              }
              tmp.push(service);
            }
            service.months[item.month - 1].count = item.count;
            service.months[item.month - 1].cash = item.cash;
          }
          for (let item of tmp) {
            item.count = item.months.map(x => x.count).reduce((a, b) => a + b);
            item.cash = item.months.map(x => x.cash).reduce((a, b) => a + b);
          }

          this.cashServicesDataTable = tmp;

          for (let i = 0; i <= tmp.length; i++) {
            this.colors.push(this.getRandomColor());
          }

          let dateSets = [];
          const names = tmp.map(x => x.name);
          const chartdata = tmp.map(x => x.cash);

          for (let i = 0; i < chartdata.length; i++) {
            dateSets.push({
              data: [chartdata[i]],
              label: names[i],
              backgroundColor: this.colors[i]
            });
          }

          this.cashServicesDataLine = dateSets;
        });
    },
    getCash() {
      this.$http
        .get('statistic/cash', {
          params: {
            year: this.$route.query.year
          }
        })
        .then(response => {
          let data = response.body.data;
          let tmp = [];
          for (let i = 1; i <= this.months.length; i++) {
            let value = 0;

            let item = data.filter(x => x.month == i);
            if (item.length) {
              value = item[0].cash;
            }
            tmp.push(value);
          }
          this.cashDataLine = tmp;
        });
    },
    getServiceStats() {
      this.$http
        .get('statistic/services', {
          params: {
            year: this.$route.query.year
          }
        })
        .then(response => {
          let data = response.body.data;
          let tmp = [];

          for (let i = 1; i <= this.months.length; i++) {
            let value = 0;

            let item = data.filter(x => x.month == i);
            if (item.length) {
              value = item[0].count;
            }

            tmp.push(value);
          }

          this.serviceDataLine = tmp;
        });
    },
    // Pobiera statystyki wejść
    getObjcetUsage() {
      this.$http.post('statistic/objcetUsage', {objectId: this.objcetSelected.id}).then(response => {
        const data = response.body.data;
        this.objectUsage.data = [{data: data.map(stat => stat.count), label: 'Ilość wejść', backgroundColor: '#17a2b8'}];
        this.objectUsage.labels = data.map(stat => this.$moment(stat.date).format('DD.MM'));
      });
    },
    // Pobiera liste obiektów
    getObjcetList() {
      this.$http.get('object/find').then(response => {
        this.objectList = response.body.data.data;
        this.objcetSelected = {name: 'Wszystkie obiekty', id: undefined};
        this.objectList.unshift(this.objcetSelected);
      });
    },
    // Zmienia obiekt
    changeObject(object) {
      this.objcetSelected = object;
      this.getObjcetUsage();
    },
    // Tworzy losowy kolor
    getRandomColor() {
      return '#' + (0x1000000 + Math.random() * 0xffffff).toString(16).substr(1, 6);
    },
    // Pobiera wszystkie statystyki
    getAllStats() {
      this.getServiceStats();
      this.getCash();
      this.getCashServices();
      this.getObjcetUsage();
      this.getObjcetList();
      this.getNewClientStats();
    },
    // Ustawia rok
    setYear() {
      this.year = this.$route.query.year ? new Date(this.$route.query.year) : new Date();
    },
    // Pobiera liste usług
    getServiceList() {
      this.$http.get('service/find').then(response => {
        this.serviceList = response.data.data.data;
        this.serviceList.unshift({name: 'Wszystkie usługi', id: undefined});
        this.serviceSelected = this.serviceList[0];
      });
    },
    // Zmiena wybraną usługę
    changeService(service) {
      this.serviceSelected = service;
      this.getNewClientStats();
    },
    // Pobiera statystyki nowych klientów
    getNewClientStats() {
      this.$http
        .post(
          'statistic/stats',
          JSON.stringify({
            cardOwnerServiceId: this.serviceSelected.id,
            cardownersByMonth: true
          }),
          {
            params: {
              year: this.$route.query.year
            }
          }
        )
        .then(response => {
          const cardownersByMonth = response.data.data.cardownersByMonth;
          this.newClientsDataLine = [];
          this.newClientMonths = this.$months;
          for (let i = 0; 12 > i; i++) {
            this.newClientsDataLine.push(cardownersByMonth[i]);
          }
        });
    }
  },
  watch: {
    year(value) {
      this.$router.replace({query: {year: this.year.getFullYear()}});
      this.getAllStats();
    }
  }
};
</script>
