<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card card-danger card-outline">
          <div class="card-header">
            <h3 class="card-title">
              Dodawanie rezerwacji <loading-icon v-if="loading"></loading-icon> &nbsp;
              <button v-if="closeForm == 'close'" class="btn btn-danger float-right" @click="plusButton()">
                <i class="fa fa-plus"></i>
              </button>
              <button v-if="closeForm == 'open'" class="btn btn-danger float-right" @click="closeForm = 'close'">
                <i class="fa fa-minus"></i>
              </button>
            </h3>
            <div class="card-tools">
              <div class="input-group input-group-sm">
                <!-- Search -->
              </div>
            </div>
          </div>
          <div class="card-body p-0" v-bind:class="{closeForm: closeForm != 'open', openForm: closeForm == 'open'}">
            <div>
              <b-form-group style="padding: 10px 30px 0px 30px">
                <b-form-radio-group v-model="option" :options="options" name="radioInline"> </b-form-radio-group>
              </b-form-group>
              <div v-if="option != 'update'" class="row" style="padding: 10px 30px 10px 30px">
                <div class="col-6 szukaj">
                  <label>Podmiot</label>
                  <customSelect
                    v-if="this.$hasRole('admin')"
                    :url="'client/find'"
                    @invalid="invalidClient"
                    @picked="pickUser"
                    :doValidation="true"
                    :itemId="+this.$route.query.clientId"
                  >
                    <template slot-scope="child">{{ child.child.name }}</template>
                  </customSelect>
                  <customSelect
                    v-else-if="this.$hasRole('partner')"
                    :disabled="true"
                    :url="'client/find'"
                    @invalid="invalidClient"
                    @picked="pickUser"
                    :doValidation="true"
                    :id="+this.$store.state.user.id"
                    :itemId="+this.$store.state.user.id"
                  >
                    <template slot-scope="child">{{ child.child.name }}</template>
                  </customSelect>
                </div>
                <div class="col-6 szukaj">
                  <label>Obiekt</label>
                  <customSelect :url="'object/find'" @picked="pickObject" :doValidation="true" :itemId="+this.$route.params.id">
                    <template slot-scope="child">{{ child.child.name }}</template>
                  </customSelect>
                </div>
              </div>
              <div v-if="option == 'update'" class="row" style="padding: 0px 30px 10px 30px">
                <div class="col-6 szukaj">
                  <label>Podmiot</label>
                  <customSelect
                    v-if="this.$hasRole('admin')"
                    :url="'client/find'"
                    @invalid="invalidClient"
                    @picked="pickUser"
                    :doValidation="true"
                    :itemId="+this.$route.query.clientId"
                  >
                    <template slot-scope="child">{{ child.child.name }}</template>
                  </customSelect>
                  <customSelect
                    v-else-if="this.$hasRole('partner')"
                    :disabled="true"
                    :url="'client/find'"
                    @invalid="invalidClient"
                    @picked="pickUser"
                    :doValidation="true"
                    :id="+this.$store.state.user.id"
                    :itemId="+this.$store.state.user.id"
                  >
                    <template slot-scope="child">{{ child.child.name }}</template>
                  </customSelect>
                </div>
                <div class="col-6 szukaj">
                  <label>Rezerwacja</label>
                  <customSelect
                    :url="'reservation/find/'"
                    @invalid="invalidReser"
                    @picked="pickReserv"
                    :id="+this.id"
                    :disabled="disabled"
                    :doValidation="true"
                    :itemId="+reservationId"
                    :resultFields="['reservationName']"
                  >
                    <template slot-scope="child">{{ child.child.reservationName }}</template>
                  </customSelect>
                </div>
              </div>
              <div class="row" style="padding: 0px 30px 10px 30px">
                <div class="col-6">
                  <label> Częstotliwość powtarzania</label>
                  <select class="form-control" name="frequency" id="frequency" v-model="frequency">
                    <option v-for="(pick, index) in picks" :value="pick.id" :key="index">{{ pick.name }}</option>
                  </select>
                </div>
                <div class="col-6 szukaj" v-if="option == 'update'">
                  <label>Obiekt</label>
                  <customSelect :url="'object/find'" @picked="pickObject" :doValidation="true" :itemId="+this.$route.params.id">
                    <template slot-scope="child">{{ child.child.name }}</template>
                  </customSelect>
                </div>
                <div v-if="option != 'update'" class="col-6">
                  <label>Typ rezerwacji</label>
                  <select name="listTypes" class="form-control" v-model="type">
                    <option value="0">Brak</option>
                    <!--
                        <option v-for="(listType,index) in listTypes" :key="index" :value="listType.id" v-if="listType.objectId==objectId || !listType.objectId">
                          {{listType.name}}
                        </option>
                        -->
                    <option v-for="(listType, index) in listTypes" :key="index" :value="listType.id">
                      {{ listType.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div v-if="true || option != 'update'" class="row" style="padding: 10px 30px">
                <div class="col-6">
                  <label :class="{'text-danger': $v.dateFrom.$anyError || $v.dateTo.$anyError}" for="name ">Data rozpoczęcia</label>
                  <datepicker
                    :monday-first="false"
                    v-model.trim="$v.dateFrom.$model"
                    :language="pl"
                    :input-class="{'form-control is-invalid': $v.dateFrom.$anyError || $v.dateTo.$anyError, 'form-control': !($v.dateFrom.$anyError || $v.dateTo.$anyError)}"
                    :full-month-name="true"
                    format="dd.MM.yyyy "
                  ></datepicker>
                  <small v-if="$v.dateFrom.$anyError || $v.dateTo.$anyError" class="text-danger">Proszę poprawnie wpełnić pole</small>
                </div>
                <div v-if="frequency > 0" class="col-6">
                  <label :class="{'text-danger': $v.dateTo.$anyError || $v.dateFrom.$anyError}" for="name">Data zakończenia </label>
                  <datepicker
                    :monday-first="false"
                    v-model.trim="$v.dateTo.$model"
                    :language="pl"
                    :input-class="{'form-control is-invalid': $v.dateTo.$anyError || $v.dateFrom.$anyError, 'form-control': !($v.dateTo.$anyError || $v.dateFrom.$anyError)}"
                    :full-month-name="true"
                    format="dd.MM.yyyy"
                  ></datepicker>
                  <small v-if="$v.dateTo.$anyError || $v.dateFrom.$anyError" class="text-danger">Proszę poprawnie wpełnić pole</small>
                </div>
              </div>
              <div class="row" style="padding: 10px 30px">
                <div class="col-12">
                  <div class="row" style="height: 20px"></div>
                  <div class="row">
                    <label class="col-6" :class="{'text-danger': $v.fromHour.$anyError || $v.fromMinute.$anyError}" for="name">Godzina rozpoczęcia </label>

                    <label class="col-6" :class="{'text-danger': $v.toHour.$anyError || $v.toMinute.$anyError}" for="name">Godzina zakończenia </label>
                  </div>
                  <div class="row text-center">
                    <div class="col-2">
                      <button class="btn plusminus" @click="plusFromHour()">
                        <i class="fa fa-angle-up" aria-hidden="true"></i>
                      </button>
                    </div>
                    <div class="col-1"></div>
                    <div class="col-2">
                      <button class="btn plusminus" @click="plusFromMinute()">
                        <i class="fa fa-angle-up" aria-hidden="true"></i>
                      </button>
                    </div>
                    <div class="col-1"></div>
                    <div class="col-2">
                      <button class="btn plusminus" @click="plusToHour()">
                        <i class="fa fa-angle-up" aria-hidden="true"></i>
                      </button>
                    </div>
                    <div class="col-1"></div>
                    <div class="col-2">
                      <button class="btn plusminus" @click="plusToMinute()">
                        <i class="fa fa-angle-up" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-2">
                      <input :class="{'is-invalid': $v.fromHour.$anyError}" class="form-control text-center" type="text" v-model.trim="$v.fromHour.$model" />
                    </div>
                    <div class="col-1 text-center">:</div>
                    <div class="col-2">
                      <input :class="{'is-invalid': $v.fromMinute.$anyError}" class="form-control text-center" type="text" v-model.trim="$v.fromMinute.$model" />
                    </div>
                    <div class="col-1"></div>
                    <div class="col-2">
                      <input :class="{'is-invalid': $v.toHour.$anyError}" class="form-control text-center" type="text" v-model.trim="$v.toHour.$model" />
                    </div>
                    <div class="col-1 text-center">:</div>
                    <div class="col-2">
                      <input :class="{'is-invalid': $v.toMinute.$anyError}" class="form-control text-center" type="text" v-model.trim="$v.toMinute.$model" />
                    </div>
                  </div>
                  <div class="row text-center">
                    <div class="col-2">
                      <button class="btn plusminus" @click="minusFromHour()">
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                      </button>
                    </div>
                    <div class="col-1"></div>
                    <div class="col-2">
                      <button class="btn plusminus" @click="minusFromMinute()">
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                      </button>
                    </div>
                    <div class="col-1"></div>
                    <div class="col-2">
                      <button class="btn plusminus" @click="minusToHour()">
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                      </button>
                    </div>
                    <div class="col-1"></div>
                    <div class="col-2">
                      <button class="btn plusminus" @click="minusToMinute()">
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer p-0" v-bind:class="{closeForm: closeForm != 'open', openForm: closeForm == 'open'}">
            <div class="mailbox-controls text-right">
              <!-- switch buttons -->
              <button class="btn btn-danger" @click="find()" v-if="id && objectId && validClient && option == 'new'">
                <i class="fa fa-fw fa-search"></i>
                Szukaj wolnych terminów
              </button>
              <button class="btn btn-danger" @click="find()" v-else-if="id && objectId && validClient && option == 'update' && validReser">
                <i class="fa fa-fw fa-search"></i>
                Szukaj wolnych terminów
              </button>
              <button disabled class="btn btn-danger" @click="find()" v-else>
                <i class="fa fa-fw fa-search"></i>
                Szukaj wolnych terminów
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="card card-danger card-outline" v-if="addRez && showAdding">
        <div class="card-header">
          <h3 class="card-title">Wybrane terminy</h3>
        </div>
        <div class="card-body p-0">
          <h5 class="text-center">{{ reserName }}</h5>
          <h6 class="text-center">{{ reserDesc }}</h6>
          <div class="row" style="padding: 2px" v-for="(wybrany, index) in getWybrane()" :key="index">
            <div class="col-6">
              <b>{{ wybrany.date | moment('LL') }}</b>
            </div>
            <div class="col-6">
              <div class="row" style="padding: 2px" v-for="(item, index) in wybrany.items" :key="index">
                <label disabled="disabled" style="color: green">{{ item.start | hminute }} - {{ (item.start + item.length) | hminute }}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="float-right">
            <button id="edit" class="btn btn-danger" @click="edit()"><i class="fa fa-pencil" aria-hidden="true"></i> Edytuj</button>
            <button class="btn btn-danger" @click="save()"><i class="fa fa-fw fa-check"></i> Zatwierdź</button>
          </div>
        </div>
      </div>
    </div>
    <div v-show="response.dates && responseShow" class="row">
      <div class="col-12">
        <div class="card card-danger card-outline">
          <div class="card-header">
            <h3 class="card-title">Lista wolnych terminów</h3>
            <div class="card-tools">
              <button class="btn btn-danger" @click="addreser()"><i class="fa fa-fw fa-plus"></i> Dodaj rezerwacje</button>
            </div>
          </div>
          <div class="card-body p-0">
            <div class="row" style="padding: 1px 8px 0px 8px">
              <div v-if="option != 'update'" class="col-12 form-group">
                <label :class="{'text-danger': $v.reserName.$anyError}" for="surname"> Nazwa rezerwacji</label>
                <input :class="{'is-invalid': $v.reserName.$anyError}" class="form-control" id="surname" type="text" v-model.trim="$v.reserName.$model" />
                <small class="text-danger" v-if="$v.reserName.$anyError">Nazwa rezerwacji wymagana</small>
              </div>

              <div class="col-12 form-group">
                <label :class="{'text-danger': $v.price.$anyError}" for="surname"> Cena jednostkowa</label>
                <input :class="{'is-invalid': $v.price.$anyError}" class="form-control" id="surname" type="text" v-model.trim="$v.price.$model" />
                <small class="text-danger" v-if="$v.price.$anyError">Cena niepoprawna</small>
              </div>

              <div v-if="option != 'update'" class="col-12 form-group">
                <label :class="{'text-danger': $v.reserDesc.$anyError}"> Opis rezerwacji </label>
                <textarea :class="{'is-invalid': $v.reserDesc.$anyError}" class="form-control" type="text" v-model.trim="$v.reserDesc.$model"></textarea>
                <small class="text-danger" v-if="$v.reserDesc.$anyError">Opis nie może być większy niż 500 znaków</small>
              </div>
              <div v-if="response.dates && response.dates.length > 1" class="col-4 form-group">
                <div class="card card-danger card-outline">
                  <div class="card-header">
                    <div class="form-check-inline">
                      <label class="form-check-label">
                        <input type="checkbox" class="form-check-input" v-model="selectAllPick" @click="selectAll(!selectAllPick)" />
                        <b>Zaznacz wszystkie terminy</b>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" style="padding: 8px; padding-top: 0px">
              <template :class="dateWidthClass" v-for="(data, dzien) in response.dates">
                <div :class="dateWidthClass" v-if="data.errors.length == 0" :key="dzien">
                  <div class="card card-danger card-outline">
                    <div class="card-header">
                      <h3 class="card-title">
                        <div :class="[data.errors.length > 0 ? 'text-danger text-bold' : 'text-default text-bold']">{{ data.date | moment('LL') }}</div>
                      </h3>
                    </div>
                    <div class="card-body p-0">
                      <template v-for="(errors, index) in data.errors">
                        <div class="text-danger text-bold" style="padding-left: 30px" v-if="data.errors.length > 0" :key="index">{{ errors }}</div>
                      </template>
                      <div style="border-bottom: 1px solid #ccc; padding-left: 6px; padding-top: 5px" class="checkbox" v-if="!data.errors.length && data.useTimeunits">
                        <label class="checkbox-bootstrap checkbox-lg" style="padding-right: 5px">
                          <!--
                          <input type="checkbox" :id="dzien+ 'checkbox' " @click="reserAll(dzien,data.date,data.items,data.timetableId) ">
                          -->
                          <input type="checkbox" :id="dzien + 'checkbox'" v-model="data.picked" @click="selData(data)" />
                          <span class="checkbox-placeholder"></span>
                          <span v-if="!data.errors.length && data.useTimeunits"> Zaznacz wszystkie</span>
                        </label>
                      </div>
                      <div style="height: 5px"></div>
                      <div style="padding-left: 6px" v-for="item in data.items" :key="item.timeunitId">
                        <div style="display: inline" v-if="item.errors">
                          <label style="padding-right: 5px">
                            <input disabled type="checkbox" v-model="item.picked" />
                          </label>
                          <label style="color: red" :title="item.errors.map((err) => err.info).join('\r\n')">
                            {{ item.start | hminute }} - {{ (item.start + item.length) | hminute }} </label
                          >&nbsp;

                          <i :title="item.errors.map((err) => err.info).join('\r\n')" class="fa fa-question-circle" aria-hidden="true"></i>
                        </div>

                        <div v-else style="display: inline" class="checkbox">
                          <label style="padding-right: 5px" class="checkbox-bootstrap checkbox-lg">
                            <input :id="data.date + '-' + item.timeunitId + 'checkbox'" type="checkbox" v-model="item.picked" />
                            <span class="checkbox-placeholder"></span>
                            <span :id="data.date + '-' + item.timeunitId" title="wolny termin " :style="{color: item.picked ? 'green' : 'black'}">
                              {{ item.start | hminute }} - {{ (item.start + item.length) | hminute }}
                            </span>
                          </label>
                          <i v-if="!item.errors && !data.useTimeunits" style="cursor: pointer" class="fa fa-fw fa-edit" @click="editTimeUnitButton(data, dzien)"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <div class="col-12" v-if="closedDays.length > 0">
                <div>
                  <label class="text-danger"> Zamknięte w dni: </label>
                  <br />
                  <ul>
                    <li v-for="(data, index) in closedDays" :key="index">{{ data.date | moment('LL') }}&nbsp;</li>
                  </ul>
                </div>
              </div>
              <div class="col-12" v-if="emptylist.length > 0">
                <div>
                  <label class="text-danger"> Terminy które nie posiadają harmonogramu: </label>
                  <br />
                  <ul>
                    <li v-for="(data, index) in emptylist" :key="index">{{ data }}&nbsp;</li>
                  </ul>
                </div>
              </div>
              <div class="col-12" v-if="noUnits.length > 0">
                <div>
                  <label class="text-danger"> Terminy które nie posiadają jednostek w harmonogramie: </label>
                  <br />
                  <ul>
                    <li v-for="(data, index) in noUnits" :key="index">{{ data.date | moment('LL') }}&nbsp;</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="success">
      <div class="col-6 text-center">
        <div class="card card-success card-outline">
          <div class="card-header">
            <div class="card-title">Pomyślnie dodano rezerwacje</div>
            <div class="card-body">
              <router-link :to="{name: 'Terms', params: {id: rezerid}}" class="btn btn-success"> <i class="fa fa-fw fa-check"></i> Przejdź do terminów </router-link><br />

              <router-link style="margin-top: 0.4rem" :to="{name: 'Timeline', params: {id: objectId}, query: {date: addedResDate}}" class="btn btn-success">
                <i class="fa fa-fw fa-lg fa-calendar"></i> Pokaż w terminarzu
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <edit-unit v-if="showModal" :show="showModal" v-on:cancel="cancelModal" v-on:save="addNewUnit" v-on:saveAll="addNewUnits" :data="editingDate"></edit-unit>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import loadingIcon from '../modules/LoadingIcon.vue';
import {pl} from 'vuejs-datepicker/dist/locale';
import {setTimeout, clearTimeout} from 'timers';
import customSelect from '../modules/CustomSelect.vue';
import {required, between, numeric, maxLength, minValue, maxValue, withParams} from 'vuelidate/lib/validators';
import editUnit from './EditTimeUnit.vue';
export default {
  validations: {
    dateTo: {
      required,
      withParams(value) {
        return this.dateTo >= this.dateFrom;
      }
    },
    dateFrom: {
      required,
      withParams(value) {
        return new Date(this.dateFrom) <= new Date(this.dateTo);
      }
    },
    fromHour: {
      between: between(0, 23)
    },
    fromMinute: {
      between: between(0, 59)
    },
    toHour: {
      between: between(0, 23)
    },
    toMinute: {
      between: between(0, 59)
    },
    reserName: {
      required
    },
    price: {
      required,
      numeric
    },
    reserDesc: {
      maxLength: maxLength(500)
    }
  },
  components: {
    Datepicker,
    loadingIcon,
    customSelect,
    editUnit
  },

  data() {
    return {
      pl: pl,
      dateWidth: 4,
      dateFrom: new Date(),
      dateTo: new Date(),
      users: '',
      frequency: 0,
      empty: [],
      searchText: '',
      objectId: this.$route.params.id,
      reservation: {},
      listTypes: '',
      type: 0,
      price: '',
      noUnits: [],
      validReser: false,
      validClient: false,
      picks: [
        {name: 'jednorazowo', id: 0},
        {name: 'codziennie', id: 1},
        {name: 'co tydzień', id: 2},
        {name: 'co miesiąc', id: 3},
        {name: 'co poniedziałek', id: 11},
        {name: 'co wtorek', id: 12},
        {name: 'co środe', id: 13},
        {name: 'co czwartek', id: 14},
        {name: 'co piątek', id: 15},
        {name: 'co sobote', id: 16},
        {name: 'co niedziele', id: 17},
        {name: 'w kazdy dzień roboczy pon-pt', id: 18},
        {name: 'w kazdy dzień weekendu so-nd', id: 19}
      ],
      fromHour: '00',
      fromMinute: '00',
      toHour: '00',
      toMinute: '00',
      response: [],
      options: [
        {text: 'Dodaj nową', value: 'new'},
        {text: 'Dodaj do istniejącej', value: 'update'}
      ],
      emptylist: [],
      start: '',
      end: '',
      id: '',
      reserName: '',
      reserDesc: '',
      addRez: '',
      loading: false,
      loadingUsers: false,
      responseShow: true,
      success: false,
      rezerid: null,
      closeForm: 'open',
      option: 'new',
      reservationId: 0,
      closedDays: [],
      showAdding: false,
      addedResDate: '',
      selectAllPick: true,
      showModal: false,
      editingDate: {},
      disabled: true
    };
  },
  computed: {
    dateWidthClass() {
      if (this.response && this.response.dates) {
        if (this.response.dates.length <= 1) {
          return 'col-12';
        } else if (this.response.dates.length == 2) {
          return 'col-6';
        }
        return 'col-4';
      }
    }
  },
  methods: {
    pickUser(user) {
      this.id = user.id;
      this.disabled = false;
      this.reservationId = '';
    },
    invalidReser(validation) {
      if (validation) {
        this.validReser = false;
      } else {
        this.validReser = true;
      }
    },
    invalidClient(validation) {
      if (validation) {
        this.validClient = false;
      } else {
        this.validClient = true;
      }
    },
    pickObject(object) {
      this.objectId = object.id;
    },
    pickReserv(reserv) {
      if (reserv) {
        // debugger;
        // alert('pickReservation: ' + JSON.stringify(reserv));
        this.reservationId = reserv.reservationId;
        //this.objectId = reserv.objectId;
        this.$router.push({
          name: 'ObjectReservations',
          params: {id: this.objectId},
          query: {
            clientId: reserv.clientId,
            type: reserv.reservationTypeId,
            dateFrom: this.$fdate(reserv.startDate),
            dateTo: this.$fdate(reserv.endDate),
            start: this.start,
            end: this.end,
            frequency: 1,
            option: 'update',
            reservationId: reserv.reservationId
          }
        });
        //this.option = "update";
      }
    },
    addNewUnit(newUnit) {
      this.response.dates[newUnit.dzien].items.push({
        length: newUnit.length,
        picked: true,
        start: newUnit.start,
        timeunitId: 0
      });
      this.response.dates[newUnit.dzien].items.splice(0, 1);
      this.showModal = false;
    },
    addNewUnits(newUnits) {
      for (let i = 0; this.response.dates.length > i; i++) {
        if (this.response.dates[i].timetableId == newUnits.timetableId) {
          this.response.dates[i].items.push({
            length: newUnits.length,
            picked: true,
            start: newUnits.start,
            timeunitId: 0
          });
          this.response.dates[i].items.splice(0, 1);
        }
      }
      this.showModal = false;
    },
    plusButton() {
      this.success = false;
      this.closeForm = 'open';
      this.responseShow = false;
      this.response.dates = [];
      this.selectAllPick = true;
    },
    editTimeUnitButton(data, dzien) {
      this.showModal = true;
      data.dzien = dzien;
      this.editingDate = data;
    },
    cancelModal() {
      this.showModal = false;
    },
    changeclass() {
      var select = document.getElementById('selectid');
      select.className = 'col-12 displaytrue';
    },
    changeclassback() {
      var select = document.getElementById('selectid');
      setTimeout(function () {
        select.className = 'col-12 notshow';
      }, 300);
    },
    selData(data) {
      if (data.picked) {
        //this.$set(data,"picked",false);
        data.picked = false;
      } else {
        //this.$set(data,"picked",true);
        data.picked = true;
      }
      for (let i = 0; i < data.items.length; i++) {
        //this.$set(data.items[i],"picked",data.picked);
        if (!data.items[i].errors) data.items[i].picked = data.picked;
      }
    },
    getWybrane() {
      let dates = [];
      for (let i = 0; i < this.response.dates.length; i++) {
        let date = this.response.dates[i];
        let items = [];
        for (let j = 0; j < date.items.length; j++) {
          let item = date.items[j];
          // item.date = this.$fdate(date.items[j].date);
          if (item.picked) {
            items.push(item);
          }
          //date.items[j].picked=sel;
        }
        if (items.length > 0) {
          dates.push({
            //date: this.$fdate(date.date),
            date: date.date,
            items: items,
            timetableId: date.timetableId
          });
        }
      }
      return dates;
    },

    save() {
      let post;
      this.showAdding = false;
      let dates = this.getWybrane();
      this.addedResDate = dates[0].date;

      if (this.$v.price.numeric == false) {
        this.$v.$touch();
        return;
      }

      //if ((""+this.price)=="") {
      //  alert("nie wypełniono ceny, jeśli nie chcesz podawać wpisz 0");
      //  return;
      //}

      if (this.option == 'update' || this.reservationId) {
        post = {
          clientId: parseInt(this.id),
          objectId: parseInt(this.objectId),
          price: this.price,
          description: this.reserDesc,
          name: this.reserName,

          reservationTypeId: this.type,
          dates: dates,
          id: parseInt(this.reservationId)
        };
      } else {
        if (this.option != 'update') {
          post = {
            clientId: parseInt(this.id),
            objectId: parseInt(this.objectId),
            price: this.price,
            name: this.reserName,
            description: this.reserDesc,
            reservationTypeId: this.type,
            dates: dates
          };
        }
      }

      this.$http.post('reservation/add', JSON.stringify(post), {}).then(
        (response) => {
          if (response && response.body && response.body.status == 1) {
            this.rezerid = response.data.data.id;
            this.addRez = false;
            this.success = true;
            //this.post.id = response.data.id;
            this.reload();
            // if (this.option == "update") {
            //   this.$router.push({ name: "Terms", params: { id: this.reservationId } });
            // }
            this.$komunikat('success', 'Sukces', 'Pomyślnie dodane terminy: ' + response.body.data.count, 3000);
            if (response.body.data.failed != 0) {
              this.$komunikat('error', 'Błąd', 'Nie udało się dodać terminów: ' + response.body.data.failed, 3000);
            }
          } else {
            this.$komunikat('error', 'Błąd', 'Błąd przy dodawaniu rezerwacji', 3000);
          }
        },
        (response) => {}
      );
    },
    addreser() {
      if (this.$v.price.$invalid) {
        this.$v.price.$touch();
        return;
      }
      let wybrane = this.getWybrane();
      if (this.$v.$invalid == true && this.option != 'update') {
        this.$v.$touch();
      } else {
        if (wybrane.length > 0) {
          this.showAdding = true;
          this.addRez = true;
          this.responseShow = false;
        }
      }
    },
    edit() {
      this.addRez = false;
      this.responseShow = true;
    },

    selectAll(sel) {
      for (let i = 0; i < this.response.dates.length; i++) {
        let date = this.response.dates[i];
        //date.picked=sel;
        let allpicked = true;
        for (let j = 0; j < date.items.length; j++) {
          if (!date.items[j].errors) {
            this.$set(date.items[j], 'picked', sel);
          } else {
            this.$set(date.items[j], 'picked', false);
            allpicked = false;
          }
          //date.items[j].picked=sel;
        }
        if (allpicked) {
          this.$set(date, 'picked', sel);
        }
      }
    },
    reload() {
      this.loading = true;
      this.$http.post('reservation/findfreeterms', JSON.stringify(this.reservation), {}).then((response) => {
        this.response = response.data.data;
        this.noUnits = [];
        this.empty = this.response.dates.filter(function (el) {
          return el.errors.includes('brak harmonogramu');
        });
        for (let i = 0; this.response.dates.length > i; i++) {
          let date = this.response.dates[i];

          if (date.useTimeunits == false) {
            //this.response.dates[i].editTimeUnit = false;
            date.minStart = date.items[0].start;
            date.maxEnd = date.items[0].start + date.items[0].length;
          }
          for (let j = 0; date.errors.length > j; j++) {
            if (date.errors[j].indexOf('brak jednostek') != -1) {
              this.noUnits.push(date);
            }
          }
        }
        this.closedDays = this.response.dates.filter(function (el) {
          return el.closed == true;
        });
        var sekunda = 1000;
        var minuta = 60 * sekunda;
        var godzina = 60 * minuta;
        var dzien = 24 * godzina;
        var liczba = 0;
        for (var i = 0; i < this.empty.length - 1; i++) {
          var data = new Date(this.empty[i].date).getTime();
          if (new Date(this.empty[i + 1].date).getTime() - data == dzien) {
            liczba++;
            if (i == this.empty.length - 2) {
              var start = data - dzien * liczba;
              var end = data;
              this.emptylist.push(this.$moment(new Date(start)).format('LL') + '-' + this.$moment(new Date(end)).format('LL'));
            }
          } else {
            var start = data - dzien * liczba;
            liczba = 0;
            var end = data;
            if (start != end) {
              this.emptylist.push(this.$moment(new Date(start)).format('LL') + '-' + this.$moment(new Date(end)).format('LL'));
            } else {
              this.emptylist.push(this.$moment(new Date(start)).format('LL'));
            }
          }
        }
        if (this.empty.length == 1) {
          this.emptylist.push(this.$moment(new Date(this.$fdate(this.empty[0].date || this.dateFrom))).format('LL'));
        }

        this.selectAll(true);

        this.loading = false;
      });
    },
    reloadLink() {
      this.id = this.$route.query.clientId;
      this.dateFrom = this.$route.query.dateFrom;
      this.start = this.$route.query.start;
      this.dateTo = this.$route.query.dateTo;
      this.end = this.$route.query.end;
      this.frequency = this.$route.query.frequency;
      this.type = this.$route.query.type;
      this.option = this.$route.query.option;
      this.reservationId = this.$route.query.reservationId;
      // if (this.reservationId) {
      //   alert('reservationId' + this.reservationId);
      // }
      if (this.end > 0) {
        if (this.end % 60 == 0) {
          this.toHour = this.end / 60;
        } else {
          this.toHour = (this.end - (this.end % 60)) / 60;
        }
        this.toMinute = this.end % 60;
        if (this.toMinute == 0) {
          this.toMinute = '00';
        }
      }
      if (this.start > 0) {
        if (this.start % 60 == 0) {
          this.fromHour = this.start / 60;
        } else {
          this.fromHour = (this.start - (this.start % 60)) / 60;
        }
        this.fromMinute = this.start % 60;
        if (this.fromMinute == 0) {
          this.fromMinute = '00';
        }
      }
      if (!this.dateFrom) {
        this.dateFrom = new Date();
      }
      if (!this.type) {
        this.type = 0;
      }
      if (!this.dateTo) {
        this.dateTo = new Date();
      }
      if (!this.start && this.start !== 0) {
        this.fromHour = 8;
        this.fromMinute = '00';
      }
      if (!this.end && this.end !== 0) {
        this.toHour = 20;
        this.toMinute = '00';
      }
      if (!this.frequency) {
        this.frequency = 0;
      }
      if (!this.option) {
        this.option = 'new';
      }
    },
    find() {
      this.emptylist = [];
      this.start = this.fromHour * 60 + parseInt(this.fromMinute);
      this.end = this.toHour * 60 + parseInt(this.toMinute);
      if (this.frequency == 0) {
        this.dateTo = this.dateFrom;
      }
      this.reservation = {
        objectId: parseInt(this.objectId),
        type: this.type,
        startDate: this.dateFrom,
        endDate: this.dateTo,
        startFullDate: this.dateFrom,
        endFullDate: this.dateTo,
        repeat: this.frequency,
        start: this.start,
        end: this.end
      };

      this.reload();
      this.responseShow = true;
      this.$router.push({
        name: 'ObjectReservations',
        params: {id: this.objectId},
        query: {
          clientId: this.id,
          type: this.type,
          dateFrom: this.$fdate(this.dateFrom),
          start: this.start,
          dateTo: this.$fdate(this.dateTo),
          end: this.end,
          frequency: this.frequency,
          option: this.option,
          reservationId: this.reservationId
        }
      });
      this.closeForm = 'close';
    },
    plusFromHour() {
      if (this.fromHour < 23) {
        this.fromHour = parseInt(this.fromHour) + 1;
        if (this.fromHour < 10) {
          this.fromHour = this.fromHour;
        }
      } else {
        this.fromHour = '0';
      }
    },
    minusFromHour() {
      if (this.fromHour > 0) {
        this.fromHour = parseInt(this.fromHour) - 1;
        if (this.fromHour < 10) {
          this.fromHour = this.fromHour;
        }
      } else {
        this.fromHour = 23;
      }
    },
    plusFromMinute() {
      if (this.fromMinute < 55) {
        this.fromMinute = parseInt(this.fromMinute) + 5;
        if (this.fromMinute < 10) {
          this.fromMinute = '0' + this.fromMinute;
        }
      } else {
        this.fromMinute = '00';
      }
    },
    minusFromMinute() {
      if (this.fromMinute > 0) {
        this.fromMinute = parseInt(this.fromMinute) - 5;
        if (this.fromMinute < 10) {
          this.fromMinute = '0' + this.fromMinute;
        }
      } else {
        this.fromMinute = 55;
      }
    },
    plusToHour() {
      if (this.toHour < 23) {
        this.toHour = parseInt(this.toHour) + 1;
        if (this.toHour < 10) {
          this.toHour = this.toHour;
        }
      } else {
        this.toHour = '0';
      }
    },
    minusToHour() {
      if (this.toHour > 0) {
        this.toHour = parseInt(this.toHour) - 1;
        if (this.toHour < 10) {
          this.toHour = this.toHour;
        }
      } else {
        this.toHour = 23;
      }
    },
    plusToMinute() {
      if (this.toMinute < 55) {
        this.toMinute = parseInt(this.toMinute) + 5;
        if (this.toMinute < 10) {
          this.toMinute = '0' + this.toMinute;
        }
      } else {
        this.toMinute = '00';
      }
    },
    minusToMinute() {
      if (this.toMinute > 0) {
        this.toMinute = parseInt(this.toMinute) - 5;
        if (this.toMinute < 10) {
          this.toMinute = '0' + this.toMinute;
        }
      } else {
        this.toMinute = 55;
      }
    }
  },
  created() {
    this.reloadLink();
    this.loadingUsers = true;
    // this.$http.get("client/find").then(response => {
    //   this.users = response.data.data.data;
    //   this.loadingUsers = false;
    // });
    this.$http.get('reservation/listtypes').then((response) => {
      this.listTypes = response.data.data;
    });
  },
  watch: {
    $route(to, from) {
      this.objectId = this.$route.params.id;
      this.reloadLink();
    },
    option: function (val) {
      if (this.option == 'update') {
        this.reloadLink();
        this.option = 'update';
      } else {
      }
    }
  }
};
</script>

<style scoped>
.plusminus {
  background-color: transparent;
}
.lol {
  background-color: lightblue;
}
.notshow {
  display: none;
}

.displaytrue {
  display: block;
}
.szukaj:hover + .notshow {
  display: block;
}
#frequency {
  margin-bottom: 10px;
}
#edit {
  margin-right: 0.5em;
}
.openForm {
  display: block;
}
.closeForm {
  display: none;
}
label span {
  font-size: 17px;
}
h3.card-title {
  font-size: 1.05rem;
}
</style>