<template>
  <div style="cursor: pointer" :title="itemText" class="calitem" @click="clickItem" v-bind:style="itemPosition" v-if="res.length && !res.hidden">
    <div v-bind:style="itemColor" v-if="res.status != 3 || partnerCheck">
      <small v-if="selectMode && !res.group && partnerCheck && !enoughDaysBefore" class="text-danger">Nie można anulować</small>
      <div v-if="minuteHeight > 1 || height > 55" class="small">{{ itemText }}</div>
      <span class="no-print" v-if="res.group || res.cols"><i class="fa fa-ellipsis-v"></i>&nbsp; </span>
      <input v-if="showCheckbox" type="checkbox" :checked="res.selected" style="margin-right: 3px" />
      <i class="fa fa-fw fa-credit-card" title="opłacona" v-if="paid && partnerCheck"></i>
      <span @click.prevent="$emit('changePrice')" v-if="showPrice && res.price != undefined && partnerCheck" class="price" :class="{zero: res.price == 0}">{{(res.price || 0) | money}}</span
      ><span>{{ res.clientName }} </span>
      <div class="small" v-if="res.status == 3">Wstępna rezerwacja</div>
      <span class="small no-print">{{ res.reservationName }}</span>
      <span v-if="res.light" class="small" title="oświetlenie">&nbsp; <i class="fa fa-fw fa-lightbulb-o"></i></span>
      <span v-if="res.personCount" class="small">&nbsp; <i class="fa fa-fw fa-user"></i>{{ res.personCount }}</span>
    </div>
    <div style="" v-if="res.status == 3">
      <small>Wstępna rezerwacja {{ res.status }}</small>
    </div>
  </div>
</template>

<script>
export default {
  props: ['res', 'startMinute', 'minuteHeight', 'childs', 'selectMode', 'showPrice'],
  computed: {
    height() {
      return this.minuteHeight * this.res.length - 1;
    },
    showCheckbox() {
      return this.selectMode && !this.res.group && !this.res.cols && this.partnerCheck && this.enoughDaysBefore;
    },
    itemPosition() {
      let twidth = 100;
      let tleft = 0;
      let width = 100;
      if (this.res.totalrow) {
        twidth = 100 / this.res.totalrow;
        tleft = (this.res.rowlp - 1) * twidth;
        if (this.res.rowlp == this.res.totalrow) {
          width = this.childs * 100 + twidth;
        } else {
          width = twidth;
        }
      } else {
        if (this.res.width) {
          width = this.res.width * 100;
        } else {
          width = this.childs * 100 + twidth;
        }
      }
      return {
        top: (this.res.start - this.startMinute) * this.minuteHeight + 'px',
        height: this.height + 'px',
        width: width + '%',
        left: tleft + '%'
      };
    },
    itemColor() {
      return {
        backgroundColor: this.$rgba(this.res.clientColor, 0.1),
        borderLeftColor: this.res.clientColor
      };
    },
    itemText() {
      return this.$hminute(this.res.start) + ' - ' + this.$hminute(this.res.start + this.res.length);
    },
    partnerCheck() {
      if (!this.$hasRole('admin')) {
        return this.res.clientId === this.$store.state.user.id;
      }
      return true;

    },
    enoughDaysBefore() {
      if (!this.$hasRole('admin')) {
        return new Date(this.res.date) >= this.$addDay(new Date(), this.$store.state.user.minDaysBeforeCancellation);
      }
      return true;
    },
    paid() {
      return this.res.resPrice && (this.res.resPrice === this.res.resPayPrice)
    }

  },
  /*
  watch: {
    "res.selected": function() {
      if (this.selectMode) {
      if (this.res.selected) {
        this.$refs.itemSelect.checked=true;

      } else {
        this.$refs.itemSelect.checked=false;

      }
      }


    }
  },
  */
  methods: {
    selectItem(value) {
      if (this.selectMode && this.partnerCheck && this.enoughDaysBefore) {
        this.$emit('select');
      }
    },
    clickItem(event) {
      // debugger;
      if (this.selectMode) {
        if (this.showCheckbox) {
          this.selectItem();
        }
        return;
      }
      //console.log(event.target);
      //if (event.target.tagName=="INPUT") {

      //return false;
      //}
      if (this.res.group || this.res.cols) {
        this.$emit('click');
      } else if (this.partnerCheck) {
        if (this.selectMode) {
          if (this.enoughDaysBefore) {
            this.$emit('select');
          }
        } else {
          this.$emit('click');
        }
      }
      //event.preventDefault();
      return false;
    },


  }
};
</script>
