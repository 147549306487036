import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import App from './App.vue';
import router from './router';
import store from './store';
// import './registerServiceWorker';
import VueResource from 'vue-resource';
import { HttpOptions, HttpResponse, $http, $resource, HttpHeaders } from 'vue-resource/types/vue_resource';
import { Http } from 'vue-resource/types/vue_resource';
import methods from './methods';
import axios from 'axios';
import config from './config';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import { NotificationOptions } from 'vue-notification';
import Notifications from 'vue-notification';
import websocket from '@/websocket';
//@ts-ignore
import Vuelidate from 'vuelidate';
//const Vuelidate = require("vuelidate").default;

//import io from "socket.io";
Vuelidate(Vue);
Vue.use(Notifications);

document.title = config.current.title;

//Vue.use(Vuelidate);
Vue.use(BootstrapVue);
Vue.use(VueResource);
Vue.config.productionTip = false;
import * as moment from 'moment';
//const moment = require("moment");
import 'moment/locale/pl';
//require("moment/locale/pl");
//@ts-ignore
import * as vuemoment from 'vue-moment';
var app: any = null;
//Vue.use(require("vue-moment"), {
Vue.use(vuemoment, {
  moment
});
declare module 'vue/types/vue' {
  interface VueConstructor {
    http: {
      options: HttpOptions & { root: string };
      interceptors: any[];
      get: $http;
    };
    notify: (options: NotificationOptions | string) => void;
    fdate: (date?: Date) => string;
    $appVersion: string
  }
}

var app: any;

//Vue.http.options.root = "http://localhost:30001/api";
//Vue.http.options.root = "http://ngo.witkac.pl:30001/api";
Vue.prototype.$appVersion = "1.8.1"
Vue.http.options.root = config.current.api;

//Vue.http.options.root = "http://localhost:30004/api";  //local sosir
//https://github.com/pagekit/vue-resource/blob/develop/docs/http.md#interceptors
Vue.http.interceptors.push(function (this: any, request: any) {
  let apikey = '' + localStorage.getItem('apikey');
  if (apikey && apikey != 'null') {
    request.params.apikey = apikey; // dodanie apikey do query
  }
  //disable cache
  request.params.ts = (new Date()).getTime();

  // modify method
  //request.method = 'POST';
  // modify headers
  //request.headers.set('X-CSRF-TOKEN', 'TOKEN');
  //request.headers.set('Authorization', 'Bearer TOKEN');
  return (response: any) => {
    if (response.status === 200) {
      //tu sprawdz websocket

      if (request.method === 'POST' && request.body) {
        let data = JSON.parse(request.body);
        if (data && data.draw && data.draw != 1) {
          //na pierwszy nie raeguje
          let tmp: any = {};
          for (var item in data) {
            if (item !== 'draw') {
              tmp[item] = data[item];
            }
          }
          if (data.draw == 1) {
            //this.$router.replace({ query: { ...this.$route.query, ...tmp } });
            router.replace({ query: { ...this.$route.query, ...tmp } });
          } else {
            //this.$router.push({ query: { ...this.$route.query, ...tmp } });
            router.push({ query: { ...this.$route.query, ...tmp } });
          }
        }
      }
    } else if (/*(response.status === 403) ||*/ response.status === 401) {
      websocket.disconnect();
      let loginUrl = '/login';
      if (!config.current.clientLogin) loginUrl = '/login/admin';

      localStorage.removeItem('apikey');

      let q: any = {};
      if (app.$router.history.current.fullPath.indexOf('login') == -1) {
        q.redirect = app.$router.history.current.fullPath;
      }

      router.push({
        path: loginUrl,
        query: q
      });
    }
  };
});

/*
let apikey = localStorage.getItem("apikey");
if (apikey) {
  if (!websocket.isConnected()) {
    websocket.start(apikey);
  }
}
*/

//configure axios
/*
axios.defaults.baseURL = 'http://ngo.witkac.pl:30001/api';

axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  let apikey = localStorage.getItem("apikey");
  if (apikey) {
    
    if (!config.params) {
      config.params = {};
    }
    config.params.apikey = apikey; // dodanie apikey do query
  }
  return config;

}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
  // Do something with response data
 

  if (response.status === 200) {
    if (response.request.method === "POST" && response.request.body) {
      let data = JSON.parse(response.request.body);
      if (data && data.draw) {
        let tmp: any = {};
        for (var item in data) {
          if (item !== "draw") {
            tmp[item] = data[item];
          }
        }
        router.push({ query: { ...app.$route.query, ...tmp } });
      }
    }
  } else if (response.status === 403) {
    localStorage.removeItem("apikey");
    router.push({
      path: "/login",
      query: {
        redirect: app.$route.history.current.fullPath
      }
    });
  }  
  return response;
}, function (error) {
  // Do something with response error
  return Promise.reject(error);
});
*/

Vue.use(methods);

/*
//format date
let fdate = Vue.prototype.$fdate = function(date? : Date) {
  if (!date) date = new Date();
  var result = "" + date.getFullYear() + "-";
  let m = date.getMonth() + 1;
  if (m < 10) result += "0";
  result += m + "-";
  let d = date.getDate();
  if (d < 10) result += "0";
  return result + d;

}
Vue.filter('fdate', function (value : any) {
  if (!value) return ''
  return fdate(value);
})
*/

app = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
