<template>
  <div class="clientitem">
    <div class="mailbox-name">
      <i class="fa fa-square fa-fw" :style="{color: user.color}"></i>
      <b>
        <router-link :to="{name: 'UsersProfile', params: {id: this.user.id}}" style="color: black"> {{ user.name }}</router-link>
      </b>
      <span v-if="user.nameFull && user.nameFull != user.name" style="margin-left: 10px;">{{ user.nameFull }}</span>
    </div>
    <div>
      <span class="clientspan" v-if="user.firstname"> <i class="fa fa-user fa-fw"></i> {{ user.firstname }} {{ user.surname }} </span>
      <span class="clientspan" v-if="user.phone"> <i class="fa fa-phone fa-fw"></i> {{ user.phone }} </span>
      <span class="clientspan" v-if="user.email"> <i class="fa fa-envelope fa-fw"></i> {{ user.email }}</span>
    </div>
    <div class="itembuttons align-items-center">
      <div class="btn-group">
        <router-link title="Rezerwacje" :to="{name: 'ClientReservations', params: {id: user.id}}" class="btn btn-warning ">
          <i class="fa fa-fw fa-lg fa-address-book"></i>
        </router-link>
        <router-link title="Raporty" :to="{name: 'ClientRaports', params: {id: user.id}}" class="btn btn-warning">
          <i class="fa fa-fw fa-lg fa-bars "></i>
        </router-link>
      </div>
      &nbsp;
      <div class="btn-group">
        <button type="button" title="Edytuj" class="btn btn-primary btn-sm" @click="$emit('edit')">
          <i class="fa fa-fw  fa-edit "></i>
        </button>
        <button title="Usuń" class="btn btn-danger btn-sm" @click="$emit('delete')">
          <i class="fa fa-fw   fa-trash"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userid: 0
    };
  },
  props: ['user']
};
</script>

<style></style>
