<template>
  <div>
    <div class="row">
      <div class="col-lg-3 col-6" v-if="$config.current.clients">
        <!-- small box -->
        <div class="small-box bg-info bg-info-gradient">
          <div class="inner">
            <h3>{{ klientow }}</h3>

            <p>Klientów</p>
          </div>
          <div class="icon">
            <i class="fa fa-user fa-fw"></i>
          </div>

          <router-link :to="{name: 'clients'}" class="nav-link small-box-footer">
            Zobacz więcej
            <i class="fa fa-arrow-circle-right"></i>
          </router-link>
        </div>
      </div>
      <!-- ./col -->
      <div class="col-lg-3 col-6">
        <!-- small box -->
        <div class="small-box bg-success bg-success-gradient">
          <div class="inner">
            <h3>{{ obiektow }}</h3>

            <p>Obiektów</p>
          </div>
          <div class="icon">
            <i class="fa fa-sitemap"></i>
          </div>
          <router-link to="/objects/0/ " class="nav-link small-box-footer">
            Zobacz więcej
            <i class="fa fa-arrow-circle-right"></i>
          </router-link>
        </div>
      </div>
      <!-- ./col -->
      <div class="col-lg-3 col-6">
        <!-- small box -->
        <div class="small-box bg-warning bg-warning-gradient">
          <div class="inner">
            <h3>{{ podmiotow }}</h3>

            <p>Podmiotów</p>
          </div>
          <div class="icon">
            <i class="fa fa-users"></i>
          </div>
          <router-link to="/users" class="nav-link small-box-footer">
            Zobacz więcej
            <i class="fa fa-arrow-circle-right"></i>
          </router-link>
        </div>
      </div>
      <!-- ./col -->
      <div class="col-lg-3 col-6">
        <!-- small box -->
        <div class="small-box bg-danger bg-danger-gradient">
          <div class="inner">
            <h3>{{ rezerwacji }}</h3>

            <p>Rezerwacji</p>
          </div>
          <div class="icon">
            <i class="fa fa-address-book"></i>
          </div>
          <router-link to="/reservations" class="nav-link small-box-footer">
            Zobacz więcej
            <i class="fa fa-arrow-circle-right"></i>
          </router-link>
        </div>
      </div>
      <div class="col-lg-3 col-6">
        <!-- small box -->
        <div class="small-box bg-primary bg-primary-gradient">
          <div class="inner">
            <h3>{{ oczekujacych }}</h3>

            <p>Oczekujących</p>
          </div>
          <div class="icon">
            <i class="fa fa-clock-o"></i>
          </div>

          <router-link to="/reservations/list?reservationStatus=3" class="nav-link small-box-footer">
            Zobacz więcej
            <i class="fa fa-arrow-circle-right"></i>
          </router-link>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6" v-if="$config.current.clients && months && dataLine">
        <div class="card card-primary card-outline">
          <div class="card-header">
            <h3 class="card-title">Nowi klienci miesięcznie w {{ new Date().getFullYear() }} roku</h3>

            <b-dropdown :text="serviceSelected.name" variant="info">
              <b-dropdown-item v-for="(service, index) in serviceList" :key="index" @click="changeService(service)">
                {{ service.name }}
              </b-dropdown-item>
            </b-dropdown>
          </div>

          <div class="card-body p-0">
            <chartjs-line :data="dataLine" :names="months"></chartjs-line>
          </div>
        </div>
      </div>
      <div class="col-6" v-if="rezerwacjiActive || rezerwacjiNotActive">
        <div class="card card-danger card-outline">
          <div class="card-header">
            <h3 class="card-title">Rezerwacje</h3>
          </div>
          <div class="card-body p-0">
            <chartjs-doughnut :data="dataDoughnut" :colors="colorsDoughnut" :names="namesDoughnut"></chartjs-doughnut>
          </div>
        </div>
      </div>
    </div>

    <!-- ./col -->
  </div>
</template>

<script>
import VueCharts from 'vue-chartjs';
import chartjsDoughnut from './charts/chartDonut.js';
import chartjsLine from './charts/chartLine.js';

export default {
  components: {chartjsDoughnut, chartjsLine},
  data() {
    return {
      klientow: 0,
      obiektow: 0,
      podmiotow: 0,
      rezerwacji: 0,
      oczekujacych: 0,
      rezerwacjiActive: '',
      rezerwacjiNotActive: '',
      namesDoughnut: ['Rezerwacje aktywne', 'Rezerwacje odwołane'],
      namesLine: [],
      dataDoughnut: [],
      cardownersByMonth: [],
      dataLine: [],
      serviceList: [],
      serviceSelected: {},

      months: [],
      colorsDoughnut: ['#28a745', '#dc3545']
    };
  },
  methods: {
    donutReload() {
      this.dataDoughnut = [this.rezerwacjiActive, this.rezerwacjiNotActive];
    },
    chartlineReload() {
      this.dataLine = [];
      this.months = this.$months;
      for (let i = 0; 12 > i; i++) {
        this.dataLine.push(this.cardownersByMonth[i]);
      }
    },
    // Pobiera liste usług
    getServiceList() {
      this.$http.get('service/find').then((response) => {
        this.serviceList = response.data.data.data;
        this.serviceList.unshift({name: 'Wszystkie usługi', id: undefined});
        this.serviceSelected = this.serviceList[0];
      });
    },
    // Pobiera statystyki
    getStats() {
      this.$http
        .post(
          'statistic/stats',
          JSON.stringify({
            allCardOwners: true,
            allObjects: true,
            allReservations: true,
            cardOwnerServiceId: this.serviceSelected.id,
            allReservationsActive: true,
            allReservationsCancelled: true,
            allReservationsNotConfirmed: true,
            allClients: true,
            cardownersByMonth: true
          }),
          {}
        )
        .then((response) => {
          this.klientow = response.data.data.allCardOwners;
          this.obiektow = response.data.data.allObjects;
          this.rezerwacji = response.data.data.allReservations;
          this.podmiotow = response.data.data.allClients;
          this.rezerwacjiActive = response.data.data.allReservationsActive;
          this.rezerwacjiNotActive = response.data.data.allReservationsCancelled;
          this.cardownersByMonth = response.data.data.cardownersByMonth;
          this.oczekujacych = response.data.data.allReservationsNotConfirmed;
          this.donutReload();
          this.chartlineReload();
        });
    },
    // Zmienia usługe
    changeService(service) {
      this.serviceSelected = service;
      this.getStats();
    }
  },
  created() {
    this.getServiceList();
    this.getStats();
  }
};
</script>
