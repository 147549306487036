<template>
  <b-modal v-model="showLocal" @hidden="$emit('cancel')">
    <h5 slot="modal-title">Utwórz konto</h5>
    <div slot="modal-header-close">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$emit('cancel')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="box-body text-center">
      <h1 style="font-size: 70px;" class="text-center">
        <i class="fa fa-warning text-warning" aria-hidden="true"></i>
      </h1>
      Czy na pewno utworzyć konto na podstawie danych użytkownika {{ data.firstName }} {{ data.lastName }}?
    </div>
    <!-- Modal-footer -->
    <div slot="modal-footer">
      <button type="button" class="btn btn-default" data-dismiss="modal" @click="$emit('cancel')">Nie</button>&nbsp;
      <b-button id="save" type="button" class="btn btn-info" data-dismiss="modal" @click="save()">Tak</b-button>
    </div>
    <!-- /Modal-footer -->
  </b-modal>
</template>
<script>
import {required, numeric} from 'vuelidate/lib/validators';

export default {
  props: {
    show: Boolean,
    data: Object
  },

  data() {
    return {
      showLocal: this.show,
      form: {...this.data},
      name: this.data.name,
      price: this.data.price,
      count: this.data.count,
      daysLength: this.data.daysLength,
      type: this.data.type || 'DEFAULT'
    };
  },
  validations: {
    name: {
      required
    },
    price: {
      numeric
    },
    count: {
      numeric
    },
    daysLength: {
      numeric
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    save() {
      let link = '&link=' + window.location.origin + '/account/activation';
      this.$http.get('account/fromcardowner/?cardOwnerId=' + this.data.id + link).then(response => {
        if (response && response.body && response.body.status == 1) {
          this.$komunikat('success', 'Sukces', 'Pomyślnie utworzono konto', 3000);
          this.$emit('reload');
          this.$emit('cancel');
        } else {
          this.$komunikat('error', 'Błąd', 'Błąd przy tworzeniu konta', 3000);
        }
      });
    }
  },
  watch: {
    showLocal: function(val) {
      if (this.showLocal == false) {
        this.$emit('cancel');
      }
    }
  }
};
</script>
<style></style>
