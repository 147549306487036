<template>
  <div>
    <footer class="main-footer">
      <strong>Copyright &copy; 2018 - 2020 witkac sp. z o.o.</strong>
      <div class="float-right d-none d-sm-inline-block"><b>Version</b> 2.0 (2021-04-19)</div>
    </footer>

    <!-- Control Sidebar -->
    <aside class="control-sidebar control-sidebar-dark">
      <!-- Control sidebar content goes here -->
    </aside>
    <div id="sidebar-overlay" @click.prevent="$emit('sbtoggle')"></div>
    <!-- /.control-sidebar -->
  </div>
</template>

<script>
export default {
  name: 'Footer'
};
</script>

<style></style>
