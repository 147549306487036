import {Bar} from 'vue-chartjs';

export default {
  extends: Bar,
  props: {
    // Dane do wyświetlenia
    data: {
      type: Array,
      required: true
    },
    min: {
      // Minimalna wartość na wykresie
      type: Number,
      required: false,
      default: 0
    },
    // Dokładność
    stepSize: {
      type: Number,
      required: false,
      default: undefined
    },
    // Podpisy
    labels: {
      type: Array,
      required: false,
      default: undefined
    }
  },
  data() {
    return {};
  },

  mounted() {},
  watch: {
    data: function() {
      this.renderChart(
        {
          labels: this.labels,
          datasets: this.data
        },
        {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            yAxes: [
              {
                ticks: {
                  stepSize: this.stepSize,
                  min: this.min
                }
              }
            ]
          }
        }
      );
    }
  }
};
