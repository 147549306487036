<template>
  <!-- Modal -->
  <b-modal v-model="showLocal" @hidden="$emit('cancel')">
    <div slot="modal-title">Edytuj rezerwację</div>

    <div class="modal-body">
      <div class="box box-success min-height-1">
        <div class="box-body">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="name">Nazwa rezerwacji: </label>
                <input class="form-control" id="name" type="text" v-model="reservationName" />
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label for="type">Typ rezerwacji:</label>
                <select class="form-control" name="type" v-model="reservationTypeId">
                  <option v-for="(type, index) in types" :value="type.id" :key="index"> {{ type.name }}</option>
                </select>
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-group">
                <label for="name">Opis: </label>
                <textarea class="form-control text-area" id="name" type="text" v-model="description"></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div slot="modal-footer">
      <button type="button " class="btn btn-default" data-dismiss="modal " @click="showLocal = false">Wyjdź</button>&nbsp;
      <button type="button " class="btn btn-warning " v-if="res.status == 1" data-dismiss="modal " @click="anulujReser()">Anuluj rezerwacje</button>&nbsp;
      <button type="button " class="btn btn-warning " v-if="res.status == 0" data-dismiss="modal " @click="wznowReser()">Przywróć rezerwacje</button>&nbsp;
      <button type="button " class="btn btn-danger " data-dismiss="modal" @click="deleteButton()">Usuń</button>&nbsp;
      <button type="button " class="btn btn-success " data-dismiss="modal " @click="saveButton()">Zapisz</button>&nbsp;
    </div>
  </b-modal>
</template>

<script>
export default {
  props: ['res', 'show'],
  data() {
    return {
      showLocal: this.show,
      reservationName: this.res.reservationName,
      reservationTypeId: this.res.reservationTypeId,
      description: this.res.reservationDescription,
      types: []
    };
  },

  methods: {
    getTypes() {
      this.$http.post('reservationtype/find', {}).then(response => {
        if (response && response.body && response.body.status == 1) {
          this.types = response.data.data.data;
        }
      });
    },
    anulujReser() {
      this.$http
        .post('reservation/changereservationstatus', {
          reason: this.description,
          reservationId: this.res.reservationId,
          status: 0
        })
        .then(response => {
          if (response && response.body && response.body.status == 1) {
            this.$emit('reload');
            this.$emit('cancel');
            this.$komunikat('success', 'Sukces', 'Pomyślnie anulowano rezerwacje', 3000);
          } else {
            this.$komunikat('error', 'Błąd', 'Błąd przy zmianie statusu', 3000);
          }
        });
    },
    wznowReser() {
      this.$http
        .post('reservation/changereservationstatus', {
          reason: this.description,
          reservationId: this.res.reservationId,
          status: 1
        })
        .then(response => {
          if (response && response.body && response.body.status == 1) {
            this.$emit('reload');
            this.$emit('cancel');
            this.$komunikat('success', 'Sukces', 'Pomyślnie przywrócono rezerwacje', 3000);
          } else {
            this.$komunikat('error', 'Błąd', 'Błąd przy zmianie statusu', 3000);
          }
        });
    },
    deleteButton() {
      this.$emit('del');
    },
    saveButton() {
      let post = {
        id: this.res.reservationId,
        name: this.reservationName,
        description: this.description,
        reservationTypeId: this.reservationTypeId
      };
      this.$http.post('reservation/update', JSON.stringify(post)).then(response => {
        if (response && response.body && response.body.status == 1) {
          this.$emit('reload');
          this.$emit('cancel');
          this.$komunikat('success', 'Sukces', 'Pomyślnie edytowano rezerwacje', 3000);
        } else {
          this.$komunikat('error', 'Błąd', 'Błąd przy edycji rezerwacji', 3000);
        }
      });
    }
  },

  watch: {
    showLocal: function(val) {
      if (this.showLocal == false) {
        this.$emit('cancel');
      }
    }
  },
  mounted() {
    this.getTypes();
  }
};
</script>

<style></style>
