<template>
  <div class="row">
    <div class="col">
      <div class="card card-success card-outline">
        <!-- card-header -->
        <div class="card-header">
          <h3 class="card-title">
            <i class="fa fa-fw fa-calendar"></i>Harmonogramy obiektu
            <button v-if="hasPerm" type="button" class="btn btn-success btn-sm float-right" @click="addButton()"><i class="fa fa-fw fa-plus"></i> Dodaj</button>
          </h3>
        </div>
        <!-- /.card-header -->
        <div class="card-body" style="overflow: hidden">
          <div v-if="!owntimetables.length > 0" class="alert alert-success">Brak harmonogramów</div>

          <ul class="todo-list">
            <li v-for="tt in owntimetables" :key="'tt' + tt.id">
              <div>
                <span class="text">{{ tt.name }}</span>
                <span>&nbsp; - &nbsp;</span>

                <span class="badge badge-success">{{ tt.typeIdent | ttype }}</span>
                <div class="tools">
                  <i title="Jednostki czasu" v-if="hasPerm && tt.useTimeunits && !tt.closed" class="fa fa-bars" @click="unitsButton(tt)"></i>
                  <i v-if="hasPerm" title="Edytuj" class="fa fa-edit" @click="editButton(tt)"></i>
                  <i v-if="hasPerm" title="Usuń" class="fa fa-trash-o" @click="deleteButton(tt)"></i>
                </div>
              </div>
              <small>{{ tt.startDate | fdate }} - {{ tt.endDate | fdate }}</small>
              <small v-if="tt.end"> &nbsp; <i class="fa fa-fw fa-clock-o"></i>{{ tt.start | hminute }} - {{ tt.end | hminute }}</small>

              <small v-else-if="tt.closed" class="text-danger"> &nbsp; Zamknięte</small>
              <small v-else-if="!tt.end && !tt.closed" class="text-danger"> &nbsp; Brak harmonogramu</small>
            </li>
          </ul>
        </div>
      </div>
      <div class="card card-success card-outline">
        <!-- card-header -->
        <div class="card-header">
          <h3 class="card-title"><i class="fa fa-fw fa-calendar"></i>Harmonogramy dziedziczone</h3>
        </div>
        <!-- /.card-header -->
        <div class="card-body" style="overflow: hidden">
          <div v-if="!parenttimetables.length > 0" class="alert alert-success">Brak dziedziczonych harmonogramów</div>

          <ul class="todo-list">
            <li v-for="tt in parenttimetables" :key="'tt' + tt.id">
              <div>
                <span class="text">{{ tt.name }} &nbsp; </span>
                <span class="badge badge-success">{{ tt.typeIdent | ttype }}</span>
                <div class="tools">
                  <i v-if="tt.useTimeunits && !tt.closed" class="fa fa-bars" @click="unitsButton(tt)"></i>
                  <i v-if="hasPerm" class="fa fa-edit" @click="editButton(tt)"></i>
                  <i v-if="hasPerm" class="fa fa-trash-o" @click="deleteButton(tt)"></i>
                </div>
              </div>
              <small>{{ tt.startDate | fdate }} - {{ tt.endDate | fdate }}</small>
              <small v-if="tt.end"> &nbsp; <i class="fa fa-fw fa-clock-o"></i>{{ tt.start | hminute }} - {{ tt.end | hminute }}</small>
              <small v-else-if="tt.closed" class="text-danger"> &nbsp; Zamknięte</small>
              <small v-else class="text-danger"> &nbsp; brak harmonogramu</small>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col"></div>
    <app-delete v-if="hasPerm && showDelete" :show="showDelete" :object="editTimetable" @cancel="cancel()" v-on:delete="del"></app-delete>
    <app-edit v-if="hasPerm && showEdit" :show="showEdit" :object="editTimetable" @cancel="cancel()" v-on:reload="reload" :option="option" :objectIdAdd="objectId"></app-edit>
  </div>
</template>

<script>
import appDelete from '../../modules/Delete.vue';
import appEdit from './Edit.vue';
export default {
  data() {
    return {
      objectId: this.$route.params.id,
      timetables: [],
      showDelete: false,
      showEdit: false,

      editTimetable: {},
      option: 'edit',
      startDate: new Date(),
      enDate: new Date()
    };
  },
  components: {
    appDelete,
    appEdit
  },
  computed: {
    owntimetables() {
      return this.timetables.filter((x) => x.objectId == this.objectId);
    },
    parenttimetables() {
      return this.timetables.filter((x) => x.objectId != this.objectId);
    },
    hasPerm() {
      var result = this.$hasPerm(+this.objectId);
      console.log('calc hasPerm', result);
      return result;
    }
  },

  mounted() {
    this.objectId = this.$route.params.id;

    this.reload();
  },
  watch: {
    $route(to, from) {
      this.objectId = this.$route.params.id;
      this.reload();
    }
  },

  methods: {
    fixHours(units) {
      if (units.length > 0) {
        let minminute = Math.floor(units[0].start / 60) * 60;
        if (minminute < this.startMinute) this.startMinute = minminute;
        let maxminute = Math.floor((units[units.length - 1].start + units[units.length - 1].length) / 60) * 60;
        if (maxminute > this.endMinute) this.endMinute = maxminute;
        this.totalHeight = (this.endMinute - this.startMinute) * this.minuteHeight + 20;
        this.hours = [];
        for (let j = this.startMinute; j <= this.endMinute; j += 60) {
          this.hours.push(j);
        }
      }
    },
    reload() {
      // this.hasPerm = this.$hasPerm(+this.objectId);
      this.$http.get('timetable/list/' + this.objectId + '?units=true').then((response) => {
        this.timetables = response.data.data;
        this.startMinute = 600;
        this.endMinute = 1200;
        // this.hasPerm = this.$hasPerm(+this.objectId);
        //let today = new Date();
        //this.startDate=new Date();
        //this.endDate=new Date(this.startDate.getFullYear(),12,31);
        let startDate = null;
        let endDate = null;

        for (let i = 0; i < this.timetables.length; i++) {
          try {
            let d1 = new Date(this.timetables[i].startDate);
            if (startDate == null) startDate = d1;
          } catch (err) {}
          try {
            let d2 = new Date(this.timetables[i].endDate);
            if (endDate == null) endDate = d2;
          } catch (err) {}

          if (this.timetables[i].units == null) {
            this.$http.get('timetable/listunits/' + this.timetables[i].id).then((response) => {
              let units = response.data.data;
              this.timetables[i].units = units;
              this.fixHours(units);
            });
          } else {
            this.fixHours(this.timetables[i].units);
          }
        }
        if (startDate != null) {
          this.startDate = startDate;
        }
        if (endDate) {
          this.endDate = endDate;
        }
      });
    },
    nextMonth() {
      this.currentMonth++;
      if (this.currentMonth > 11) {
        this.currentMonth = 0;
        this.currentYear++;
      }
      this.reload();
    },
    prevMonth() {
      this.currentMonth--;
      if (this.currentMonth < 0) {
        this.currentMonth = 11;
        this.currentYear--;
      }
      this.reload();
    },
    today() {
      this.currentMonth = new Date().getMonth();
      this.currentYear = new Date().getFullYear();
      this.reload();
    },
    cancel() {
      this.showDelete = false;
      this.showEdit = false;
    },
    del(id) {
      this.$http.get('timetable/delete/' + id).then(
        (response) => {
          if (response && response.body && response.body.status == 1) {
            this.reload();
            this.showDelete = false;
            this.$komunikat('success', 'Sukces', 'Pomyślnie usunięto harmonogram', 3000);
          } else {
            this.$komunikat('error', 'Błąd', 'Błąd przy usuwaniu harmonogramu', 3000);
          }
        },
        (response) => {}
      );
    },
    deleteButton(tt) {
      this.editTimetable = tt;
      this.showDelete = true;
    },
    editButton(tt) {
      tt.startDate = new Date(tt.startDate);
      tt.endDate = new Date(tt.endDate);
      this.editTimetable = tt;
      this.showEdit = true;
      this.option = 'edit';
    },
    addButton() {
      this.editTimetable = {useTimeunits: true, startDate: this.startDate, endDate: this.endDate};
      this.showEdit = true;
      this.option = 'false';
    },
    unitsButton(tt) {
      this.$router.push({name: 'Timeunits', params: {id: tt.id}});
    }
  }
};
</script>

<style>
.HarmsItemBox {
  border: 1px solid #dedede;
  border-radius: 2px;
  margin-right: 5px;
  font-weight: 400;
  margin-bottom: 8px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.12);
  transition: all 0.2s;
}
</style>
