<template>
  <!-- Modal -->
  <b-modal v-model="showLocal" @hidden="$emit('cancel')">
    <center>
      <i id="warningIcon" class="fa fa-warning text-danger"></i><br />
      <h1>Usuwanie</h1>
      <br />
      <span v-if="object.reservations">{{ object.name }} posiada {{ object.reservations }} rezerwacji.<br /> </span> Czy na pewno chcesz usunąć {{ object.name }}?
    </center>

    <div slot="modal-footer">
      <button type="button " class="btn" data-dismiss="modal " @click="close()">Anuluj</button>&nbsp;
      <button type="button " class="btn btn-danger" data-dismiss="modal " @click="deleteObject()">Usuń</button>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: ['object', 'show'],
  data() {
    return {
      showLocal: this.show
    };
  },
  methods: {
    deleteObject() {
      this.$emit('delete', this.object.id);
    },
    close() {
      this.$emit('cancel');
    }
  },
  watch: {
    showLocal: function(val) {
      if (this.showLocal == false) {
        this.$emit('cancel');
      }
    }
  }
};
</script>

<style>
#warningIcon {
  font-size: 70px;
}
</style>
