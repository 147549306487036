<template>
  <!-- Modal -->
  <b-modal v-model="showLocal" @hidden="close()">
    <div slot="modal-title" v-if="res">Rezerwacja: {{ res.reservationName }}</div>

    <div slot="modal-header-close">
      <button type="button" class="close" aria-label="Close" @click="close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="box-body" v-if="res">
      <label>Zmień cenę {{ res.start | hminute }} - {{ (res.start + res.length) | hminute }} w obiekcie {{ res.objectName }}</label>
      <div class="form-group">
        <div class="form-check">
          <label class="form-check-label"><input class="form-check-input" type="radio" v-model="changeType" :value="0" />Tylko to wystąpienie</label>
        </div>
        <div class="form-check">
          <label class="form-check-label"
            ><input class="form-check-input" type="radio" v-model="changeType" :value="3" />Wszystkie dni <b>{{ res.date | dayOfWeek }}</b> od <b>{{ res.date }}</b> do przodu<br /><small
              class="form-text text-muted"
              >{{ info[3] }}</small
            ></label
          >
        </div>
        <div class="form-check">
          <label class="form-check-label"
            ><input class="form-check-input" type="radio" v-model="changeType" :value="4" />Wszystkie dni <b>{{ res.date | dayOfWeek }}</b> w ramach rezerwacji<br /><small
              class="form-text text-muted"
              >{{ info[4] }}</small
            ></label
          >
        </div>
        <div class="form-check">
          <label class="form-check-label"
            ><input class="form-check-input" type="radio" v-model="changeType" :value="1" />Wszystkie dni od <b>{{ res.date }}</b> do przodu<br /><small
              class="form-text text-muted"
              >{{ info[1] }}</small
            ></label
          >
        </div>
        <div class="form-check">
          <label class="form-check-label"
            ><input class="form-check-input" type="radio" v-model="changeType" :value="2" />Wszystkie dni w ramach rezerwacji<br /><small class="form-text text-muted">{{
              info[2]
            }}</small></label
          >
        </div>
      </div>
      <div class="form-group">
        <label :class="{'text-danger': $v.price.$invalid}" for="name">Nowa cena: </label>
        <input class="form-control" :class="{'is-invalid': $v.price.$invalid}" v-model.trim="$v.price.$model" type="text" />
        <small v-if="$v.price.numeric == false" class="text-danger">Proszę poprawnie wpełnić pole</small>
        <small v-if="$v.price.required == false" class="text-danger">Cena nie może być pusta</small>
      </div>
      <!--
      <div  class="form-group">
        <div class="form-check">
           <label class="form-check-label"><input class="form-check-input" type="checkbox" v-model="sendMail" />Wysłać powiadomienie email</label>
           </div>
      </div>    
-->
    </div>
    <div slot="modal-footer">
      <button type="button " class="btn" data-dismiss="modal " @click="close()">Anuluj</button>&nbsp;
      <button type="button " class="btn btn-success" data-dismiss="modal " @click="submit()">Zmień ceny</button>
    </div>
  </b-modal>
</template>

<script>
import {numeric, required} from 'vuelidate/lib/validators';

export default {
  props: ['res', 'show'],
  validations: {
    price: {
      required,
      numeric
    }
  },

  data() {
    return {
      showLocal: this.$props.show,
      sendMail: false,
      changeType: 0,
      price: this.$props.res ? this.$props.res.price || 0 : 0,
      info: []
    };
  },
  methods: {
    submit() {
      if (this.$v.price.$invalid == true) {
        this.$v.$touch();
        return;
      }

      let post = {
        objectReservationId: this.res.id,
        price: this.price,
        changeType: this.changeType,
        sendMail: this.sendMail
      };
      console.log('post', post);
      //return;
      this.$http.post('reservation/changeprice', JSON.stringify(post)).then(
        response => {
          if (response && response.body && response.body.status == 1) {
            this.$emit('reload');
            this.$emit('cancel');
            this.$komunikat('success', 'Sukces', 'Pomyślnie zmieniono cenę ' + response.data.data + ' rezerwacji', 3000);
          } else {
            this.$komunikat('error', 'Błąd', 'Błąd przy zmianie statusu', 3000);
          }
        },
        response => {}
      );
    },
    close() {
      this.$emit('cancel');
    }
  },
  watch: {
    show(newValue) {
      this.showLocal = newValue;
      this.info = [];
      this.changeType = 0;
      this.price = this.res ? this.res.price || 0 : 0;
    },
    changeType(newValue) {
      if (newValue > 0 && this.info[newValue] === undefined) {
        //if (this.forwardInfo == "") {
        this.$http.get('reservation/repeatCount/' + this.res.id + '?type=' + newValue).then(response => {
          if (response && response.body && response.body.status == 1) {
            this.$set(this.info, newValue, 'Wystąpień do zmiany: ' + response.body.data);
          } else {
            this.$set(this.info, newValue, '');
          }
        });
        //}
      }
    }
  }
};
</script>

<style></style>
