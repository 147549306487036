<template>
  <b-modal v-model="showLocal" @hidden="$emit('cancel')">
    <h5 slot="modal-title">Edytuj usługę</h5>

    <div slot="modal-header-close">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$emit('cancel')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="box-body">
      <service v-if="model && serviceList && serviceList.length" :service-list="serviceList" :model="model" :edit="true" />
    </div>

    <div slot="modal-footer">
      <button type="button" class="btn btn-default" data-dismiss="modal" @click="$emit('cancel')">Anuluj</button>&nbsp;

      <b-button id="save" type="button" class="btn btn-info" data-dismiss="modal" @click="save()">Zapisz</b-button>
    </div>
  </b-modal>
</template>
<script>
import service from './Service.vue';
export default {
  components: {service},
  props: {
    show: Boolean,
    serviceList: Array,
    id: Number
  },
  data() {
    return {
      showLocal: this.show,
      model: undefined
    };
  },
  mounted() {
    this.$http.get('cardowner/service/' + this.id).then(response => {
      let data = response.body.data;
      this.model = {
        id: data.id,
        cardOwnerId: data.cardOwnerId,
        count: data.count,
        price: data.price,
        debit: data.debit,
        dateStart: data.dateStart,
        dateEnd: data.dateEnd,
        serviceId: data.serviceId
      };
    });
    // this.model = {};
  },
  methods: {
    save() {
      this.$http.post('cardowner/editService/' + this.model.id, this.model).then(response => {
        this.$emit('reload');
      });
    }
  }
};
</script>
